import { useContext, useCallback } from 'react';
import { ModalContext } from '../modal';
import {
  DeleteConfirmation,
  type DeleteConfirmationProps,
} from './delete-confirmation';

type ConfirmOptions = {
  against?: string;
} & Pick<
  DeleteConfirmationProps,
  'title' | 'onDelete' | 'body' | 'confirmButtonLabel' | 'placeholder' | 'type'
>;

export default function useDeleteConfirmation() {
  const { showModal, closeModal } = useContext(ModalContext);

  const confirm = useCallback(
    (options: ConfirmOptions) => {
      const {
        title = 'Are you sure?',
        against,
        onDelete,
        body,
        confirmButtonLabel,
        placeholder,
        type,
      } = options;

      showModal(
        <DeleteConfirmation
          title={title}
          confirmationText={against}
          onDelete={async () => {
            if (onDelete) {
              await onDelete();
              closeModal();
            } else {
              closeModal();
            }
          }}
          onCancel={closeModal}
          body={body}
          confirmButtonLabel={confirmButtonLabel}
          placeholder={placeholder}
          type={type}
        />,
        { className: 'sm:max-w-lg sm:w-full' }
      );
    },
    [closeModal, showModal]
  );

  return { confirm, close: closeModal };
}
