import { EmailIcon, MobileIcon, OptionCard } from '@pandler/shared/react-ui';

interface TemplateSelectionProps {
  onSelect?: (type: 'email' | 'sms') => void;
}

const noop = () => {
  //
};

export function CreateTypeSelection({
  onSelect = noop,
}: TemplateSelectionProps) {
  return (
    <>
      <div className="text-center bg-primary-700 text-white py-5">
        <h1 className="text-lg antialiased font-semibold">
          Create a new template
        </h1>
      </div>

      <div className="mt-8 mb-8 mx-12">
        <div className="flex flex-col divide-y divide-gray-200/75 space-y-10">
          <div>
            <p className="text-sm text-center mb-4 text-gray-500">
              Which type of template would you like to create?
            </p>

            <div className="grid grid-cols-2 gap-6">
              <OptionCard
                icon={<EmailIcon className="h-full" />}
                title="Email"
                description="Sent from your business email directly to your customers inbox."
                onClick={() => onSelect('email')}
              />

              <OptionCard
                icon={<MobileIcon className="h-full" />}
                title="SMS"
                description="Sent from a Pandler mobile number directly to your customers phones."
                onClick={() => onSelect('sms')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
