import * as React from 'react';

export function EventsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 36 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 11c0 3-3.134 7-7 7s-7-4-7-7h14ZM25 37c0-4-3.134-7-7-7s-7 3-7 7h14Z"
        fill="#4F46E5"
      />
      <path
        d="M32 11v-1c0-1.103.897-2 2-2s2-.897 2-2V2c0-1.103-.897-2-2-2H2C.897 0 0 .897 0 2v4c0 1.103.897 2 2 2s2 .897 2 2v1c0 5.894 3.666 10.939 8.834 13C7.666 26.061 4 31.106 4 37v1c0 1.103-.897 2-2 2s-2 .897-2 2v4c0 1.103.897 2 2 2h32c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2s-2-.897-2-2v-1c0-5.894-3.666-10.939-8.834-13C28.334 21.939 32 16.894 32 11ZM2 2h32l-.003 4H2V2Zm31.997 44H2v-4h32l-.003 4ZM30 37v1c0 .732.212 1.409.556 2H5.443a3.959 3.959 0 0 0 .556-2v-1c0-6.28 4.851-11.438 11-11.949V27h2v-1.949C25.149 25.562 30 30.72 30 37ZM19 22.949V21h-2v1.949C10.851 22.438 6 17.279 6 11v-1c0-.732-.212-1.409-.556-2h25.113a3.959 3.959 0 0 0-.556 2v1C30 17.28 25.149 22.438 19 22.949Z"
        fill="#303033"
      />
    </svg>
  );
}
