import { type InvoiceWithCustomer } from '@pandler/shared/data-types';
import { TableSize } from '@pandler/shared/react-ui';
import { createCurrencyFormatter } from '@pandler/shared/utils';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { InvoiceStatus } from '../invoice-status';
import { OptionsButton } from './options-button';

const columnHelper = createColumnHelper<InvoiceWithCustomer>();

export const columns = [
  columnHelper.accessor('number', {
    size: TableSize['w-2/12'],
    header: 'Invoice ID',
    cell: (info) => <span className="font-semibold">{info.getValue()}</span>,
  }),
  columnHelper.accessor('issuedDate', {
    size: TableSize['w-2/12'],
    header: 'Issue date',
    cell: (info) => dayjs(info.getValue()).format('DD/MM/YYYY'), // @todo user settings
  }),
  columnHelper.accessor('customer.name', {
    size: TableSize['w-2/12'],
    header: 'Customer',
  }),
  columnHelper.accessor('dueDate', {
    size: TableSize['w-2/12'],
    header: 'Due date',
    cell: (info) => dayjs(info.getValue()).format('DD/MM/YYYY'), // @todo user settings
  }),
  columnHelper.accessor('total', {
    header: 'Amount',
    size: TableSize['w-2/12'],
    cell: (info) =>
      createCurrencyFormatter({
        currency: info.row.original?.currency ?? 'USD',
      })(info.getValue()),
  }),
  columnHelper.accessor('status', {
    size: TableSize['w-1/12'],
    cell: (info) => <InvoiceStatus status={info.getValue()} />,
  }),
  columnHelper.accessor('currency', {
    header: '',
    size: TableSize['w-1/12'],
    cell: ({ row }) => <OptionsButton invoice={row.original} />,
  }),
];
