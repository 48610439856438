import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { Button, LoadingIcon } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { useState } from 'react';

export interface SendButtonProps {
  onSend?: () => Promise<void>;
}

export function SendButton({ onSend }: SendButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    if (onSend) {
      setIsLoading(true);
      await onSend();
      setIsLoading(false);
    }
  };

  return (
    <Button
      disabled={isLoading}
      onClick={handleOnClick}
      className={classNames('transition-all duration-300', {
        'w-24': !isLoading,
        'w-12 px-2': isLoading,
      })}
    >
      {isLoading ? (
        <LoadingIcon className="w-5 h-5 text-white mx-auto" strokeWidth={2} />
      ) : (
        <>
          <PaperAirplaneIcon className="w-4 h-4 mr-1.5" />
          Send
        </>
      )}
    </Button>
  );
}
