import { Helmet } from 'react-helmet-async';
import { ChartContainer } from '@pandler/shared/react-ui';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { useDashboard } from '@pandler/cloud/shared';
import { Chart } from '@pandler/shared/charts-ui';
import { HeroAnalytics } from '../../components/hero-analytics';
import { RecentActivity } from '../../components/recent-activity';
import { InvoiceSummaryOverview } from '../../components/invoice-summary-overview';
import { EntityTabs } from '../../components/entity-tabs';

export function DashboardPage() {
  const { data } = useDashboard({ suspense: true });

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <Content center>
        {data && (
          <>
            <h4 className="mb-sm-0 text-xl font-semibold grow text-gray-800 dark:text-gray-100 mb-4">
              Dashboard
            </h4>

            <section className="flex flex-col space-y-10">
              <HeroAnalytics data={data} />

              <div className="grid md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4 mb-4">
                <div className="sm:col-span-12  md:col-span-12 lg:col-span-8 xl:col-span-8">
                  <ChartContainer title="Summary">
                    <Chart
                      title="Last 30 days"
                      {...data.charts.activitySummary}
                    />
                  </ChartContainer>
                </div>
                <div className="sm:col-span-12  md:col-span-12 lg:col-span-4 xl:col-span-4">
                  <InvoiceSummaryOverview data={data} />
                </div>
              </div>

              <div className="grid md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4 mb-4">
                <div className="sm:col-span-12  md:col-span-12 lg:col-span-5 xl:col-span-4 ">
                  <RecentActivity />
                </div>
                <div className="sm:col-span-12  md:col-span-12 lg:col-span-7 xl:col-span-8 ">
                  <EntityTabs />
                </div>
              </div>
            </section>
          </>
        )}
      </Content>
    </>
  );
}

export default withAuthenticationRequired(DashboardPage);
