import { useSearchPlatformTemplate } from '@pandler/cloud/shared';
import { InlineError, LoadingContainer } from '@pandler/shared/react-ui';
import type { Templates } from '@pandler/shared/templates';
import { TemplateSelection } from '../template-selection';

export interface PlatformTemplatesSelectionProps {
  type: Templates[number]['type'];
  category?: Templates[number]['category'][number];
  filter?: string;
}

export function PlatformTemplatesSelection({
  type,
  category,
  filter,
}: PlatformTemplatesSelectionProps) {
  const { data, isLoading, error } = useSearchPlatformTemplate({
    type,
    category: category ? [category] : undefined,
    filter,
  });

  if (error) {
    return <InlineError>{error.message}</InlineError>;
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  return <TemplateSelection templates={data} />;
}
