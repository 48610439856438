import Markdown from 'react-markdown';
import {
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';
import type { SystemActivity } from '@pandler/shared/data-types';
import {
  EmptyState,
  IconFeed,
  VoidIcon,
  type IconFeedInputArguments,
} from '@pandler/shared/react-ui';
import { formatShortDate } from '@pandler/shared/utils';
import { useMemo } from 'react';

export interface ActivityFeedProps {
  className?: string;
  items: SystemActivity[];
}

const categoryToTheme = (
  category: SystemActivity['category']
): Pick<IconFeedInputArguments['items'][number], 'icon' | 'iconClass'> => {
  switch (category) {
    case 'message':
      return {
        icon: EnvelopeIcon,
        iconClass: 'bg-amber-500',
      };
    case 'payment':
      return {
        icon: CurrencyDollarIcon,
        iconClass: 'bg-green-500',
      };
    default:
      return {
        icon: ClipboardDocumentListIcon,
        iconClass: 'bg-primary-500',
      };
  }
};

const toIconItem = ({
  id,
  content,
  createdAt,
  category,
}: SystemActivity): IconFeedInputArguments['items'][number] => ({
  id,
  ...categoryToTheme(category),
  content: <Markdown>{content}</Markdown>,
  dateTime: formatShortDate(createdAt),
});

export function ActivityFeed({ className = '', items }: ActivityFeedProps) {
  const $items = useMemo(() => items.map(toIconItem), [items]);

  return (
    <div className={className}>
      {$items.length > 0 ? (
        <IconFeed items={$items} />
      ) : (
        <EmptyState
          icon={VoidIcon}
          iconClassName="h-48"
          title="Nothing to show"
          body="Connect your accounting platform to get started."
        />
      )}
    </div>
  );
}
