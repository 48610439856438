import type { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import type { ErrorScreenProps } from '../error-screen';

export interface ErrorPageProps {
  title: string;
  screen: ReactElement<ErrorScreenProps>;
  hideLinks?: boolean;
}

export function ErrorPage({
  screen,
  title,
  hideLinks = false,
}: ErrorPageProps) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-6xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          {screen}
        </main>
        {!hideLinks && (
          <footer className="flex-shrink-0 max-w-6xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <a
                href="https://pandler.io/support"
                className="text-sm font-medium text-gray-500 hover:text-gray-600"
              >
                Contact Support
              </a>
              <span
                className="inline-block border-l border-gray-300"
                aria-hidden="true"
              />
              <a
                href="https://twitter.com/pandlerio"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="text-sm font-medium text-gray-500 hover:text-gray-600"
              >
                Twitter
              </a>
            </nav>
          </footer>
        )}
      </div>
    </>
  );
}
