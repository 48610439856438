import { DelayUntil, type Day, type When } from '@pandler/shared/data-types';
import {
  Button,
  DayPicker,
  FieldGroupFieldError,
  FormGroup,
  Input,
  Select,
} from '@pandler/shared/react-ui';
import { camelCaseToWords } from '@pandler/shared/utils';
import { useEffect, useState } from 'react';
import { useZorm } from 'react-zorm';

export interface DelayUntilFormProps {
  data: DelayUntil;
}

const FormDefinition = DelayUntil.omit({ type: true });
export function DelayUntilForm({ data }: DelayUntilFormProps) {
  const [whenValue, setWhenValue] = useState<When | ''>(data.when ?? '');
  const [dayValue, setDayValue] = useState<Day | undefined>(data.day);
  const zo = useZorm('settings', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      console.log('Form ok!\n' + JSON.stringify(e.data, null, 2));
    },
  });

  useEffect(() => {
    if (whenValue !== 'specificDay') {
      setDayValue(undefined);
    }
  }, [whenValue]);

  return (
    <div>
      <p className="mb-3.5 text-sm text-gray-700">
        The workflow will wait until a specified time.
      </p>

      <form ref={zo.ref}>
        <FormGroup
          id="time"
          label="Time"
          description="The time to wait until before triggering the next action"
          input={
            <Input
              type="time"
              name={zo.fields.time()}
              placeholder="hh:mm"
              hasError={zo.errors.time(true)}
              defaultValue="12:00"
            />
          }
          error={zo.errors.time(FieldGroupFieldError)}
        />

        <input type="hidden" name={zo.fields.when()} value={whenValue} />
        <FormGroup
          id="when"
          label="Time slot"
          description="The next time slot to wait until"
          input={
            <Select
              name={zo.fields.when()}
              items={DelayUntil.shape.when._def.values.map((value) => ({
                value,
                label: camelCaseToWords(value),
              }))}
              onChange={(value) => setWhenValue(value)}
              value={whenValue}
            />
          }
          error={zo.errors.when(FieldGroupFieldError)}
        />
        {whenValue === 'sameDay' && (
          <p className="text-sm -mt-1 text-gray-700 mb-5">
            <span className="font-semibold">Note: </span>If the time has elapsed
            in the current day, the action will resume the next day.
          </p>
        )}

        {whenValue === 'specificDay' && (
          <>
            <input
              type="hidden"
              name={zo.fields.day()}
              value={dayValue ?? ''}
            />
            <FormGroup
              id="day"
              label="Day"
              description="The next time slot to wait until"
              input={
                <DayPicker
                  value={dayValue ? [dayValue] : []}
                  onClick={setDayValue}
                  showSummary
                  summaryPrefix="Workflow will continue on the next "
                />
              }
              error={zo.errors.day(FieldGroupFieldError)}
            />
          </>
        )}

        <Button type="submit">Save</Button>
      </form>
    </div>
  );
}
