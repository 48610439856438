import { type ReactNode, useCallback } from 'react';
import { useDeleteConfirmation } from '../delete-confirmation';
import { Button } from '../button';
import { twMerge } from 'tailwind-merge';

export interface DeleteButtonProps {
  className?: string;
  confirmationLabel?: string;
  confirmationText?: string | ReactNode;
  onDelete: () => Promise<void>;
  children: ReactNode | ReactNode[];
  against?: string;
}

export function DeleteButton({
  className = '',
  confirmationText = 'Are you sure you want to delete the selected item? This cannot be undone.',
  confirmationLabel = 'Confirm',
  onDelete,
  children,
  against = undefined,
}: DeleteButtonProps) {
  const { confirm } = useDeleteConfirmation();

  const handleOnDelete = useCallback(() => {
    confirm({
      against,
      confirmButtonLabel: confirmationLabel,
      body: confirmationText,
      onDelete,
      type: 'error',
    });
  }, [confirm, confirmationLabel, confirmationText, onDelete, against]);

  return (
    <Button
      className={twMerge(
        'bg-red-600 hover:bg-red-700 disabled:bg-red-200',
        className
      )}
      onClick={handleOnDelete}
    >
      {children}
    </Button>
  );
}
