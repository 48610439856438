import { AddDomainButton } from '../add-domain-button';

export function NoEmailDomains() {
  return (
    <div className="space-y-4">
      <p className="text-gray-500 text-sm">
        Manage your custom domains for improved email deliverability.
      </p>

      <AddDomainButton />
    </div>
  );
}
