import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export const IntegrationConnection = z.object({
  id: z.string().uuid().default(uuidv4),
  organizationId: z.string().uuid(),
  status: z.string(),
  integrationId: z.string(),
});

export type IntegrationConnection = z.infer<typeof IntegrationConnection>;

export const PartialIntegrationConnection = IntegrationConnection.partial();

export type PartialIntegrationConnection = z.infer<
  typeof PartialIntegrationConnection
>;
