import axios from 'axios';
import Result from 'esresult';

export type PlatformApiScope =
  | 'read:organization'
  | 'write:organization'
  | 'admin:organizations'
  | 'admin:users'
  | 'write:user'
  | 'read:user'
  | 'read:me'
  | 'write:me'
  | 'list:integrations'
  | 'write:integration'
  | 'read:integration'
  | 'admin:integrations'
  | 'read:customer'
  | 'write:customer'
  | 'admin:customers'
  | 'read:invoice'
  | 'write:invoice'
  | 'admin:invoices'
  | 'read:workflow'
  | 'write:workflow'
  | 'admin:workflows'
  | 'read:payments'
  | 'write:payments'
  | 'admin:payments'
  | 'read:templates'
  | 'write:templates'
  | 'admin:templates'
  | 'send:email'
  | 'read:onboarding'
  | 'write:onboarding'
  | 'admin:onboarding'
  | 'read:notifications'
  | 'read:dashboard'
  | 'write:dashboard'
  | 'admin:dashboards'
  | 'write:media'
  | 'read:notifications'
  | 'write:notifications'
  | 'admin:notifications'
  | 'read:activity'
  | 'write:activity'
  | 'admin:activity'
  | 'read:domain-verification'
  | 'write:domain-verification'
  | 'admin:domain-verifications';

export const PlatformApiAudience = 'https://api.pandler.cloud/';

type HighestPrivilegeOptions = { defaultToLowest?: boolean };

/**
 * Given a set of scopes in order of priority from highest to least privileged,
 * will return the highest scope the user has.
 */
export function highestPrivilege(
  scopes: PlatformApiScope[],
  userScopes: PlatformApiScope[] = [],
  options?: HighestPrivilegeOptions
): PlatformApiScope | undefined {
  const opts = options ?? ({} as HighestPrivilegeOptions);
  const scope = scopes.find((scope) => userScopes.some((s) => scope === s));
  if (scope) {
    return scope;
  }

  if (opts.defaultToLowest && scopes[0]) {
    return scopes[scopes.length - 1] as PlatformApiScope;
  }

  return undefined;
}

interface GenerateMTMTokenOptions {
  domain?: string;
  audience?: string;
  grantType?: string;
  clientId: string;
  secret: string;
  scope?: string;
}

export interface TokenResponse {
  expiry: number;
  accessToken: string;
}

/**
 * Generate an access token with the required scopes (system:user) to create
 * a user on the remote api
 */
export async function generateMTMToken(
  options: GenerateMTMTokenOptions
): Result.Async<
  TokenResponse,
  ['UNKNOWN', { message: string; status: number }]
> {
  const {
    domain = 'id.pandler.cloud',
    audience = 'https://api.pandler.cloud/',
    grantType = 'client_credentials',
    scope = '',
    clientId,
    secret,
  } = options;

  const tokenResponse = await axios.post(
    `https://${domain}/oauth/token`,
    {
      client_id: clientId,
      client_secret: secret,
      audience,
      grant_type: grantType,
      scope,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (tokenResponse.status !== 200 || !tokenResponse.data.access_token) {
    return Result.error([
      'UNKNOWN',
      {
        message: 'Unable to generate remote token',
        status: tokenResponse.status,
      },
    ]);
  }

  const {
    data: { access_token: accessToken, expires_in: expiry },
  } = tokenResponse;

  return Result({ accessToken, expiry });
}
