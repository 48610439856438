import { z } from 'zod';

export const EditorText = z.object({
  text: z.string(),
  bold: z.literal(true).optional(),
  italic: z.literal(true).optional(),
  underline: z.literal(true).optional(),
});

export type EditorText = z.infer<typeof EditorText>;

export const EditorParagraphElement = z.object({
  type: z.literal('paragraph'),
  children: z.array(EditorText),
});

export type EditorParagraphElement = z.infer<typeof EditorParagraphElement>;

export type EditorElement = EditorParagraphElement;
