import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import {
  CompletePage,
  HomePage,
  IntegrationsPage,
  LinkPage,
  OrganizationPage,
} from './pages';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/integrations" element={<IntegrationsPage />} />
      <Route path="/organization" element={<OrganizationPage />} />
      <Route path="/link" element={<LinkPage />} />
      <Route path="/complete" element={<CompletePage />} />
    </ReactRouterRoutes>
  );
}
