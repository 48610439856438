import { Accordion } from '@pandler/shared/react-ui';
import { DomainDetails } from '../domain-details/domain-details';
import type { VerifiedDomain } from '@pandler/shared/data-types';
import { DnsInstructions } from '../dns-instructions';

export interface DomainDetailsPaneProps {
  domain: VerifiedDomain;
}

export function DomainDetailsPane({ domain }: DomainDetailsPaneProps) {
  return (
    <Accordion
      buttonClassName="bg-gray-50 hover:bg-gray-100 text-gray-800 font-medium"
      panelClassName="px-0"
      items={[
        {
          heading: 'Domain settings',
          body: <DomainDetails domain={domain} />,
        },
        ...(domain.status === 'NOT_VERIFIED'
          ? [
              {
                heading: 'DNS Instructions',
                body: <DnsInstructions domain={domain} />,
              },
            ]
          : []),
      ]}
      defaultOpen
    />
  );
}
