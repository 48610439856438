import { useAuth0 } from '@auth0/auth0-react';
import { type IntegrationConnection } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';

export default function useIntegrationConnections({
  organizationId,
}: {
  organizationId: string;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, error, data, isRefetching } = useQuery<
    IntegrationConnection[],
    Error
  >(
    ['integrations', organizationId],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:integration',
      });
      const $response = await fetch(
        `${API_ENDPOINT}/integrations/${organizationId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      const { data } = await $response.json();
      if (!Array.isArray(data)) {
        throw new Error('Unable to retrieve integrations');
      }

      return data as IntegrationConnection[];
    },
    { staleTime: Infinity, retry: false }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['integrations', organizationId],
    });
  }, [organizationId]);

  return { isLoading, error, data, invalidate, isRefetching };
}
