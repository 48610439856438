import { friendlyInvoiceStatusName } from '@pandler/shared/data-types';
import type { Column } from '@tanstack/react-table';
import type { z} from 'zod';
import { ZodEnum, ZodNumber, ZodString, type AnyZodObject } from 'zod';
import { Select } from '../../select';
import type {
  RangeFilterProps,
  TextFilterProps} from './custom-filters';
import {
  RangeFilter,
  TextFilter
} from './custom-filters';

export function TableFilter({
  column,
  className = '',
  model,
  as,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any>;
  className?: string;
  model: AnyZodObject;
  as?:
    | ((props: RangeFilterProps) => JSX.Element)
    | ((props: TextFilterProps) => JSX.Element);
}) {
  const columnFilterValue = column.getFilterValue();

  if (typeof as === 'function') {
    // passed through a custom filter
    return (
      <div className={`flex space-x-2 ${className}`}>
        {typeof as === 'function' &&
          as({
            value: columnFilterValue,
            onChange: (value) => column.setFilterValue(value),
          })}
      </div>
    );
  }

  type ModelType = z.infer<typeof model>;
  const id = column.id as keyof ModelType;
  const col = model.shape[id];

  if (!col) {
    return null;
  }

  return (
    <div className={`flex space-x-2 ${className}`}>
      {col instanceof ZodString && (
        <TextFilter
          value={(columnFilterValue ?? '') as string}
          onChange={(value) => column.setFilterValue(value)}
        />
      )}

      {col instanceof ZodNumber && (
        <RangeFilter
          value={(columnFilterValue as [string, string]) ?? ['', '']}
          onChange={(value) => column.setFilterValue(value)}
        />
      )}

      {col instanceof ZodEnum && (
        <div className="w-full relative">
          <Select
            items={Object.values(col.Values).map((value) => ({
              value,
              label: friendlyInvoiceStatusName(value),
            }))}
            value={(columnFilterValue ?? '') as string}
            onChange={(value) => column.setFilterValue(value)}
            includeEmpty
          />
        </div>
      )}
    </div>
  );
}
