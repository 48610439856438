import { selector } from 'recoil';
import { userState } from './atoms';

export const userAvatarState = selector({
  key: 'UserAvatar',
  get: ({ get }) => {
    const user = get(userState);

    return user?.avatar ?? 'http://'; // @todo default avatar
  },
});
