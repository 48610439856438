import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader } from '@pandler/shared/react-ui';
import { useCurrentUser, useOrganization } from '@pandler/cloud/shared';
import { UserProfile } from '../../components';

export function ProfilePage() {
  const { user } = useCurrentUser();
  const { organization } = useOrganization();

  if (!organization || !user) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>User profile</title>
      </Helmet>

      <PageHeader
        title="Your profile"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Your profile', href: '/user/profile', current: true },
            ]}
          />
        }
      />

      <Content center>
        {user && (
          <main className="flex flex-col gap-8">
            <section className="border rounded-lg py-9 px-8">
              <UserProfile organization={organization} user={user} />
            </section>

            {/* <section className="border rounded-lg py-6 pb-9 px-8">
              <h2 className="text-lg font-semibold mb-4">
                Multi-Factor Authentication
              </h2>
              <MFAOptions user={user} />
            </section> */}
          </main>
        )}
      </Content>
    </>
  );
}

export default withAuthenticationRequired(ProfilePage);
