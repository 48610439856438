import { ChartContainer } from '@pandler/shared/react-ui';
import { ViewActionBar } from '../view-action-bar';
import { DetailedMessage } from '../detailed-message';
import type { Message } from '../../types';
import type { AttachmentProps } from '../attachment';
import { twMerge } from 'tailwind-merge';

export interface DetailedMessageViewProps {
  onDelete?: () => Promise<void>;
  onSend?: () => Promise<void>;
  message: Omit<Message, 'attachments'> & { attachments: AttachmentProps[] };
  hideActionBar?: boolean;
  className?: string;
  innerClassName?: string;
  hideChart?: boolean;
}

export function DetailedMessageView({
  onDelete,
  onSend,
  message,
  hideActionBar = false,
  className = '',
  innerClassName = '',
  hideChart = false,
}: DetailedMessageViewProps) {
  return (
    <div className={twMerge('flex flex-col w-full', className)}>
      {!hideActionBar && <ViewActionBar onDelete={onDelete} onSend={onSend} />}

      <div className={twMerge('p-6', innerClassName)}>
        {hideChart ? (
          <DetailedMessage {...message} />
        ) : (
          <ChartContainer>
            <DetailedMessage {...message} />
          </ChartContainer>
        )}
      </div>
    </div>
  );
}
