import * as React from 'react';

export function ChatIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      aria-labelledby="title"
      aria-describedby="desc"
      {...props}
    >
      <title>{'Read Email'}</title>
      <path
        data-name="layer1"
        fill="none"
        stroke="#4f46e5"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M10 21.1 2 28m52-6.9 8 6.9"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        data-name="layer2"
        fill="none"
        stroke="#3730a3"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M54 33.6V2H10v31.6M18 12h10m-4 8h22m-28 8h28"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        data-name="layer1"
        fill="none"
        stroke="#4f46e5"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M62 28v34H2V28m40.1 13.9L62 28M2 28l19.9 13.9"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        data-name="layer1"
        d="m2 62 20.9-21.1c3.9-3.9 14.2-3.9 18.1 0L62 62"
        fill="none"
        stroke="#4f46e5"
        strokeMiterlimit={10}
        strokeWidth={2}
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  );
}

export { ChatIcon as EmailIcon };
