import type { User } from '@pandler/shared/data-types';

interface UserSummaryProps {
  user: User;
}

export function UserSummary({ user }: UserSummaryProps) {
  const { avatar, firstName, lastName, email } = user;
  return (
    <div className="flex px-3 pt-3 pb-4 flex-row gap-x-4 items-center">
      {avatar && <img className="h-9 w-9 rounded-full" src={avatar} alt="" />}
      <div className="flex flex-col gap-y-0.5 text-sm">
        <span className="text-gray-800">
          {[firstName, lastName].filter(Boolean).join(' ')}
        </span>
        <span className="text-gray-500">{email}</span>
      </div>
    </div>
  );
}
