import { useWebSocketState } from '../../hook/use-websocket-state';

interface AuthenticatedWsStateProps {
  organizationId: string;
  token: string;
  userId: string;
}

export function AuthenticatedWsState({
  token,
  organizationId,
  userId,
}: AuthenticatedWsStateProps) {
  useWebSocketState({ token, organizationId, userId });

  return null;
}
