import type { Integration } from '@pandler/shared/data-types';
import { ErrorScreen, LoadingContainer } from '@pandler/shared/react-ui';
import { keyBy } from 'lodash';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  useAvailableIntegrations,
  useIntegrationConnections,
  useOrganization,
} from '../../hooks';
import { IntegrationNode } from '../integration-node';

interface IntegrationsListProps {
  className?: string;
}

export function IntegrationsList({ className = '' }: IntegrationsListProps) {
  const { organization } = useOrganization();
  const integrations = useAvailableIntegrations();
  const active = useIntegrationConnections({
    organizationId: organization?.id ?? '',
  });

  if (integrations.isLoading || active.isLoading || active.isRefetching) {
    return <LoadingContainer />;
  }

  if (integrations.error || active.error) {
    return <ErrorScreen title="Unable to load integrations" />;
  }

  const $integrations = keyBy(integrations.data, 'id');
  const isActive = (id: string) =>
    active.data?.some(({ integrationId }) => id === integrationId);

  return (
    <div
      className={twMerge(
        'grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 pt-3',
        className
      )}
    >
      {active.data?.map(({ integrationId, status, id }) => (
        <Fragment key={integrationId}>
          {$integrations[integrationId] && (
            <IntegrationNode
              integration={$integrations[integrationId] as Integration}
              active
              status={status}
              connect={integrations.connect}
              isLoading={integrations.connectLoading}
              connectionId={id}
            />
          )}
        </Fragment>
      ))}

      {integrations.data
        ?.filter(({ id }) => !isActive(id))
        .map((integration) => (
          <IntegrationNode
            integration={integration}
            active={false}
            key={integration.id}
            connect={integrations.connect}
            isLoading={integrations.connectLoading}
          />
        ))}
    </div>
  );
}
