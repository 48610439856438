import { BillingPlans } from '@pandler/shared/react-ui';

const features = [
  {
    id: 'overview',
    label: 'Overview',
    items: [
      { id: 'customers', label: 'Customers' },
      { id: 'reminders', label: 'Invoice reminders' },
      { id: 'payments', label: 'Digital payments' },
      { id: 'unlimitedWorkflows', label: 'Unlimited workflows' },
      { id: 'branding', label: 'Custom branding' },
      { id: 'users', label: 'Users' },
    ],
  },
  {
    id: 'payments',
    label: 'Payments',
    items: [
      { id: 'bnpl', label: 'Buy now pay later' },
      { id: 'customUrls', label: 'Custom URLs' },
    ],
  },
  {
    id: 'reminders',
    label: 'Reminders',
    items: [
      { id: 'emailReminders', label: 'Email reminders' },
      { id: 'smsReminders', label: 'SMS reminders' },
    ],
  },
];

const plans = [
  {
    id: 'starter',
    label: 'Starter',
    price: 49,
    description: 'Perfect for individuals and sole traders.',
    features: {
      customers: 250,
      reminders: true,
      payments: true,
      unlimitedWorkflows: true,
      branding: false,
      users: 1,
      bnpl: true,
      customUrls: false,
      emailReminders: true,
      smsReminders: true,
    },
  },
  {
    id: 'growth',
    label: 'Growth',
    price: 99,
    description: 'Perfect for small businesses and start ups.',
    features: {
      customers: 500,
      reminders: true,
      payments: true,
      unlimitedWorkflows: true,
      branding: false,
      users: 10,
      bnpl: true,
      customUrls: false,
      emailReminders: true,
      smsReminders: true,
    },
  },
  {
    id: 'enterprise',
    label: 'Enterprise',
    price: 'Custom',
    description: 'Perfect for medium and large businesses.',
    cta: 'Contact Us',
    features: {
      customers: 2000,
      reminders: true,
      payments: true,
      unlimitedWorkflows: true,
      branding: false,
      users: 'Unlimited',
      bnpl: true,
      customUrls: false,
      emailReminders: true,
      smsReminders: true,
    },
  },
];

export function SubscriptionTab() {
  return (
    <div className="border rounded-lg py-9 px-8">
      <BillingPlans
        currentPlan="growth"
        plans={plans}
        features={features}
        buttonLabel={(plan) => (plan === 'starter' ? 'Downgrade' : 'Upgrade')}
      />
    </div>
  );
}
