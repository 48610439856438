export function timeSince(date: Date): string {
  const diff = new Date().getTime() - date.getTime();
  const intervals = [
    { label: 'year', seconds: 31536000 * 1000 },
    { label: 'month', seconds: 2592000 * 1000 },
    { label: 'week', seconds: 604800 * 1000 },
    { label: 'day', seconds: 86400 * 1000 },
    { label: 'hour', seconds: 3600 * 1000 },
    { label: 'minute', seconds: 60 * 1000 },
    { label: 'second', seconds: 1 * 1000 },
  ];

  const interval = intervals.find((i) => Math.floor(diff / i.seconds) >= 1);
  if (interval) {
    const count = Math.floor(diff / interval.seconds);
    return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
  }

  return 'just now';
}
