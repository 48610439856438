import { type Invoice } from '@pandler/shared/data-types';
import {
  DefaultTextEditorValue,
  type Editor,
  error,
  LoadingIcon,
  RichTextEditor,
  success,
} from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useState } from 'react';
import type { Descendant } from 'slate';
import { useAddComment, useCurrentUser } from '@pandler/cloud/shared';

interface CommentsFormProps {
  invoice: Invoice;
}

export function CommentsForm({ invoice }: CommentsFormProps) {
  const { mutateAsync: addComment, isLoading } = useAddComment({
    invoiceId: invoice.id,
  });
  const { user } = useCurrentUser();
  const [editor, setEditor] = useState<Editor | undefined>(undefined);
  const [value, setValue] = useState<Descendant[]>(DefaultTextEditorValue);
  const hasValue = !isEqual(value, DefaultTextEditorValue);

  async function handleOnSubmit() {
    try {
      await addComment({ message: value });
      success({ message: 'Comment successfully saved' });
      setValue(DefaultTextEditorValue);
      editor?.reset();
    } catch (err: unknown) {
      if (err instanceof Error) {
        error({ message: err.message });
      }
    }
  }

  return (
    <>
      <div className="flex items-start space-x-4 mt-6 mb-2">
        <div className="flex-shrink-0 pt-8">
          <img
            className="inline-block h-10 w-10 rounded-full"
            src={user?.avatar}
            alt=""
          />
        </div>
        <div className="min-w-0 flex-1">
          <RichTextEditor
            placeholder="Add comment..."
            value={value}
            onValueChange={(value) => setValue(value)}
            onInit={setEditor}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className={classNames(
            'h-10 inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500/40 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 transition-all duration-300',
            {
              'w-12': isLoading,
              'w-16': !isLoading,
            }
          )}
          disabled={!hasValue || isLoading}
          onClick={handleOnSubmit}
        >
          {isLoading ? (
            <LoadingIcon className="w-3.5 h-3.5 text-white" />
          ) : (
            'Post'
          )}
        </button>
      </div>
    </>
  );
}
