import { smsTemplates } from '../sms';
import { emailTemplates } from '../email';

export const templates = [...emailTemplates, ...smsTemplates] as const;

export type Templates = typeof templates;
export type TemplateId = Templates[number]['id'];

export type TemplatesDictionary = {
  [property in Templates[number]['id']]: Templates[number];
};
