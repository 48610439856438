const colors = {
  red: {
    '50': '#FEF2F2',
    '100': '#FEE2E2',
    '200': '#FECACA',
    '300': '#FCA5A5',
    '400': '#F87171',
    '500': '#EF4444',
    '600': '#DC2626',
    '700': '#B91C1C',
    '800': '#991B1B',
    '900': '#7F1D1D',
  },
  orange: {
    '50': '#FFFAF0',
    '100': '#FEEBC8',
    '200': '#FBD38D',
    '300': '#F6AD55',
    '400': '#ED8936',
    '500': '#DD6B20',
    '600': '#C05621',
    '700': '#9C4221',
    '800': '#7B341E',
    '900': '#652B19',
  },
  yellow: {
    '50': '#FEFCE8',
    '100': '#FEF9C3',
    '200': '#FEF08A',
    '300': '#FDE047',
    '400': '#FACC15',
    '500': '#EAB308',
    '600': '#CA8A04',
    '700': '#A16207',
    '800': '#854D0E',
    '900': '#713F12',
  },
  green: {
    '50': '#F0FFF4',
    '100': '#C6F6D5',
    '200': '#9AE6B4',
    '300': '#68D391',
    '400': '#48BB78',
    '500': '#38A169',
    '600': '#2F855A',
    '700': '#276749',
    '800': '#22543D',
    '900': '#1C4532',
  },
  teal: {
    '50': '#F0FDFA',
    '100': '#CCFBF1',
    '200': '#99F6E4',
    '300': '#5EEAD4',
    '400': '#2DD4BF',
    '500': '#14B8A6',
    '600': '#0D9488',
    '700': '#0F766E',
    '800': '#115E59',
    '900': '#134E4A',
  },
  blue: {
    '50': '#EFF6FF',
    '100': '#DBEAFE',
    '200': '#BFDBFE',
    '300': '#93C5FD',
    '400': '#60A5FA',
    '500': '#3B82F6',
    '600': '#2563EB',
    '700': '#1D4ED8',
    '800': '#1E40AF',
    '900': '#1E3A8A',
  },
  indigo: {
    '50': '#EEF2FF',
    '100': '#E0E7FF',
    '200': '#C7D2FE',
    '300': '#A5B4FC',
    '400': '#818CF8',
    '500': '#6366F1',
    '600': '#4F46E5',
    '700': '#4338CA',
    '800': '#3730A3',
    '900': '#312E81',
  },
  purple: {
    '50': '#F5F3FF',
    '100': '#EDE9FE',
    '200': '#DDD6FE',
    '300': '#C4B5FD',
    '400': '#A78BFA',
    '500': '#8B5CF6',
    '600': '#7C3AED',
    '700': '#6D28D9',
    '800': '#5B21B6',
    '900': '#4C1D95',
  },
  pink: {
    '50': '#FDF2F8',
    '100': '#FCE7F3',
    '200': '#FBCFE8',
    '300': '#F9A8D4',
    '400': '#F472B6',
    '500': '#EC4899',
    '600': '#DB2777',
    '700': '#BE185D',
    '800': '#9D174D',
    '900': '#831843',
  },
  gray: {
    '50': '#F9FAFB',
    '100': '#F3F4F6',
    '200': '#E5E7EB',
    '300': '#D1D5DB',
    '400': '#9CA3AF',
    '500': '#6B7280',
    '600': '#4B5563',
    '700': '#374151',
    '800': '#1F2937',
    '900': '#111827',
  },
} as const;

export default colors;
