import { useOrganization } from '@pandler/cloud/shared';
import { LoadingContainer } from '@pandler/shared/react-ui';
import { Outlet } from 'react-router-dom';
import { PublicIdCreate } from '../../components';

export function PublicIdLayout() {
  const { organization } = useOrganization();

  if (!organization) {
    return <LoadingContainer />;
  }

  if (!organization.publicId) {
    return <PublicIdCreate />;
  }

  return <Outlet />;
}
