import { useAuth0 } from '@auth0/auth0-react';
import { type Template } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

type CloneRequest = {
  id: string;
  name: string;
};

const isCloneRequest = (candidate: unknown): candidate is CloneRequest =>
  candidate !== null &&
  typeof candidate === 'object' &&
  'id' in candidate &&
  'name' in candidate;

export default function useCreateTemplate() {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useOrganization();

  return useMutation({
    mutationFn: async (
      data: Template | CloneRequest
    ): Promise<{ id: string }> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:templates',
      });

      const $response = await fetch(`${API_ENDPOINT}/templates`, {
        // if a string is provided, will clone using the PUT endpoint
        method: isCloneRequest(data) ? 'PUT' : 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          isCloneRequest(data) ? { ...data, organizationId: id } : data
        ),
      });

      if ($response.status === 409) {
        throw new Error('Template already exists');
      } else if ($response.status === 201) {
        const { data } = (await $response.json()) as { data: { id: string } };

        queryClient.invalidateQueries({
          queryKey: ['organization-templates', id],
        });

        return data as { id: string };
      }

      throw new Error('An unknown error occurred');
    },
  });
}
