import { PlusSmallIcon } from '@heroicons/react/24/solid';
import { classNames } from '@pandler/shared/utils';
import { useState } from 'react';
import { LoadingIcon } from '../loading-icon';

interface SummaryItem {
  name: string;
  description?: string;
  image?: string;
  id: string;
}
export interface SummaryButtonListInputArguments {
  items: SummaryItem[];
  buttonText: string;
  title?: string;
  imageClassName?: string;
  onButtonClick?: (id: string) => Promise<void>;
  setLoadingOnButtonClick?: boolean;
  loadingButtonClass?: string;
  notLoadingButtonClass?: string;
}

export function SummaryButtonList({
  items,
  buttonText,
  title,
  imageClassName = 'h-10 w-10 rounded-full',
  onButtonClick,
  setLoadingOnButtonClick,
  loadingButtonClass = '',
  notLoadingButtonClass = '',
}: SummaryButtonListInputArguments) {
  const [loading, setLoading] = useState('');

  return (
    <>
      {title && (
        <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide text-center">
          {title}
        </h3>
      )}

      <ul className="mt-4 border-t border-b border-gray-100 divide-y divide-gray-100">
        {items.map((item) => (
          <li
            key={item.name}
            className="py-4 flex items-center justify-between space-x-3"
          >
            <div className="min-w-0 flex-1 flex items-center space-x-3">
              {item.image && (
                <div className="flex-shrink-0">
                  <img className={imageClassName} src={item.image} alt="" />
                </div>
              )}
              <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {item.name}
                </p>
                {item.description && (
                  <p className="text-sm text-gray-500 truncate">
                    {item.description}
                  </p>
                )}
              </div>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className={classNames(
                  'inline-flex items-center justify-center py-2 px-3 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 duration-300',
                  loading === item.name
                    ? loadingButtonClass
                    : notLoadingButtonClass
                )}
                onClick={async () => {
                  if (onButtonClick) {
                    if (setLoadingOnButtonClick) {
                      setLoading(item.name);
                    }
                    await onButtonClick(item.id);

                    if (setLoadingOnButtonClick) {
                      setLoading('');
                    }
                  }
                }}
                disabled={loading === item.name}
              >
                {loading === item.name && (
                  <LoadingIcon className="w-4 h-4 text-gray-500" />
                )}
                {loading !== item.name && (
                  <>
                    <PlusSmallIcon
                      className="-ml-1 mr-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-gray-900">
                      {buttonText}
                    </span>
                  </>
                )}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
