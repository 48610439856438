import Markdown from 'react-markdown';
import type { Activity } from '@pandler/shared/data-types';
import { RichTextEditor } from '../../../rich-text-editor';

export function Text({ value }: { value: Activity['body'] }) {
  if (typeof value === 'string') {
    return <Markdown>{value}</Markdown>;
  }

  return <RichTextEditor value={value} readOnly />;
}
