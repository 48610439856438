import type { Payment } from '@pandler/shared/data-types';
import {
  EmptyState,
  EntityTable,
  LoadingContainer,
  SlideOutContext,
  StripeIcon,
} from '@pandler/shared/react-ui';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnFiltersState,
  type PaginationState,
} from '@tanstack/react-table';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useSearchPayments from '../../hooks/use-search-payments';
import { PaymentSummary } from '../payment-summary';
import { columns } from './helpers';

export interface PaymentsTableProps {
  hidePagination?: boolean;
  narrow?: boolean;
}

export function PaymentsTable({
  hidePagination = false,
  narrow = false,
}: PaymentsTableProps) {
  const { closePane, showPane } = useContext(SlideOutContext);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const columnFilterQuery = useMemo(() => ({}), []);

  const [filterQuery] = useDebounce(columnFilterQuery, 500);
  const { data, isLoading, isPreviousData } = useSearchPayments({
    page: pageIndex + 1,
    perPage: pageSize,
    query: { sortBy: 'issuedDate.desc', ...filterQuery },
  });

  const defaultData = useMemo(() => [], []);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handleOnPaymentClick = useCallback(
    (payment: Payment) => {
      showPane(<PaymentSummary payment={payment} />, {
        onClose: closePane,
        title: 'View payment',
      });
    },
    [closePane, showPane]
  );

  const instance = useReactTable({
    data: data && Array.isArray(data.data) ? data.data : defaultData,
    columns,
    pageCount: data
      ? Math.ceil(data.pagination.records / data.pagination.perPage)
      : -1,
    state: {
      pagination,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
  });

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (!data?.data || data.data.length === 0) {
    return (
      <EmptyState
        className="mt-12"
        icon={StripeIcon}
        iconClassName="h-44"
        title="You haven't received any payments"
        titleClassName="text-slate-800 text-lg"
        body="Start sending reminders and when a customer pays an invoice online it will show up here."
        bodyClassName="text-slate-500"
      />
    );
  }

  return (
    <EntityTable
      narrow={narrow}
      hidePagination={hidePagination}
      isPreviousData={isPreviousData}
      instance={instance}
      onRowClick={({ row, cell }) => {
        if (row.original && cell.column.id !== 'invoiceNumber') {
          handleOnPaymentClick(row.original);
        }
      }}
    />
  );
}
