import { useAuth0 } from '@auth0/auth0-react';
import type { EmailQueueItem } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';

interface UseMessageQueueOptions {
  filter?: string;
  queryOptions?: UseQueryOptions<EmailQueueItem[], Error, EmailQueueItem[]>;
}

const useMessageQueue = ({
  filter = '',
  queryOptions = {},
}: UseMessageQueueOptions) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useOrganization();

  const query = useQuery<EmailQueueItem[], Error>(
    ['messages', 'queue', id, filter],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'send:email',
      });

      try {
        const { data } = await (
          await fetch(`${API_ENDPOINT}/email/queue/${id}?${filter}`, {
            headers: { authorization: `Bearer ${token}` },
          })
        ).json();

        return data;
      } catch {
        throw Error('Unable to retrieve messages');
      }
    },
    { staleTime: Infinity, retry: false }
  );

  return { ...query, ...queryOptions };
};

export default useMessageQueue;
