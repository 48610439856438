import { type ReactNode, type ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export interface ErrorScreenProps {
  title: string;
  description?: string | ReactElement;
  errorCode?: string;
  homeLink?: boolean;
  icon?: ReactNode;
  bodyClassName?: string;
  titleClassName?: string;
  hideIcon?: boolean;
}

export function ErrorScreen({
  title,
  errorCode,
  description,
  homeLink,
  icon,
  hideIcon = false,
  bodyClassName = '',
  titleClassName = '',
}: ErrorScreenProps) {
  return (
    <>
      {!hideIcon && (
        <div className="flex-shrink-0 flex justify-center">
          {!icon && (
            <a href="/" className="inline-flex">
              <span className="sr-only">Pandler</span>
              <img className="h-20 w-auto" src="/favicon.png" alt="Pandler" />
            </a>
          )}

          {icon}
        </div>
      )}
      <div className={twMerge('py-16', bodyClassName)}>
        <div className="text-center">
          {errorCode && (
            <p className="text-sm font-semibold text-primary-600 uppercase tracking-wide">
              {errorCode}
            </p>
          )}
          <h1
            className={twMerge(
              'mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-4xl',
              titleClassName
            )}
          >
            {title}
          </h1>
          {description && (
            <p className="mt-2 text-base text-gray-500">{description}</p>
          )}
          {homeLink && (
            <div className="mt-6">
              <Link
                to="/"
                className="text-base font-medium text-primary-600 hover:text-primary-500"
              >
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
