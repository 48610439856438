import {
  AdjustmentsVerticalIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  CogIcon,
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
} from '@heroicons/react/24/outline';

type NavigationItem = {
  name: string;
  href: string;
  icon?: React.ElementType;
  children?: NavigationItem[];
};

export const baseMenuItems: NavigationItem[] = [
  { name: 'Dashboard', href: '/dashboard', icon: ChartPieIcon },
];

export const menuItems: NavigationItem[] = [
  ...baseMenuItems,
  {
    name: 'Invoices',
    icon: DocumentChartBarIcon,
    href: '/invoices',
    children: [
      { name: 'All invoices', href: '/invoices' },
      { name: 'Overdue', href: '/invoices/status/overdue' },
      { name: 'Unpaid', href: '/invoices/status/unpaid' },
      { name: 'Paid', href: '/invoices/status/paid' },
    ],
  },
  { name: 'Payments', href: '/payments', icon: CreditCardIcon },
  {
    name: 'Notifications',
    icon: ChatBubbleBottomCenterTextIcon,
    href: '/notifications',
    children: [
      { name: 'Queue', href: '/notifications/queue' },
      { name: 'Sent', href: '/notifications/logs' },
      { name: 'Templates', href: '/notifications/templates' },
    ],
  },
  {
    name: 'Workflows',
    href: '/workflows',
    icon: AdjustmentsVerticalIcon,
  },
  {
    name: 'Settings',
    icon: CogIcon,
    href: '/settings',
    children: [
      { name: 'General settings', href: '/settings' },
      { name: 'Custom domains', href: '/settings/custom-domains' },
      { name: 'Team', href: '/settings/users' },
      { name: 'Integrations', href: '/settings/integrations' },
      { name: 'Subscription', href: '/settings/subscription' },
    ],
  },
];
