import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { Result } from 'esresult';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export default function useDisableIntegration() {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();

  return useMutation({
    mutationFn: async (id: string): Result.Async<true, 'UNKNOWN'> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:integration',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/integration/${organization?.id}/connection/${id}`,
        {
          method: 'DELETE',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if ($response.status === 204) {
        queryClient.invalidateQueries({
          queryKey: ['integrations', organization?.id],
        });
        return Result(true);
      }

      return Result.error('UNKNOWN');
    },
  });
}
