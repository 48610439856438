import Handlebars from 'handlebars';

import { newlinesToBr } from './newline-to-br';

Handlebars.registerHelper(
  'nl2br',
  function (this: unknown, options: Handlebars.HelperOptions) {
    const data = options.data as Record<string, unknown>;
    return new Handlebars.SafeString(
      newlinesToBr(options.fn.call(this, this, data))
    );
  }
);

export default Handlebars;
