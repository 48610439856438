import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export default function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    const gtag = (window as any).gtag;
    if (gtag) {
      gtag('event', 'page_view', {
        page_path: `${location.pathname}${location.search}`,
        page_location: window.location.href,
      });
    } else {
      console.warn('Missing gtag property');
    }
  }, [location]);
}
