import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { dateSchema } from './date';
import { EditorParagraphElement } from './rich-text';

export const Activity = z.object({
  id: z.string().uuid().default(uuidv4),
  type: z.enum(['Comment', 'Payment', 'Communication', 'System', 'Danger']),
  date: dateSchema.default(new Date()),
  author: z.string().optional(),
  avatar: z.string().optional(),
  body: z.union([z.array(EditorParagraphElement), z.string()]),
});

export type Activity = z.infer<typeof Activity>;
