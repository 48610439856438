import { useState } from 'react';
import { Configuration } from './configuration';
import { TemplateSelection } from './template-selection';

interface CreateWizardProps {
  className?: string;
}

export function CreateWizard({ className = '' }: CreateWizardProps) {
  const [step, setStep] = useState(1);

  return (
    <div className={className}>
      {step === 1 && <TemplateSelection onSelect={() => setStep(2)} />}
      {step === 2 && <Configuration />}
    </div>
  );
}
