import { Bars3Icon } from '@heroicons/react/24/outline';
import { HeaderBlock } from './HeaderBlock';
import { UserBar } from '../user-bar';
import { NotificationsBar } from '../notifications-bar';

export interface MobileSidebar {
  setIsOpen: (isOpen: boolean) => void;
}

export function Header({ setIsOpen }: MobileSidebar) {
  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white dark:bg-neutral-900 shadow-sm print:hidden">
      <button
        type="button"
        className="md:hidden mt-2 -ml-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
        onClick={() => setIsOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex-1 flex justify-between relative">
        <div className="flex-1 flex">
          <HeaderBlock />
        </div>
        <div className="ml-4 flex items-center md:ml-6 pr-4 absolute right-0 top-0 h-16">
          <NotificationsBar />
          <UserBar />
        </div>
      </div>
    </div>
  );
}
