import { useMessageLog } from '@pandler/cloud/shared';
import { Button, LoadingContainer } from '@pandler/shared/react-ui';
import { Message } from '../message';
import { Inbox } from '@pandler/shared/inbox-ui';
import { type Message as TMessage } from '../../utils/log-item-to-message';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface LogMessageProps {
  id: string;
  messages: TMessage[];
  goBack?: () => void;
  className?: string;
}

export function LogMessage({
  id,
  messages,
  goBack,
  className = '',
}: LogMessageProps) {
  const [messageId, setMessageId] = useState(id);
  const { message, isLoading, error } = useMessageLog(messageId);

  const showIsLoading = isLoading || error || !message;

  const $messages = messages.map((message) => ({
    ...message,
    isActive: message.id === messageId,
  }));

  return (
    <div className={twMerge('flex flex-auto w-full h-full', className)}>
      <div className="w-96 min-w-[384px] max-w-[384px] shrink-0 p-0 text-sm border-r border-slate-200/80">
        {goBack && (
          <div className="p-4 border-b border-slate-200/800">
            <Button onClick={goBack}>Go back</Button>
          </div>
        )}
        <Inbox
          messageClassName="p-4 rounded-none sm:flex-col sm:items-start [&>*:not(:first-child)]:font-normal [&>*:not(:first-child)]:text-slate-500 [&>*]:w-full"
          dontSlide
          messages={$messages}
          hideSelect
          hideActionBar
          onMessageClick={(message) => setMessageId(message.id)}
        />
      </div>
      <div className="flex grow bg-slate-50 items-start">
        {showIsLoading ? (
          <LoadingContainer />
        ) : (
          <Message message={message} hideActionBar />
        )}
      </div>
    </div>
  );
}
