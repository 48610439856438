import { UpcomingRemindersConfiguration } from '../templates/upcoming-reminders';

interface ConfigurationProps {
  template?: 'upcoming-reminders' | 'overdue-reminders' | 'debt-collection';
}

export function Configuration({
  template = 'upcoming-reminders',
}: ConfigurationProps) {
  if (template === 'upcoming-reminders') {
    return <UpcomingRemindersConfiguration />;
  }

  return <div>Unknown template</div>;
}
