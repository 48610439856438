import {
  EmptyState,
  LoadingContainer,
  MailboxIcon,
} from '@pandler/shared/react-ui';
import {
  MessageQueueInbox,
  type OnDataChangeCallback,
} from '../message-queue-inbox';
import { useCallback, useState } from 'react';
import type { EmailQueueItem } from '@pandler/shared/data-types';
import { InvoiceEmptyState } from '../inbox-empty-state';
import classNames from 'classnames';
import type { MessageProps } from '@pandler/shared/inbox-ui';
import { MessagePreview } from '../message-preview';

export function InboxFullscreen() {
  const [activeMessage, setActiveMessage] = useState<string | undefined>();
  const [{ isRefetching, data, isLoading }, setState] = useState<{
    isRefetching: boolean;
    isLoading: boolean;
    data: EmailQueueItem[] | undefined;
  }>({ isRefetching: false, isLoading: true, data: undefined });

  const handleOnDataChange = useCallback<OnDataChangeCallback>(
    (options) => {
      setState(options);
      setActiveMessage(undefined);
    },
    [setState]
  );

  const handleOnMessageClick = useCallback(
    ({ id }: MessageProps) => setActiveMessage(id),
    [setActiveMessage]
  );

  if (Array.isArray(data) && data.length === 0 && !isLoading && !isRefetching) {
    return <InvoiceEmptyState />;
  }

  return (
    <div className="flex flex-auto w-full h-full">
      {isLoading && <LoadingContainer />}

      <MessageQueueInbox
        className={classNames(
          'w-96 min-w-[384px] max-w-[384px] shrink-0 p-0 text-sm border-r border-slate-200/80',
          { hidden: isLoading }
        )}
        messageClassName="pb-4 rounded-none sm:flex-col sm:items-start [&>*:not(:first-child)]:pl-10 [&>*:not(:first-child)]:font-normal [&>*:not(:first-child)]:text-slate-500 [&>*]:w-full"
        onDataChange={handleOnDataChange}
        onMessageClick={handleOnMessageClick}
        activeMessage={activeMessage}
        dontSlide
      />

      <div
        className={classNames('flex grow bg-slate-50', {
          hidden: isLoading,
          'justify-center items-center': !activeMessage,
          'items-start': activeMessage,
        })}
      >
        {activeMessage ? (
          <MessagePreview id={activeMessage} />
        ) : (
          <EmptyState
            className="mt-0"
            icon={MailboxIcon}
            title="Select a message to get started"
            titleClassName="font-medium text-gray-600"
          />
        )}
      </div>
    </div>
  );
}
