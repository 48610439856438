import {
  ErrorPage,
  ErrorScreen,
  LoadingScreen,
} from '@pandler/shared/react-ui';
import { type ReactNode } from 'react';
import { useOrganization } from '@pandler/cloud/shared';
import { useLocation, Navigate } from 'react-router-dom';

type OrganizationWrapperProps = {
  children: ReactNode;
  id: string;
};

export function OrganizationWrapper({
  children,
  id,
}: OrganizationWrapperProps) {
  const { error, isLoading, hasOnboarded } = useOrganization({
    organizationId: id,
  });
  const { pathname } = useLocation();

  if (error) {
    return (
      <ErrorPage
        title="Error"
        screen={
          <ErrorScreen
            errorCode="Connection error"
            title="Organization error"
            description={error.message}
          />
        }
      />
    );
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!hasOnboarded && !pathname.startsWith('/onboarding')) {
    return <Navigate to="/onboarding" replace />;
  }

  return <>{children}</>; //eslint-disable-line
}
