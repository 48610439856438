import { useAuth0 } from '@auth0/auth0-react';
import type { Email } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { isString } from '@pandler/shared/type-guards';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export interface UseSendEmailOptions {
  email: Email;
  type?: 'send' | 'queue';
  invoiceId?: string;
}

export default function useSendEmail() {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useOrganization();

  return useMutation({
    mutationFn: async ({
      email,
      type = 'send',
      invoiceId = '',
    }: UseSendEmailOptions): Promise<void> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'send:email',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/email${
          type === 'queue'
            ? `/queue/${id}`
            : `?invoiceId=${invoiceId}&organizationId=${id}`
        }`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(email),
        }
      );

      if ($response.status !== 204 && $response.status !== 201) {
        const { message } = ((await $response.json()) ?? {}) as Record<
          string,
          unknown
        >;

        if (isString(message)) {
          throw new Error(message);
        } else {
          throw Error('An error occurred when sending the email');
        }
      }

      queryClient.invalidateQueries({
        queryKey: ['messages', 'queue'],
      });
    },
  });
}
