import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader, Tabs } from '@pandler/shared/react-ui';
import { tabs } from '../../config';

export function SubscriptionPage() {
  return (
    <>
      <Helmet>
        <title>Subscription</title>
      </Helmet>

      <PageHeader
        title="Subscription"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Settings', href: '/settings' },
              {
                name: 'Subscription',
                href: '/settings/subscription',
                current: true,
              },
            ]}
          />
        }
      />

      <Content>
        <Tabs
          initialActive="subscription"
          tabs={tabs}
          contentClassName="mt-10"
        />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(SubscriptionPage);
