import type { ZodIssue } from 'zod';

interface FieldErrorProps {
  error: ZodIssue;
  defaultMessage?: string;
}

export function FieldError({
  error,
  defaultMessage = 'This field is required',
}: FieldErrorProps) {
  return <>{error?.message ?? defaultMessage}</>;
}

export const FieldGroupFieldError = (error: ZodIssue) => (
  <FieldError error={error} />
);
