import { EmptyStateIcon } from './svgs/icon';
import { CreateTemplateButton } from '../create-template-button';

export function EmptyState() {
  return (
    <div className="flex flex-col space-y-6 justify-center text-center mt-16">
      <EmptyStateIcon className="h-60 mb-4" />
      <div>
        <h2 className="text-xl font-bold mb-1.5">
          You don't have any custom templates
        </h2>
        <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
          Templates allow you to easily build, personalise and manage email and
          sms communications with your customers.
        </p>
      </div>

      <div>
        <CreateTemplateButton />
      </div>
    </div>
  );
}
