import { Helmet } from 'react-helmet-async';
import {
  Breadcrumbs,
  ChartContainer,
  Invoice,
  PageHeader,
} from '@pandler/shared/react-ui';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { useParams } from 'react-router-dom';
import { Comments } from '../../components/comments';
import { useOrganization } from '@pandler/cloud/shared';
import { Timeline } from '../../components/timeline';
import { CustomerCard } from '../../components/customer-card';
import useGetInvoice from '../../hooks/use-get-invoice';
import { InvoiceActionBar } from '../../components/invoice-action-bar';

export function ViewPage() {
  const { id = '' } = useParams();
  const { data, isPreviousData } = useGetInvoice({ id });
  const { organization } = useOrganization();

  return (
    <>
      <Helmet>
        <title>
          {data ? `Invoice #${data.invoice.number}` : 'View invoice'}
        </title>
      </Helmet>

      <PageHeader
        className="print:hidden"
        title="View invoice"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Invoices', href: '/invoices' },
              { name: 'View invoice', href: '/invoices', current: true },
            ]}
          />
        }
        right={
          data?.invoice ? (
            <InvoiceActionBar invoice={data.invoice} />
          ) : (
            <></> // eslint-disable-line
          )
        }
      />

      <Content>
        <div className="mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Invoice */}
            {data && organization && (
              <>
                <Invoice
                  className="print:shadow-none print:p-0"
                  invoice={data.invoice}
                  company={{
                    address: organization.billingAddress,
                    name: organization.legalName,
                    logo: organization.logo,
                  }}
                  customer={data.customer}
                />

                <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-7 divide-y divide-gray-200 print:hidden">
                  <h2
                    id="activity-title"
                    className="text-lg font-medium text-gray-900  pb-4"
                  >
                    Activity
                  </h2>

                  <div className="pt-6">
                    <Comments
                      invoice={data.invoice}
                      showLoading={isPreviousData}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-3 print:hidden"
          >
            {data?.customer && (
              <ChartContainer
                childContainerClassName="py-8"
                title={data.customer.name}
              >
                <CustomerCard customer={data.customer} />
              </ChartContainer>
            )}

            <div>
              {!data && (
                <div className="animate-pulse bg-slate-200 rounded h-[350px]" />
              )}

              {data && (
                <ChartContainer title="Timeline">
                  <div className="max-h-[350px] overflow-y-scroll">
                    <Timeline invoiceId={data.invoice.id} />
                  </div>
                </ChartContainer>
              )}
            </div>
          </section>
        </div>
      </Content>
    </>
  );
}

export default withAuthenticationRequired(ViewPage);
