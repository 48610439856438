import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { LoadingIcon } from '../loading-icon';

interface LoadingScreenProps {
  children?: ReactNode | ReactNode[];
  position?: 'top' | 'bottom';
  className?: string;
}

export function LoadingScreen({
  children,
  position,
  className = '',
}: LoadingScreenProps) {
  return (
    <main
      className={twMerge(
        'w-screen h-screen p-3 bg-black-50 flex items-center justify-center',
        className
      )}
    >
      {children && position === 'top' && <>{children}</>}

      <LoadingIcon
        strokeWidth={1.9}
        className="w-14 h-14 text-primary-600"
        iconClassName="text-primary-700 h-6"
      />

      {children && position === 'bottom' && <>{children}</>}
    </main>
  );
}
