import type { Organization, User } from '@pandler/shared/data-types';

interface UserProfileProps {
  organization: Organization;
  user: User;
}

function Attribute({ title, value }: { title: string; value: string }) {
  return (
    <div className="flex flex-col text-sm gap-2">
      <span className="text-gray-500">{title}</span>
      <span className="text-gray-700">{value}</span>
    </div>
  );
}

export function UserProfile({ organization, user }: UserProfileProps) {
  return (
    <section className="flex gap-12">
      {user.avatar && (
        <img className="h-32 w-32 rounded-full" src={user.avatar} alt="" />
      )}
      <section className="grow grid grid-cols-3 gap-4">
        <Attribute
          title="Name"
          value={[user.firstName, user.lastName].filter(Boolean).join(' ')}
        />
        <Attribute title="Email" value={user.email} />
        {organization.name && (
          <Attribute title="Organization" value={organization.name} />
        )}
        {organization.website && (
          <Attribute title="Website" value={organization.website} />
        )}
      </section>
    </section>
  );
}
