import { type ReactNode, useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

interface Item {
  heading: string | ReactNode;
  body: string | ReactNode;
}

export interface AccordionProps {
  className?: string;
  items: Item[];
  buttonClassName?: string;
  defaultOpen?: boolean;
  panelClassName?: string;
}

interface AccordionItemProps {
  item: Item;
  open: boolean;
  buttonClassName?: string;
  panelClassName?: string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  item,
  open,
  buttonClassName = '',
  panelClassName = '',
}) => {
  const panelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { current: panel } = panelRef;

    if (!panel) {
      return;
    }

    const recalculateHeight = () => {
      panel.style.height = `${open ? panel.scrollHeight : 0}px`;
    };

    const observer = new MutationObserver(recalculateHeight);

    observer.observe(panel, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    recalculateHeight();

    return () => observer.disconnect();
  }, [open]);

  return (
    <>
      <Disclosure.Button
        className={twMerge(
          'flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 rounded-lg hover:bg-gray-50 transition-all duration-300',
          buttonClassName
        )}
      >
        <div>{item.heading}</div>
        <ChevronUpIcon
          className={`${
            open ? 'transform rotate-180' : ''
          } w-5 h-5 text-gray-500`}
        />
      </Disclosure.Button>
      <div
        ref={panelRef}
        className="overflow-hidden transition-all duration-300 ease-in-out"
      >
        <Disclosure.Panel
          static
          className={twMerge(
            'px-4 pt-4 pb-2 text-sm text-gray-500',
            panelClassName
          )}
        >
          {item.body}
        </Disclosure.Panel>
      </div>
    </>
  );
};

export function Accordion({
  items,
  className = '',
  buttonClassName = '',
  defaultOpen,
  panelClassName = '',
}: AccordionProps) {
  return (
    <div className={className}>
      {items.map((item, idx) => (
        <Disclosure
          as="div"
          key={idx}
          className="mt-2"
          defaultOpen={defaultOpen}
        >
          {({ open }) => (
            <AccordionItem
              item={item}
              open={open}
              buttonClassName={buttonClassName}
              panelClassName={panelClassName}
            />
          )}
        </Disclosure>
      ))}
    </div>
  );
}
