import Result from 'esresult';

export type Operator = '$.add';

type OperatorOptions = {
  currentValue: unknown;
  input: unknown;
};

type ApplyOperatorsToDocumentOptions = {
  document: Record<string, unknown>;
  update: Record<string, unknown>;
};

type ApplyOperatorOptions = Pick<OperatorOptions, 'currentValue'> & {
  operator: Operator;
  value: unknown;
};

type ApplyOperatorsOptions = {
  currentValue: unknown;
  operators: Record<Operator, unknown>;
};

const Operators = {
  '$.add': add,
} as Record<
  Operator,
  (options: OperatorOptions) => Result<unknown, 'ILLEGAL' | 'INVALID'>
>;

/**
 * Check to see if a request body has any operators within them
 */
export function hasOperator(param: unknown): boolean {
  if (!param || typeof param !== 'object') {
    return false;
  }

  if (Array.isArray(param)) {
    return false;
  }

  return Object.keys(param).some(
    (key) => !!(Operators as Record<string, unknown>)[key]
  );
}

/**
 * Checks if a request body has any db operators.
 *
 * An example of a request body with a db operator:
 *
 * ```json
 * {
 *   "name": "Harry Potter",
 *   "quidditchWins": {
 *     "$.add": {
 *       {
 *         "date": "2000-01-01",
 *         "description": "Won the final game of the season against slytherin"
 *       }
 *     }
 *   }
 * }
 * ```
 */
export function hasOperators(request: Record<string, unknown>): boolean {
  return Object.values(request).some((value) => hasOperator(value));
}

/**
 * Applies an operator (e.g. "$.add") to a value and returns error when unsuccessful
 */
export function applyOperator(options: ApplyOperatorOptions) {
  const { currentValue, operator, value } = options;

  const fn = Operators[operator];
  if (!fn) {
    return Result.error('InvalidError');
  }

  const $update = fn({ currentValue, input: value });
  if ($update.error) {
    return Result.error('UpdateError', { cause: $update });
  }

  return Result($update.value);
}

/**
 * Applies an object of operators against a (rolling) value
 */
export function applyOperators(
  options: ApplyOperatorsOptions
): Result<
  Record<string, unknown>,
  ['Invalid', { errors: Result.Error.Any[] }]
> {
  const { currentValue, operators } = options;

  // check if any operations return an error
  const $errors = Object.entries(operators)
    .map(([operator, value]: [string, unknown]) =>
      applyOperator({ currentValue, operator: operator as Operator, value })
    )
    .filter((result) => result.error);

  if ($errors.length > 0) {
    return Result.error([
      'Invalid',
      {
        errors: $errors.map(
          ({ error }) => error as unknown as Result.Error.Any
        ),
      },
    ]);
  }

  // @todo copy value a little more eloquently
  let rollingValue = JSON.parse(JSON.stringify(currentValue));
  Object.entries(operators).forEach(([operator, value]) => {
    const $value = applyOperator({
      currentValue: rollingValue,
      operator: operator as Operator,
      value,
    });

    if (!$value.error) {
      rollingValue = $value.value;
    }
  });

  return Result(rollingValue);
}

/**
 * Apply operators to document
 * @todo tidy up errors - it all chains at the moment (should not - flatten)
 */
export function applyOperatorsToDocument(
  options: ApplyOperatorsToDocumentOptions
) {
  const { document, update } = options;

  const $invalidOperators = Object.entries(update)
    .filter(([, operators]) => hasOperator(operators))
    .map(([key, value]) =>
      applyOperators({
        currentValue: document[key],
        operators: value as Record<Operator, unknown>,
      })
    )
    .filter((result) => result.error);

  if ($invalidOperators.length > 0) {
    return Result.error([
      'INVALID',
      { errors: $invalidOperators.map(({ error }) => error) },
    ]);
  }

  const newDocument = Object.entries(update).reduce((obj, [key, value]) => {
    if (hasOperator(value)) {
      const updated = applyOperators({
        currentValue: document[key],
        operators: value as Record<Operator, unknown>,
      });
      if (!updated.error) {
        return { ...obj, [key]: updated.value };
      }
    }

    return obj;
  }, {});

  return Result(newDocument);
}

/**
 * As the name suggests, the `add` operator adds a value to an `object` or `array`.
 * If adding to an array, it is placed at the end of the array. If adding to an object,
 * then the input value must also be an object.
 */
export function add(
  options: OperatorOptions
): Result<unknown[] | Record<string, unknown>, 'Illegal' | 'Invalid'> {
  const { currentValue, input } = options;

  if (typeof currentValue !== 'object') {
    return Result.error('Illegal');
  }

  if (Array.isArray(currentValue)) {
    return Result([...currentValue, input]);
  }

  if (typeof input !== 'object' || Array.isArray(input)) {
    return Result.error('Invalid');
  }

  return Result({ ...currentValue, ...input });
}
