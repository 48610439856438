import { useGetDomainDnsStatus } from '@pandler/cloud/shared';
import type { VerifiedDomain } from '@pandler/shared/data-types';
import classNames from 'classnames';

interface DomainStatusProps {
  domain: VerifiedDomain;
}

export function DomainStatus({ domain }: DomainStatusProps) {
  const { data, isLoading, isError } = useGetDomainDnsStatus({
    id: domain.id,
    refetchUntilReady: true,
    refetchInterval: 60000,
  });

  if (isLoading) {
    return (
      <div className="w-2.5 h-2.5 rounded-full bg-amber-500 animate-pulse" />
    );
  }

  if (isError || !data.record) {
    return (
      <>
        <div className="w-2.5 h-2.5 rounded-full bg-red-500 animate-pulse" />
        <span>Unable to fetch status</span>
      </>
    );
  }

  return (
    <>
      <div
        className={classNames('w-2.5 h-2.5 rounded-full', {
          'bg-emerald-500': data.record.status === 'VERIFIED',
          'bg-amber-500 animate-pulse': data.record.status === 'NOT_VERIFIED',
        })}
      />
      <span>{domain.status === 'VERIFIED' ? 'Connected' : 'Verifying'}</span>
    </>
  );
}
