import { EmptyState, PowerfulIcon } from '@pandler/shared/react-ui';

export function InvoiceEmptyState() {
  return (
    <EmptyState
      icon={PowerfulIcon}
      title="You're all caught up!"
      body="You have no outstanding notifications pending delivery."
    />
  );
}
