import {
  createContext,
  type ReactNode,
  useState,
  useCallback,
  useMemo,
} from 'react';
import type { SlideOutProps } from './slide-out';
import { SlideOut } from './slide-out';

export interface SlideOutContext {
  showPane: (pane: ReactNode, props?: Partial<SlideOutProps>) => void;
  closePane: () => void;
}

export const SlideOutContext = createContext<SlideOutContext>({
  showPane: (pane: ReactNode) => {
    // do nothing
  },
  closePane: () => {
    // do nothing
  },
});

interface SlideOutProviderProps {
  children: ReactNode;
}

export function SlideOutProvider({ children }: SlideOutProviderProps) {
  const [open, setOpen] = useState(false);
  const [pane, setPane] = useState<
    { node: ReactNode; props: Partial<SlideOutProps> } | undefined
  >();

  const showPane = useCallback(
    (SlideOut: ReactNode, props?: Partial<SlideOutProps>) => {
      setPane({ node: SlideOut, props: props ?? {} });
      setTimeout(() => {
        setOpen(true);
      }, 0);
    },
    []
  );

  const closePane = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      setPane(undefined);
    }, 700);
  }, []);

  const paneMemo = useMemo<SlideOutContext>(
    () => ({ showPane, closePane }),
    [showPane, closePane]
  );

  return (
    <SlideOutContext.Provider value={paneMemo}>
      <>
        {pane && (
          <SlideOut {...(pane?.props ?? {})} open={open}>
            {pane.node}
          </SlideOut>
        )}

        {children}
      </>
    </SlideOutContext.Provider>
  );
}
