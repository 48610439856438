import { TINYMCE_API_KEY, useSendEmailQueueItem } from '@pandler/cloud/shared';
import type { EmailQueueItem } from '@pandler/shared/data-types';
import { success, error as showError } from '@pandler/shared/react-ui';
import { EmailForm } from '@pandler/shared/inbox-ui';
import { asArray } from '@pandler/shared/type-guards';

interface EditQueueItemFormProps {
  message: EmailQueueItem;
  onClose: () => void;
  className?: string;
}

export function EditQueueItemForm({
  message,
  onClose,
  className,
}: EditQueueItemFormProps) {
  const { mutate: sendEmail, isLoading } = useSendEmailQueueItem();

  return (
    <EmailForm
      apiKey={TINYMCE_API_KEY}
      className={className}
      to={message.recipient}
      message={asArray(message.email.content)?.[0]?.value ?? ''}
      subject={message.email.subject}
      onSubmit={() =>
        sendEmail(
          { id: message.id },
          {
            onSuccess: () => {
              success({
                message: `Your message has successfully been sent`,
              });
              onClose();
            },
            onError: (error) =>
              showError({
                message:
                  error instanceof Error
                    ? error.message
                    : `There was an error sending your message`,
              }),
          }
        )
      }
      isLoading={isLoading}
      hideQueue
      additionalOptions={
        <span
          className="text-red-500 hover:underline hover:text-red-600 transition-colors text-sm cursor-pointer"
          onClick={onClose}
        >
          Close
        </span>
      }
    />
  );
}
