import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { TemplatePageEditor } from '../../components';

export function HomePage() {
  return (
    <>
      <Helmet>
        <title>Templates</title>
      </Helmet>

      <TemplatePageEditor />
    </>
  );
}

export default withAuthenticationRequired(HomePage);
