import { EmptyWorkflowStateIcon } from '@pandler/shared/react-ui';
import { NewWorkflowButton } from '../new-workflow-button';

interface EmptyWorkflowStateProps {
  title?: string;
}

export function EmptyWorkflowState({
  title = 'You do not have any workflows',
}: EmptyWorkflowStateProps) {
  return (
    <div className="flex flex-col space-y-6 justify-center text-center mt-16">
      <EmptyWorkflowStateIcon className="h-52 mb-4" />
      <div>
        <h2 className="text-xl font-bold mb-1.5">{title}</h2>
        <p className="text-sm text-gray-500">
          Create your first workflow to start automating your receivables.
        </p>
      </div>

      <div>
        <NewWorkflowButton />
      </div>
    </div>
  );
}
