import type { PlatformApiScope } from '@pandler/shared/utils';
import { z } from 'zod';
import { createUpdatableZodObject } from '../helpers';
import { Address } from './address';

export const Customer = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  contactName: z.string().optional(),
  address: Address.optional(),
  organizationId: z.string().uuid(),
});

export type Customer = z.infer<typeof Customer>;

type CustomerAttribute = keyof typeof Customer.shape;

const protectedAttributes = {
  'write:customer': ['organizationId'],
} as Record<PlatformApiScope, CustomerAttribute[]>;

export const UpdatableCustomer = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: Customer,
    protectedAttributes,
  });
