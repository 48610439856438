import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { InboxFullscreen } from '../../components/inbox-fullscreen';

export function QueuePage() {
  return (
    <>
      <Helmet>
        <title>Notifications queue</title>
      </Helmet>

      <Content
        className="flex flex-col flex-auto grow h-full w-full md:px-0"
        childrenContainerClassName="flex flex-col flex-auto grow h-full w-full p-0"
        containerClassName="flex flex-col flex-auto grow h-full w-full py-0"
      >
        <InboxFullscreen />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(QueuePage);
