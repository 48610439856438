import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '@pandler/shared/utils';
import { Logo } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '@pandler/cloud/shared';

type NavigationItem = {
  name: string;
  href?: string;
  icon?: React.ElementType;
  children?: NavigationItem[];
  current?: boolean;
};

export interface MobileSidebar {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  navigation: NavigationItem[];
}

export function MobileSidebar({
  isOpen,
  navigation,
  setIsOpen,
}: MobileSidebar) {
  const { user } = useCurrentUser();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 md:hidden"
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-primary-700">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <Logo className="h-10 text-white" textColor="#ffffff" />
              </div>
              <nav className="mt-5 px-2 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-primary-800 text-white'
                        : 'text-white hover:bg-primary-600 hover:bg-opacity-75',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                  >
                    {item.icon && (
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-primary-300"
                        aria-hidden="true"
                      />
                    )}
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-primary-800 p-4">
              {user && (
                <Link to="/" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    {user.avatar && (
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={user.avatar}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="ml-3 flex flex-col gap-1">
                      <Link
                        to="/user/profile"
                        className="text-base font-medium text-white"
                      >
                        {user.firstName} {user.lastName}
                      </Link>
                      <Link
                        to="/user/profile"
                        className="text-sm font-medium text-primary-200 group-hover:text-white"
                      >
                        View profile
                      </Link>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
