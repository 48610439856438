import { z } from 'zod';
import { Email } from './email';

export const EmailQueueItem = z.object({
  id: z.string().uuid(),
  organizationId: z.string().uuid(),
  email: Email,
  subject: z.string(),
  recipient: z.string(),
  queuedAt: z.number(),
  status: z.enum(['pending', 'sent']),
});

export type EmailQueueItem = z.infer<typeof EmailQueueItem>;
