import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export interface StatBoxInputArguments {
  title: string;
  stat: string;
  change?: string;
  changeDirection?: 'up' | 'down';
  changeType?: 'good' | 'bad';
  icon?: React.ElementType;
  link?: string;
  iconClassName?: string;
  description?: string;
  className?: string;
  copyClassName?: string;
}

export function StatBox({
  title,
  stat,
  change,
  changeType,
  changeDirection,
  link,
  description,
  icon: Icon,
  iconClassName = '',
  className = '',
  copyClassName = '',
}: StatBoxInputArguments) {
  return (
    <div
      className={twMerge(
        classNames(
          'relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden',
          { 'pb-12': !!link }
        ),
        className
      )}
    >
      <dt>
        {Icon && (
          <div
            className={twMerge(
              'absolute bg-primary-500 rounded-md p-3',
              iconClassName
            )}
          >
            <Icon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
        )}
        <p className="ml-16 text-sm font-medium text-gray-500 truncate">
          {title}
        </p>
      </dt>
      <dd className={twMerge('ml-16 pb-2 sm:pb-7', copyClassName)}>
        <div className="flex items-baseline ">
          <p className="text-2xl font-semibold text-gray-900">{stat}</p>
          {change && (
            <p
              className={classNames(
                changeType === 'good' ? 'text-green-600' : 'text-red-600',
                'ml-2 flex items-baseline text-sm font-semibold'
              )}
            >
              {changeDirection === 'up' ? (
                <ArrowSmallUpIcon
                  className={classNames(
                    changeType === 'good' ? 'text-green-500' : 'text-red-600',
                    'self-center flex-shrink-0 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
              ) : (
                <ArrowSmallDownIcon
                  className={classNames(
                    changeType === 'good' ? 'text-green-500' : 'text-red-600',
                    'self-center flex-shrink-0 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
              )}

              <span className="sr-only">
                {changeDirection === 'up' ? 'Increased' : 'Decreased'} by
              </span>
              {change}
            </p>
          )}
        </div>

        {description && (
          <p className="text-sm mt-2 text-gray-400">{description}</p>
        )}

        {link && (
          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link
                to={link}
                className="font-medium text-primary-600 hover:text-primary-500"
              >
                View all<span className="sr-only"> {title} stats</span>
              </Link>
            </div>
          </div>
        )}
      </dd>
    </div>
  );
}
