export function moveStylesToBody(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const styles = doc.querySelectorAll('style');
  const body = doc.body;

  if (styles.length > 0) {
    styles.forEach((style) => {
      body.insertBefore(style, body.firstChild);
    });
  }

  return doc.documentElement.outerHTML;
}
