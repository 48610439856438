import * as React from 'react';

export function BuildersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 744 417" {...props}>
      <path
        d="M515.786 132.155H501.65a1.818 1.818 0 0 1-1.816-1.647l-2.83-29.001h23.427l-2.83 29.001a1.818 1.818 0 0 1-1.815 1.647Z"
        fill="#e6e6e6"
      />
      <path
        d="M520.394 105.155h-23.352a1.826 1.826 0 0 1-1.824-1.824v-4.378a1.826 1.826 0 0 1 1.824-1.825h23.352a1.826 1.826 0 0 1 1.824 1.825v4.378a1.826 1.826 0 0 1-1.824 1.824Z"
        fill="#ccc"
      />
      <path
        d="M350.4 101h-56V84c17.696-12.315 33.375-11.244 54 1Z"
        fill="#6c63ff"
      />
      <path
        fill="#ffb8b8"
        d="m281.433 402.957-12.26-.001-1.833-47.288 14.095.001-.002 47.288z"
      />
      <path
        d="M284.56 414.84h-39.531v-.5a15.386 15.386 0 0 1 15.386-15.387h24.145Z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="m350.486 402.957 12.26-.001 1.833-47.288-14.095.001.002 47.288z"
      />
      <path
        d="m347.36 398.954 24.143-.001h.001a15.386 15.386 0 0 1 15.387 15.386v.5l-39.531.002ZM350.808 396.896a4.52 4.52 0 0 1-4.394-3.522l-30.386-132.54a1.457 1.457 0 0 0-1.464-1.173h-.008a1.458 1.458 0 0 0-1.46 1.188l-27.905 131.646a4.522 4.522 0 0 1-4.402 3.566h-13.293a4.5 4.5 0 0 1-4.492-4.766l9.901-166.855 3.984-1.179.075.001 78.258.685 16.667 166.672a4.498 4.498 0 0 1-4.116 4.922l-16.604 1.34q-.181.015-.36.015Z"
        fill="#2f2e41"
      />
      <circle cx={318.491} cy={45.971} r={24.561} fill="#ffb8b8" />
      <path
        d="M303.445 242.734a42.393 42.393 0 0 1-12.728-1.791 28.097 28.097 0 0 1-17.038-14.375 4.505 4.505 0 0 1-.335-3.355c2.709-9.245 15.799-57.3 7.447-95.503a40.485 40.485 0 0 1 5.755-31.114 39.977 39.977 0 0 1 25.927-17.438v-.001a60.107 60.107 0 0 1 4.14-.66A39.836 39.836 0 0 1 348.87 89.11a40.82 40.82 0 0 1 12.816 32.36l-6.419 103.004a4.46 4.46 0 0 1-2.398 3.703c-7.392 3.874-29.6 14.557-49.424 14.557Z"
        fill="#3f3d56"
      />
      <path
        d="M346.237 27.892a30.156 30.156 0 0 0-33.052-18.584l7.744 6.258c-9.622.324-19.75-.365-26.62 6.38 2.187-.062 5.054 4.616 7.241 4.556-4.089.08-7.797 2.954-9.603 6.623a18.865 18.865 0 0 0-1.114 11.997c.808 4.01 6.968 13.402 8.627 17.14-.286-9.77 18.949-33.074 28.341-30.642a25.772 25.772 0 0 0-11.9 9.035 34.904 34.904 0 0 1 20.7-3.387 14.65 14.65 0 0 0 4.936.276 6.025 6.025 0 0 0 3.572-9.628Z"
        fill="#2f2e41"
      />
      <path
        d="M301.753 236.549a4.504 4.504 0 0 1-1.802-.38l-46.6-20.34a4.47 4.47 0 0 1-2.554-5.278l30.85-116.539a16.865 16.865 0 0 1 20.94-11.903l12.336 3.52-8.684 146.681a4.513 4.513 0 0 1-2.146 3.57 4.466 4.466 0 0 1-2.34.669ZM388 210.55l-2.05-7.73-2.97-11.24-1.43-5.39-2.97-11.21-21.43-80.97a16.847 16.847 0 0 0-20.94-11.9l-12.33 3.52 6.11 103.17.63 10.77.4 6.79v.01l.64 10.71.9 15.23a4.53 4.53 0 0 0 4.49 4.24 4.583 4.583 0 0 0 1.8-.38l46.6-20.34a4.466 4.466 0 0 0 2.55-5.28Z"
        fill="#6c63ff"
      />
      <path
        fill="#ccc"
        d="m381.55 186.19-50.929 13.38-.631-10.77 48.591-13.82 2.969 11.21zM385.95 202.82l-54.29 14.26-.639-10.71v-.01l51.96-14.78 2.969 11.24zM308.181 191.12l-.631 10.57-49.949-14.88 2.929-11.06 47.651 15.37zM307.14 208.65l-50.97-16.45-2.93 11.09 53.28 15.87.62-10.51z"
      />
      <path
        d="M257.059 219.704a10.743 10.743 0 0 0 7.044-14.89l23.962-75.226-16.992-3.559-21.969 73.753a10.8 10.8 0 0 0 7.955 19.922Z"
        fill="#ffb8b8"
      />
      <path
        d="M288.73 92.606c4.879.497 7.623 5.983 6.41 12.814l-5.821 32.778c-.437 2.457-2.459 4.403-4.14 3.983l-19.69-4.922c-1.515-.379-2.073-2.532-1.215-4.685l11.821-29.654c2.567-6.44 7.904-10.797 12.635-10.314Z"
        fill="#3f3d56"
      />
      <path
        d="M318.4 0a31.993 31.993 0 0 1 31.975 31h6.025a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-68a2 2 0 0 1-2-2v-9a32 32 0 0 1 32-32Z"
        fill="#6c63ff"
      />
      <path
        d="M322.224 197.805H308.09a1.818 1.818 0 0 1-1.816-1.647l-2.83-29.002h23.427l-2.83 29.002a1.818 1.818 0 0 1-1.816 1.647Z"
        fill="#e6e6e6"
      />
      <path
        d="M326.832 170.805h-23.351a1.826 1.826 0 0 1-1.825-1.824v-4.379a1.826 1.826 0 0 1 1.825-1.824h23.351a1.826 1.826 0 0 1 1.824 1.824v4.379a1.826 1.826 0 0 1-1.824 1.824Z"
        fill="#ccc"
      />
      <path
        d="M315.46 182.252a10.526 10.526 0 0 1 1.626.319l37.646-32.284-2.2-11.846 17.442-5.657 5.934 20.591a8 8 0 0 1-3.41 8.975l-47.677 30.17a10.497 10.497 0 1 1-9.361-10.268Z"
        fill="#ffb8b8"
      />
      <path
        d="M354.83 95.313c5.212-1.459 10.42 2.508 12.164 9.266l8.374 32.43c.627 2.431-.592 5.015-2.496 5.289l-22.307 3.208c-1.716.247-3.228-1.513-3.291-3.831l-.866-31.913c-.188-6.931 3.37-13.034 8.422-14.449Z"
        fill="#3f3d56"
      />
      <path
        d="M654.663 302.47a167.506 167.506 0 0 1-24.075-44.932 4.303 4.303 0 0 1 2.031-5.18l35.146-18.83a4.247 4.247 0 0 1 3.725-.152 4.32 4.32 0 0 1 2.461 2.884 102.328 102.328 0 0 0 22.446 41.89 4.318 4.318 0 0 1 1.038 3.647 4.247 4.247 0 0 1-2.189 3.018l-35.145 18.832a4.303 4.303 0 0 1-5.438-1.177Z"
        fill="#ccc"
      />
      <path
        d="M657.061 300.668a1.299 1.299 0 0 0 1.622.335l35.146-18.832a1.246 1.246 0 0 0 .652-.897 1.318 1.318 0 0 0-.324-1.128 105.34 105.34 0 0 1-23.107-43.125 1.319 1.319 0 0 0-.758-.895 1.247 1.247 0 0 0-1.11.046l-35.146 18.831a1.298 1.298 0 0 0-.62 1.538 164.507 164.507 0 0 0 23.645 44.127Z"
        fill="#fff"
      />
      <path
        d="m670.072 260.402-19.142 8.45a3.003 3.003 0 0 1-3.956-1.533l-.473-1.073a3.003 3.003 0 0 1 1.533-3.956l19.141-8.45a3.003 3.003 0 0 1 3.956 1.533l.474 1.073a3.003 3.003 0 0 1-1.533 3.956ZM677.072 273.402l-19.142 8.45a3.003 3.003 0 0 1-3.956-1.533l-.473-1.073a3.003 3.003 0 0 1 1.533-3.956l19.141-8.45a3.003 3.003 0 0 1 3.956 1.533l.474 1.073a3.003 3.003 0 0 1-1.533 3.956Z"
        fill="#ccc"
      />
      <path
        d="M632.179 262.57a10.743 10.743 0 0 1-2.063-16.343l-8.072-114.558 23.253 2.255.639 112.186a10.8 10.8 0 0 1-13.757 16.46Z"
        fill="#a0616a"
      />
      <path
        d="M627.308 170.162a4.482 4.482 0 0 1-1.86-3.4l-1.703-30.877a12.399 12.399 0 0 1 24.347-3.927l7.484 27.605a4.505 4.505 0 0 1-3.166 5.52l-21.29 5.773a4.483 4.483 0 0 1-3.812-.694Z"
        fill="#3f3d56"
      />
      <path
        fill="#a0616a"
        d="M587.676 403.408h-12.26l-5.832-47.288h18.094l-.002 47.288z"
      />
      <path
        d="M566.66 399.905h23.643v14.886h-38.53a14.887 14.887 0 0 1 14.886-14.886Z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M633.676 403.408h-12.26l-5.832-47.288h18.094l-.002 47.288z"
      />
      <path
        d="M612.66 399.905h23.643v14.886h-38.53a14.887 14.887 0 0 1 14.886-14.886ZM587.097 379.585l-13.497-.643a4.499 4.499 0 0 1-4.285-4.463c-3.558-54.92-8.486-113.807-.942-136.557a4.501 4.501 0 0 1 5.146-4.485l53.994 7.838a4.474 4.474 0 0 1 3.853 4.42c6.894 26.936 7.205 75.782 6.945 126.534a4.5 4.5 0 0 1-4.5 4.534h-14.55a4.479 4.479 0 0 1-4.446-3.8l-8.977-57.068a3.5 3.5 0 0 0-6.932.128l-7.127 59.602a4.517 4.517 0 0 1-4.468 3.966q-.107 0-.214-.006Z"
        fill="#2f2e41"
      />
      <path
        d="M596.598 245.7c-11.9-6.61-21.197-8.348-25.68-8.795a4.418 4.418 0 0 1-3.054-1.673 4.478 4.478 0 0 1-.93-3.402l12.937-96.05a33.22 33.22 0 0 1 19.363-25.957 32.306 32.306 0 0 1 31.396 2.46q.665.443 1.305.904a33.178 33.178 0 0 1 12.636 34.573c-7.933 32.455-10.658 85.662-11.124 96a4.465 4.465 0 0 1-2.918 4.004 45.085 45.085 0 0 1-15.226 2.711 38.125 38.125 0 0 1-18.705-4.774Z"
        fill="#3f3d56"
      />
      <circle cx={607.419} cy={72.22} r={24.561} fill="#a0616a" />
      <path
        d="M624.8 91.651c-4.582 4.881-13.091 2.261-13.688-4.407a8.056 8.056 0 0 1 .01-1.556c.308-2.953 2.014-5.635 1.606-8.753a4.59 4.59 0 0 0-.84-2.149c-3.652-4.89-12.223 2.187-15.669-2.24-2.113-2.713.371-6.986-1.25-10.02-2.14-4.003-8.479-2.028-12.454-4.221-4.423-2.44-4.158-9.225-1.247-13.353 3.55-5.033 9.776-7.72 15.923-8.106s12.253 1.274 17.993 3.511c6.52 2.541 12.987 6.054 17 11.788 4.88 6.973 5.35 16.348 2.91 24.501-1.485 4.96-6.552 11.02-10.294 15.005Z"
        fill="#2f2e41"
      />
      <path
        d="M644.25 115.53c-.43-2.02-4.12-4.78-9.4-7.03a58.135 58.135 0 0 0-52.5 4.5 2.48 2.48 0 0 0-2.29 1.95L562.3 193.5l-2.31 10.23-1.53 6.77-2.31 10.2-1.23 5.45a2.508 2.508 0 0 0 2.22 3.05l76.69 6.62c.07 0 .15.01.22.01a2.5 2.5 0 0 0 2.48-2.34l.73-11.11.78-11.88.34-5.15.77-11.85 5.11-77.84v-.07Z"
        fill="#6c63ff"
      />
      <path
        d="M518.416 120.474a10.526 10.526 0 0 1 .24 1.64l42.957 24.783 10.441-6.011 11.131 14.572-22.337 15.92-49.008-38.662a10.496 10.496 0 1 1 6.576-12.242Z"
        fill="#a0616a"
      />
      <path
        d="M563.147 144.79a4.482 4.482 0 0 1 1.293-3.654l21.863-21.869a12.399 12.399 0 0 1 19.168 15.517l-15.57 23.992a4.505 4.505 0 0 1-6.224 1.325l-18.504-12.009a4.483 4.483 0 0 1-2.026-3.303Z"
        fill="#3f3d56"
      />
      <path
        fill="#ccc"
        d="m639.15 193.5-.77 11.85-78.39-1.62 2.31-10.23h76.85zM638.041 210.5l-.781 11.88-81.11-1.68 2.311-10.2h79.58z"
      />
      <path
        d="M607.9 24.5a31.993 31.993 0 0 0-31.974 31H569.9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h68a2 2 0 0 0 2-2v-9a32 32 0 0 0-32-32Z"
        fill="#6c63ff"
      />
      <path
        d="M108.114 343.056a39.063 39.063 0 0 1-37.72-6.645c-13.213-11.089-17.355-29.358-20.726-46.275L39.7 240.101l20.872 14.371c15.01 10.335 30.357 21.001 40.75 35.971s14.929 35.405 6.579 51.604"
        fill="#e6e6e6"
      />
      <path
        d="M106.33 403.635c2.113-15.388 4.285-30.973 2.803-46.525-1.316-13.812-5.53-27.303-14.109-38.364a63.82 63.82 0 0 0-16.373-14.838c-1.638-1.033-3.145 1.561-1.515 2.59a60.672 60.672 0 0 1 24 28.957c5.224 13.29 6.063 27.776 5.163 41.893-.545 8.537-1.699 17.019-2.862 25.49a1.554 1.554 0 0 0 1.048 1.844 1.51 1.51 0 0 0 1.845-1.047Z"
        fill="#f2f2f2"
      />
      <path
        d="M85.983 376.406a28.755 28.755 0 0 1-25.055 12.931c-12.683-.602-23.256-9.454-32.775-17.858L0 346.622l18.633-.892c13.4-.64 27.145-1.239 39.907 2.893s24.533 14.077 26.867 27.288"
        fill="#e6e6e6"
      />
      <path
        d="M112.3 412.48c-10.167-17.991-21.961-37.986-43.034-44.377a48.022 48.022 0 0 0-18.101-1.869c-1.922.166-1.442 3.128.476 2.963a44.614 44.614 0 0 1 28.886 7.642c8.145 5.544 14.487 13.252 19.855 21.425 3.287 5.007 6.232 10.226 9.178 15.438.941 1.666 3.693.463 2.74-1.222Z"
        fill="#f2f2f2"
      />
      <path
        d="M742.704 416.5h-712a1 1 0 0 1 0-2h712a1 1 0 0 1 0 2Z"
        fill="#ccc"
      />
    </svg>
  );
}
