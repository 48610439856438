import { EnvelopeIcon } from '@heroicons/react/24/solid';
import type { NodeProps} from 'react-flow-renderer';
import { Handle, Position } from 'react-flow-renderer';
import type { Logic } from '../../../types';
import { AddNodeButton } from '../../add-node-button';

export function LogicNode({ data, isConnectable }: NodeProps<Logic>) {
  return (
    <div className="px-4 pt-3 pb-3.5 bg-white shadow rounded w-80 relative cursor-pointer hover:bg-slate-50 transition-colors duration-300">
      <div className="absolute left-0 top-0 w-1 h-full bg-amber-500 rounded rounded-r-none" />
      <Handle
        type="target"
        position={Position.Top}
        className="invisible"
        isConnectable={isConnectable}
      />
      <div className="flex items-center">
        <div className="w-8 h-8 bg-amber-100/75 rounded-full flex items-center justify-center">
          <EnvelopeIcon className="w-5 h-5 text-amber-500" />
        </div>
        <div className="grow text-gray-700 ml-3">
          <div className="text-xs text-gray-500 font-semibold">
            Conditional split
          </div>
          <div className="text-sm">{data.title}</div>
        </div>
      </div>

      <AddNodeButton />

      <Handle
        type="source"
        position={Position.Bottom}
        className="invisible"
        isConnectable={isConnectable}
      />
    </div>
  );
}
