import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useCurrentUser } from '../users';

export interface UseAddCommentOptions {
  invoiceId: string;
}

export interface UseAddCommentMutationOptions {
  message: unknown;
}

export default function useAddComment({ invoiceId }: UseAddCommentOptions) {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useCurrentUser();

  return useMutation({
    mutationFn: async ({ message }: UseAddCommentMutationOptions) => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:invoice',
      });

      const response = await (
        await fetch(`${API_ENDPOINT}/invoice/${invoiceId}`, {
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            activity: {
              '$.add': {
                type: 'Comment',
                author: user ? `${user.firstName} ${user.lastName}` : 'Unknown',
                avatar: user?.avatar,
                body: message,
              },
            },
          }),
        })
      ).json();

      const validResponse = Boolean(response.data?.updated);
      if (!validResponse) {
        return Error('Unable to add comment to invoice');
      }

      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] });

      return true;
    },
  });
}
