import {
  LoadingEllipsis,
  LoadingIcon,
  TimedText,
} from '@pandler/shared/react-ui';

interface LoadingScreenProps {
  onComplete: () => void;
}

export function LoadingScreen({ onComplete }: LoadingScreenProps) {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <LoadingIcon className="w-8 h-8 text-primary-600" />

      <h4 className="text-gray-800 font-bold text-lg mt-6">
        Creating your workflow
      </h4>

      <TimedText
        steps={[
          { text: 'Validating your configuration', offset: 0 },
          { text: 'Creating your automations', offset: 2500 },
          {
            text: 'Executing a test run to ensure everything is working',
            offset: 5000,
          },
        ]}
        suffix={<LoadingEllipsis className="text-base" />}
        className="text-sm text-gray-400"
        completeOffset={3000}
        onComplete={onComplete}
      />
    </div>
  );
}
