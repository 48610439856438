import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import type { Templates } from '@pandler/shared/templates';
import type { PlatformTemplatesApiSearchQueryParameters } from '@pandler/shared/api-typings';

const useSearchPlatformTemplate = (
  options: PlatformTemplatesApiSearchQueryParameters
) => {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery<Templates[number][], Error>(
    ['platform-templates', options],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:templates',
      });

      const queryString = new URLSearchParams(
        options as unknown as Record<string, string>
      ).toString();

      try {
        const response = await fetch(
          `${API_ENDPOINT}/platform-templates?${queryString}`,
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );

        return response.json();
      } catch {
        throw Error('Unable to search for templates');
      }
    },
    { staleTime: Infinity, retry: false }
  );

  return query;
};

export default useSearchPlatformTemplate;
