import * as React from 'react';

export function StripeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 467 594"
      fill="none"
      {...props}
    >
      <path
        fill="#3F3D56"
        d="M273.127 0H64.583C32.15 0 5.855 26.294 5.855 58.728v476.544C5.855 567.706 32.149 594 64.583 594h208.544c32.434 0 58.728-26.294 58.728-58.728V58.728C331.855 26.294 305.561 0 273.127 0Z"
      />
      <path
        fill="#fff"
        d="M326.355 58.733v476.539a53.29 53.29 0 0 1-53.23 53.23H64.585a53.29 53.29 0 0 1-53.23-53.23V58.732a53.292 53.292 0 0 1 53.23-53.23h27.27v9.42a16.08 16.08 0 0 0 16.08 16.08h121.84a16.079 16.079 0 0 0 16.08-16.08v-9.42h27.27a53.293 53.293 0 0 1 53.23 53.23Z"
      />
      <path
        fill="#3F3D56"
        d="M4.355 120.5a4.003 4.003 0 0 1-4-4v-8a3.999 3.999 0 0 1 6.828-2.828 3.998 3.998 0 0 1 1.172 2.828v8a4.005 4.005 0 0 1-4 4ZM4.355 161.521a4.007 4.007 0 0 1-4-4V135.5a3.999 3.999 0 0 1 6.828-2.828 3.998 3.998 0 0 1 1.172 2.828v22.021a4.006 4.006 0 0 1-4 4ZM4.355 194.5a4.003 4.003 0 0 1-4-4v-22.021a4 4 0 1 1 8 0V190.5a4.005 4.005 0 0 1-4 4ZM332.354 176.5a4.004 4.004 0 0 1-4-4v-53.733a4.002 4.002 0 0 1 6.829-2.829 4 4 0 0 1 1.171 2.829V172.5a4.007 4.007 0 0 1-4 4Z"
      />
      <path
        fill="#fff"
        d="M177.447 18.346h-31a2.5 2.5 0 0 1 0-5h31a2.501 2.501 0 0 1 0 5ZM193.447 17.846a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
      <path
        fill="#E6E6E6"
        d="M79.94 29.073h-27a5 5 0 0 1 0-10h27a5 5 0 0 1 0 10ZM182.355 238.695h-27a5.002 5.002 0 0 1-5-5 4.999 4.999 0 0 1 5-5h27a5 5 0 1 1 0 10ZM256.94 28.573a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM270.94 28.573a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM284.94 28.573a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM255.158 308.695H68.425a18.154 18.154 0 0 1-18.133-18.134v-18.732a18.155 18.155 0 0 1 18.133-18.134h186.733a18.154 18.154 0 0 1 18.134 18.134v18.732a18.157 18.157 0 0 1-18.134 18.134Zm-186.733-53a16.151 16.151 0 0 0-16.133 16.134v18.732a16.15 16.15 0 0 0 16.133 16.134h186.733a16.153 16.153 0 0 0 16.134-16.134v-18.732a16.155 16.155 0 0 0-16.134-16.134H68.425ZM182.355 377.695h-27a5.002 5.002 0 0 1-5-5 4.999 4.999 0 0 1 5-5h27a5 5 0 1 1 0 10Z"
      />
      <path
        fill="#6C63FF"
        d="M188.558 522.695h-39.405a7.31 7.31 0 0 1-5.166-2.134 7.305 7.305 0 0 1-2.14-5.163 7.305 7.305 0 0 1 2.14-5.163 7.296 7.296 0 0 1 5.166-2.135h39.405a7.297 7.297 0 0 1 0 14.595Z"
      />
      <path
        fill="#E6E6E6"
        d="M255.158 447.695H68.425a18.154 18.154 0 0 1-18.133-18.134v-18.732a18.155 18.155 0 0 1 18.133-18.134h186.733a18.154 18.154 0 0 1 18.134 18.134v18.732a18.157 18.157 0 0 1-18.134 18.134Zm-186.733-53a16.151 16.151 0 0 0-16.133 16.134v18.732a16.15 16.15 0 0 0 16.133 16.134h186.733a16.153 16.153 0 0 0 16.134-16.134v-18.732a16.155 16.155 0 0 0-16.134-16.134H68.425ZM289.44 193.5H47.94v2h241.5v-2Z"
      />
      <path
        fill="#FFB6B6"
        d="M283.16 272.865a6.479 6.479 0 0 0 7.143 5.538 6.18 6.18 0 0 0 1.005-.215l13.435 18.182 11.624-3.571-21.097-22.917a6.465 6.465 0 0 0-6.752-4.08 6.178 6.178 0 0 0-4.119 2.333 6.188 6.188 0 0 0-1.239 4.73ZM452.222 400.518a6.48 6.48 0 0 1-.173-9.037c.239-.247.497-.473.773-.678l-6.551-21.637 9.809-7.187 5.77 30.611a6.46 6.46 0 0 1-.764 7.852 6.187 6.187 0 0 1-6.708 1.49 6.187 6.187 0 0 1-2.156-1.414ZM396.623 582.429l-9.901-.001-4.71-38.19 14.613.001-.002 38.19Z"
      />
      <path
        fill="#2F2E41"
        d="m396.971 592.758-30.447-.001v-.385a11.855 11.855 0 0 1 11.851-11.851l5.563-4.219 10.376 4.22h2.658l-.001 12.236Z"
      />
      <path
        fill="#FFB6B6"
        d="m439.623 582.429-9.901-.001-4.71-38.19 14.613.001-.002 38.19Z"
      />
      <path
        fill="#2F2E41"
        d="m439.971 592.758-30.447-.001v-.385a11.855 11.855 0 0 1 11.851-11.851l5.563-4.219 10.376 4.22h2.658l-.001 12.236ZM367.291 377.153s-11.27 15.711-3.135 42.856c8.135 27.144 3.568 51.412 5.852 56.278 2.283 4.866 6.88 9.487 3.082 13.177-3.799 3.689-5.799 6.689-2.799 7.689 3 1 13 71 13 71l17 1s6.159-32.517-.42-58.759c0 0 1.444-19.05-1.568-24.645-3.012-5.596-2.421-.549-2.216-7.573a61.657 61.657 0 0 1 2.204-15.023c1-4 1.58-44.315 1.58-44.315l22.1 49.798c.664 1.843.913 3.81.73 5.761-.41 2.756-.413 6.874.088 8.315.502 1.441 1.061 1.744.782 4.592-.28 2.849-.28 78.391-.28 78.391h19l9-75.542a24.785 24.785 0 0 1-4-6c-2-4-2.078-4.221-1.039-7.11 1.039-2.89 1.039-8.52 1.039-10.205 0-1.685 1.039-78.182-11.98-89.434-13.02-11.251-68.02-.251-68.02-.251Z"
      />
      <path
        fill="#3F3D56"
        d="m425.638 257.067-18.981-4.918-8.86-12.22-21.041 2.587-4.479 16.668-14.98 8.695s8.28 87.571 6.31 92.38c-1.969 4.808-9.03 4.411-3 10.61 6.031 6.198 6.83 3.702 5.43 10.45-1.399 6.748-11.6 13.89 0 13.819 11.601-.071 81.601-1.071 79.601-9.071s-1.969-5.968-4.984-10.984c-3.016-5.016-5.046-2.213-4.031-9.614 1.015-7.402.015-12.402.015-16.402 0-4-3.351-40.348-3.351-40.348l-7.649-51.652Z"
      />
      <path
        fill="#3F3D56"
        d="M366.418 266.199s-18.78-2.132-22.78 6.868-14.081 27.741-14.081 27.741-7.859-8.222-8.62-11.949c-.645-3.164-1 2.121-6.28-3.6-2.365-2.564-.366-2.564-3.366-3.564l-6-2-10 9s-.195 10.874 5 11c5.196.126-2-2 5 5 3.615 3.614.3 3.016 6.323 6.694 6.024 3.678 5.344 3.663 7.184 5.671 1.84 2.007 11.714 3.469 11.714 3.469l44.22-24.47-8.314-29.86ZM415.769 260.851s8.053-11.799 21.816 3.53c13.764 15.328 24.056 85.304 23.41 87.816-.646 2.512-4.275 1.071.039 5.292 4.315 4.22 7.315 1.22 4.315 4.22s-9.261 5.823-4.13 6.912c5.13 1.088-14.828 7.079-14.828 7.079s-2.908-1.062-3.031-8.069c-.069-3.936 1.931-5.936-1.069-6.936s.689-7.678.374-8.832c-.316-1.154-19.316-52.154-19.316-52.154l-7.58-38.858Z"
      />
      <path
        fill="#FFB6B6"
        d="M384.118 237.837c12.733 0 23.056-10.322 23.056-23.055 0-12.734-10.323-23.056-23.056-23.056s-23.056 10.322-23.056 23.056c0 12.733 10.323 23.055 23.056 23.055Z"
      />
      <path
        fill="#2F2E41"
        d="M399.292 232.695c-5.45-4.26 2-23-7-19 0 0-11-7-13.21-2.4-2.559 5.326-11.66 2.91-17.25 1.03.439-1.021.751-2.093.93-3.19a25.609 25.609 0 0 1-3.27 2.19 6.616 6.616 0 0 1-2-1.64 4.055 4.055 0 0 1-.51-4.61c.93-1.43 2.85-1.87 3.98-3.14a1.378 1.378 0 0 0 .187-1.256 3.596 3.596 0 0 1 3.22-4.501 1.29 1.29 0 0 0 .613-.133 14.07 14.07 0 0 0 2.61-2.06 25.915 25.915 0 0 1-3.94-.11c-.27-.03-.54-.06-.81-.1a8.861 8.861 0 0 1 5.47-4.97 26.026 26.026 0 0 1 7.92-1.11l4.64-.15a46.461 46.461 0 0 1 9.79.34 23.916 23.916 0 0 1 16.57 11.84c3.39 6.15 7.06 25.97-7.94 32.97Z"
      />
      <path
        fill="#6C63FF"
        d="m340.458 341.259-118.85 42.021a11.764 11.764 0 0 1-14.996-7.162l-20.417-57.747a11.768 11.768 0 0 1 7.162-14.996l118.85-42.02a11.762 11.762 0 0 1 14.996 7.162l20.417 57.746a11.764 11.764 0 0 1-7.162 14.996Z"
      />
      <path
        fill="#fff"
        d="M214.191 316.782c-7.878 5.184-17.974 1.283-21.505-4.399l.563-.32c6.706-4.181 16.666-1.852 20.942 4.719ZM312.913 331.49a6.443 6.443 0 0 1-5.161-.093c-3.382-1.681-4.969-6.664-6.244-10.668-.371-1.164-.722-2.265-1.079-3.164l-.005-.014a7.172 7.172 0 0 1 4.346-9.104l15.986-5.652a6.446 6.446 0 0 1 5.155.092c3.381 1.679 4.97 6.66 6.246 10.662.372 1.167.724 2.27 1.082 3.171a7.178 7.178 0 0 1-4.343 9.119l-15.983 5.651ZM214.954 347.392a1.69 1.69 0 0 1-1.717-.285 2.115 2.115 0 0 1-.691-1.952 1.695 1.695 0 0 1 1.19-1.314l6.084-2.151a1.7 1.7 0 0 1 1.75.276 2.115 2.115 0 0 1 .69 1.952 1.704 1.704 0 0 1-1.19 1.311l-6.085 2.152-.031.011ZM211.723 361.197a2.99 2.99 0 0 1-2.889-1.079 3.74 3.74 0 0 1-.538-3.631 2.998 2.998 0 0 1 2.516-1.88l22.663-7.605a3.003 3.003 0 0 1 2.95 1.074 3.748 3.748 0 0 1 .536 3.63 3.022 3.022 0 0 1-2.516 1.877l-22.663 7.605-.059.009ZM331.648 281.087l-140.605 49.712.237.672 140.606-49.713-.238-.671Z"
      />
      <path
        fill="#6C63FF"
        d="M309.292 319.695a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM322.292 320.695a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
      <path
        fill="#635BFF"
        fillRule="evenodd"
        d="M107.871 140.665c0 9.29-7.42 14.63-18.22 14.63a36.017 36.017 0 0 1-14.16-2.95v-12.35c4.36 2.38 9.86 4.15 14.16 4.15 2.91 0 4.99-.78 4.99-3.17 0-6.17-19.72-3.89-19.72-18.21 0-9.19 7-14.69 17.54-14.69 4.404-.042 8.775.77 12.87 2.39v12.19a28.937 28.937 0 0 0-12.87-3.32c-2.75 0-4.41.78-4.41 2.8 0 5.82 19.77 3.07 19.82 18.53ZM123.081 108.955h9.86v11.06h-9.86v18.42c0 7.68 8.2 5.29 9.86 4.62v10.53c-1.72.94-4.83 1.71-9.09 1.71a13.026 13.026 0 0 1-9.588-3.773 13.034 13.034 0 0 1-3.852-9.557l.05-41.57 12.67-2.7-.05 11.26ZM159.872 108.955v11.94c-1.61-.57-6.8-1.3-9.86 2.7v30.77h-12.97v-45.41h11.21l.83 3.84c3.06-5.6 9.129-4.46 10.79-3.84ZM163.041 94.425l13.02-2.75v10.53l-13.02 2.81v-10.59Z"
        clipRule="evenodd"
      />
      <path fill="#635BFF" d="M176.062 108.955h-13.02v45.41h13.02v-45.41Z" />
      <path
        fill="#635BFF"
        fillRule="evenodd"
        d="M203.152 108.075a14.676 14.676 0 0 0-10.17 4.05l-.67-3.22h-11.42v60.51l12.97-2.75.05-14.69a14.618 14.618 0 0 0 9.19 3.27c9.29 0 17.75-7.47 17.75-23.92-.05-15.05-8.62-23.25-17.7-23.25Zm-3.11 35.76a7.779 7.779 0 0 1-6.131-2.44l-.049-19.26a7.742 7.742 0 0 1 6.18-2.54c4.72 0 7.99 5.29 7.99 12.09 0 6.96-3.22 12.15-7.99 12.15ZM261.742 131.845c0-13.29-6.43-23.77-18.73-23.77-12.35 0-19.83 10.48-19.83 23.66 0 15.62 8.83 23.51 21.49 23.51 5 .138 9.952-1.023 14.37-3.37v-10.38a27.563 27.563 0 0 1-12.71 2.85c-5.03 0-9.5-1.76-10.07-7.88h25.38c0-.68.1-3.38.1-4.62Zm-25.63-4.93c0-5.87 3.58-8.31 6.85-8.31 3.16 0 6.54 2.44 6.54 8.31h-13.39Z"
        clipRule="evenodd"
      />
      <path
        fill="#F2F2F2"
        d="M97.654 568.353a2.81 2.81 0 0 1-2.872-3.402 2.81 2.81 0 0 1 .838-1.464l.192-.765-.076-.183a7.54 7.54 0 0 0-13.907.051c-2.275 5.478-5.17 10.966-5.883 16.758a22.307 22.307 0 0 0 .391 7.671 89.424 89.424 0 0 1-8.134-37.139 86.295 86.295 0 0 1 .535-9.628 89.764 89.764 0 0 1 1.23-7.807 90.45 90.45 0 0 1 17.939-38.337 24.075 24.075 0 0 0 10.012-10.388 18.352 18.352 0 0 0 1.67-5.018c-.488.064-1.838-7.359-1.47-7.814-.68-1.031-1.895-1.543-2.637-2.549-3.69-5.002-8.773-4.129-11.426 2.669-5.669 2.861-5.724 7.606-2.246 12.169 2.213 2.903 2.517 6.832 4.459 9.94-.2.256-.407.503-.607.759a90.998 90.998 0 0 0-9.503 15.054 37.847 37.847 0 0 0-2.259-17.579c-2.163-5.217-6.216-9.611-9.786-14.121-4.288-5.418-13.08-3.054-13.836 3.814a26.86 26.86 0 0 0-.021.199c.53.3 1.05.617 1.557.953a3.806 3.806 0 0 1 .488 5.945 3.806 3.806 0 0 1-2.023.985l-.078.012c.189 1.911.523 3.805.999 5.665-4.58 17.71 5.307 24.16 19.424 24.45.312.16.615.32.927.472a92.914 92.914 0 0 0-5.002 23.539 88.137 88.137 0 0 0 .064 14.231l-.024-.168a23.292 23.292 0 0 0-7.95-13.448c-6.119-5.026-14.763-6.877-21.363-10.916a4.365 4.365 0 0 0-4.705-.012 4.373 4.373 0 0 0-1.99 4.264l.028.176c.984.401 1.942.862 2.868 1.382.53.299 1.05.617 1.557.953a3.807 3.807 0 0 1-1.535 6.93l-.078.012-.16.024a37.914 37.914 0 0 0 6.976 10.922c2.863 15.461 15.162 16.927 28.318 12.425h.008a92.885 92.885 0 0 0 6.24 18.218h22.293c.08-.248.152-.503.224-.751-2.064.13-4.135.006-6.169-.368 1.654-2.029 3.308-4.075 4.962-6.104.037-.038.072-.078.104-.12.839-1.039 1.686-2.069 2.525-3.108v-.001a37.113 37.113 0 0 0-1.088-9.452Z"
      />
      <path
        fill="#3F3D56"
        d="M273.127 0H64.583C32.15 0 5.855 26.294 5.855 58.728v476.544C5.855 567.706 32.149 594 64.583 594h208.544c32.434 0 58.728-26.294 58.728-58.728V58.728C331.855 26.294 305.561 0 273.127 0Z"
      />
      <path
        fill="#fff"
        d="M326.355 58.733v476.539a53.29 53.29 0 0 1-53.23 53.23H64.585a53.29 53.29 0 0 1-53.23-53.23V58.732a53.292 53.292 0 0 1 53.23-53.23h27.27v9.42a16.08 16.08 0 0 0 16.08 16.08h121.84a16.079 16.079 0 0 0 16.08-16.08v-9.42h27.27a53.293 53.293 0 0 1 53.23 53.23Z"
      />
      <path
        fill="#3F3D56"
        d="M4.355 120.5a4.003 4.003 0 0 1-4-4v-8a3.999 3.999 0 0 1 6.828-2.828 3.998 3.998 0 0 1 1.172 2.828v8a4.005 4.005 0 0 1-4 4ZM4.355 161.521a4.007 4.007 0 0 1-4-4V135.5a3.999 3.999 0 0 1 6.828-2.828 3.998 3.998 0 0 1 1.172 2.828v22.021a4.006 4.006 0 0 1-4 4ZM4.355 194.5a4.003 4.003 0 0 1-4-4v-22.021a4 4 0 1 1 8 0V190.5a4.005 4.005 0 0 1-4 4ZM332.354 176.5a4.004 4.004 0 0 1-4-4v-53.733a4.002 4.002 0 0 1 6.829-2.829 4 4 0 0 1 1.171 2.829V172.5a4.007 4.007 0 0 1-4 4Z"
      />
      <path
        fill="#fff"
        d="M177.447 18.346h-31a2.5 2.5 0 0 1 0-5h31a2.501 2.501 0 0 1 0 5ZM193.447 17.846a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
      <path
        fill="#E6E6E6"
        d="M79.94 29.073h-27a5 5 0 0 1 0-10h27a5 5 0 0 1 0 10ZM182.355 238.695h-27a5.002 5.002 0 0 1-5-5 4.999 4.999 0 0 1 5-5h27a5 5 0 1 1 0 10ZM256.94 28.573a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM270.94 28.573a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM284.94 28.573a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM255.158 308.695H68.425a18.154 18.154 0 0 1-18.133-18.134v-18.732a18.155 18.155 0 0 1 18.133-18.134h186.733a18.154 18.154 0 0 1 18.134 18.134v18.732a18.157 18.157 0 0 1-18.134 18.134Zm-186.733-53a16.151 16.151 0 0 0-16.133 16.134v18.732a16.15 16.15 0 0 0 16.133 16.134h186.733a16.153 16.153 0 0 0 16.134-16.134v-18.732a16.155 16.155 0 0 0-16.134-16.134H68.425ZM182.355 377.695h-27a5.002 5.002 0 0 1-5-5 4.999 4.999 0 0 1 5-5h27a5 5 0 1 1 0 10Z"
      />
      <path
        fill="#6C63FF"
        d="M188.558 522.695h-39.405a7.31 7.31 0 0 1-5.166-2.134 7.305 7.305 0 0 1-2.14-5.163 7.305 7.305 0 0 1 2.14-5.163 7.296 7.296 0 0 1 5.166-2.135h39.405a7.297 7.297 0 0 1 0 14.595Z"
      />
      <path
        fill="#E6E6E6"
        d="M255.158 447.695H68.425a18.154 18.154 0 0 1-18.133-18.134v-18.732a18.155 18.155 0 0 1 18.133-18.134h186.733a18.154 18.154 0 0 1 18.134 18.134v18.732a18.157 18.157 0 0 1-18.134 18.134Zm-186.733-53a16.151 16.151 0 0 0-16.133 16.134v18.732a16.15 16.15 0 0 0 16.133 16.134h186.733a16.153 16.153 0 0 0 16.134-16.134v-18.732a16.155 16.155 0 0 0-16.134-16.134H68.425ZM289.44 193.5H47.94v2h241.5v-2Z"
      />
      <path
        fill="#FFB6B6"
        d="M283.16 272.865a6.479 6.479 0 0 0 7.143 5.538 6.18 6.18 0 0 0 1.005-.215l13.435 18.182 11.624-3.571-21.097-22.917a6.465 6.465 0 0 0-6.752-4.08 6.178 6.178 0 0 0-4.119 2.333 6.188 6.188 0 0 0-1.239 4.73ZM452.222 400.518a6.48 6.48 0 0 1-.173-9.037c.239-.247.497-.473.773-.678l-6.551-21.637 9.809-7.187 5.77 30.611a6.46 6.46 0 0 1-.764 7.852 6.187 6.187 0 0 1-6.708 1.49 6.187 6.187 0 0 1-2.156-1.414ZM396.623 582.429l-9.901-.001-4.71-38.19 14.613.001-.002 38.19Z"
      />
      <path
        fill="#2F2E41"
        d="m396.971 592.758-30.447-.001v-.385a11.855 11.855 0 0 1 11.851-11.851l5.563-4.219 10.376 4.22h2.658l-.001 12.236Z"
      />
      <path
        fill="#FFB6B6"
        d="m439.623 582.429-9.901-.001-4.71-38.19 14.613.001-.002 38.19Z"
      />
      <path
        fill="#2F2E41"
        d="m439.971 592.758-30.447-.001v-.385a11.855 11.855 0 0 1 11.851-11.851l5.563-4.219 10.376 4.22h2.658l-.001 12.236ZM367.291 377.153s-11.27 15.711-3.135 42.856c8.135 27.144 3.568 51.412 5.852 56.278 2.283 4.866 6.88 9.487 3.082 13.177-3.799 3.689-5.799 6.689-2.799 7.689 3 1 13 71 13 71l17 1s6.159-32.517-.42-58.759c0 0 1.444-19.05-1.568-24.645-3.012-5.596-2.421-.549-2.216-7.573a61.657 61.657 0 0 1 2.204-15.023c1-4 1.58-44.315 1.58-44.315l22.1 49.798c.664 1.843.913 3.81.73 5.761-.41 2.756-.413 6.874.088 8.315.502 1.441 1.061 1.744.782 4.592-.28 2.849-.28 78.391-.28 78.391h19l9-75.542a24.785 24.785 0 0 1-4-6c-2-4-2.078-4.221-1.039-7.11 1.039-2.89 1.039-8.52 1.039-10.205 0-1.685 1.039-78.182-11.98-89.434-13.02-11.251-68.02-.251-68.02-.251Z"
      />
      <path
        fill="#3F3D56"
        d="m425.638 257.067-18.981-4.918-8.86-12.22-21.041 2.587-4.479 16.668-14.98 8.695s8.28 87.571 6.31 92.38c-1.969 4.808-9.03 4.411-3 10.61 6.031 6.198 6.83 3.702 5.43 10.45-1.399 6.748-11.6 13.89 0 13.819 11.601-.071 81.601-1.071 79.601-9.071s-1.969-5.968-4.984-10.984c-3.016-5.016-5.046-2.213-4.031-9.614 1.015-7.402.015-12.402.015-16.402 0-4-3.351-40.348-3.351-40.348l-7.649-51.652Z"
      />
      <path
        fill="#3F3D56"
        d="M366.418 266.199s-18.78-2.132-22.78 6.868-14.081 27.741-14.081 27.741-7.859-8.222-8.62-11.949c-.645-3.164-1 2.121-6.28-3.6-2.365-2.564-.366-2.564-3.366-3.564l-6-2-10 9s-.195 10.874 5 11c5.196.126-2-2 5 5 3.615 3.614.3 3.016 6.323 6.694 6.024 3.678 5.344 3.663 7.184 5.671 1.84 2.007 11.714 3.469 11.714 3.469l44.22-24.47-8.314-29.86ZM415.769 260.851s8.053-11.799 21.816 3.53c13.764 15.328 24.056 85.304 23.41 87.816-.646 2.512-4.275 1.071.039 5.292 4.315 4.22 7.315 1.22 4.315 4.22s-9.261 5.823-4.13 6.912c5.13 1.088-14.828 7.079-14.828 7.079s-2.908-1.062-3.031-8.069c-.069-3.936 1.931-5.936-1.069-6.936s.689-7.678.374-8.832c-.316-1.154-19.316-52.154-19.316-52.154l-7.58-38.858Z"
      />
      <path
        fill="#FFB6B6"
        d="M384.118 237.837c12.733 0 23.056-10.322 23.056-23.055 0-12.734-10.323-23.056-23.056-23.056s-23.056 10.322-23.056 23.056c0 12.733 10.323 23.055 23.056 23.055Z"
      />
      <path
        fill="#2F2E41"
        d="M399.292 232.695c-5.45-4.26 2-23-7-19 0 0-11-7-13.21-2.4-2.559 5.326-11.66 2.91-17.25 1.03.439-1.021.751-2.093.93-3.19a25.609 25.609 0 0 1-3.27 2.19 6.616 6.616 0 0 1-2-1.64 4.055 4.055 0 0 1-.51-4.61c.93-1.43 2.85-1.87 3.98-3.14a1.378 1.378 0 0 0 .187-1.256 3.596 3.596 0 0 1 3.22-4.501 1.29 1.29 0 0 0 .613-.133 14.07 14.07 0 0 0 2.61-2.06 25.915 25.915 0 0 1-3.94-.11c-.27-.03-.54-.06-.81-.1a8.861 8.861 0 0 1 5.47-4.97 26.026 26.026 0 0 1 7.92-1.11l4.64-.15a46.461 46.461 0 0 1 9.79.34 23.916 23.916 0 0 1 16.57 11.84c3.39 6.15 7.06 25.97-7.94 32.97Z"
      />
      <path
        fill="#6C63FF"
        d="m340.458 341.259-118.85 42.021a11.764 11.764 0 0 1-14.996-7.162l-20.417-57.747a11.768 11.768 0 0 1 7.162-14.996l118.85-42.02a11.762 11.762 0 0 1 14.996 7.162l20.417 57.746a11.764 11.764 0 0 1-7.162 14.996Z"
      />
      <path
        fill="#fff"
        d="M214.191 316.782c-7.878 5.184-17.974 1.283-21.505-4.399l.563-.32c6.706-4.181 16.666-1.852 20.942 4.719ZM312.913 331.49a6.443 6.443 0 0 1-5.161-.093c-3.382-1.681-4.969-6.664-6.244-10.668-.371-1.164-.722-2.265-1.079-3.164l-.005-.014a7.172 7.172 0 0 1 4.346-9.104l15.986-5.652a6.446 6.446 0 0 1 5.155.092c3.381 1.679 4.97 6.66 6.246 10.662.372 1.167.724 2.27 1.082 3.171a7.178 7.178 0 0 1-4.343 9.119l-15.983 5.651ZM214.954 347.392a1.69 1.69 0 0 1-1.717-.285 2.115 2.115 0 0 1-.691-1.952 1.695 1.695 0 0 1 1.19-1.314l6.084-2.151a1.7 1.7 0 0 1 1.75.276 2.115 2.115 0 0 1 .69 1.952 1.704 1.704 0 0 1-1.19 1.311l-6.085 2.152-.031.011ZM211.723 361.197a2.99 2.99 0 0 1-2.889-1.079 3.74 3.74 0 0 1-.538-3.631 2.998 2.998 0 0 1 2.516-1.88l22.663-7.605a3.003 3.003 0 0 1 2.95 1.074 3.748 3.748 0 0 1 .536 3.63 3.022 3.022 0 0 1-2.516 1.877l-22.663 7.605-.059.009ZM331.648 281.087l-140.605 49.712.237.672 140.606-49.713-.238-.671Z"
      />
      <path
        fill="#6C63FF"
        d="M309.292 319.695a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM322.292 320.695a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
      <path
        fill="#635BFF"
        fillRule="evenodd"
        d="M107.871 140.665c0 9.29-7.42 14.63-18.22 14.63a36.017 36.017 0 0 1-14.16-2.95v-12.35c4.36 2.38 9.86 4.15 14.16 4.15 2.91 0 4.99-.78 4.99-3.17 0-6.17-19.72-3.89-19.72-18.21 0-9.19 7-14.69 17.54-14.69 4.404-.042 8.775.77 12.87 2.39v12.19a28.937 28.937 0 0 0-12.87-3.32c-2.75 0-4.41.78-4.41 2.8 0 5.82 19.77 3.07 19.82 18.53ZM123.081 108.955h9.86v11.06h-9.86v18.42c0 7.68 8.2 5.29 9.86 4.62v10.53c-1.72.94-4.83 1.71-9.09 1.71a13.026 13.026 0 0 1-9.588-3.773 13.034 13.034 0 0 1-3.852-9.557l.05-41.57 12.67-2.7-.05 11.26ZM159.872 108.955v11.94c-1.61-.57-6.8-1.3-9.86 2.7v30.77h-12.97v-45.41h11.21l.83 3.84c3.06-5.6 9.129-4.46 10.79-3.84ZM163.041 94.425l13.02-2.75v10.53l-13.02 2.81v-10.59Z"
        clipRule="evenodd"
      />
      <path fill="#635BFF" d="M176.062 108.955h-13.02v45.41h13.02v-45.41Z" />
      <path
        fill="#635BFF"
        fillRule="evenodd"
        d="M203.152 108.075a14.676 14.676 0 0 0-10.17 4.05l-.67-3.22h-11.42v60.51l12.97-2.75.05-14.69a14.618 14.618 0 0 0 9.19 3.27c9.29 0 17.75-7.47 17.75-23.92-.05-15.05-8.62-23.25-17.7-23.25Zm-3.11 35.76a7.779 7.779 0 0 1-6.131-2.44l-.049-19.26a7.742 7.742 0 0 1 6.18-2.54c4.72 0 7.99 5.29 7.99 12.09 0 6.96-3.22 12.15-7.99 12.15ZM261.742 131.845c0-13.29-6.43-23.77-18.73-23.77-12.35 0-19.83 10.48-19.83 23.66 0 15.62 8.83 23.51 21.49 23.51 5 .138 9.952-1.023 14.37-3.37v-10.38a27.563 27.563 0 0 1-12.71 2.85c-5.03 0-9.5-1.76-10.07-7.88h25.38c0-.68.1-3.38.1-4.62Zm-25.63-4.93c0-5.87 3.58-8.31 6.85-8.31 3.16 0 6.54 2.44 6.54 8.31h-13.39Z"
        clipRule="evenodd"
      />
      <path
        fill="#F2F2F2"
        d="M97.654 568.353a2.81 2.81 0 0 1-2.872-3.402 2.81 2.81 0 0 1 .838-1.464l.192-.765-.076-.183a7.54 7.54 0 0 0-13.907.051c-2.275 5.478-5.17 10.966-5.883 16.758a22.307 22.307 0 0 0 .391 7.671 89.424 89.424 0 0 1-8.134-37.139 86.295 86.295 0 0 1 .535-9.628 89.764 89.764 0 0 1 1.23-7.807 90.45 90.45 0 0 1 17.939-38.337 24.075 24.075 0 0 0 10.012-10.388 18.352 18.352 0 0 0 1.67-5.018c-.488.064-1.838-7.359-1.47-7.814-.68-1.031-1.895-1.543-2.637-2.549-3.69-5.002-8.773-4.129-11.426 2.669-5.669 2.861-5.724 7.606-2.246 12.169 2.213 2.903 2.517 6.832 4.459 9.94-.2.256-.407.503-.607.759a90.998 90.998 0 0 0-9.503 15.054 37.847 37.847 0 0 0-2.259-17.579c-2.163-5.217-6.216-9.611-9.786-14.121-4.288-5.418-13.08-3.054-13.836 3.814a26.86 26.86 0 0 0-.021.199c.53.3 1.05.617 1.557.953a3.806 3.806 0 0 1 .488 5.945 3.806 3.806 0 0 1-2.023.985l-.078.012c.189 1.911.523 3.805.999 5.665-4.58 17.71 5.307 24.16 19.424 24.45.312.16.615.32.927.472a92.914 92.914 0 0 0-5.002 23.539 88.137 88.137 0 0 0 .064 14.231l-.024-.168a23.292 23.292 0 0 0-7.95-13.448c-6.119-5.026-14.763-6.877-21.363-10.916a4.365 4.365 0 0 0-4.705-.012 4.373 4.373 0 0 0-1.99 4.264l.028.176c.984.401 1.942.862 2.868 1.382.53.299 1.05.617 1.557.953a3.807 3.807 0 0 1-1.535 6.93l-.078.012-.16.024a37.914 37.914 0 0 0 6.976 10.922c2.863 15.461 15.162 16.927 28.318 12.425h.008a92.885 92.885 0 0 0 6.24 18.218h22.293c.08-.248.152-.503.224-.751-2.064.13-4.135.006-6.169-.368 1.654-2.029 3.308-4.075 4.962-6.104.037-.038.072-.078.104-.12.839-1.039 1.686-2.069 2.525-3.108v-.001a37.113 37.113 0 0 0-1.088-9.452Z"
      />
    </svg>
  );
}
