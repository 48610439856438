import type { VerifiedDomain } from '@pandler/shared/data-types';
import { ButtonDropdown, SlideOutContext } from '@pandler/shared/react-ui';
import { DeleteDomain } from './delete-domain';
import { useContext } from 'react';
import { DomainDetailsPane } from '../domain-details-pane';
import { DomainStatus } from '../domain-status';

interface DomainProps {
  domain: VerifiedDomain;
  number: number;
}

export function Domain({ domain, number }: DomainProps) {
  const { closePane, showPane } = useContext(SlideOutContext);

  const handleOnClick = () => {
    showPane(<DomainDetailsPane domain={domain} />, {
      onClose: closePane,
      title: 'Domain settings',
    });
  };

  return (
    <div
      className="rounded-lg hover:bg-gray-100 transition-all duration-300 p-3 flex flex-row space-x-6 group items-center cursor-pointer"
      key={domain.domain}
      onClick={handleOnClick}
    >
      <div className="w-16 h-12 bg-gray-100 group-hover:bg-gray-200 transition-all duration-300 rounded-lg flex items-center justify-center font-semibold text-gray-800">
        {number}
      </div>
      <div className="grow space-y-1">
        <p className="font-bold text-gray-700">{domain.domain}</p>
        <div className="text-xs text-gray-600 font-medium flex flex-row space-x-1.5 items-center">
          <DomainStatus domain={domain} />
        </div>
      </div>
      <div>
        <ButtonDropdown
          onClick={(e) => e.stopPropagation()}
          className="bg-gray-50 group-hover:bg-gray-200/80 hover:bg-gray-200 transition-all duration-300"
          items={[
            {
              label: <DeleteDomain domain={domain} />,
            },
          ]}
        />
      </div>
    </div>
  );
}
