import { z } from 'zod';
import isISO31661Alpha2 from 'validator/lib/isISO31661Alpha2';

export const Address = z.object({
  streetName: z.string().array().max(2),
  city: z.string(),
  country: z
    .string()
    .refine(isISO31661Alpha2, { message: 'Must be a valid country code' }),
  state: z.string().optional(),
  postCode: z.string(),
});

export type Address = z.infer<typeof Address>;

export function addressAsString({
  streetName,
  city,
  country,
  state,
  postCode,
}: Address) {
  return [...streetName, city, postCode, state, country]
    .filter((o) => !!o)
    .join(', ');
}
