import { type AnyZodObject, z } from 'zod';

export const DelayUntil = z.object({
  type: z.literal('delay-until'),
  time: z.string().min(5),
  when: z.enum(['sameDay', 'nextDay', 'nextWeek', 'specificDay']),
  day: z.enum(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']),
});

export type DelayUntil = z.infer<typeof DelayUntil>;
export type Day = DelayUntil['day'];
export type When = DelayUntil['when'];

export const withValidator = (obj: AnyZodObject) =>
  obj.refine((data) => {
    if (data['unit'] === 'specificDay' && !data['day']) {
      return false;
    }

    return true;
  });
