import type { PlatformApiScope } from '@pandler/shared/utils';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { createUpdatableZodObject } from '../helpers';
import { dateSchema } from './date';

const TemplateDef = {
  id: z.string().uuid().default(uuidv4),
  organizationId: z.string().uuid(),
  type: z.enum(['sms', 'email']),
  createdAt: dateSchema,
  updatedAt: dateSchema.optional(),
  name: z.string(),
  description: z.string().max(250).optional(),
  previewUrl: z.string().url().optional(),
  body: z.string(),
  defaultFrom: z.string().optional(),
  defaultSubject: z.string().optional(),
};

export const Template = z.object(TemplateDef);
export type Template = z.infer<typeof Template>;

type TemplateAttribute = keyof typeof Template.shape;

const protectedAttributes = {
  'write:templates': ['organizationId', 'createdAt', 'updatedAt', 'type'],
} as Record<PlatformApiScope, TemplateAttribute[]>;

export const UpdatableTemplate = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: Template,
    protectedAttributes,
  });

export const isTemplate = (candidate: unknown): candidate is Template =>
  typeof candidate === 'object' && !!candidate && 'body' in candidate;
