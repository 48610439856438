import { type PlatformApiScope } from '@pandler/shared/utils';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { createUpdatableZodObject } from '../helpers';

const PaymentSettingsDef = {
  id: z.string().uuid().default(uuidv4),
  organizationId: z.string().uuid(),
  stripeId: z.string(),
  status: z.enum(['UNVERIFIED', 'VERIFIED']).default('UNVERIFIED').optional(),
  account: z.unknown().optional(),
};

export const PaymentSettings = z.object(PaymentSettingsDef);
export type PaymentSettings = z.infer<typeof PaymentSettings>;

type PaymentSettingsAttribute = keyof typeof PaymentSettings.shape;

const protectedAttributes = {
  'write:payments': ['organizationId'],
} as Record<PlatformApiScope, PaymentSettingsAttribute[]>;

export const UpdatablePaymentSettings = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: PaymentSettings,
    protectedAttributes,
  });

export const isPaymentSettings = (
  candidate: unknown
): candidate is PaymentSettings =>
  Boolean(
    typeof candidate === 'object' &&
      candidate &&
      'organizationId' in candidate &&
      'stripeId' in candidate
  );
