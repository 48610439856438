import type { Tuple } from '@pandler/shared/utils';

export default function isValidAbn(abn: string) {
  abn = abn.replace(/\D/g, '');

  // ABN must be exactly 11 digits long
  if (abn.length !== 11) {
    return false;
  }

  // Subtract 1 from the first (left-most) digit of the ABN
  const abnArray = abn.split('').map(Number) as Tuple<11>;
  abnArray[0] = abnArray[0] - 1;

  // Calculate the sum of the products of each digit and its weighting factor
  const weightingFactors = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19] as Tuple<11>;
  const products = abnArray.map(
    (digit, index) => digit * (weightingFactors[index] ?? 0)
  );
  const sum = products.reduce((acc, curr) => acc + curr);

  // Verify the ABN using the modulus 89 calculation
  const remainder = sum % 89;
  return remainder === 0;
}
