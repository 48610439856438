import {
  friendlyInvoiceStatusName,
  type Invoice,
} from '@pandler/shared/data-types';
import classNames from 'classnames';

interface InvoiceStatusProps {
  status: Invoice['status'];
}

export function InvoiceStatus({ status }: InvoiceStatusProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        {
          'bg-gray-100 text-gray-800': status === 'Draft',
          'bg-green-100 text-green-800': status === 'Paid',
          'bg-blue-100 text-blue-800': status === 'Submitted',
          'bg-emerald-100 text-emerald-800': status === 'PartiallyPaid',
          'bg-red-100 text-red-800': status === 'Void',
        }
      )}
    >
      {friendlyInvoiceStatusName(status)}
    </span>
  );
}
