import { useAuth0 } from '@auth0/auth0-react';
import { type Organization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { Result } from 'esresult';
import { GetAccessTokenDefaults } from '../../config';

export default function useTriggerSync() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({
      organizationId,
    }: {
      organizationId: string;
    }): Result.Async<Organization, 'UNKNOWN'> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:organization',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/organization/${organizationId}/sync`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if ($response.status === 200) {
        const json = await $response.json();
        return Result(json.data);
      }

      return Result.error('UNKNOWN');
    },
  });
}
