import { type EditorText } from '@pandler/shared/data-types';
import { Editor } from 'slate';
import { type PandlerEditor } from './types';

export function isMarkActive(
  editor: PandlerEditor,
  format: keyof Omit<EditorText, 'text'>
) {
  const marks = Editor.marks(editor);
  return marks && marks[format] === true;
}

export function toggleMark(
  editor: PandlerEditor,
  format: keyof Omit<EditorText, 'text'>
) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}
