import { useAuth0 } from '@auth0/auth0-react';
import {
  GetAccessTokenDefaults,
  useCurrentUser,
  useOrganization,
} from '@pandler/cloud/shared';
import { useEffect, useState } from 'react';
import { AuthenticatedWsState } from './authenticated-ws-state';

export function WsState() {
  const [token, setToken] = useState<string | undefined>();
  const { userId } = useCurrentUser();
  const { organizationId } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchToken() {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:organization',
      });

      setToken(token);
    }

    if (!token) {
      fetchToken();
    }
  }, [getAccessTokenSilently, token]);

  if (!token) {
    return null;
  }

  return (
    <AuthenticatedWsState
      token={token}
      organizationId={organizationId ?? ''}
      userId={userId ?? ''}
    />
  );
}
