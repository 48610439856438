import { useEmailQueueItem } from '@pandler/cloud/shared';
import { LoadingContainer } from '@pandler/shared/react-ui';
import { Message } from '../message';

export interface MessagePreviewProps {
  id: string;
}

export function MessagePreview({ id }: MessagePreviewProps) {
  const { message, isLoading, error } = useEmailQueueItem(id);

  if (isLoading || error || !message) {
    return <LoadingContainer />;
  }

  return <Message message={message} />;
}
