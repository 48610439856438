import * as React from 'react';

interface BellIconProps extends React.SVGProps<SVGSVGElement> {
  primary?: string;
  secondary?: string;
}

export function BellIcon({
  primary = '#303033',
  secondary = '#4F46E5',
  ...props
}: BellIconProps) {
  return (
    <svg
      viewBox="0 0 38 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 24h9v-2c0-4.4-3.6-8-8-8h-1v10Z" fill={secondary} />
      <path
        d="M38 40c-2.206 0-4-1.794-4-4V21.5c0-6.315-4.85-11.617-11.369-13.083A4.972 4.972 0 0 0 24 5c0-2.757-2.243-5-5-5s-5 2.243-5 5c0 1.33.53 2.533 1.38 3.429C8.855 9.947 4 15.447 4 22v14c0 2.206-1.794 4-4 4v2h12.08c.488 3.387 3.401 6 6.92 6s6.432-2.613 6.92-6H38v-2ZM19 2c1.654 0 3 1.346 3 3a2.996 2.996 0 0 1-2 2.816V5h-2v2.816A2.996 2.996 0 0 1 16 5c0-1.654 1.346-3 3-3Zm0 44a5.01 5.01 0 0 1-4.899-4h2.083A2.996 2.996 0 0 0 19 44a2.996 2.996 0 0 0 2.816-2h2.083A5.01 5.01 0 0 1 19 46ZM4.468 40A5.976 5.976 0 0 0 6 36V22c0-6.617 5.832-12 13-12s13 5.159 13 11.5V36c0 1.535.58 2.938 1.532 4H4.468Z"
        fill={primary}
      />
    </svg>
  );
}
