import { FormGroup, Input, Select } from '@pandler/shared/react-ui';
import { useEffect, useState } from 'react';

interface ChooseScheduleProps {
  onValidChange: (isValid: boolean) => void;
}

const items = new Array(30).fill('').map((d, index) => ({
  value: index + 1,
  label: `${index + 1} day${index > 0 ? 's' : ''}`,
}));

export function ChooseSchedule({ onValidChange }: ChooseScheduleProps) {
  const [schedule, setSchedule] = useState<number[]>([1, 7, 14]);
  const [isValid, setIsValid] = useState(schedule.length > 0);

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  return (
    <form className="pb-10 px-6">
      <h3 className="text-xl mb-1 text-gray-700 font-semibold">
        When would you like to schedule your reminders?
      </h3>
      <p className="text-sm text-gray-400 mb-4">
        How many days before your invoice is due would you like to send a
        reminder?
      </p>

      <Select
        items={items}
        value={schedule}
        multiple
        onChange={(value) => {
          setSchedule(value as number[]);
          setIsValid((value as number[]).length > 0);
        }}
        className="mb-8"
        listBoxClassName="max-h-48"
      />

      <FormGroup
        id="time"
        label="Time of day"
        description="What time in the day would you like messages to be sent?"
        input={
          <Input
            className="mt-2"
            type="time"
            name="time"
            placeholder="hh:mm"
            defaultValue="09:00"
          />
        }
      />
    </form>
  );
}
