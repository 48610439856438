import type { PublicOrganization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

interface UsePublicOrganizationOptions {
  id: string;
  queryOptions?: UseQueryOptions<PublicOrganization, Error, PublicOrganization>;
}

export default function usePublicOrganization({
  id,
  queryOptions = {},
}: UsePublicOrganizationOptions) {
  return useQuery<PublicOrganization, Error>(
    ['public-organization', id],
    async () => {
      const $response = await fetch(
        `${API_ENDPOINT}/organization/public/${id}`,
        {
          headers: { accept: 'application/json' },
        }
      );

      const data = await $response.json();
      if (!data) {
        throw new Error('Unable to retrieve organization');
      }

      return data;
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );
}
