import { type PlatformApiScope } from '@pandler/shared/utils';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { createUpdatableZodObject } from '../helpers';
import { WorkflowEdge } from './workflow-edge';
import { WorkflowNode } from './workflow-node';

const WorkflowDef = {
  id: z.string().uuid().default(uuidv4),
  type: z
    .enum(['overdue', 'reminders', 'debt-collection', 'custom'])
    .default('custom'),
  status: z.enum(['active', 'disabled']).default('active'),
  name: z.string().min(1),
  description: z.string().optional(),
  nodes: z.array(WorkflowNode).default([]),
  edges: z.array(WorkflowEdge).default([]),
  organizationId: z.string().uuid(),
};

export const Workflow = z.object(WorkflowDef);
export type Workflow = z.infer<typeof Workflow>;

type WorkflowAttribute = keyof typeof Workflow.shape;

const protectedAttributes = {
  'write:workflow': ['organizationId'],
} as Record<PlatformApiScope, WorkflowAttribute[]>;

export const UpdatableWorkflow = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: Workflow,
    protectedAttributes,
  });
