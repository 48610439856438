import { Helmet } from 'react-helmet-async';
import {
  Content,
  RuntimeError,
  withAuthenticationRequired,
} from '@pandler/cloud/skeleton';
import { GetAccessTokenDefaults, useOrganization } from '@pandler/cloud/shared';
import {
  Breadcrumbs,
  LoadingContainer,
  PageHeader,
  Tabs,
} from '@pandler/shared/react-ui';
import { WorkflowsList } from '../../components/workflows-list';
import { EmptyWorkflowState } from '../../components/empty-workflows';
import { NewWorkflowButton } from '../../components/new-workflow-button';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import type { Workflow } from '@pandler/shared/data-types';

export function HomePage() {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();
  const { isLoading, data, error } = useQuery<
    { active: Workflow[]; all: Workflow[]; disabled: Workflow[] },
    Error
  >(['workflows'], async () => {
    const token = await getAccessTokenSilently({
      ...GetAccessTokenDefaults,
      scope: 'read:workflow',
    });
    const $response = await fetch(
      `${API_ENDPOINT}/workflows/${organization?.id}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    const { data = {} } = await $response.json();
    if (Array.isArray(data)) {
      const byStatus = (status: string) =>
        (data as Workflow[]).filter((w) => w.status === status);

      return {
        all: data as Workflow[],
        active: byStatus('active'),
        disabled: byStatus('disabled'),
      };
    }

    throw new Error('An unexpected error occurred');
  });

  const isEmpty = !isLoading && data && data.all.length === 0;

  return (
    <>
      <Helmet>
        <title>Workflows</title>
      </Helmet>

      {!isEmpty && (
        <PageHeader
          title="Workflows"
          breadcrumbs={
            <Breadcrumbs
              items={[{ name: 'Workflows', href: '/workflows', current: true }]}
            />
          }
          right={<NewWorkflowButton />}
        />
      )}

      <Content>
        {isLoading && <LoadingContainer /> /* Retrieving data over api */}
        {error && <RuntimeError error={error} /> /* Error during api fetch */}
        {isEmpty && <EmptyWorkflowState /> /* No workflows */}

        {!isLoading && data && data.all.length > 0 && (
          <Tabs
            tabs={[
              {
                id: 'active',
                title: 'Active',
                content:
                  data.active.length > 0 ? (
                    <WorkflowsList items={data.active} />
                  ) : (
                    <EmptyWorkflowState title="You do not have any active workflows" />
                  ),
              },
              {
                id: 'disabled',
                title: 'Disabled',
                content:
                  data.disabled.length > 0 ? (
                    <WorkflowsList items={data.disabled} />
                  ) : (
                    <p className="text-sm text-gray-900">
                      You currently have no disabled workflows.
                    </p>
                  ),
              },
            ]}
          />
        )}
      </Content>
    </>
  );
}

export default withAuthenticationRequired(HomePage);
