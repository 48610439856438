import { z } from 'zod';

export const dateSchema = z
  .preprocess((arg: unknown) => {
    if (typeof arg == 'string' || arg instanceof Date) {
      return new Date(arg);
    }

    return arg;
  }, z.date())
  .transform((d) => d.toISOString());

export type DateSchema = z.infer<typeof dateSchema>;
