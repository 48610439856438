import { createContext } from 'react';
import type { WorkflowAction } from '../types';

interface WorkflowContext {
  actions: WorkflowAction[];
  getAction: (id: string) => WorkflowAction | undefined;
  initialized: boolean;
}

export const WorkflowContext = createContext<WorkflowContext>({
  actions: [],
  getAction: () => undefined,
  initialized: false,
});
