import { Button, SlideOutContext } from '@pandler/shared/react-ui';
import { useContext } from 'react';
import { DomainForm } from '../domain-form';

export function AddDomainButton() {
  const { closePane, showPane } = useContext(SlideOutContext);

  const handleOnClick = () => {
    showPane(<DomainForm />, {
      onClose: closePane,
      title: 'Add domain',
    });
  };

  return <Button onClick={handleOnClick}>Add domain</Button>;
}
