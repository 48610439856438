import { type Zorm } from 'react-zorm';
import { twMerge } from 'tailwind-merge';
import type { FormDefinition } from './form-definition';
import { useMemo } from 'react';

export interface AvailableMessageProps {
  className?: string;
  zo: Zorm<typeof FormDefinition>;
}

export function AvailableMessage({
  className = '',
  zo,
}: AvailableMessageProps) {
  const value = useMemo(
    () => (zo.validation?.success ? zo.validation.data.publicId : ''),
    [zo.validation]
  );

  return (
    <p
      className={twMerge(
        'text-sm text-center text-emerald-600 font-semibold',
        className
      )}
    >
      The url https://{value}.pandler.app is available.
    </p>
  );
}
