import { stepStatus } from '@pandler/shared/react-ui';
import { useState } from 'react';
import { TemplateModal } from '../../../template-modal';
import { CreateDialog } from '../create-dialog/create-dialog';
import { ChooseSchedule } from './forms/choose-schedule';
import { CommunicationType } from './forms/communication-type';
import { MessageContent } from './forms/message-content';
import { Review } from './forms/review';

const steps = [
  {
    name: 'Communication type',
    description: 'Choose from email and SMS messages.',
  },
  {
    name: 'Delivery schedule',
    description: 'When messages are sent.',
  },
  {
    name: 'Messages',
    description: 'Content of messages to send.',
  },
  {
    name: 'Review',
    description: 'Confirm your workflow.',
  },
];

function StepBody({
  step,
  onValidChange,
}: {
  step: number;
  onValidChange: (isValid: boolean) => void;
}) {
  if (step === 1) {
    return <CommunicationType onValidChange={onValidChange} />;
  }

  if (step === 2) {
    return <ChooseSchedule onValidChange={onValidChange} />;
  }

  if (step === 3) {
    return <MessageContent onValidChange={onValidChange} />;
  }

  if (step === 4) {
    return <Review onValidChange={onValidChange} />;
  }

  if (step === 5) {
    return <CreateDialog />;
  }

  return <>Unknown step</>;
}

function nextLabel(step: number) {
  switch (step) {
    case 1:
      return 'Proceed to schedule';
    case 2:
      return 'Proceed to message content';
    case 3:
      return 'Proceed to review';
    case 4:
      return 'Create';
  }

  return 'Next';
}

export function UpcomingRemindersConfiguration() {
  const [valid, setValid] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  function handleOnNextClick() {
    setCurrentStep(currentStep + 1);
    setValid(false);
  }

  function handleOnBackClick() {
    setCurrentStep(currentStep - 1);
    setValid(true);
  }

  return (
    <TemplateModal
      title="Upcoming Invoice Reminders"
      description="Scheduled email and SMS reminders as invoices approach their due date."
      steps={steps.map((step, i) => ({
        ...step,
        status: stepStatus(currentStep, i + 1),
      }))}
      hideBack={currentStep === 1}
      hideNavigation={currentStep === 5}
      nextDisabled={!valid}
      nextButtonText={nextLabel(currentStep)}
      onBackClick={handleOnBackClick}
      onNextClick={handleOnNextClick}
      body={
        <StepBody
          step={currentStep}
          onValidChange={(isValid) => {
            setValid(isValid);
          }}
        />
      }
    />
  );
}
