import type { Payment } from '@pandler/shared/data-types';
import { friendlyPaymentStatus } from '@pandler/shared/data-types';
import classNames from 'classnames';

interface PaymentStatusProps {
  status: Payment['status'];
}

export function PaymentStatus({ status }: PaymentStatusProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        {
          'bg-yellow-100 text-yellow-800': status === 'pending',
          'bg-green-100 text-green-800': status === 'succeeded',
          'bg-red-100 text-red-800': status === 'failed',
        }
      )}
    >
      {friendlyPaymentStatus(status)}
    </span>
  );
}
