import type { PaymentGetIntentResponse } from '@pandler/shared/api-typings';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

interface UseGetPaymentIntentOptions {
  id: string;
  queryOptions?: UseQueryOptions<
    PaymentGetIntentResponse,
    Error,
    PaymentGetIntentResponse
  >;
}

export default function useGetPaymentIntent({
  id,
  queryOptions = {},
}: UseGetPaymentIntentOptions) {
  return useQuery<PaymentGetIntentResponse, Error>(
    ['payment-intent', id],
    async () => {
      const $response = await fetch(`${API_ENDPOINT}/payments/intent/${id}`, {
        headers: { accept: 'application/json' },
      });

      const data = await $response.json();
      if (!data) {
        throw new Error('Unable to retrieve organization');
      }

      return data;
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );
}
