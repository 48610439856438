import { Fragment } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid';

interface FeatureCategory {
  id: string;
  label: string;
  items: Feature[];
}

interface Feature {
  id: string;
  label: string;
}

interface BillingPlan {
  id: string;
  label: string;
  price?: number | string;
  description?: string;
  cta?: string;
  features: Record<string, boolean | string | undefined | number>;
}

export interface BillingPlansProps {
  features: FeatureCategory[];
  plans: BillingPlan[];
  currentPlan?: string;
  buttonLabel?: string | ((plan: string) => string);
}

export function BillingPlans({
  plans,
  features,
  currentPlan,
  buttonLabel = 'Buy',
}: BillingPlansProps) {
  const isCurrent = (id: string) => id === currentPlan;

  const labelText = (plan: string) =>
    typeof buttonLabel === 'function' ? buttonLabel(plan) : buttonLabel;

  return (
    <table className="w-full h-px table-fixed">
      <caption className="sr-only">Pricing plan comparison</caption>
      <thead>
        <tr>
          <th
            className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
            scope="col"
          >
            <span className="sr-only">Feature by</span>
            <span>Plans</span>
          </th>
          {plans.map(({ id, label }) => (
            <th
              className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
              scope="col"
              key={`plan-name-${id}`}
            >
              <div className=" flex flex-row gap-x-2 items-center">
                {label}
                {isCurrent(id) && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-600">
                    Current Plan
                  </span>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="border-t border-gray-200 divide-y divide-gray-200">
        <tr>
          <th
            className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
            scope="row"
          >
            Pricing
          </th>
          {plans.map(({ id, price, description, label, cta }) => (
            <td className="h-full py-8 px-6 align-top" key={`pricing-${id}`}>
              <div className="relative h-full table">
                <p>
                  {typeof price === 'number' && (
                    <>
                      <span className="text-4xl font-extrabold text-gray-900">
                        ${price}
                      </span>
                      <span className="text-base font-medium text-gray-500">
                        /mo
                      </span>
                    </>
                  )}
                  {typeof price === 'string' && (
                    <span className="text-4xl font-extrabold text-gray-900">
                      {price}
                    </span>
                  )}
                </p>
                {description && (
                  <p className="mt-4 mb-16 text-sm text-gray-500">
                    {description}
                  </p>
                )}
                <button
                  disabled={isCurrent(id)}
                  className="disabled:bg-gray-50 disabled:border-gray-50 disabled:text-gray-200 absolute bottom-0 flex-grow block w-full bg-primary-600 border border-primary-600 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-primary-700"
                >
                  {cta ?? labelText(id) ?? `Buy ${label}`}
                </button>
              </div>
            </td>
          ))}
        </tr>

        {features.map(({ id, label, items }) => (
          <Fragment key={`group-${id}`}>
            <tr>
              <th
                className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                colSpan={4}
                scope="colgroup"
              >
                {label}
              </th>
            </tr>

            {items.map(({ id, label }) => (
              <tr key={`feature-${id}`}>
                <th
                  className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                  scope="row"
                >
                  {label}
                </th>

                {plans.map(({ features }) => (
                  <td className="py-5 px-6">
                    {typeof features[id] === 'boolean' && !features[id] && (
                      <MinusIcon className="h-5 w-5 text-gray-400" />
                    )}
                    {typeof features[id] === 'boolean' && features[id] && (
                      <CheckIcon className="h-5 w-5 text-teal-500" />
                    )}
                    {(typeof features[id] === 'string' ||
                      typeof features[id] === 'number') && (
                      <span className="block text-sm text-gray-700">
                        {features[id]}
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr className="border-t border-gray-200">
          <th className="sr-only" scope="row">
            Choose your plan
          </th>

          {plans.map(({ id, label, cta }) => (
            <td className="pt-5 px-6" key={`plan-footer-${id}`}>
              <button
                disabled={isCurrent(id)}
                className="disabled:bg-gray-50 disabled:border-gray-50 disabled:text-gray-200 block w-full bg-primary-600 border border-primary-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-primary-700"
              >
                {cta ?? labelText(id) ?? `Buy ${label}`}
              </button>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}
