import { AdjustmentsVerticalIcon, BoltIcon } from '@heroicons/react/24/solid';
import type { NodeProps} from 'react-flow-renderer';
import { Handle, Position } from 'react-flow-renderer';
import type { Trigger } from '../../../types';
import { AddNodeButton } from '../../add-node-button';

export function TriggerNode(props: NodeProps<Trigger>) {
  const { data, isConnectable } = props;

  return (
    <div className="px-4 py-5 bg-slate-100 shadow rounded-lg sm:p-6 w-80 cursor-pointer overflow-hidden">
      <div className="flex max-w-full overflow-hidden">
        <div className="bg-white/90 rounded-full flex items-center justify-center w-8 h-8 min-w-[32px]">
          <BoltIcon className="text-slate-800 w-5 h-5" />
        </div>
        <div className="ml-2">
          <div className="text-xs text-gray-500 font-semibold">Trigger</div>
          <div className="text-sm text-ellipsis overflow-hidden">
            When{' '}
            <span className="font-semibold">{JSON.stringify(data.filter)}</span>
            {/* @todo make human readable */}
          </div>
        </div>
      </div>

      <div className="mt-2 -mb-2 flex items-center">
        <div className="flex items-center justify-center w-8 h-8">
          <AdjustmentsVerticalIcon className="w-5 h-5 text-gray-500" />
        </div>
        <div className="ml-2">
          <span className="text-sm text-gray-500">Show Filters</span>
        </div>
      </div>

      <AddNodeButton />

      <Handle
        type="source"
        position={Position.Bottom}
        className="invisible"
        isConnectable={isConnectable}
      />
    </div>
  );
}
