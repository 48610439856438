import { type EditorText } from '@pandler/shared/data-types';
import { type ReactNode } from 'react';
import { useSlate } from 'slate-react';
import { toggleMark } from '../utils';

interface MarkButtonProps {
  format: keyof Omit<EditorText, 'text'>;
  children: ReactNode;
}

export function MarkButton({ format, children }: MarkButtonProps) {
  const editor = useSlate();

  return (
    <div className="flex items-center">
      <button
        type="button"
        className="-m-2.5 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500 transition-all duration-300"
        onClick={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        {children}
      </button>
    </div>
  );
}
