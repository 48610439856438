import { Helmet } from 'react-helmet-async';
import {
  Content,
  RuntimeError,
  withAuthenticationRequired,
} from '@pandler/cloud/skeleton';
import { WelcomePane } from '../../components/welcome-pane';
import { OnboardingPane } from '../../components/onboarding-pane';
import { InviteTeamMembers } from '../../components/invite-team-members';
import { PlanTrial } from '../../components/plan-trial';
import { useOnboarding } from '@pandler/cloud/shared';
import { LoadingContainer } from '@pandler/shared/react-ui';

export function OnboardingPage() {
  const { data, isLoading, error } = useOnboarding();

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <Content center className="max-w-5xl">
        {isLoading && <LoadingContainer />}

        {!isLoading && error && <RuntimeError error={error} />}
        {data && (
          <section className="flex flex-col space-y-12">
            {data.welcomeNotification === 'visible' && (
              <WelcomePane showTour={data.welcomeTour === 'visible'} />
            )}
            {data.quickStartNotification === 'visible' && (
              <OnboardingPane data={data} />
            )}
            <PlanTrial />
            {data.membersNotification === 'visible' && <InviteTeamMembers />}
          </section>
        )}
      </Content>
    </>
  );
}

export default withAuthenticationRequired(OnboardingPage);
