import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { type MutationOptions, useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export default function useDismissNotification(
  options: MutationOptions<true, unknown, { id: string }> = {}
) {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId } = useOrganization();

  return useMutation({
    ...options,
    mutationFn: async ({
      id,
      invalidate = true,
    }: {
      id: string;
      invalidate?: boolean;
    }): Promise<true> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:onboarding',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/onboarding/${organizationId}`,
        {
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ [id]: 'dismissed' }),
        }
      );

      if ($response.status === 200) {
        if (invalidate) {
          queryClient.invalidateQueries({
            queryKey: ['onboarding', organizationId],
          });
        }

        return true;
      }

      throw new Error('Unknown');
    },
  });
}
