import { DocumentTextIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { type ReactNode, useMemo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import type { Attachment } from '../../types';

export type AttachmentType = 'pdf' | 'unknown';

export type AttachmentProps = Omit<Attachment, 'size'> & {
  className?: string;
  onClick?: () => void;
  size?: string | ReactNode | ReactNode[];
};

const icon = (type: AttachmentType) => {
  switch (type) {
    case 'pdf':
      return DocumentTextIcon;
    default:
      return PaperClipIcon;
  }
};

export function Attachment({
  className,
  fileName,
  size,
  type = 'unknown',
  onClick,
}: AttachmentProps) {
  const Icon = useMemo(() => icon(type), [type]);
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <button
      className={twMerge(
        'w-52 flex items-center justify-start cursor-pointer select-none border px-4 py-2 border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600 space-x-2 text-sm',
        className
      )}
      onClick={handleOnClick}
    >
      <Icon className="text-red-500 w-7 h-7 shrink-0" />

      <div className="grow flex flex-col items-start">
        <div className="font-semibold text-gray-900 dark:text-gray-100">
          {fileName}
        </div>
        {size && <div className="text-slate-400">{size}</div>}
      </div>
    </button>
  );
}
