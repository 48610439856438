import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { classNames } from '@pandler/shared/utils';
import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface AlertProps {
  type: 'danger' | 'warning' | 'info' | 'success';
  title?: string | ReactNode;
  titleClassName?: string;
  description?: string | ReactNode;
  descriptionClassName?: string;
  className?: string;
  hideIcon?: boolean;
  copyClassName?: string;
  children?: ReactNode | ReactNode[];
}

export function Alert({
  type,
  title,
  titleClassName = '',
  description,
  descriptionClassName = '',
  className = '',
  hideIcon = false,
  copyClassName = '',
  children,
}: AlertProps) {
  return (
    <div
      className={twMerge(
        'rounded-md p-4 flex',
        classNames(
          type === 'danger' ? 'bg-red-50' : '',
          type === 'warning' ? 'bg-yellow-50' : '',
          type === 'info' ? 'bg-blue-50' : '',
          type === 'success' ? 'bg-green-50' : ''
        ),
        className
      )}
    >
      {!hideIcon && (
        <div className="flex-shrink-0">
          {type === 'danger' && (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          )}
          {type === 'warning' && (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          )}
          {type === 'info' && (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          )}
          {type === 'success' && (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          )}
        </div>
      )}

      <div className={twMerge('ml-3', copyClassName)}>
        {title && (
          <h3
            className={twMerge(
              'text-sm font-medium',
              classNames(
                type === 'danger' ? 'text-red-800' : '',
                type === 'warning' ? 'text-yellow-800' : '',
                type === 'info' ? 'text-blue-800' : '',
                type === 'success' ? 'text-green-800' : ''
              ),
              titleClassName
            )}
          >
            {title}
          </h3>
        )}
        {description && (
          <div
            className={twMerge(
              'text-sm',
              classNames(
                title ? 'mt-2' : '',
                type === 'danger' ? 'text-red-700' : '',
                type === 'warning' ? 'text-yellow-700' : '',
                type === 'info' ? 'text-blue-700' : '',
                type === 'success' ? 'text-green-700' : ''
              ),
              descriptionClassName
            )}
          >
            <p>{description}</p>
          </div>
        )}
      </div>

      {children}
    </div>
  );
}
