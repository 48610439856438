import { User } from '@pandler/shared/data-types';
import {
  Button,
  FieldGroupFieldError,
  FormGroup,
  Input,
  LoadingIcon,
  success,
} from '@pandler/shared/react-ui';
import { useEffect } from 'react';
import { useZorm } from 'react-zorm';
import { useCurrentUser, useUpdateUser } from '../../hooks';

interface UserFormProps {
  user: User;
  onSuccess?: () => void;
}

const FormDefinition = User.pick({
  firstName: true,
  lastName: true,
  email: true,
  avatar: true,
});

export function UserForm({ onSuccess, user }: UserFormProps) {
  const { user: currentUser, invalidate: invalidateCurrentUser } =
    useCurrentUser();
  const { isLoading, mutate, isSuccess } = useUpdateUser();
  const zo = useZorm('user-settings', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      mutate({ id: user.id, data: e.data });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      success({ message: 'User updated' });
      if (onSuccess) {
        onSuccess();
      }

      if (user.id === currentUser?.id) {
        invalidateCurrentUser();
      }
    }
  }, [isSuccess, onSuccess, user.id, currentUser?.id, invalidateCurrentUser]);

  return (
    <form ref={zo.ref}>
      <FormGroup
        id="firstName"
        label="First name"
        input={
          <Input
            type="text"
            name={zo.fields.firstName()}
            defaultValue={user.firstName}
            hasError={zo.errors.firstName(true)}
          />
        }
        error={zo.errors.firstName(FieldGroupFieldError)}
      />

      <FormGroup
        id="lastName"
        label="Last name"
        input={
          <Input
            type="text"
            name={zo.fields.lastName()}
            defaultValue={user.lastName}
            hasError={zo.errors.lastName(true)}
          />
        }
        error={zo.errors.lastName(FieldGroupFieldError)}
      />

      <FormGroup
        id="email"
        label="Email"
        input={
          <Input
            type="email"
            name={zo.fields.email()}
            defaultValue={user.email}
            hasError={zo.errors.email(true)}
            placeholder="email@example.com"
          />
        }
        error={zo.errors.email(FieldGroupFieldError)}
      />

      <FormGroup
        id="avatar"
        label="Avatar"
        input={
          <Input
            type="text"
            name={zo.fields.avatar()}
            defaultValue={user.avatar}
            hasError={zo.errors.avatar(true)}
          />
        }
        error={zo.errors.avatar(FieldGroupFieldError)}
      />

      <Button type="submit" disabled={isLoading}>
        {isLoading ? (
          <LoadingIcon className="w-3.5 h-3.5 text-white" />
        ) : (
          'Save'
        )}
      </Button>
    </form>
  );
}
