import type { ReactNode } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

export interface Step {
  name: string;
  description?: string | ReactNode;
  status: 'complete' | 'current' | 'upcoming';
  link?: string;
}

export interface VerticalStepsProps {
  steps: Step[];
  className?: string;
  itemContainerClassName?: string;
  itemClassName?: string;
  itemProgressClassName?: string;
  itemPaddingClassName?: string;
  withBottomPadding?: boolean;
  completeContainerClassName?: string;
  currentContainerClassName?: string;
  pendingContainerClassName?: string;
  descriptionClassName?: string;
}

export function stepStatus(currentStep: number, step: number) {
  if (step === currentStep) {
    return 'current';
  }

  return step > currentStep ? 'upcoming' : 'complete';
}

export function VerticalSteps({
  steps,
  className = '',
  itemContainerClassName = '',
  itemClassName = '',
  itemProgressClassName = '',
  itemPaddingClassName = 'pb-10',
  withBottomPadding = false,
  completeContainerClassName = 'text-gray-500',
  currentContainerClassName = 'text-gray-900',
  pendingContainerClassName = 'text-gray-400',
  descriptionClassName = '',
}: VerticalStepsProps) {
  const navigate = useNavigate();

  const handleItemOnClick = (item: Step) => () => {
    const { link } = item;
    if (link) {
      navigate(link);
    }
  };

  return (
    <nav aria-label="Progress" className={className}>
      <ol className="overflow-hidden">
        {steps.map((item, stepIdx) => (
          <li
            key={item.name}
            className={twMerge(
              classNames('relative', {
                [itemPaddingClassName]:
                  withBottomPadding || stepIdx !== steps.length - 1,
                'cursor-pointer group/link': item.link,
              }),
              itemContainerClassName
            )}
            onClick={handleItemOnClick(item)}
          >
            {item.status === 'complete' && (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className={twMerge(
                      '-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-primary-600 z-10',
                      itemProgressClassName
                    )}
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className={twMerge(
                    'relative flex items-stretch group',
                    itemClassName,
                    completeContainerClassName
                  )}
                >
                  <span className="h-9 flex items-center">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-800 transition-all">
                      <CheckIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <div className="ml-4 min-w-0 flex flex-col justify-center">
                    <span className="text-sm font-semibold tracking-wide group-hover/link:text-primary-600 transition-all">
                      {item.name}
                    </span>
                    {item.description && (
                      <span
                        className={twMerge('text-sm', descriptionClassName)}
                      >
                        {item.description}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}

            {item.status === 'current' && (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className={twMerge(
                      '-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-200 z-10',
                      itemProgressClassName
                    )}
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className={twMerge(
                    'relative flex items-stretch group',
                    itemClassName,
                    currentContainerClassName
                  )}
                  aria-current="step"
                >
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-700 rounded-full">
                      <span className="h-2.5 w-2.5 bg-primary-600 rounded-full" />
                    </span>
                  </span>
                  <div className="ml-4 min-w-0 flex flex-col justify-center">
                    <span className="text-sm font-semibold tracking-wide group-hover/link:text-primary-600 transition-all">
                      {item.name}
                    </span>
                    {item.description && (
                      <span
                        className={twMerge('text-sm', descriptionClassName)}
                      >
                        {item.description}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}

            {item.status === 'upcoming' && (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className={twMerge(
                      '-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-200 z-10',
                      itemProgressClassName
                    )}
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className={twMerge(
                    'relative flex items-stretch group',
                    itemClassName,
                    pendingContainerClassName
                  )}
                >
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary-700 rounded-full group-hover:border-primary-700 transition-all">
                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-primary-600 transition-all" />
                    </span>
                  </span>
                  <div className="ml-4 min-w-0 flex flex-col justify-center">
                    <span className="text-sm font-semibold tracking-wide group-hover/link:text-primary-600 transition-all">
                      {item.name}
                    </span>
                    {item.description && (
                      <span
                        className={twMerge('text-sm', descriptionClassName)}
                      >
                        {item.description}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
