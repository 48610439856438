import * as React from 'react';

export function LoadingPaymentsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 675.67004 631.94692"
      fill="none"
      {...props}
    >
      <path
        d="M886.75657,617.4844l30.31095,4.737a98.98541,98.98541,0,0,0,20.60431-42.93875c-22.16391,18.14266-55.86923,8.5016-82.74464,18.40656A59.5399,59.5399,0,0,0,819.307,635.03277L804.49369,650.9701A99.77016,99.77016,0,0,0,887.905,646.378a96.3717,96.3717,0,0,0,19.64-14.09929C896.93923,625.60042,886.75657,617.4844,886.75657,617.4844Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#f2f2f2"
      />
      <path
        d="M875.99631,569.00709l29.24218-9.2783a98.98538,98.98538,0,0,0-.70813-47.62114c-11.74636,26.12313-46.21379,32.5259-65.851,53.37718a59.53993,59.53993,0,0,0-15.227,49.31l-6.15081,20.871a99.77014,99.77014,0,0,0,72.60872-41.31,96.37253,96.37253,0,0,0,11.29063-21.37854C888.72981,571.73,875.99631,569.00709,875.99631,569.00709Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#f2f2f2"
      />
      <path
        d="M937.835,660.62474a1.18647,1.18647,0,0,1-1.19006,1.19H263.355a1.19,1.19,0,0,1,0-2.38h673.29A1.18651,1.18651,0,0,1,937.835,660.62474Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#ccc"
      />
      <circle cx="278.99773" cy="260.8659" r="215" fill="#e6e6e6" />
      <path
        d="M726.16271,394.89244a184.74377,184.74377,0,0,1-74.67,148.51c-1.55,1.16-3.13,2.29-4.72,3.39a185.03127,185.03127,0,0,1-211.22,0c-1.59-1.1-3.17-2.23-4.72-3.39a185.01966,185.01966,0,0,1,0-297.02c1.55-1.16,3.13-2.29,4.72-3.39a185.0315,185.0315,0,0,1,211.22,0c1.59,1.1,3.17,2.23,4.72,3.39A184.74369,184.74369,0,0,1,726.16271,394.89244Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <circle cx="278.99773" cy="260.8659" r="169" fill="#6c63ff" />
      <path
        d="M541.60362,226.36486c-2.562,0-5.10815.067-7.64221.1798a168.99467,168.99467,0,0,1-7.15649,337.168c4.87781.42328,9.81116.65223,14.7987.65223a169,169,0,0,0,0-338Z"
        transform="translate(-262.16498 -134.02654)"
        opacity="0.2"
      />
      <path
        d="M541.66271,213.89244a5.50968,5.50968,0,0,0-5.5,5.5v24a5.5,5.5,0,0,0,11,0v-24A5.50968,5.50968,0,0,0,541.66271,213.89244Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M651.49272,246.38243c-1.55-1.16-3.13-2.29-4.72-3.39a5.49662,5.49662,0,0,0-4.9,2.39l-13.51,19.83a5.49872,5.49872,0,0,0,9.09,6.19l13.51-19.83A5.4644,5.4644,0,0,0,651.49272,246.38243Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M712.87273,336.80242a5.4929,5.4929,0,0,0-7.16-3.02l-22.24,9.03a5.49945,5.49945,0,0,0,4.14,10.19l22.24-9.03A5.51241,5.51241,0,0,0,712.87273,336.80242Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M453.96269,265.21245l-13.51-19.83a5.49661,5.49661,0,0,0-4.9-2.39c-1.59,1.1-3.17,2.23-4.72,3.39a5.46442,5.46442,0,0,0,.53,5.19l13.51,19.83a5.49872,5.49872,0,0,0,9.09-6.19Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M398.85271,342.81243l-22.24-9.03a5.49942,5.49942,0,0,0-4.14,10.18994l22.24,9.03a5.49945,5.49945,0,0,0,4.14-10.19Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M541.66271,540.89244a5.50968,5.50968,0,0,0-5.5,5.5v24a5.5,5.5,0,0,0,11,0v-24A5.50968,5.50968,0,0,0,541.66271,540.89244Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M650.96269,538.21245l-13.51-19.83a5.49872,5.49872,0,1,0-9.09,6.19l13.51,19.83a5.49657,5.49657,0,0,0,4.9,2.39c1.59-1.1,3.17-2.23,4.72-3.39A5.46436,5.46436,0,0,0,650.96269,538.21245Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M709.85271,445.81243l-22.24-9.03a5.49942,5.49942,0,0,0-4.14,10.18994l22.24,9.03a5.49945,5.49945,0,0,0,4.14-10.19Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M452.51268,516.93242a5.5,5.5,0,0,0-7.63995,1.45l-13.51,19.83a5.46439,5.46439,0,0,0-.53,5.19c1.55,1.16,3.13,2.29,4.72,3.39a5.49656,5.49656,0,0,0,4.9-2.39l13.51-19.83A5.49838,5.49838,0,0,0,452.51268,516.93242Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M401.87273,439.80242a5.4929,5.4929,0,0,0-7.16-3.02l-22.24,9.03a5.49945,5.49945,0,0,0,4.14,10.19l22.24-9.03A5.51241,5.51241,0,0,0,401.87273,439.80242Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#fff"
      />
      <path
        d="M548.79894,392.16338a11.5131,11.5131,0,0,1-9.40873,13.26558l-97.59815,16.60128a11.5,11.5,0,0,1-3.85686-22.67432l97.59815-16.60127A11.5131,11.5131,0,0,1,548.79894,392.16338Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#3f3d56"
      />
      <path
        d="M651.51837,500.29429a7.5082,7.5082,0,0,1-10.60652.04177l-105.063-104.23895a7.5,7.5,0,0,1,10.56475-10.64828l105.063,104.23895A7.50819,7.50819,0,0,1,651.51837,500.29429Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#3f3d56"
      />
      <circle cx="278.99773" cy="260.8659" r="13" fill="#ccc" />
      <path
        d="M451.61885,169.47127c-19.95483,31.3725-50.40146,52.19272-87.94977,65.87042a7.05892,7.05892,0,0,1-9.88158-1.41838l0,0c-20.3959-27.23248-15.79051-66.15312,11.1278-86.96189a62.06846,62.06846,0,0,1,87.55785,11.87509l.56413.75322A7.05892,7.05892,0,0,1,451.61885,169.47127Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#e6e6e6"
      />
      <path
        d="M629.85228,158.83648a62.06846,62.06846,0,0,1,87.55785-11.87509c26.91832,20.80877,31.52371,59.72941,11.1278,86.96189l0,0a7.05889,7.05889,0,0,1-9.88155,1.41838c-34.8573-13.34536-63.4422-36.43945-87.94983-65.87042a7.059,7.059,0,0,1-1.41838-9.88157Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#e6e6e6"
      />
      <path
        d="M621.10592,581.16067a5.50968,5.50968,0,0,0-2.11376,7.48546l31.74188,56.7226a5.5,5.5,0,0,0,9.59921-5.3717l-31.74188-56.7226A5.50968,5.50968,0,0,0,621.10592,581.16067Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#e6e6e6"
      />
      <path
        d="M453.734,583.27443,421.99216,639.997a5.5,5.5,0,0,0,9.59921,5.3717l31.74188-56.7226a5.5,5.5,0,1,0-9.59921-5.3717Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#e6e6e6"
      />
      <circle cx="394.99773" cy="508.8659" r="17" fill="#e6e6e6" />
      <circle cx="406.99773" cy="73.8659" r="17" fill="#e6e6e6" />
      <circle cx="277.99773" cy="33.8659" r="17" fill="#e6e6e6" />
      <circle cx="154.99773" cy="71.8659" r="17" fill="#e6e6e6" />
      <circle cx="165.99773" cy="508.8659" r="17" fill="#e6e6e6" />
      <path
        d="M643.82785,490.73326a8.51615,8.51615,0,0,0,12.95041,1.67682l26.67551,14.29343-1.43061-15.66135L656.911,479.86993a8.56232,8.56232,0,0,0-13.08312,10.86333Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#ffb7b7"
      />
      <path
        d="M663.26422,496.199l10.45348-14.42967,50.35989,23.80654,62.667-11.75832a21.47122,21.47122,0,0,1,25.43021,21.06258h0a21.49411,21.49411,0,0,1-21.43119,21.51043l-71.81625.13324Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#3f3d56"
      />
      <polygon
        points="384.999 611.399 374.422 606.341 388.951 562.133 404.56 569.599 384.999 611.399"
        fill="#ffb7b7"
      />
      <path
        d="M718.07,738.29872c-.7691.48175-1.5382.95554-2.31507,1.42951-1.546.94777-3.10749,1.88-4.669,2.79663-.78465.45843-1.57707.91685-2.36172,1.3675v.02314l-.04661.00777-.01551.00778-1.19641.29531-1.71686-4.537-.79243-2.08974-1.1187-2.95994-2.051-5.41478-10.79851-28.51121,16.78822-4.1408.62926,2.5715h.00778l.31072,1.274,5.86543,23.788,1.19637,4.86323.03883.14756.72249,2.9055.62153,2.51706.69139,2.81238Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#ffb7b7"
      />
      <path
        d="M818.21837,638.17372c-1.06431,1.70913-2.152,3.41049-3.24732,5.09629q-3.05317,4.70782-6.26938,9.27587c0,.00777-.00777.00777-.01555.01555a21.93387,21.93387,0,0,1-4.11742,3.0065c-.06216.04661-.13205.07767-.19422.1165a15.17964,15.17964,0,0,1-3.46487,1.39063c-.435.10873-.87787.2175-1.31292.32628-1.56925.37288-3.123.69142-4.669.97109-2.75012.48943-5.46917.839-8.17268,1.05653-.21754.02333-.435.03884-.65259.05439-11.117.81571-21.93889-.49721-33.23461-2.64912-5.578-1.05658-11.27244-2.32289-17.16893-3.6358-1.92663-.43505-3.861-.86232-5.81877-1.28959-.14761-.03106-.303-.06994-.45838-.101-2.79673-.61371-5.64006-1.2197-8.55334-1.81791-3.66686-.75354-7.45023-1.4838-11.38121-2.17523l.27967,5.53911a41.68909,41.68909,0,0,0,2.62583,12.4533c1.67807,4.90985,3.0065,8.79421.202,16.88144l-1.35176,3.91543a13.37221,13.37221,0,0,1-1.37508,11.83181l-.27188.41172,11.27243,28.519.73027,1.849h-5.77221l-7.10059.00778H696.69209l-17.36314-34.835-.83126-1.66252v-.08544l1.79457-66.94316c.02333-.59043.06994-1.18864.14761-1.77907a20.35271,20.35271,0,0,1,7.74545-13.704,19.60027,19.60027,0,0,1,14.59743-3.99314l5.9431.7691,12.14253,1.5693,8.55334,1.11092.17094.02328,25.22505,3.26288,1.81013-7.3803.33405-.12428,10.61989-3.89214,36.73054-13.46323.09322-.03105,2.41607-.88565.28744.46611c.08549.13987.17093.27971.2486.41954a71.99912,71.99912,0,0,1,5.80327,13.471,211.47728,211.47728,0,0,1,6.09844,23.28292q.10489.51275.18644.93226l.02333.08544c.02328.13206.03883.2175.04661.24083C820.36255,629.60479,819.77212,633.9087,818.21837,638.17372Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#2f2e41"
      />
      <path
        d="M819.76865,613.50321c-.11655-.49721-.26415-1.00215-.41949-1.49159a106.28979,106.28979,0,0,0-4.30386-10.89178c-1.83341-4.03975-4.032-8.36694-6.65-13.05146q-1.89946-3.40275-4.08637-7.0773-1.09539-1.84121-2.27623-3.75231a1.972,1.972,0,0,0-.101-.17089l-.22527-.37289-36.34216,4.86324-13.53311,1.81013-.80794,6.74325-.19421,1.6703c-.00778,0-.00778,0,0,.00777l-.54383,4.55247v.00778l-.72248,6.013-.06216-.00778-20.08216-2.60251-20.69591-2.68022-11.11706-1.43719a19.58836,19.58836,0,0,0-14.59743,3.99314,20.3629,20.3629,0,0,0-7.7454,13.704c-.06994.54382-.11655,1.10315-.13988,1.65475l-10.48779,24.85216a160.34347,160.34347,0,0,0-11.8551,46.64346l-7.69106,24.58806,1.09537.101,16.29879,1.51492,4.30386.404,9.47786-13.83611c1.07987-1.57707,2.10534-3.20071,3.06088-4.86323a73.86106,73.86106,0,0,0,4.18736-8.42133l20.5017-48.78763q2.37727.408,4.70785.77687,10.77918,1.748,20.69591,2.85112c21.49607,2.40055,40.11,2.587,56.68069.55159q6.98021-.85065,13.49429-2.2374,2.38894-.50113,4.71562-1.07986c.36511-.08545.72249-.17089,1.07987-.26416a19.82527,19.82527,0,0,0,13.86717-12.95824A20.22821,20.22821,0,0,0,819.76865,613.50321Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#2f2e41"
      />
      <path
        d="M650.54238,738.20692l-11.44332-5.48473-1.79458-.86231-2.86666,7.03847-20.01227,4.4049a4.74845,4.74845,0,0,0-.77687,9.035l19.55389,7.97851,6.20721-5.58572-1.52269,7.49684,7.36474,3.0065,8.07168-25.69121Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#2f2e41"
      />
      <polygon
        points="458.703 593.34 459.434 595.189 458.703 595.189 458.703 593.34"
        fill="#2f2e41"
      />
      <path
        d="M716.883,729.6106,704.89244,733.765l-1.88192.65,2.91542,7.01841-11.12085,17.21106a4.74844,4.74844,0,0,0,5.80521,6.9667l19.50836-8.08917.48047-8.3366,4.193,6.39847,7.34864-3.04564-12.34131-23.93495Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#2f2e41"
      />
      <path
        d="M816.77843,624.75865a4.40445,4.40445,0,0,1-2.043-.50683l-63.27441-33.0625-.731-2.99317-1.37793-9.47168.019-.08886,6.01758-27.74317c-.11035-.23828-.34082-.6289-.60107-1.07031-2.68653-4.55176-10.86231-18.40527,4.74121-37.48047l27.3955-31.93945,23.5127-1.01172.26758.33789A61.242,61.242,0,0,1,821.11485,536.617l-2.44824,44.2168,2.626,8.92969a24.6393,24.6393,0,0,1-.72168,15.94433l.602,14.459a4.41745,4.41745,0,0,1-4.39453,4.59179Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#3f3d56"
      />
      <path
        d="M761.89233,661.22445a8.5162,8.5162,0,0,0,2.12627-12.88426L779.23125,622.178l-15.70163.885-12.039,24.70838a8.56232,8.56232,0,0,0,10.40167,13.45308Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#ffb7b7"
      />
      <path
        d="M768.03088,641.99l-14.05733-10.94906,25.54384-49.50134L769.946,518.50143a21.47121,21.47121,0,0,1,21.9344-24.68218h0a21.49411,21.49411,0,0,1,20.752,22.16643l-2.36488,71.77743Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#3f3d56"
      />
      <path
        id="f4e08e8b-ef08-4d70-8ad3-b81af8a71ff9-136"
        data-name="a1ca5f83-7fa1-45b1-9d3b-bf096f25c0e5"
        d="M771.84232,467.13077V443.864a31.177,31.177,0,1,1,62.35408-.04005v.04005c5.49493,11.45055,2.05727,16.8945,0,23.2668a4.19307,4.19307,0,0,1-4.18608,4.18608H776.03048A4.1931,4.1931,0,0,1,771.84232,467.13077Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#2f2e41"
      />
      <circle cx="530.63215" cy="312.44125" r="23.33714" fill="#ffb7b7" />
      <path
        d="M817.22046,452.58578c-.21152-.03343-.423-.078-.63455-.11143-3.64026-.64562-7.28046-1.2801-10.90958-1.937l-.334-8.2266-4.39718,7.3694c-10.03015,3.32853-19.41462,1.44724-28.24248-2.70506a79.73731,79.73731,0,0,1-8.46054-4.66434,30.92749,30.92749,0,0,1,15.19553-21.886c.334-.18929.65677-.35617.99079-.52331a.0109.0109,0,0,0,.01107-.01115c.56776-.26716,1.14666-.52318,1.72556-.75691a25.70666,25.70666,0,0,1,26.98453,5.23209C816.27418,431.16737,819.48027,442.39976,817.22046,452.58578Z"
        transform="translate(-262.16498 -134.02654)"
        fill="#2f2e41"
      />
    </svg>
  );
}
