import { DelayFor } from '@pandler/shared/data-types';
import {
  Button,
  FieldGroupFieldError,
  FormGroup,
  Input,
  Select,
} from '@pandler/shared/react-ui';
import { toTitleCase } from '@pandler/shared/utils';
import { useState } from 'react';
import { useZorm } from 'react-zorm';

export interface DelayForFormProps {
  data: DelayFor;
}

const FormDefinition = DelayFor.omit({ type: true });

export function DelayForForm({ data }: DelayForFormProps) {
  const [unitValue, setUnitValue] = useState(data.unit ?? '');
  const zo = useZorm('settings', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      console.log('Form ok!\n' + JSON.stringify(e.data, null, 2));
    },
  });

  return (
    <div>
      <p className="mb-3.5 text-sm text-gray-700">
        The workflow will wait a specific period of time before continuing.
      </p>

      <form ref={zo.ref}>
        <FormGroup
          id="duration"
          label="Duration"
          description="The amount of time to wait before proceeding."
          input={
            <Input
              type="number"
              name={zo.fields.duration()}
              defaultValue={data.duration ?? 0}
              min={0}
              hasError={zo.errors.duration(true)}
            />
          }
          error={zo.errors.duration(FieldGroupFieldError)}
        />

        <input type="hidden" name={zo.fields.unit()} value={unitValue} />
        <FormGroup
          id="unit"
          label="Unit"
          description="The unit by which to wait (minutes, hours, etc.)"
          input={
            <Select
              name={zo.fields.unit()}
              items={DelayFor.shape.unit._def.values.map((value) => ({
                value,
                label: `${toTitleCase(value)}(s)`,
              }))}
              onChange={(value) => setUnitValue(value)}
              value={unitValue}
            />
          }
          error={zo.errors.unit(FieldGroupFieldError)}
        />

        <Button type="submit">Save</Button>
      </form>
    </div>
  );
}
