import { selector } from 'recoil';
import { layoutBlocksState } from './atoms';

export const headerBlockState = selector({
  key: 'HeaderBlock',
  get: ({ get }) => {
    const blocks = get(layoutBlocksState);

    return blocks['header'] ?? null;
  },
});
