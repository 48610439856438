import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ChartContainerProps {
  title?: string | ReactNode;
  titleRight?: ReactNode | ReactNode[];
  className?: string;
  childContainerClassName?: string;
  children: ReactNode | ReactNode[];
}

export function ChartContainer({
  title,
  titleRight,
  className = '',
  childContainerClassName = '',
  children,
}: ChartContainerProps) {
  const showTitle = title || titleRight;

  return (
    <div
      className={twMerge(
        'bg-white dark:bg-slate-800 shadow border border-gray-100/75 rounded-md w-full relative mb-4',
        className
      )}
    >
      {showTitle && (
        <div className="border-b border-dashed border-slate-200 dark:border-slate-700 py-3 px-4 dark:text-slate-300/70">
          <div className="flex-none md:flex">
            {title && (
              <h4 className="font-medium flex-1 self-center mb-2 md:mb-0">
                {title}
              </h4>
            )}
            {titleRight}
          </div>
        </div>
      )}

      <div
        className={twMerge('flex-auto p-4 relative', childContainerClassName)}
      >
        {children}
      </div>
    </div>
  );
}
