import { QueryClient } from '@tanstack/react-query';
import { NotFoundError } from '../errors';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof NotFoundError) {
          // don't retry when not found
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

export { queryClient };
