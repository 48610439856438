import { PlusIcon } from '@heroicons/react/24/solid';
import { Button, ModalContext } from '@pandler/shared/react-ui';
import { useContext } from 'react';
import { CreateWizard } from '../create-wizard';

export function NewWorkflowButton() {
  const { closeModal, showModal } = useContext(ModalContext);

  return (
    <Button
      onClick={() =>
        showModal(<CreateWizard />, {
          onClose: closeModal,
          className: 'sm:max-w-4xl sm:w-full p-0 sm:p-0 rounded-2xl',
        })
      }
    >
      <PlusIcon className="-ml-1 mr-2 h-3.5 w-3.5" aria-hidden="true" />
      Create workflow
    </Button>
  );
}
