import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { LogsFilterableContainer } from '../../components/logs-filterable-container';
import { PageHeader } from '@pandler/shared/react-ui';

export function LogsPage() {
  return (
    <>
      <Helmet>
        <title>Logs</title>
      </Helmet>

      <PageHeader
        title="Message logs"
        subTitle="Invoice reminders sent by e-mail and SMS."
      />

      <Content
        className="flex flex-col flex-auto grow h-full w-full md:px-0"
        childrenContainerClassName="flex flex-col flex-auto grow h-full w-full p-0"
        containerClassName="flex flex-col flex-auto grow h-full w-full py-0"
      >
        <LogsFilterableContainer />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(LogsPage);
