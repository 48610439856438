import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Fragment, useState, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type AllowedValueType = number | string;

interface SelectItem<T extends AllowedValueType> {
  value: T;
  label: string | ReactNode;
}

interface Value<T> {
  value?: T;
  onChange?: (value: T) => void;
  initialValue?: T;
}

type SelectSingleProps<T extends AllowedValueType> = {
  multiple?: false;
} & Value<T>;

type SelectMultiProps<T extends AllowedValueType> = {
  multiple: true;
} & Value<T[]>;

export type SelectProps<T extends AllowedValueType> = {
  items: SelectItem<T>[];
  className?: string;
  listBoxClassName?: string;
  showAsValue?: boolean;
  includeEmpty?: boolean;
  uncontrolled?: boolean;
  name?: string;
  input?: ReactNode;
} & (SelectSingleProps<T> | SelectMultiProps<T>);

export function Select<T extends AllowedValueType>({
  value: propValue,
  onChange,
  items,
  className,
  showAsValue,
  includeEmpty,
  name,
  uncontrolled,
  initialValue,
  input,
  multiple,
  listBoxClassName = '',
}: SelectProps<T>) {
  const [uncontrolledValue, setUncontrolledValue] = useState<
    T[] | T | undefined
  >(initialValue);
  const value = uncontrolled ? uncontrolledValue : propValue;
  const selected = items.filter((item) => {
    if (Array.isArray(value)) {
      return value.includes(item.value);
    }

    return value === item.value;
  });

  const itemsWithEmpty = includeEmpty
    ? [{ value: '', label: '-' }, ...items]
    : items;
  const isEmpty = selected.length === 0;

  return (
    <div className={twMerge('relative', className)}>
      {uncontrolled && name && (
        <>
          {input ?? (
            <input
              type="hidden"
              name={name}
              value={value as unknown as string}
            />
          )}
        </>
      )}

      <Listbox
        value={value}
        onChange={(changedValue) => {
          if (changedValue !== undefined) {
            if (uncontrolled) {
              setUncontrolledValue(changedValue);
            }

            if (onChange) {
              // eslint-disable-next-line
              onChange(changedValue as any); //@todo
            }
          }
        }}
        multiple={multiple}
      >
        {({ open }) => (
          <>
            <Listbox.Button className="bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 px-4 py-3.5 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
              <span className="block truncate">
                <>
                  {!isEmpty && (
                    <>
                      {multiple &&
                        selected.map((item) => (
                          <div
                            key={`selected-${item.value}`}
                            className="inline-flex items-center h-6 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-1 overflow-hidden"
                          >
                            <span className="ml-2.5 mr-1.5">{item.label}</span>

                            <span
                              className="hover:text-red-700 pr-2 transition-colors cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();

                                if (onChange) {
                                  const filtered = selected
                                    .filter(({ value }) => value !== item.value)
                                    .map(({ value }) => value);

                                  onChange(filtered);
                                }
                              }}
                            >
                              <XMarkIcon className="w-3 h-3 mt-px" />
                            </span>
                          </div>
                        ))}

                      {!multiple && (
                        <>
                          {!isEmpty &&
                            (showAsValue ? value : selected[0]?.label)}
                        </>
                      )}
                    </>
                  )}

                  {isEmpty && <>&nbsp;</>}
                </>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={twMerge(
                  'absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                  listBoxClassName
                )}
              >
                {itemsWithEmpty.map(({ value, label }) => (
                  <Listbox.Option
                    key={value as unknown as string}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {label}
                        </span>

                        {selected && value ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
}
