import { ActivityFeed, useActivityFeed } from '@pandler/cloud/shared';
import {
  Alert,
  Button,
  InlineError,
  LoadingContainer,
  LoadingIcon,
} from '@pandler/shared/react-ui';

interface TimelineProps {
  invoiceId: string;
  emptyText?: string;
}

export function Timeline({
  invoiceId,
  emptyText = 'No recorded activity for invoice.',
}: TimelineProps) {
  const {
    feed,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useActivityFeed({ entityId: invoiceId });

  if (error) {
    return <InlineError>{error.message}</InlineError>;
  }

  if (isLoading || !feed) {
    return <LoadingContainer iconClassName="w-5 h-5" />;
  }

  const items = feed.pages.flatMap(({ data }) => data);

  if (items.length === 0) {
    return <Alert type="info" description={emptyText} />;
  }

  return (
    <>
      <ActivityFeed items={items} />

      {hasNextPage && (
        <Button
          className="mt-4"
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
        >
          {isFetchingNextPage ? (
            <LoadingIcon className="w-3.5 h-3.5 text-white" strokeWidth={3} />
          ) : (
            'Load more'
          )}
        </Button>
      )}
    </>
  );
}
