import { useCallback, type Ref, type MutableRefObject } from 'react';

export function useMergedRef<T>(
  ref1: Ref<T>,
  ref2: MutableRefObject<T | null>
): (instance: T | null) => void {
  return useCallback(
    (instance: T | null) => {
      if (typeof ref1 === 'function') {
        ref1(instance);
      } else if (ref1) {
        (ref1 as MutableRefObject<T | null>).current = instance;
      }

      ref2.current = instance;
    },
    [ref1, ref2]
  );
}
