import { Helmet } from 'react-helmet-async';
import {
  Content,
  RuntimeError,
  withAuthenticationRequired,
} from '@pandler/cloud/skeleton';
import { GetAccessTokenDefaults, NotFoundError } from '@pandler/cloud/shared';
import {
  Editor,
  type Node,
  WorkflowProvider,
} from '@pandler/shared/workflow-ui';
import { type MouseEvent, useContext } from 'react';
import {
  Breadcrumbs,
  LoadingContainer,
  SlideOutContext,
} from '@pandler/shared/react-ui';
import {
  type Edge as ReactFlowEdge,
  type Node as ReactFlowNode,
} from 'react-flow-renderer';
import { NodeSettingsForm } from '../../components/node-settings-form';
import { Actions } from '../../config/actions';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import type { Workflow } from '@pandler/shared/data-types';
import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { EmptyWorkflow } from '../../components/empty-workflow';

export function ViewPage() {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { showPane, closePane } = useContext(SlideOutContext);

  const { isLoading, data, error } = useQuery<Workflow, NotFoundError | Error>(
    ['workflow', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:workflow',
      });
      const $response = await fetch(`${API_ENDPOINT}/workflow/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      if ($response.status === 404) {
        throw new NotFoundError();
      }

      const { data = {} } = await $response.json();
      if (data.id) {
        return data as Workflow;
      }

      throw new Error('An unexpected error occurred');
    }
  );

  const handleOnNodeClick = (event: MouseEvent, { data }: Node) => {
    const options = ['condition', 'invoice-updated'].includes(data.type)
      ? {
          containerClassName:
            'fixed inset-y-0 pl-16 right-0 flex w-full max-w-3xl ',
          innerClassName: 'max-w-3xl w-screen',
        }
      : {};

    showPane(<NodeSettingsForm data={data} />, {
      ...options,
      onClose: closePane,
      title: 'Settings',
      subTitle: data.title,
    });
  };

  return (
    <>
      <Helmet>
        <title>Workflows</title>
      </Helmet>

      {!isLoading && (
        <div className="py-7 pl-10">
          <Breadcrumbs
            items={[
              { name: 'Workflows', href: '/workflows' },
              { name: 'Overdue Invoice Reminder', current: true },
            ]}
          />
        </div>
      )}

      <Content>
        {isLoading && <LoadingContainer />}
        {!isLoading && error && <RuntimeError error={error} />}

        {!isLoading && data && (
          <>
            {data.nodes.length === 0 && <EmptyWorkflow />}
            {data.nodes.length > 0 && (
              <div className="h-[800px]">
                <WorkflowProvider actions={Actions}>
                  <Editor
                    onNodeClick={handleOnNodeClick}
                    nodes={data.nodes as unknown as ReactFlowNode[]}
                    edges={data.edges as unknown as ReactFlowEdge[]}
                  />
                </WorkflowProvider>
              </div>
            )}
          </>
        )}
      </Content>
    </>
  );
}

export default withAuthenticationRequired(ViewPage);
