import type { Amount } from '@pandler/shared/data-types';

function formatNumber(amount: number): string {
  return amount.toLocaleString();
}

function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  }).format(amount);
}

export function formatAmount(amount: Amount): string {
  if (amount.type === 'number') {
    return formatNumber(amount.value);
  } else if (amount.type === 'currency') {
    return formatCurrency(amount.value);
  } else {
    throw new Error(`Unsupported amount type: ${amount.type}`);
  }
}
