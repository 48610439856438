import { type MutationOptions, useMutation } from '@tanstack/react-query';
import { Result } from 'esresult';
import { dummyPromise } from '../../utils';

export default function useAgreeTermsConditions(options: MutationOptions = {}) {
  return useMutation({
    ...options,
    mutationFn: async (): Result.Async<true, 'UNKNOWN'> => {
      await dummyPromise(3000);
      return Result(true);
    },
  });
}
