import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Wizard } from '../../components/wizard';

export function IntegrationsPage() {
  return (
    <>
      <Helmet>
        <title>Integration Settings</title>
      </Helmet>

      <Wizard stage="integrations" />
    </>
  );
}

export default withAuthenticationRequired(IntegrationsPage);
