import { useAuth0 } from '@auth0/auth0-react';
import { type Organization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { type MutationOptions, useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export default function useCompleteOnboarding(options: MutationOptions = {}) {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();

  return useMutation({
    ...options,
    mutationFn: async (): Promise<Organization> => {
      if (!organization?.id) {
        throw new Error('Missing organization id');
      }

      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:organization',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/organization/${organization.id}`,
        {
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ onboarded: true }),
        }
      );

      if ($response.status === 200) {
        queryClient.invalidateQueries({
          queryKey: ['organization', organization.id],
        });

        const json = await $response.json();
        return json.data;
      }

      throw new Error('Unknown');
    },
  });
}
