import { classNames } from '@pandler/shared/utils';
import { type ReactElement, type ReactNode } from 'react';
import { type BreadcrumbsProps } from '../breadcrumbs';
import { twMerge } from 'tailwind-merge';

export interface PageHeaderProps {
  center?: boolean;
  title?: string;
  subTitle?: string;
  left?: ReactNode;
  right?: ReactNode;
  breadcrumbs?: ReactElement<BreadcrumbsProps>;
  className?: string;
}

export function PageHeader({
  title,
  left,
  right,
  breadcrumbs,
  subTitle,
  center = true,
  className = '',
}: PageHeaderProps) {
  return (
    <div className={className}>
      <div
        className={classNames('px-4 sm:px-6 md:px-8', center ? 'mx-auto' : '')}
      >
        <div className="pt-6 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            {left}
            {!left && (
              <>
                {title && (
                  <h1 className="mb-sm-0 text-xl font-semibold grow text-gray-800 dark:text-gray-100">
                    {title}
                  </h1>
                )}
                {subTitle && (
                  <h2 className="mt-0.5 text-base text-gray-400">{subTitle}</h2>
                )}
              </>
            )}
          </div>
          {right && (
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">{right}</div>
          )}
        </div>
      </div>
    </div>
  );
}
