import { z } from 'zod';
import { dateSchema } from './date';

export const Plan = z.object({
  name: z.enum(['Free', 'Starter', 'Growth', 'Enterprise']),
  startDate: dateSchema,
  endDate: dateSchema,
});

export type Plan = z.infer<typeof Plan>;
