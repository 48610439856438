import { ErrorPage, ErrorScreen } from '@pandler/shared/react-ui';

export function NotFoundPage() {
  return (
    <ErrorPage
      title="Page not found"
      screen={
        <ErrorScreen
          title="Page not found."
          errorCode="404 error"
          description="Sorry, we couldn’t find the page you’re looking for."
          homeLink
        />
      }
    />
  );
}
