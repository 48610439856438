import { Helmet } from 'react-helmet-async';
import {
  Content,
  RuntimeError,
  withAuthenticationRequired,
} from '@pandler/cloud/skeleton';
import { EmptyState } from '../../components/empty-state';
import usePaymentSettings from '../../hooks/use-payment-settings';
import {
  Breadcrumbs,
  LoadingContainer,
  PageHeader,
} from '@pandler/shared/react-ui';
import { PaymentsTable } from '../../components/payments-table';

export function HomePage() {
  const { isLoading, data, error } = usePaymentSettings();

  const isReady = !isLoading && !error;
  const isNotConnected = isReady && (!data || data?.status === 'UNVERIFIED');

  return (
    <>
      <Helmet>
        <title>Payments</title>
      </Helmet>

      {isLoading && <LoadingContainer className="mt-10" />}
      {!isLoading && error && <RuntimeError error={error} />}
      {isReady && isNotConnected && <EmptyState />}
      {isReady && data && data.status === 'VERIFIED' && (
        <>
          <PageHeader
            title="Payments"
            breadcrumbs={
              <Breadcrumbs
                items={[{ name: 'Payments', href: '/payments', current: true }]}
              />
            }
          />

          <Content>
            <PaymentsTable />
          </Content>
        </>
      )}
    </>
  );
}

export default withAuthenticationRequired(HomePage);
