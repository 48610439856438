import { useState, useEffect, useMemo } from 'react';
import { CalendarIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { Dropdown } from '@pandler/shared/react-ui';

export type DateValue =
  | 'today'
  | 'last 7 days'
  | 'last month'
  | 'last 3 months'
  | 'last 6 months'
  | 'last 12 months'
  | 'all time';

export type OnChangeValue = { type: DateValue; dateTime: string };

interface DateFilterProps {
  onChange?: (value: OnChangeValue) => void;
}

const items = [
  { label: 'today' },
  { label: 'last 7 days' },
  { label: 'last month' },
  { label: 'last 3 months' },
  { label: 'last 6 months' },
  { label: 'last 12 months' },
  { label: 'all time' },
];

export const ALL_TIME_DATETIME = '1900-01-01T00:00:00.000Z';

export const DEFAULT_DATE: OnChangeValue = {
  type: 'all time',
  dateTime: ALL_TIME_DATETIME,
};

function getRelativeDate(dateValue: DateValue): string {
  const currentDate = new Date();

  switch (dateValue) {
    case 'today':
      currentDate.setHours(0, 0, 0, 0);
      return currentDate.toISOString();

    case 'last 7 days':
      currentDate.setDate(currentDate.getDate() - 7);
      currentDate.setHours(0, 0, 0, 0);
      return currentDate.toISOString();

    case 'last month':
      currentDate.setMonth(currentDate.getMonth() - 1);
      currentDate.setHours(0, 0, 0, 0);
      return currentDate.toISOString();

    case 'last 3 months':
      currentDate.setMonth(currentDate.getMonth() - 3);
      currentDate.setHours(0, 0, 0, 0);
      return currentDate.toISOString();

    case 'last 6 months':
      currentDate.setMonth(currentDate.getMonth() - 6);
      currentDate.setHours(0, 0, 0, 0);
      return currentDate.toISOString();

    case 'last 12 months':
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      return currentDate.toISOString();

    case 'all time':
      return ALL_TIME_DATETIME;

    default:
      throw new Error(`Invalid DateValue: ${dateValue}`);
  }
}

const EmptyIcon = () => <div className="w-5 h-5" />;

export function DateFilter({ onChange }: DateFilterProps) {
  const [value, setValue] = useState<DateValue>('all time');

  useEffect(() => {
    if (onChange) {
      onChange({ type: value, dateTime: getRelativeDate(value) });
    }
  }, [value, onChange]);

  const itemsWithIcons = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        icon: item.label === value ? CheckCircleIcon : EmptyIcon,
        onClick: () => setValue(item.label as DateValue),
      })),
    [value]
  );

  return (
    <Dropdown
      type="light"
      icon={CalendarIcon}
      buttonClassName="capitalize w-48 h-full"
      buttonLabelClassName="grow text-left"
      itemClassName="capitalize"
      itemIconClassName="text-primary-600"
      itemsContainerClassName="z-10"
      label={value}
      items={itemsWithIcons}
    />
  );
}
