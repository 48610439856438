import { CreditCardIcon } from '@heroicons/react/24/outline';
import { ProgressBar } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';

export function PlanTrial() {
  return (
    <section className="border rounded-lg py-5 px-6 pb-6 shadow-md border-gray-50 flex items-center">
      <section className="flex-grow">
        <div className="max-w-2xl">
          <h2 className="text-xl font-bold text-gray-800 sm:leading-9 sm:truncate flex-grow">
            You're on a trial of the Growth plan
          </h2>

          <ProgressBar progress={3} className="mt-4" />

          <p className="text-gray-500 text-sm mt-2">
            30 days until the trial expires.
          </p>
        </div>
      </section>

      <Link
        to="/settings/subscription"
        className="text-primary-500 text-sm hover:underline hover:text-primary-600 transition-all duration-300 flex items-center pt-3"
      >
        <CreditCardIcon className="h-5 mr-1.5" /> Select a plan
      </Link>
    </section>
  );
}
