import * as React from 'react';

export function DoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 680 584" {...props}>
      <path
        fill="#e6e6e6"
        d="M51.125 536.144a24.215 24.215 0 0 0 23.383-4.119c8.19-6.874 10.758-18.196 12.846-28.681l6.18-31.017-12.938 8.908c-9.304 6.407-18.818 13.019-25.26 22.298s-9.252 21.947-4.078 31.988"
        data-name="Path 438"
      />
      <path
        fill="#f2f2f2"
        d="M53.123 575.855c-1.629-11.864-3.304-23.881-2.16-35.872 1.016-10.65 4.265-21.049 10.88-29.58a49.206 49.206 0 0 1 12.624-11.44c1.262-.796 2.424 1.204 1.167 1.997a46.78 46.78 0 0 0-18.504 22.326c-4.029 10.246-4.676 21.416-3.982 32.3.42 6.582 1.31 13.121 2.206 19.653a1.198 1.198 0 0 1-.808 1.422 1.163 1.163 0 0 1-1.423-.808Z"
        data-name="Path 439"
      />
      <path
        fill="#e6e6e6"
        d="M64.844 556.818a17.825 17.825 0 0 0 15.531 8.019c7.865-.373 14.418-5.86 20.317-11.07l17.452-15.41-11.55-.552c-8.306-.398-16.826-.77-24.738 1.793s-15.207 8.727-16.654 16.916"
        data-name="Path 442"
      />
      <path
        fill="#f2f2f2"
        d="M48.52 582.674c7.84-13.87 16.932-29.287 33.18-34.215a37.026 37.026 0 0 1 13.956-1.441c1.482.128 1.112 2.412-.367 2.284a34.398 34.398 0 0 0-22.272 5.893c-6.28 4.274-11.17 10.217-15.307 16.519-2.536 3.86-4.806 7.884-7.077 11.903-.725 1.284-2.847.357-2.113-.943Z"
        data-name="Path 443"
      />
      <path
        fill="#fff"
        d="M675.815 411.433H243.6a5.03 5.03 0 0 1-5.023-5.024V5.024A5.03 5.03 0 0 1 243.6 0h432.215a5.03 5.03 0 0 1 5.024 5.024v401.384a5.03 5.03 0 0 1-5.024 5.024Z"
        data-name="Path 141"
      />
      <path
        fill="#cacaca"
        d="M675.815 411.433H243.6a5.03 5.03 0 0 1-5.023-5.024V5.024A5.03 5.03 0 0 1 243.6 0h432.215a5.03 5.03 0 0 1 5.024 5.024v401.384a5.03 5.03 0 0 1-5.024 5.024ZM243.6 2.005a3.018 3.018 0 0 0-3.011 3.012v401.391a3.018 3.018 0 0 0 3.011 3.012h432.215a3.017 3.017 0 0 0 3.012-3.012V5.024a3.018 3.018 0 0 0-3.012-3.012Z"
        data-name="Path 141"
      />
      <path
        fill="#e4e4e4"
        d="M447.83 104.301a3.41 3.41 0 0 0 0 6.821h187.142a3.41 3.41 0 0 0 0-6.82Z"
        data-name="Path 142"
      />
      <path
        fill="#e4e4e4"
        d="M447.83 124.766a3.41 3.41 0 0 0 0 6.821h95.54a3.41 3.41 0 0 0 0-6.82Z"
        data-name="Path 143"
      />
      <path
        fill="#e4e4e4"
        d="M284.26 234.82a3.41 3.41 0 0 0 0 6.822h350.894a3.41 3.41 0 0 0 0-6.821Z"
        data-name="Path 142"
      />
      <path
        fill="#e4e4e4"
        d="M284.26 255.286a3.41 3.41 0 0 0 0 6.82h259.292a3.41 3.41 0 0 0 0-6.82Z"
        data-name="Path 143"
      />
      <path
        fill="#e4e4e4"
        d="M284.26 275.288a3.41 3.41 0 0 0 0 6.82h350.894a3.41 3.41 0 0 0 0-6.82Z"
        data-name="Path 142"
      />
      <path
        fill="#e4e4e4"
        d="M284.26 295.753a3.41 3.41 0 0 0 0 6.821h259.292a3.41 3.41 0 0 0 0-6.821Z"
        data-name="Path 143"
      />
      <path
        fill="#e4e4e4"
        d="M284.26 316.288a3.41 3.41 0 0 0 0 6.82h350.894a3.41 3.41 0 0 0 0-6.82Z"
        data-name="Path 142"
      />
      <path
        fill="#e4e4e4"
        d="M284.26 336.753a3.41 3.41 0 0 0 0 6.821h259.292a3.41 3.41 0 0 0 0-6.821Z"
        data-name="Path 143"
      />
      <path
        fill="#6c63ff"
        d="M339.839 166.944a49 49 0 1 1 49-49 49.056 49.056 0 0 1-49 49Z"
      />
      <path
        fill="#a0616a"
        d="M191.098 352.216a12.248 12.248 0 0 0-14.953-11.362l-16.197-22.825-16.271 6.46 23.325 31.912a12.314 12.314 0 0 0 24.096-4.185Z"
      />
      <path
        fill="#3f3d56"
        d="m159.531 350.525-49.007-63.578 18.362-57.711c1.346-14.51 10.425-18.56 10.812-18.726l.59-.253 15.978 42.612-11.732 31.286 28.796 48.432Z"
      />
      <path
        fill="#a0616a"
        d="M329.727 154.536a12.248 12.248 0 0 0-10.172 15.787l-21.505 17.912 7.699 15.724 30.013-25.723a12.314 12.314 0 0 0-6.035-23.7Z"
      />
      <path
        fill="#3f3d56"
        d="m330.481 186.138-59.598 53.777-58.958-13.846c-14.57-.22-19.312-8.958-19.506-9.33l-.298-.569 41.249-19.226 32.1 9.279 46.06-32.455Z"
      />
      <path
        fill="#a0616a"
        d="m227.248 568.437 16.013-.001 7.617-61.764-23.633.001.003 61.764z"
      />
      <path
        fill="#2f2e41"
        d="m274.143 583.304-50.326.002v-19.444l36.206-.002a14.12 14.12 0 0 1 14.12 14.119v5.325Z"
      />
      <path
        fill="#a0616a"
        d="m163.247 568.437 16.013-.001 7.618-61.764-23.633.001.002 61.764z"
      />
      <path
        fill="#2f2e41"
        d="m210.143 583.304-50.326.002-.001-19.444 36.207-.002a14.12 14.12 0 0 1 14.12 14.119v5.325ZM157.552 342.991l1.306 91.429 1.307 120.164 28.734-2.612 14.368-165.878 18.286 165.878h29.665l2.988-167.184-10.449-36.572-86.205-5.225z"
      />
      <path
        fill="#3f3d56"
        d="M213.793 355.27c-31.265.002-60.044-14.15-60.433-14.343l-.322-.162-2.624-62.966c-.761-2.225-15.743-46.131-18.28-60.086-2.571-14.14 34.688-26.548 39.213-27.999l1.027-11.374 41.753-4.5 5.292 14.554 14.98 5.617a7.41 7.41 0 0 1 4.592 8.704l-8.326 33.857L251 348.584l-4.378.19c-10.493 4.739-21.817 6.495-32.828 6.495Z"
      />
      <circle
        cx={454.467}
        cy={294.46}
        r={30.063}
        fill="#a0616a"
        transform="rotate(-28.663 15.686 723.534)"
      />
      <path
        fill="#2f2e41"
        d="M170.536 165.677c5.73 6.103 16.37 2.827 17.116-5.51a10.072 10.072 0 0 0-.013-1.946c-.385-3.693-2.519-7.046-2.008-10.945a5.74 5.74 0 0 1 1.05-2.687c4.566-6.114 15.283 2.734 19.592-2.8 2.642-3.394-.464-8.737 1.564-12.53 2.676-5.006 10.601-2.536 15.572-5.278 5.53-3.05 5.2-11.535 1.559-16.696-4.44-6.294-12.224-9.652-19.91-10.136s-15.321 1.594-22.498 4.39c-8.154 3.178-16.24 7.57-21.257 14.74-6.103 8.719-6.69 20.44-3.638 30.636 1.857 6.202 8.192 13.779 12.87 18.762Z"
      />
      <path
        fill="#cacaca"
        d="M382 584.079H1a1 1 0 0 1 0-2h381a1 1 0 0 1 0 2Z"
      />
      <path
        fill="#fff"
        d="M337.01 136.451a3.488 3.488 0 0 1-2.382-.935l-16.157-15.007a3.5 3.5 0 0 1 4.763-5.13l13.686 12.713 27.076-27.077a3.5 3.5 0 1 1 4.95 4.95l-29.461 29.462a3.493 3.493 0 0 1-2.476 1.024Z"
      />
    </svg>
  );
}
