import { useZorm } from 'react-zorm';
import { Organization } from '@pandler/shared/data-types';
import {
  Button,
  DropZoneS3,
  error,
  FieldGroupFieldError,
  FormGroup,
  Input,
  LoadingIcon,
  SectionPanelContainer,
  success,
} from '@pandler/shared/react-ui';
import { useUpdateOrganization } from '../../hooks/organization';
import useOrganization from '../../hooks/organization/use-organization';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  API_ENDPOINT,
  CDN_ENDPOINT,
  getFileNameFromUrl,
} from '@pandler/shared/utils';
import { GetAccessTokenDefaults } from '../../config';
import { useAuth0 } from '@auth0/auth0-react';

interface OrganizationSettingsFormProps {
  mode?: 'standard' | 'express';
  onSuccess?: () => void;
  successMessage?: string;
  submitLabel?: string;
  hideSuccessPopup?: boolean;
  initialEmpty?: boolean;
}

type OrganizationPicks = {
  [k in keyof Organization]?: true;
};

const expressFields: OrganizationPicks = {
  legalName: true,
  registrationNumber: true,
  supportEmail: true,
  website: true,
};

const standardFields: OrganizationPicks = {
  ...expressFields,
  name: true,
  logo: true,
  primaryColor: true,
  secondaryColor: true,
  locale: true,
  address: true,
};

export function OrganizationSettingsForm({
  mode = 'standard',
  onSuccess,
  successMessage = 'Organization updated',
  submitLabel = 'Save',
  hideSuccessPopup = false,
  initialEmpty = false,
}: OrganizationSettingsFormProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { organization, invalidate } = useOrganization();
  const { isLoading, mutate, isSuccess } = useUpdateOrganization();
  const zo = useZorm(
    'organization-settings',
    Organization.pick(mode === 'standard' ? standardFields : expressFields),
    {
      onValidSubmit(e) {
        e.preventDefault();
        if (organization) {
          mutate({ id: organization.id, data: e.data });
        }
      },
    }
  );
  const [logo, setLogo] = useState<string | undefined>(organization?.logo);
  useEffect(() => {
    setLogo(organization?.logo);
  }, [organization?.logo]);

  useEffect(() => {
    if (isSuccess) {
      if (!hideSuccessPopup && successMessage) {
        success({ message: successMessage });
      }

      if (onSuccess) {
        onSuccess();
      }

      invalidate();
    }
  }, [isSuccess, onSuccess, successMessage, invalidate, hideSuccessPopup]);

  const submitButton = useMemo(
    () => (
      <Button
        type="submit"
        disabled={isLoading}
        className={
          mode === 'standard'
            ? ''
            : classNames(
                'rounded-full justify-center py-3 transition-all duration-500',
                {
                  'w-full': !isLoading,
                  'w-32': isLoading,
                }
              )
        }
      >
        {isLoading ? (
          <LoadingIcon className="w-5 h-5 text-white" strokeWidth={4} />
        ) : (
          submitLabel
        )}
      </Button>
    ),
    [isLoading, submitLabel, mode]
  );

  if (!organization) {
    return null;
  }

  return (
    <form ref={zo.ref} className="mt-8">
      <SectionPanelContainer
        className={classNames({
          'mb-10': mode === 'standard',
          'mb-5': mode === 'express',
        })}
        gridContainerClassName={classNames({
          'sm:grid-cols-1': mode === 'express',
        })}
        title="Business information"
        description="This information will be displayed publicly so be careful what
                you share."
        hideLeftPanel={mode === 'express'}
        showBorder={mode === 'standard'}
      >
        <FormGroup
          id="legalName"
          label="Legal name"
          showRequiredAsterisk
          input={
            <Input
              type="text"
              placeholder="My Business Pty Ltd"
              name={zo.fields.legalName()}
              defaultValue={initialEmpty ? '' : organization.legalName}
              hasError={zo.errors.legalName(true)}
            />
          }
          error={zo.errors.legalName(FieldGroupFieldError)}
        />

        <FormGroup
          id="supportEmail"
          label="Support email"
          description="This will be shown to your customers in case they need to reach out."
          descriptionPosition={mode === 'express' ? 'above' : 'below'}
          showRequiredAsterisk
          input={
            <Input
              type="email"
              placeholder="email@example.com"
              name={zo.fields.supportEmail()}
              defaultValue={initialEmpty ? '' : organization.supportEmail}
              hasError={zo.errors.supportEmail(true)}
            />
          }
          error={zo.errors.supportEmail(FieldGroupFieldError)}
        />

        {mode === 'standard' && (
          <FormGroup
            id="name"
            label="Trading name"
            input={
              <Input
                type="text"
                placeholder="My Business"
                name={zo.fields.name()}
                defaultValue={initialEmpty ? '' : organization.name}
                hasError={zo.errors.name(true)}
              />
            }
            error={zo.errors.name(FieldGroupFieldError)}
          />
        )}

        <FormGroup
          id="registrationNumber"
          label="ABN"
          input={
            <Input
              type="text"
              placeholder="11 222 333 444"
              name={zo.fields.registrationNumber()}
              defaultValue={initialEmpty ? '' : organization.registrationNumber}
              hasError={zo.errors.registrationNumber(true)}
            />
          }
          error={zo.errors.registrationNumber(FieldGroupFieldError)}
        />

        <FormGroup
          id="website"
          label="Website"
          input={
            <Input
              type="text"
              placeholder="https://example.com/"
              name={zo.fields.website()}
              defaultValue={initialEmpty ? '' : organization.website}
              hasError={zo.errors.website(true)}
            />
          }
          error={zo.errors.website(FieldGroupFieldError)}
        />

        {mode === 'standard' && submitButton}
      </SectionPanelContainer>

      <SectionPanelContainer
        title="Branding"
        description={
          <>
            <p>
              This will be used to personalize your customer communications.
            </p>
            {logo && (
              <img
                src={logo}
                alt={organization.name}
                className="mt-4 max-h-20"
              />
            )}
          </>
        }
        hideLeftPanel={mode === 'express'}
        showBorder={mode === 'standard'}
      >
        {mode === 'standard' && (
          <FormGroup
            id="logo"
            label="Logo"
            description="If no logo is provided, the Pandler logo will be used."
            descriptionPosition="below"
            input={
              <>
                <input type="hidden" name={zo.fields.logo()} value={logo} />
                <DropZoneS3<{ url: string }>
                  headersGenerator={async () => {
                    const token = await getAccessTokenSilently({
                      ...GetAccessTokenDefaults,
                      scope: 'write:media',
                    });

                    return { Authorization: `Bearer ${token}` };
                  }}
                  urlGenerator={(file) =>
                    `${API_ENDPOINT}/media?extension=${file.name
                      .split('.')
                      .pop()}`
                  }
                  urlParser={({ url }) => url}
                  onSuccess={(url) => {
                    success({
                      message: 'Logo successfully uploaded',
                    });
                    setLogo(`${CDN_ENDPOINT}/${getFileNameFromUrl(url)}`);
                  }}
                  onError={() => error({ message: 'Unable to upload logo' })}
                />
              </>
            }
            error={zo.errors.logo(FieldGroupFieldError)}
          />
        )}

        {mode === 'standard' && (
          <div className="flex flex-row gap-x-10">
            <FormGroup
              id="primaryColor"
              label="Primary colour"
              input={
                <input
                  type="color"
                  placeholder="#000000"
                  name={zo.fields.primaryColor()}
                  defaultValue={initialEmpty ? '' : organization.primaryColor}
                />
              }
              error={zo.errors.primaryColor(FieldGroupFieldError)}
            />

            <FormGroup
              id="secondaryColor"
              label="Secondary colour"
              input={
                <input
                  type="color"
                  placeholder="#ffffff"
                  name={zo.fields.secondaryColor()}
                  defaultValue={initialEmpty ? '' : organization.secondaryColor}
                />
              }
              error={zo.errors.secondaryColor(FieldGroupFieldError)}
            />
          </div>
        )}

        {mode === 'standard' && submitButton}
      </SectionPanelContainer>

      {mode === 'express' && submitButton}
    </form>
  );
}
