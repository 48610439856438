import { type Filter, useSearchMessageLogs } from '@pandler/cloud/shared';
import {
  Button,
  InlineError,
  LoadingContainer,
  LoadingIcon,
} from '@pandler/shared/react-ui';
import { LogsList } from '../logs-list';
import type { InboxProps } from '@pandler/shared/inbox-ui';

export interface LogsProps {
  filter?: Filter;
  messageClassName?: string;
  mainClassName?: string;
  inboxProps?: Partial<InboxProps>;
}

export function Logs({
  filter,
  messageClassName = '',
  mainClassName = '',
  inboxProps = {},
}: LogsProps) {
  const {
    feed,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSearchMessageLogs({
    filter: filter?.searchValue,
    dateFrom: filter?.dateValue?.dateTime,
  });

  if (error) {
    return <InlineError>{error.message}</InlineError>;
  }

  if (isLoading || !feed) {
    return <LoadingContainer />;
  }

  return (
    <>
      <LogsList
        inboxProps={inboxProps}
        items={feed.pages.flatMap(({ data }) => data)}
        messageClassName={messageClassName}
        mainClassName={mainClassName}
      />

      {hasNextPage && (
        <Button
          className="mt-4"
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
        >
          {isFetchingNextPage ? (
            <LoadingIcon className="w-3.5 h-3.5 text-white" strokeWidth={3} />
          ) : (
            'Load more'
          )}
        </Button>
      )}
    </>
  );
}
