import { useAuth0 } from '@auth0/auth0-react';
import type { Organization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useCallback } from 'react';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { userState } from '../../state';
import { GetAccessTokenDefaults, queryClient } from '../../config';

interface UseOrganizationOptions {
  organizationId?: string;
  queryOptions?: UseQueryOptions<
    Pick<Organization, 'sync'>,
    Error,
    Pick<Organization, 'sync'>
  >;
}

export default function useOrganizationSync({
  organizationId,
  queryOptions = {},
}: UseOrganizationOptions = {}) {
  const user = useRecoilValue(userState);
  const id = organizationId ?? user?.organizations?.[0];

  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, error, data, isRefetching } = useQuery<
    Pick<Organization, 'sync'>,
    Error
  >(
    ['organization', 'sync', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:organization',
      });
      const $response = await fetch(`${API_ENDPOINT}/organization/${id}/sync`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const { data } = await $response.json();
      if (!data) {
        throw new Error('Unable to retrieve organization sync data');
      }

      return data;
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['organization', 'sync', id] });
  }, [id]);

  return {
    isLoading,
    error,
    data,
    isSyncing: data?.sync?.status === 'running',
    invalidate,
    isRefetching,
  };
}
