import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';

export interface UseInvoicePdfUrlResponse {
  url: string;
}

interface UseInvoicePdfUrlOptions {
  invoiceId: string;
  queryOptions?: UseQueryOptions<
    UseInvoicePdfUrlResponse,
    Error,
    UseInvoicePdfUrlResponse
  >;
}

export const invoicePdfUrlQueryFn =
  ({ token }: { token: string }) =>
  async (id: string): Promise<{ url: string }> => {
    const response = await (
      await fetch(`${API_ENDPOINT}/pdf/invoice/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      })
    ).json();

    if (!response.url) {
      throw new Error('Unable to retrieve pdf');
    }

    return response;
  };

const useInvoicePdfUrl = ({
  invoiceId,
  queryOptions = {},
}: UseInvoicePdfUrlOptions) => {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery<UseInvoicePdfUrlResponse, Error>(
    ['invoice-pdf', invoiceId],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:invoice',
      });

      const fetcher = invoicePdfUrlQueryFn({ token });
      return fetcher(invoiceId);
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );

  return { ...query, customer: query.data };
};

export default useInvoicePdfUrl;
