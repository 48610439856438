import { Logo } from '@pandler/shared/react-ui';
import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
interface PageWrapperProps {
  children: ReactNode;
  className?: string;
}

export function PageWrapper({ children, className = '' }: PageWrapperProps) {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className={twMerge('w-full sm:max-w-xl xl:max-w-lg', className)}>
        <Logo className="h-12 mb-6 mx-auto" />

        {children}
      </div>
    </div>
  );
}
