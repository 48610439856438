import { Button, FeelingBlueIcon } from '@pandler/shared/react-ui';
import React, { useEffect, useState } from 'react';

type FallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => (
  <div className="flex flex-col justify-center items-center text-center grow h-full">
    <FeelingBlueIcon className="h-64 mt-24" />
    <div className="max-w-[546px] mx-auto w-full mt-12">
      <h2 className="text-slate-900 mb-4 text-2xl font-semibold">
        Oops! Something went wrong.
      </h2>
      <div className="text-gray-600 dark:text-slate-300 text-base font-normal mb-10">
        {error.message}
      </div>
      <div className="max-w-[300px] mx-auto w-full">
        <Button onClick={resetErrorBoundary} className="w-full justify-center">
          Try again
        </Button>
      </div>
    </div>
  </div>
);

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      setError(event.error);
    };

    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  const resetErrorBoundary = () => {
    setError(null);
  };

  if (error) {
    return (
      <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ErrorBoundary;
