import { Content } from '@pandler/cloud/skeleton';
import type { Template } from '@pandler/shared/data-types';
import { TemplatesSidebar } from '../templates-sidebar';
import { useState } from 'react';
import {
  BuildingBlocksIcon,
  EmptyState,
  LoadingContainer,
} from '@pandler/shared/react-ui';
import { EmptyState as SetupEmptyState } from '../empty-state';
import { TemplateEditor } from '../template-editor';
import classNames from 'classnames';
import { isEqual } from 'lodash';

export interface TemplatePageEditorProps {
  initialActiveId?: string;
}

export function TemplatePageEditor({
  initialActiveId,
}: TemplatePageEditorProps) {
  const [active, setActive] = useState<Template | undefined>();
  const [data, setData] = useState<{
    isLoading: boolean;
    hasTemplates: boolean | undefined;
  }>({ isLoading: true, hasTemplates: undefined });

  const handleOnChange = (templates: Template[] | undefined) => {
    if (Array.isArray(templates)) {
      const update = { isLoading: false, hasTemplates: templates.length > 0 };
      if (!isEqual(data, update)) {
        setData(update);
      }

      if (initialActiveId && !active) {
        const $active = templates.find(({ id }) => id === initialActiveId);
        if ($active) {
          setActive($active);
        }
      }
    }
  };

  const showEditor = !data.isLoading && data.hasTemplates;
  const showEmptyState = !data.isLoading && !data.hasTemplates;

  return (
    <Content
      className="flex flex-col flex-auto grow h-full w-full md:px-0"
      childrenContainerClassName="flex flex-col flex-auto grow h-full w-full p-0"
      containerClassName="flex flex-col flex-auto grow h-full w-full py-0"
    >
      {data.isLoading && <LoadingContainer />}
      {showEmptyState && <SetupEmptyState />}

      <div
        className={classNames('flex flex-auto w-full h-full', {
          invisible: !showEditor,
          visible: showEditor,
        })}
      >
        <TemplatesSidebar
          activeTemplate={active}
          onClick={setActive}
          onChange={handleOnChange}
        />

        <div className="flex grow bg-slate-50 justify-center items-center">
          {!data.isLoading && !active && (
            <EmptyState
              className="mt-0"
              icon={BuildingBlocksIcon}
              title="Select a template to start editing"
              titleClassName="font-medium text-gray-600"
            />
          )}
          {!data.isLoading && active && (
            <TemplateEditor
              template={active}
              onDelete={() => setActive(undefined)}
            />
          )}
        </div>
      </div>
    </Content>
  );
}
