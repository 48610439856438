import { type ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticatedWrapper } from './authenticated-wrapper';
import {
  ErrorPage,
  ErrorScreen,
  LoadingScreen,
} from '@pandler/shared/react-ui';

export interface AuthWrapper {
  children: ReactNode;
}

export function AuthWrapper({ children }: AuthWrapper) {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <ErrorPage
        title="Error"
        screen={
          <ErrorScreen
            errorCode="Application error"
            title="Uh oh!"
            description={
              <>
                An unexpected error occurred: <br />
                <strong>{error.message}</strong>
              </>
            }
          />
        }
      />
    );
  }

  if (!isLoading && !isAuthenticated) {
    // user not authenticated, render the children
    return <>{children}</>; // eslint-disable-line
  }

  if (!isLoading && isAuthenticated) {
    // user is authenticated
    return <AuthenticatedWrapper>{children}</AuthenticatedWrapper>;
  }

  // unknown error
  return null;
}
