import {
  Button,
  EmptyState,
  FieldGroupFieldError,
  InlineError,
  InputWithLabels,
  LoadingIcon,
  PortfolioIcon,
  success,
  useConfirmation,
} from '@pandler/shared/react-ui';
import { useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation } from '@tanstack/react-query';
import { FormDefinition } from './form-definition';
import { AvailableMessage } from './available-message';
import { validateId } from './validate-id';
import { useZorm } from 'react-zorm';
import { useUpdateOrganization } from '@pandler/cloud/shared';

export function PublicIdCreate() {
  const { confirm } = useConfirmation();
  const idValidation = useMutation(validateId);
  const update = useUpdateOrganization();
  const zo = useZorm('publicWebsite', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      confirm({
        body: (
          <p>
            Are you sure you want to use{' '}
            <span className="font-semibold">{e.data.publicId}.pandler.app</span>
            ? You will not be able to change this.
          </p>
        ),
        onConfirm: async () => {
          await update.mutateAsync({ data: e.data });
        },
      });
    },
    customIssues: idValidation.data?.issues,
  });

  useEffect(() => {
    if (update.isSuccess) {
      success({ message: 'Your website URL has successfully been set.' });
    }
  }, [update.isSuccess]);

  const handleOnChange = useDebouncedCallback(() => {
    const validation = zo.validate();
    if (validation.success && validation.data.publicId) {
      idValidation.mutate(validation.data.publicId);
    }
  }, 300);
  const hasError = zo.errors.publicId(true);
  const isAvailable = useMemo(
    () => idValidation.data?.issues.length === 0,
    [idValidation.data?.issues.length]
  );

  return (
    <section className="space-y-8">
      <EmptyState
        icon={PortfolioIcon}
        iconClassName="h-48"
        title="Set up your public website"
        body="Before you can start accepting payments, you need to choose a public URL. This is the URL your customers will pay on."
      />

      <form
        ref={zo.ref}
        className="lg:max-w-xl mx-auto space-x-2 flex flex-row"
      >
        <div className="space-y-4 flex flex-row grow">
          <InputWithLabels
            className="w-full"
            prefix="https://"
            input={{
              name: zo.fields.publicId(),
              hasError: zo.errors.publicId(true),
              onChange: handleOnChange,
              className: 'w-full',
              placeholder: 'my-company-name',
            }}
            suffix=".pandler.app"
          />
        </div>

        <Button
          type="submit"
          className="w-24 text-center justify-center shrink-0"
          disabled={
            hasError ||
            idValidation.isLoading ||
            !isAvailable ||
            update.isLoading
          }
        >
          {idValidation.isLoading || update.isLoading ? (
            <LoadingIcon className="w-5 h-5 text-white" strokeWidth={3} />
          ) : (
            'Create'
          )}
        </Button>
      </form>

      {hasError && (
        <InlineError className="text-sm text-center">
          {zo.errors.publicId(FieldGroupFieldError)}
        </InlineError>
      )}

      {!hasError && isAvailable && <AvailableMessage zo={zo} />}
    </section>
  );
}
