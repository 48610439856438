export class HtmlPreviewElement extends HTMLElement {
  private _shadowRoot: ShadowRoot | null;

  static get observedAttributes() {
    return ['content', 'css'];
  }

  constructor() {
    super();
    this._shadowRoot = this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.render().catch((error) => console.error('Error in render:', error));
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (['content', 'css'].includes(name) && oldValue !== newValue) {
      this.render().catch((error) => console.error('Error in render:', error));
    }
  }

  async render() {
    const content = this.getAttribute('content') ?? '';
    const css = this.getAttribute('css') ?? '';

    const template = document.createElement('template');
    const body = `<div class="root">${content}</div>`;

    template.innerHTML = `
      <style>
        ${css}
      </style>
      ${body}
    `;

    if (this._shadowRoot) {
      this._shadowRoot.innerHTML = '';
      this._shadowRoot.appendChild(template.content.cloneNode(true));
    }
  }
}
