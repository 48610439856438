import {
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';
import { type Activity } from '@pandler/shared/data-types';
import { Comment } from './components/comment';
import { Notification } from './components/notification';
import { twMerge } from 'tailwind-merge';

export interface ActivityFeedProps {
  items: Activity[];
  showComments?: boolean;
  showNotifications?: boolean;
  className?: string;
  classNames?: {
    paymentIconBackground?: string;
    paymentIconText?: string;
    communicationIconBackground?: string;
    communicationIconText?: string;
    systemIconBackground?: string;
    systemIconText?: string;
    dangerIconBackground?: string;
    dangerIconText?: string;
  };
}

export function ActivityFeed({
  items,
  showComments = true,
  showNotifications = true,
  className = '',
  classNames = {},
}: ActivityFeedProps) {
  const filteredItems = items.filter(({ type }) =>
    type === 'Comment' ? showComments : showNotifications
  );

  return (
    <div className={twMerge('flow-root', className)}>
      <ul className="-mb-8">
        {filteredItems.map((item, itemIdx) => (
          <li key={item.id}>
            <div className="relative pb-8">
              {itemIdx !== filteredItems.length - 1 ? (
                <span
                  className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}

              <div className="relative flex items-start space-x-3">
                {item.type === 'Comment' && (
                  <Comment {...item} type="Comment" />
                )}
                {item.type === 'Payment' && (
                  <Notification
                    {...item}
                    iconBackgroundClassName={
                      classNames.paymentIconBackground ?? 'bg-green-500'
                    }
                    iconTextClassName={
                      classNames.paymentIconText ?? 'text-white'
                    }
                    Icon={CurrencyDollarIcon}
                  />
                )}
                {item.type === 'Communication' && (
                  <Notification
                    {...item}
                    iconBackgroundClassName={
                      classNames.communicationIconBackground ?? 'bg-gray-100'
                    }
                    iconTextClassName={
                      classNames.communicationIconText ?? 'text-gray-500'
                    }
                    Icon={EnvelopeIcon}
                  />
                )}
                {item.type === 'System' && (
                  <Notification
                    {...item}
                    iconBackgroundClassName={
                      classNames.systemIconBackground ?? 'bg-gray-100'
                    }
                    iconTextClassName={
                      classNames.systemIconText ?? 'text-gray-500'
                    }
                    Icon={ClipboardDocumentListIcon}
                  />
                )}
                {item.type === 'Danger' && (
                  <Notification
                    {...item}
                    iconBackgroundClassName={
                      classNames.dangerIconBackground ?? 'bg-red-500'
                    }
                    iconTextClassName={
                      classNames.dangerIconText ?? 'text-white'
                    }
                    Icon={ExclamationTriangleIcon}
                  />
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
