import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import type { Template } from '@pandler/shared/data-types';

export interface UseSaveTemplateMutationOptions {
  id: string;
  data: Partial<Template>;
}

export interface UseSaveTemplateResponse {
  updated: Partial<Template>;
}

export default function useSaveTemplate() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({
      id,
      data,
    }: UseSaveTemplateMutationOptions): Promise<UseSaveTemplateResponse> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:template',
      });

      const $response = await fetch(`${API_ENDPOINT}/templates/${id}`, {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if ($response.status === 200) {
        const response = await $response.json();
        return response.data;
      }

      throw Error('Unable to save the template');
    },
  });
}
