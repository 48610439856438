import type { ReactNode } from 'react';

interface ItemProps {
  icon?: ReactNode;
  label: string;
}

export function Item({ icon, label }: ItemProps) {
  return (
    <span className="flex flex-row gap-x-2 items-center">
      {icon}
      {label}
    </span>
  );
}
