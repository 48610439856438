import { z } from 'zod';

export const Integration = z.object({
  id: z.string(),
  name: z.string(),
  logo: z.string().optional(),
});

export type Integration = z.infer<typeof Integration>;

interface StaticIntegration {
  logo: readonly [string, string];
  description: string;
}

type KnownIntegration =
  | 'xero'
  | 'sandbox'
  | 'clear books'
  | 'kashflow'
  | 'quickbooks desktop'
  | 'oracle netsuite';

type StaticIntegrationDetails = {
  [key in KnownIntegration]: StaticIntegration;
};

export const integrations: StaticIntegrationDetails = {
  xero: {
    logo: [
      'https://static.codat.io/public/officialLogos/Full/Xero_Square.png',
      'https://static.codat.io/public/officialLogos/Full/Xero_Full.png',
    ],
    description:
      'Xero connects you to your bank, accountant, bookkeeper, and other business apps.',
  },
  sandbox: {
    logo: [
      'https://static.codat.io/public/officialLogos/Full/AccountingSandbox_Square.png',
      'https://static.codat.io/public/platforms/sandbox.png',
    ],
    description:
      'A sandbox environment to test integrations with stub data. Only available on non-production.',
  },
  'clear books': {
    logo: [
      'https://static.codat.io/public/officialLogos/Square/C23CFE49-1C1B-49BD-9192-8280B281F0C0.png',
      'https://static.codat.io/public/officialLogos/Full/C23CFE49-1C1B-49BD-9192-8280B281F0C0.png',
    ],
    description:
      'Clear Books is the simple tool to make your businesses finances clear.',
  },
  kashflow: {
    logo: [
      'https://static.codat.io/public/officialLogos/Square/4E552E84-0E07-41B7-9B2D-14D5EEFD11B6.png',
      'https://static.codat.io/public/officialLogos/Full/4E552E84-0E07-41B7-9B2D-14D5EEFD11B6.png',
    ],
    description:
      'KashFlow is a cloud-based accounting solution that caters to businesses of all sizes.',
  },
  'quickbooks desktop': {
    logo: [
      'https://static.codat.io/public/officialLogos/Square/19EEFA32-58A8-4097-B95A-EF150BF24FA0.png',
      'https://static.codat.io/public/officialLogos/Full/19EEFA32-58A8-4097-B95A-EF150BF24FA0.png',
    ],
    description: 'QuickBooks is an accounting software package by Intuit.',
  },
  'oracle netsuite': {
    logo: [
      'https://static.codat.io/public/officialLogos/Square/9815B998-024D-4415-A40B-89903B2CAD6F.png',
      'https://static.codat.io/public/officialLogos/Full/9815B998-024D-4415-A40B-89903B2CAD6F.png',
    ],
    description:
      'An integrated cloud business software suite, including business accounting, ERP, CRM and ecommerce software.',
  },
} as const;

export const knownIntegrations = Object.keys(integrations);

export const isKnownIntegration = (
  candidate: unknown
): candidate is KnownIntegration => {
  console.log('isKnownIntegrations', candidate);
  return typeof candidate === 'string' && knownIntegrations.includes(candidate);
};
