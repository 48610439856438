import type { Notification } from '@pandler/shared/data-types';

export function entityToUrl(entity?: Notification['entity']) {
  if (!entity) {
    return undefined;
  }

  const { id, type } = entity;
  switch (type) {
    case 'invoice':
      return `/invoices/view/${id}`;
    case 'payment':
      return `/payments/view/${id}`;
    case 'message':
      return `/messages/queue/view/${id}`;
  }
}
