import type { Workflow } from '@pandler/shared/data-types';
import { Bell2Icon, success, ToggleNode } from '@pandler/shared/react-ui';

interface WorkflowsListProps {
  items: Workflow[];
}

export function WorkflowsList({ items }: WorkflowsListProps) {
  const handleOnDelete = (item: Workflow) => () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        success({
          message: `Workflow successfully ${
            item.status === 'active' ? 'disabled' : 'enabled'
          }`,
        });

        resolve();
      }, 2000);
    });

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 pt-3">
      {items.map((item) => (
        <ToggleNode
          key={item.id}
          name={item.name}
          description={item.description}
          icon={<Bell2Icon className="h-12 text-gray-800" />}
          on={item.status === 'active'}
          onDelete={handleOnDelete(item)}
          url={`/workflows/view/${item.id}`}
          confirmText={() => `Are you sure you want to
          ${item.status === 'active' ? 'disable' : 'enable'} the
          selected workflow? ${
            item.status === 'active'
              ? 'This will cancel all active executions.'
              : ''
          }`}
        />
      ))}
    </div>
  );
}
