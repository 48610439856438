import type { Invoice } from '@pandler/shared/data-types';
import type { InvoicesTableProps } from '../invoices-table';
import { InvoicesTable } from '../invoices-table';
import { useMemo, useState } from 'react';
import { FilterBar, type Filter } from '@pandler/cloud/shared';

type Status = Invoice['status'] | 'Overdue';
export type InvoiceListProps = {
  status?: Status;
  hideFilterBar?: boolean;
  initialFilter?: Filter;
} & Pick<InvoicesTableProps, 'hidePagination' | 'narrow' | 'slideOut'>;

const properties = ['number', 'total', 'customer.name'];

const overDueFilter = {
  every: [
    {
      property: 'status',
      operator: 'in',
      value: ['Submitted', 'PartiallyPaid'],
    },
    {
      property: 'dueDate',
      operator: 'lessThan',
      value: new Date().toISOString(),
    },
  ],
};

const toSearchFilter = ({
  filter,
  status,
}: {
  filter?: Filter;
  status?: Status;
}) => ({
  every: [
    ...(filter?.searchValue && filter.searchValue.length > 0
      ? [
          {
            some: properties.map((property) => ({
              property: {
                name: property,
                preProcess: ['toString', 'toLower'],
              },
              operator: 'contains',
              value: filter.searchValue,
            })),
          },
        ]
      : []),
    ...(filter?.dateValue
      ? [
          {
            property: 'issuedDate',
            operator: 'greaterThanInclusive',
            value: filter.dateValue.dateTime,
          },
        ]
      : []),
    ...(status
      ? [
          status === 'Overdue'
            ? overDueFilter
            : {
                property: 'status',
                operator: 'equal',
                value: status,
              },
        ]
      : []),
  ],
});

export function InvoiceList({
  status,
  hideFilterBar = false,
  initialFilter,
  slideOut = true,
  ...tableProps
}: InvoiceListProps) {
  const [$filter, setFilter] = useState<Filter | undefined>(initialFilter);

  const filter = useMemo(
    () => toSearchFilter({ filter: $filter, status }),
    [$filter, status]
  );

  return (
    <div className="flex flex-col gap-4">
      {!hideFilterBar && (
        <FilterBar onChange={setFilter} label="Search invoices..." />
      )}

      {$filter?.dateValue && (
        <InvoicesTable
          key={status}
          status={status}
          slideOut
          filter={filter}
          {...tableProps}
        />
      )}
    </div>
  );
}
