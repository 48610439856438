import type { InvoiceUpdatedTrigger } from '@pandler/shared/data-types';
import { Button, FormGroup, QueryBuilder } from '@pandler/shared/react-ui';
import type { TopLevelCondition } from 'json-rules-engine';
import { toJsonRule } from 'json-rules-engine-to-json-logic';
import { ActionElement, parseJsonLogic } from 'react-querybuilder';
import type { DataTypes} from '../../../utils/conditions';
import { mapDataTypes } from '../../../utils/conditions';

export interface InvoiceUpdatedFormProps {
  data: InvoiceUpdatedTrigger;
}

export function InvoiceUpdatedForm({ data }: InvoiceUpdatedFormProps) {
  const dataTypes: Partial<DataTypes> = { invoice: true };
  const fields = mapDataTypes(dataTypes, { prefix: 'Invoice' });
  const rule = toJsonRule(data.filter as TopLevelCondition);
  const query = parseJsonLogic(rule);
  // @todo: is top level condition

  return (
    <div className="overflow-x-auto">
      <FormGroup
        id="rule"
        label="Condition"
        description="Lorem ipsum"
        input={
          <QueryBuilder
            fields={fields}
            controlClassnames={{ queryBuilder: '-ml-4' }}
            controlElements={{
              addGroupAction: (props) =>
                props.level === 0 ? <ActionElement {...props} /> : null,
            }}
            query={query}
          />
        }
        inputClassName="mt-4"
      />

      <Button type="submit">Save</Button>
    </div>
  );
}
