import type { ElementType } from 'react';
import { twMerge } from 'tailwind-merge';

export interface RoundedIconProps {
  className?: string;
  icon: ElementType;
  iconClassName?: string;
}

export function RoundedIcon({
  className = '',
  icon: Icon,
  iconClassName = '',
}: RoundedIconProps) {
  return (
    <div>
      <span
        className={twMerge(
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
          className
        )}
      >
        <Icon
          className={twMerge('h-5 w-5 text-white', iconClassName)}
          aria-hidden="true"
        />
      </span>
    </div>
  );
}
