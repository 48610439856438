import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { type ReactNode } from 'react';

export interface FormGroupProps {
  className?: string;
  id: string;
  label?: string | ReactNode;
  description?: string | ReactNode;
  input: ReactNode;
  error?: string | ReactNode;
  inputClassName?: string;
  showRequiredAsterisk?: boolean;
  descriptionPosition?: 'above' | 'below';
}

export function FormGroup({
  id,
  input,
  label,
  description,
  descriptionPosition = 'above',
  error,
  className = 'mb-5',
  inputClassName = 'mt-1 relative font-normal',
  showRequiredAsterisk = false,
}: FormGroupProps) {
  return (
    <div className={className}>
      {label && typeof label === 'string' && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
          {showRequiredAsterisk && <span className="text-red-600">*</span>}
        </label>
      )}
      {label && typeof label !== 'string' && label}

      {description && descriptionPosition === 'above' && (
        <div className="text-gray-400 text-sm">{description}</div>
      )}

      <div className={inputClassName}>
        {input}
        {error && (
          <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none"></div>
        )}
      </div>
      {error && (
        <div className="flex items-center mt-2">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500 shrink-0 mr-2"
            aria-hidden="true"
          />
          <span className="text-sm text-red-600" id={`${id}-error`}>
            {error}
          </span>
        </div>
      )}

      {description && descriptionPosition === 'below' && (
        <div className="text-gray-400 text-sm mt-2">{description}</div>
      )}
    </div>
  );
}
