import { type WorkflowTypes } from '@pandler/shared/data-types';
import {
  ConditionalSplitForm,
  DelayForForm,
  DelayUntilForm,
  InvoiceUpdatedForm,
  SendEmailForm,
  SendSmsForm,
} from '../settings-forms';

export interface NodeSettingsFormProps {
  data: WorkflowTypes;
}

function getForm(data: WorkflowTypes) {
  switch (data.type) {
    case 'condition':
      return <ConditionalSplitForm data={data} />;
    case 'delay-for':
      return <DelayForForm data={data} />;
    case 'delay-until':
      return <DelayUntilForm data={data} />;
    case 'send-email':
      return <SendEmailForm data={data} />;
    case 'send-sms':
      return <SendSmsForm data={data} />;
    case 'invoice-updated':
      return <InvoiceUpdatedForm data={data} />;
  }

  return null;
}

export function NodeSettingsForm({ data }: NodeSettingsFormProps) {
  return getForm(data);
}
