import { useState, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { IconInput } from '@pandler/shared/react-ui';
import { useDebouncedCallback } from 'use-debounce';
import { twMerge } from 'tailwind-merge';

interface GenericSearchFilterProps {
  onChange?: (value: string) => void;
  onChangeDebounceDuration?: number;
  label?: string;
  className?: string;
}

export function GenericSearchFilter({
  onChange,
  onChangeDebounceDuration = 500,
  label = 'Search invoices...',
  className = '',
}: GenericSearchFilterProps) {
  const [searchValue, setSearchValue] = useState('');

  const debouncedOnChange = useDebouncedCallback((value: string) => {
    if (onChange) {
      onChange(value);
    }
  }, onChangeDebounceDuration);

  useEffect(() => {
    debouncedOnChange(searchValue);
  }, [searchValue, debouncedOnChange]);

  return (
    <IconInput
      className={twMerge('w-80', className)}
      icon={MagnifyingGlassIcon}
      placeholder={label}
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
    />
  );
}
