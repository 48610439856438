import {
  queryClient,
  useOrganization,
  useOrganizationSync,
  usePerformSync,
} from '@pandler/cloud/shared';
import {
  Button,
  LoadingContainer,
  SummaryTable,
} from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

interface AccountSummaryProps {
  className?: string;
}

export function AccountSummary({ className = '' }: AccountSummaryProps) {
  const { organization } = useOrganization();
  const { isLoading, mutate } = usePerformSync();
  const { isSyncing, isRefetching } = useOrganizationSync({
    organizationId: organization?.id,
  });

  if (!organization) {
    return <LoadingContainer />;
  }

  const syncing = isLoading || isSyncing || isRefetching;

  return (
    <div className={classNames({ [className]: className })}>
      <SummaryTable
        className="-mt-5 -mb-5"
        items={[
          { label: 'Organization', value: organization.legalName },
          { label: 'Subscription', value: organization.plan.name },
          {
            label: 'Last sync',
            value: (
              <div className="flex">
                <div className="grow">
                  {organization.sync?.lastExecution?.time ?? 'n/a'}
                </div>
                <Button
                  className={classNames(
                    'text-sm px-3 py-1.5 gap-x-1 transition-all duration-300 h-8',
                    {
                      'w-28': !syncing,
                      'w-10': syncing,
                    }
                  )}
                  disabled={syncing}
                  onClick={() => {
                    mutate(
                      { organizationId: organization.id },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries({
                            queryKey: ['organization', 'sync', organization.id],
                          });
                        },
                      }
                    );
                  }}
                >
                  <>
                    <ArrowPathIcon
                      className={classNames('w-4 h-4', {
                        'animate-spin': syncing,
                      })}
                    />
                    {!syncing && <>Sync data</>}
                  </>
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
