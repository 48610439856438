import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ACTIVITY_INVOICE_CATEGORIES = [
  'message',
  'payment',
  'status',
  'other',
] as const;

const BaseSystemActivity = z.object({
  id: z.string().uuid().optional().default(uuidv4),
  organizationId: z.string().uuid(),
  createdAt: z
    .number()
    .optional()
    .default(() => new Date().getTime()),
  entityId: z.string().uuid(),
  content: z.string(),
});

export const SystemActivity = z.discriminatedUnion('entityType', [
  z
    .object({
      entityType: z.literal('invoice'),
      category: z.enum(ACTIVITY_INVOICE_CATEGORIES),
    })
    .merge(BaseSystemActivity),
]);

export type SystemActivity = z.infer<typeof SystemActivity>;

export const SimpleSystemActivity = z
  .object({
    entityType: z.string(),
    category: z.string(),
  })
  .merge(BaseSystemActivity);

export type SimpleSystemActivity = z.infer<typeof SystemActivity>;
