import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { TemplatePageEditor } from '@pandler/cloud/templates';

export function TemplatesPage() {
  return (
    <>
      <Helmet>
        <title>Templates</title>
      </Helmet>

      <TemplatePageEditor />
    </>
  );
}

export default withAuthenticationRequired(TemplatesPage);
