import { Invoice } from '@pandler/shared/data-types';
import {
  EntityTable,
  LoadingContainer,
  SlideOutContext,
} from '@pandler/shared/react-ui';
import type {
  PaginationState} from '@tanstack/react-table';
import {
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSearchInvoice from '../../hooks/useSearchInvoice';
import { columns } from './helpers';
import { InvoiceSummary } from '../invoice-summary';

export interface InvoicesTableProps {
  status?: Invoice['status'] | 'Overdue';
  hidePagination?: boolean;
  narrow?: boolean;
  slideOut?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: Record<string, any>; // @todo need to type advanced condition
}

export function InvoicesTable({
  hidePagination = false,
  narrow = false,
  filter,
  slideOut,
}: InvoicesTableProps) {
  const { closePane, showPane } = useContext(SlideOutContext);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data, isLoading, isPreviousData } = useSearchInvoice({
    page: pageIndex + 1,
    perPage: pageSize,
    query: { sortBy: 'issuedDate.desc' },
    filter,
  });
  const navigate = useNavigate();

  const defaultData = useMemo(() => [], []);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const instance = useReactTable({
    data: data && Array.isArray(data.data) ? data.data : defaultData,
    columns,
    pageCount: data
      ? Math.ceil(data.pagination.records / data.pagination.perPage)
      : 1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const handleSlideOut = useCallback(
    (invoice: Invoice) => {
      showPane(<InvoiceSummary invoice={invoice} onViewMore={closePane} />, {
        onClose: closePane,
        title: `Invoice #${invoice.number}`,
      });
    },
    [closePane, showPane]
  );

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <EntityTable
      narrow={narrow}
      hidePagination={hidePagination}
      isPreviousData={isPreviousData}
      instance={instance}
      onRowClick={({ row, cell }) => {
        if (cell.column.id !== 'currency') {
          if (slideOut) {
            handleSlideOut(row.original);
          } else {
            navigate(`/invoices/view/${row.original?.id}`);
          }
        }
      }}
      model={Invoice}
    />
  );
}
