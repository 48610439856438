import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader } from '@pandler/shared/react-ui';

export function HomePage() {
  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>

      <>
        <PageHeader
          title="Notifications"
          breadcrumbs={
            <Breadcrumbs
              items={[
                {
                  name: 'Notifications',
                  href: '/notifications',
                  current: true,
                },
              ]}
            />
          }
        />

        <Content>
          <p>Notifications</p>
        </Content>
      </>
    </>
  );
}

export default withAuthenticationRequired(HomePage);
