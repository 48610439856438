import { Tabs } from '@pandler/shared/react-ui';
import { PlatformTemplatesSelectionFilterable } from '../platform-templates-selection-filterable';

export function EmailTemplateTabs() {
  return (
    <Tabs
      tabs={[
        {
          id: 'automated',
          title: 'Basic',
          content: (
            <PlatformTemplatesSelectionFilterable
              type="email"
              category="automated"
            />
          ),
        },
        {
          id: 'conversational',
          title: 'Conversational',
          content: (
            <PlatformTemplatesSelectionFilterable
              type="email"
              category="conversational"
            />
          ),
        },
      ]}
    />
  );
}
