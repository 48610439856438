import { useMessageQueue } from '@pandler/cloud/shared';
import type { DashboardGetOverviewResponse } from '@pandler/shared/api-typings';
import { Chart } from '@pandler/shared/charts-ui';
import { ChartContainer, LoadingIcon } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';
import { MessagesAlert } from '../messages-alert';

interface InvoiceSummaryOverviewProps {
  data: DashboardGetOverviewResponse;
}

export function InvoiceOrMessageSlot({ data }: InvoiceSummaryOverviewProps) {
  const {
    data: messageData,
    isLoading,
    error,
  } = useMessageQueue({
    filter: 'status=pending&attributes=id,status',
    queryOptions: { suspense: true },
  });

  if (isLoading) {
    return (
      <ChartContainer
        className="flex items-center justify-center h-[115px] w-full"
        childContainerClassName="h-full items-center justify-center flex"
      >
        <LoadingIcon className="w-5 h-5 text-primary-600" strokeWidth={2.5} />
      </ChartContainer>
    );
  }

  const hasMessages =
    !error && Array.isArray(messageData) && messageData.length > 0;
  if (hasMessages) {
    return <MessagesAlert />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
      <Link to="/invoices/unpaid">
        <Chart
          title="Unpaid invoices"
          className="py-2 mt-0.5 hover:bg-gray-50 transition-all"
          {...data.charts.totalUnpaidInvoices}
        />
      </Link>
      <Link to="/invoices/overdue">
        <Chart
          title="Overdue invoices"
          className="py-2 mt-0.5 hover:bg-gray-50/50 transition-all duration-300"
          {...data.charts.totalOverdueInvoices}
        />
      </Link>
    </div>
  );
}
