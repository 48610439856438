import { atom } from 'recoil';
import type { Organization, User } from '@pandler/shared/data-types';

export const organizationState = atom<Organization | null | undefined>({
  key: 'Organization',
  default: undefined,
});

export const userState = atom<User | undefined>({
  key: 'User',
  default: undefined,
});
