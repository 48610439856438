import { useNotificationsMeta } from '@pandler/cloud/shared';

export function NotificationsStatus() {
  const { data, isLoading, error } = useNotificationsMeta({ status: 'UNREAD' });

  if (isLoading || error || !data?.exists) {
    return null;
  }

  return (
    <button
      className="w-2 h-2 bg-primary-500 rounded-full animate-pulse right-1.5 top-1 absolute"
      title="Unread notifications"
    />
  );
}
