import type { ReactNode } from 'react';
import useSyncNotification from '../../hook/use-sync-notification';
import { WsState } from '../ws-state';

interface ReadyContainerProps {
  children: ReactNode | ReactNode[];
}

export function ReadyContainer({ children }: ReadyContainerProps) {
  useSyncNotification();

  return (
    <>
      <WsState />
      {children}
    </>
  );
}
