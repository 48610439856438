import type { EmailLogItem } from '@pandler/shared/data-types';
import type { InboxProps } from '@pandler/shared/inbox-ui';

export type Message = InboxProps['messages'][number];

export const logItemToMessage = ({
  id,
  recipient,
  subject,
  sentAt,
  email,
}: EmailLogItem): Message => ({
  id,
  name: recipient,
  message: subject,
  date: new Date(sentAt),
  subject,
  hasAttachment: email.attachments?.length > 0,
});
