import { useEffect, useState } from 'react';

interface MessageContentProps {
  onValidChange: (isValid: boolean) => void;
}

const summary = [
  { label: 'Messages', value: 'Email, SMS' },
  { label: 'Reminders', value: '1 day, 7 days, 14 days' },
  { label: 'Time of day', value: '10:00am' },
];

export function Review({ onValidChange }: MessageContentProps) {
  const [isValid] = useState(true);

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  return (
    <form className="w-full pb-20 px-6">
      <h3 className="text-xl mb-1 text-gray-700 font-semibold">Review</h3>
      <p className="text-sm text-gray-400 mb-4">
        Confirm the below details are correct.
      </p>

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4">
        <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody>
                {summary.map(({ label, value }, index) => (
                  <tr
                    key={`review-${label}`}
                    className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-4 py-2.5 whitespace-nowrap text-sm font-medium text-gray-700 w-3/10">
                      {label}
                    </td>
                    <td className="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 w-7/10">
                      {value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <p className="text-gray-400 text-sm">
        Your workflow can be updated at a later date.
      </p>
    </form>
  );
}
