import { useDeleteTemplate } from '@pandler/cloud/shared';
import type { Template } from '@pandler/shared/data-types';
import {
  ButtonDropdown,
  success,
  useDeleteConfirmation,
} from '@pandler/shared/react-ui';
import { useCallback } from 'react';

interface DeleteButtonProps {
  template: Template;
}

export function DeleteButton({ template }: DeleteButtonProps) {
  const { confirm } = useDeleteConfirmation();
  const { mutate: deleteTemplate } = useDeleteTemplate();
  const { id, name } = template;

  const handleOnDelete = useCallback(() => {
    confirm({
      against: 'confirm',
      confirmButtonLabel: 'Confirm',
      body: (
        <>
          Are you sure you want to delete the template{' '}
          <span className="font-semibold">{name}</span>? This cannot be undone.
        </>
      ),
      onDelete: async () => {
        await deleteTemplate(id);
        success({
          message: `${name} has been successfully disabled`,
          title: 'Integrations',
        });
      },
      type: 'error',
    });
  }, [confirm, name, deleteTemplate, id]);

  return (
    <ButtonDropdown
      itemsClassName="w-32"
      items={[
        {
          label: 'Edit',
          url: `/templates/edit/${id}`,
        },
        {
          label: 'Preview',
        },
        {
          label: (
            <button className="text-red-600 text-left" onClick={handleOnDelete}>
              Delete
            </button>
          ),
        },
      ]}
    />
  );
}
