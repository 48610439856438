import { useMessageLog } from '@pandler/cloud/shared';
import { LoadingContainer } from '@pandler/shared/react-ui';
import { Message, type MessageProps } from '../message';

export interface BasicLogMessageProps {
  id: string;
  messageProps?: Partial<MessageProps>;
}

export function BasicLogMessage({
  id,
  messageProps = {},
}: BasicLogMessageProps) {
  const { message, isLoading, error } = useMessageLog(id);

  const showIsLoading = isLoading || error || !message;

  if (showIsLoading) {
    return <LoadingContainer />;
  }

  return <Message {...messageProps} message={message} hideActionBar />;
}
