import classNames from 'classnames';
import { type ComponentProps } from 'react';

export type TextareaProps = ComponentProps<'textarea'> & { hasError?: boolean };

export function Textarea({ className, hasError, ...props }: TextareaProps) {
  return (
    <textarea
      className={classNames(
        `${className} px-4 py-3.5 text-sm w-full rounded-lg outline-none border placeholder:text-gray-400 hover:border-primary-300 focus:border-primary-300 focus:ring-primary-500 focus:border-primary-500`,
        {
          'border-gray-200 bg-white': !hasError,
          'border-red-300 bg-white text-red-900': hasError,
        }
      )}
      {...props}
    />
  );
}
