import { twMerge } from 'tailwind-merge';

export interface StatBox3Props {
  title: string;
  amount: string;
  className?: string;
}

export function StatBox3({ amount, title, className = '' }: StatBox3Props) {
  return (
    <div
      className={twMerge(
        'bg-white dark:bg-slate-800 shadow rounded-md w-full relative',
        className
      )}
    >
      <div className="flex-auto p-4 text-center">
        <h4 className="my-1 font-semibold text-2xl dark:text-slate-200">
          {amount}
        </h4>
        <h6 className="text-gray-400 mb-0 font-medium">{title}</h6>
      </div>
    </div>
  );
}
