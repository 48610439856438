import { type Template } from '@pandler/shared/data-types';
import { getColumnClassNameFromSize } from '@pandler/shared/react-ui';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnFiltersState,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { columns } from './helpers';

interface TemplatesTableProps {
  data: Template[];
}

export function TemplatesTable({ data }: TemplatesTableProps) {
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const instance = useReactTable({
    data,
    columns,
    pageCount: 1,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
  });

  return (
    <>
      <div className="shadow-sm border-b border-gray-200 text-sm relative">
        <table className="min-w-full divide-y divide-slate-200">
          <thead className="bg-slate-50">
            {instance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={`px-6 py-5 text-left text-black-900 font-semibold capitalize tracking-wider ${getColumnClassNameFromSize(
                      header.getSize()
                    )}`}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {instance.getRowModel().rows.length === 0 && (
              <tr>
                <td colSpan={100} className="px-6 py-5 whitespace-nowrap">
                  No results found
                </td>
              </tr>
            )}

            {instance.getRowModel().rows.map((row, rowIndex) => (
              <tr key={row.id} className="cursor-pointer group">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`px-6 py-5 whitespace-nowrap transition-all duration-200 ${
                      rowIndex % 2 === 0
                        ? 'bg-white group-hover:bg-slate-100/75'
                        : 'bg-slate-50 group-hover:bg-slate-100/75'
                    }`}
                    onClick={() => {
                      if (row.original && cell.column.id === 'name') {
                        navigate(`/templates/edit/${row.original.id}`);
                      }
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="h-2" />
    </>
  );
}
