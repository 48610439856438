import classNames from 'classnames';

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export interface DayPickerProps {
  activeClassName?: string;
  backgroundClassName?: string;
  className?: string;
  days?: Day[];
  inactiveClassName?: string;
  onClick?: (value: Day) => void;
  showSummary?: boolean;
  summaryItemClassName?: string;
  summaryClassName?: string;
  summaryPrefix?: string;
  value?: Day[];
}

const formatDay = (day: Day) => {
  if (day === 'tue') {
    return 'tuesday';
  }
  if (day === 'wed') {
    return 'wednesday';
  }
  if (day === 'thu') {
    return 'thursday';
  }
  if (day === 'sat') {
    return 'saturday';
  }

  return `${day}day`;
};

export function DayPicker({
  activeClassName = 'bg-primary-700 ring-2 ring-primary-700 ring-offset-2',
  backgroundClassName = 'w-10 h-10 rounded-full flex items-center justify-center text-white cursor-pointer transition-colors duration-200',
  className = 'flex flex-row space-x-1.5 text-sm uppercase',
  days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  inactiveClassName = 'bg-primary-600 hover:bg-primary-700 ',
  onClick,
  showSummary = false,
  summaryPrefix = '',
  summaryClassName = 'mt-3 text-sm text-gray-500',
  summaryItemClassName = 'capitalize font-semibold',
  value = [],
}: DayPickerProps) {
  return (
    <>
      <ul className={className}>
        {days.map((day) => (
          <li
            key={`item-${day}`}
            className={classNames(backgroundClassName, {
              [activeClassName]: value.includes(day),
              [inactiveClassName]: !value.includes(day),
            })}
            onClick={() => {
              if (onClick) {
                onClick(day);
              }
            }}
            title={formatDay(day)}
          >
            {day.substring(0, 1)}
          </li>
        ))}
      </ul>
      {showSummary && value.length > 0 && (
        <p className={summaryClassName}>
          {summaryPrefix}
          {value.map((day, index) => (
            <>
              <span key={`summary-${day}`} className={summaryItemClassName}>
                {formatDay(day)}
              </span>
              {index !== value.length - 1 ? ', ' : ''}
            </>
          ))}
        </p>
      )}
    </>
  );
}
