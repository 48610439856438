import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { ActionBar } from '../action-bar';
import { type MessageProps, Message } from '../message';
import { NoMessages } from '../no-messages';
import type { ContentSliderProps } from '@pandler/shared/react-ui';
import { ContentSlider } from '@pandler/shared/react-ui';
import { twMerge } from 'tailwind-merge';

export interface InboxProps {
  emptyState?: ReactNode;
  messages: MessageProps[];
  onSendAll?: (selected: string[]) => void;
  onDeleteAll?: (selected: string[]) => void;
  detailsPane?: ContentSliderProps<MessageProps>['slidePane'];
  className?: string;
  mainClassName?: string;
  detailsClassName?: string;
  messagesContainerClassName?: string;
  messageClassName?: string;
  onMessageClick?: (message: MessageProps) => void;
  hideActionBar?: boolean;
  hideSelect?: boolean;
  dontSlide?: boolean;
  mainPaneBefore?: ReactNode | ReactNode;
}

export function Inbox({
  messages,
  onSendAll,
  onDeleteAll,
  emptyState,
  detailsPane,
  className = '',
  mainClassName = '',
  messageClassName = '',
  messagesContainerClassName = '',
  onMessageClick,
  hideActionBar = false,
  hideSelect = false,
  dontSlide = false,
  mainPaneBefore,
}: InboxProps) {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const selectedArray = useMemo(
    () =>
      Object.entries(selected)
        .filter(([, value]) => !!value)
        .map(([id]) => id),
    [selected]
  );

  const onCheckAll = useCallback(
    (isChecked: boolean) =>
      setSelected(
        isChecked
          ? messages.reduce((acc, { id }) => ({ ...acc, [id]: true }), {})
          : {}
      ),
    [setSelected, messages]
  );

  return (
    <main className={twMerge('w-full px-[var(--margin-x)]', className)}>
      {messages.length === 0 && (emptyState ?? <NoMessages />)}
      {messages.length > 0 && (
        <ContentSlider<MessageProps>
          mainPane={({ setContext, slide }) => (
            <div className={twMerge('min-h-[200px]', mainClassName)}>
              {mainPaneBefore}
              {!hideActionBar && (
                <ActionBar
                  isWithSelectedVisible
                  onToggle={onCheckAll}
                  onDelete={() => {
                    if (onDeleteAll) {
                      onDeleteAll(selectedArray);
                    }
                  }}
                  onSend={() => {
                    if (onSendAll) {
                      onSendAll(selectedArray);
                    }
                  }}
                />
              )}
              <div className={twMerge('card', messagesContainerClassName)}>
                {messages.map((message) => (
                  <Message
                    key={message.id}
                    className={messageClassName}
                    isSelected={selected[message.id]}
                    hideSelect={hideSelect}
                    onSelect={() => {
                      setSelected((current) => ({
                        ...current,
                        [message.id]: !selected[message.id],
                      }));
                    }}
                    onClick={() => {
                      if (onMessageClick) {
                        onMessageClick(message);
                      }

                      if (!dontSlide) {
                        setContext(message);
                        slide();
                      }
                    }}
                    {...message}
                  />
                ))}
              </div>
            </div>
          )}
          slidePane={detailsPane}
        />
      )}
    </main>
  );
}
