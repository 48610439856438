export class NotFoundError extends Error {
  public display: { title: string; description: string; code?: number };

  constructor(message?: string) {
    super(message ?? 'Not found');

    this.name = 'NotFound';
    this.display = {
      code: 404,
      title: 'Not found',
      description: 'We were unable to find the selected resourced.',
    };
  }
}
