import type { PlatformTemplate } from '../../types';
import { templates } from './templates';

export function getPlatformTemplate<T extends string>(
  id: T
): PlatformTemplate<T> | undefined {
  return templates.find((template) => template.id === id) as
    | PlatformTemplate<T>
    | undefined;
}
