import { OrganizationSettingsForm } from '@pandler/cloud/shared';
import type { StageProps } from '../types';

export function Organization({ onSuccess }: StageProps) {
  return (
    <section>
      <h2 className="text-3xl font-bold text-gray-900 truncate mb-6">
        Organisation info
      </h2>

      <OrganizationSettingsForm
        mode="express"
        submitLabel="Continue"
        onSuccess={onSuccess}
        hideSuccessPopup
        initialEmpty
      />
    </section>
  );
}
