import type Result from 'esresult';
import type { Customer, Integration } from '../definitions';

export interface CreateConnectionInputArgs {
  companyId: string;
  connectionId: string;
}

export interface CreateConnectionOutput {
  id: string;
  status: string;
  url: string;
}

export interface DeleteConnectionInputArgs {
  companyId: string;
  connectionId: string;
}

export interface GetCustomersInputArgs {
  companyId: string;
  organizationId: string;
  meta?: Record<string, unknown>;
}

export interface GetInvoicesInputArgs {
  companyId: string;
  organizationId: string;
  meta?: Record<string, unknown>;
}

export abstract class PlatformIntegrator {
  abstract getAllIntegrations(): Result.Async<Integration[], 'HTTP_ERROR'>;

  abstract createConnection(
    options: CreateConnectionInputArgs
  ): Result.Async<CreateConnectionOutput, 'HTTP' | 'Data'>;

  abstract deleteConnection(
    options: DeleteConnectionInputArgs
  ): Result.Async<true, 'HTTP'>;

  abstract getCustomers(
    options: GetCustomersInputArgs
  ): Result.Async<Customer[], 'Error'>;
}
