import classNames from 'classnames';
import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface SectionPanelContainerProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  className?: string;
  gridContainerClassName?: string;
  hideLeftPanel?: boolean;
  children: ReactNode | ReactNode;
  showBorder?: boolean;
}

const isString = (candidate: unknown): candidate is string =>
  typeof candidate === 'string';

export function SectionPanelContainer({
  className = '',
  gridContainerClassName = '',
  title,
  description,
  hideLeftPanel = false,
  showBorder = false,
  children,
}: SectionPanelContainerProps) {
  return (
    <div
      className={twMerge(
        classNames({
          'border rounded-lg py-9 px-8': showBorder,
          [className]: className,
        })
      )}
    >
      <div
        className={twMerge(
          classNames('sm:grid sm:grid-cols-3 sm:gap-20 sm:items-start', {
            [gridContainerClassName]: gridContainerClassName,
          })
        )}
      >
        {!hideLeftPanel && (
          <div className="block text-sm font-medium text-gray-700 sm:mt-px">
            {title &&
              (isString(title) ? (
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {title}
                </h3>
              ) : (
                title
              ))}

            {description &&
              (isString(description) ? (
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {description}
                </p>
              ) : (
                description
              ))}
          </div>
        )}

        <div className="mt-1 sm:mt-0 sm:col-span-2">{children}</div>
      </div>
    </div>
  );
}
