import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '@pandler/cloud/shared';
import { GetAccessTokenDefaults } from '@pandler/cloud/shared';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { Result } from 'esresult';

export default function useCreatePaymentSettings() {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();

  return useMutation({
    mutationFn: async (): Result.Async<
      { url: string },
      'CONFLICT' | 'UNKNOWN'
    > => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:payments',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/payments/settings/${organization?.id}`,
        {
          method: 'PUT',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if ($response.status === 409) {
        return Result.error('CONFLICT');
      } else if ($response.status === 201) {
        const json = await $response.json();
        return Result(json.data);
      }

      return Result.error('UNKNOWN');
    },
  });
}
