import type { DelayFor, DelayUntil } from './timings';
import type {
  FetchInvoicesAction,
  SendEmailAction,
  SendSmsAction,
} from './actions';
import type { Condition } from './condition';
import type { CronTrigger, InvoiceUpdatedTrigger } from './triggers';

export * from './timings';
export * from './actions';
export * from './condition';
export * from './triggers';

// @todo move to index files
export type WorkflowTypes =
  // timings
  | DelayFor
  | DelayUntil
  // actions
  | FetchInvoicesAction
  | SendEmailAction
  | SendSmsAction
  // condition
  | Condition
  // triggers
  | CronTrigger
  | InvoiceUpdatedTrigger;
