import type { Chart as TChart } from '@pandler/shared/data-types';
import { InlineError, StatBox3 } from '@pandler/shared/react-ui';
import { StatBox } from '../stat-box';
import { LineChart } from '../line-chart';
import { MiniLineChart } from '../mini-line-chart';
import { DonutChart } from '../donut-chart';
import { formatAmount } from '../../utils/format-amount';

export type ChartProps = TChart & {
  title: string;
  className?: string;
};

export function Chart({ title, className, ...chart }: ChartProps) {
  if (chart.type === 'statBox') {
    return <StatBox className={className} title={title} chart={chart} />;
  }

  if (chart.type === 'lineChart') {
    return <LineChart chart={chart} />;
  }

  if (chart.type === 'miniLineChart') {
    return <MiniLineChart chart={chart} />;
  }

  if (chart.type === 'donutChart') {
    return <DonutChart chart={chart} />;
  }

  if (chart.type === 'miniStatBox') {
    return (
      <StatBox3
        className={className}
        amount={formatAmount(chart.amount)}
        title={chart.label}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <InlineError>Invalid chart type: {(chart as any).type}</InlineError>;
}
