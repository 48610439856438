import * as React from 'react';

export function EmptyStateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283 284"
      {...props}
    >
      <path
        d="M84 73.99H36a12.514 12.514 0 0 1-12.5-12.5v-48A12.514 12.514 0 0 1 36 .99h48a12.514 12.514 0 0 1 12.5 12.5v48A12.514 12.514 0 0 1 84 73.99ZM253.002 73.99h-48a12.516 12.516 0 0 1-12.5-12.5v-48a12.517 12.517 0 0 1 12.5-12.5h48a12.516 12.516 0 0 1 12.5 12.5v48a12.517 12.517 0 0 1-12.5 12.5ZM168 73.99h-48a12.516 12.516 0 0 1-12.5-12.5v-48A12.517 12.517 0 0 1 120 .99h48a12.516 12.516 0 0 1 12.5 12.5v48a12.517 12.517 0 0 1-12.5 12.5ZM36 106.991a11.51 11.51 0 0 0-11.5 11.5v48a11.514 11.514 0 0 0 11.5 11.5h48a11.515 11.515 0 0 0 11.5-11.5v-48a11.513 11.513 0 0 0-11.5-11.5H36ZM253.002 178.991h-48a12.515 12.515 0 0 1-12.5-12.5v-48a12.517 12.517 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM168 178.991h-48a12.515 12.515 0 0 1-12.5-12.5v-48a12.517 12.517 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM84 283.991H36a12.513 12.513 0 0 1-12.5-12.5v-48a12.514 12.514 0 0 1 12.5-12.5h48a12.512 12.512 0 0 1 12.5 12.5v48a12.512 12.512 0 0 1-12.5 12.5ZM253.002 283.991h-48a12.515 12.515 0 0 1-12.5-12.5v-48a12.517 12.517 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5ZM168 283.991h-48a12.515 12.515 0 0 1-12.5-12.5v-48a12.517 12.517 0 0 1 12.5-12.5h48a12.514 12.514 0 0 1 12.5 12.5v48a12.514 12.514 0 0 1-12.5 12.5Z"
        fill="#F2F2F2"
      />
      <path
        d="M264.866 257.491H18.134A18.156 18.156 0 0 1 0 239.357v-97.732a18.156 18.156 0 0 1 18.134-18.134h246.732A18.152 18.152 0 0 1 283 141.625v97.732a18.152 18.152 0 0 1-18.134 18.134Z"
        fill="#fff"
      />
      <path
        d="M264.866 257.491H18.134A18.156 18.156 0 0 1 0 239.357v-97.732a18.156 18.156 0 0 1 18.134-18.134h246.732A18.152 18.152 0 0 1 283 141.625v97.732a18.152 18.152 0 0 1-18.134 18.134Zm-199.047-132A63.816 63.816 0 0 0 2 189.31v50.047a16.13 16.13 0 0 0 9.96 14.906 16.148 16.148 0 0 0 6.174 1.228h246.732a16.145 16.145 0 0 0 11.409-4.726A16.13 16.13 0 0 0 281 239.357v-97.732c0-4.279-1.7-8.383-4.725-11.409a16.138 16.138 0 0 0-11.409-4.725H65.819Z"
        fill="#E6E6E6"
      />
      <path
        d="m124.729 170.796-89.89-1.049a.983.983 0 0 1-.904-1.363.976.976 0 0 1 .91-.604l89.891 1.049a.981.981 0 0 1 .98.987.986.986 0 0 1-.987.98ZM170.729 186.796l-135.89-1.049a.983.983 0 0 1-.904-1.363.976.976 0 0 1 .91-.604l135.891 1.049a.981.981 0 0 1 .98.987.986.986 0 0 1-.987.98Z"
        fill="#CCC"
      />
      <path
        d="M225.641 199.241c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21Z"
        fill="#4F46E5"
      />
      <path
        d="m233.048 183.336-7.407-17.556-7.406 17.556 6.406-2.721v10.626h2v-10.626l6.407 2.721Z"
        fill="#fff"
      />
      <path
        d="M246.641 218.741h-210a2.5 2.5 0 1 0 0 5h210a2.5 2.5 0 0 0 0-5Z"
        fill="#F2F2F2"
      />
      <path d="M156.641 218.741v5h-120a2.5 2.5 0 0 1 0-5h120Z" fill="#4F46E5" />
    </svg>
  );
}
