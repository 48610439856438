import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { HomePage, CreateEmailPage, EditTemplatePage } from './pages';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/create/email" element={<CreateEmailPage />} />
      <Route path="/edit/:id" element={<EditTemplatePage />} />
    </ReactRouterRoutes>
  );
}
