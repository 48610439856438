import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import isFQDN from 'validator/lib/isFQDN';

export const VerifiedDomain = z.object({
  id: z.string().uuid().default(uuidv4),
  organizationId: z.string().uuid(),
  type: z.enum(['email']),
  domain: z.string().refine(isFQDN, { message: 'Must be a valid domain name' }),
  status: z.enum(['VERIFIED', 'NOT_VERIFIED']),
  timesChecked: z.number().default(0),
  lastChecked: z.number().default(() => new Date().getTime()),
  verificationId: z.number(),
});

export type VerifiedDomain = z.infer<typeof VerifiedDomain>;
