import { ActivityFeed, useActivityFeed } from '@pandler/cloud/shared';
import {
  Button,
  ChartContainer,
  InlineError,
  LoadingContainer,
  LoadingIcon,
} from '@pandler/shared/react-ui';

export function RecentActivity() {
  const {
    feed,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useActivityFeed();

  if (error) {
    return <InlineError>{error.message}</InlineError>;
  }

  if (isLoading || !feed) {
    return <LoadingContainer iconClassName="w-5 h-5" />;
  }

  return (
    <ChartContainer title="Recent Activity">
      <div className="h-[410px] overflow-y-scroll">
        <ActivityFeed items={feed.pages.flatMap(({ data }) => data)} />

        {hasNextPage && (
          <Button
            className="mt-4"
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? (
              <LoadingIcon className="w-3.5 h-3.5 text-white" strokeWidth={3} />
            ) : (
              'Load more'
            )}
          </Button>
        )}
      </div>
    </ChartContainer>
  );
}
