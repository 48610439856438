import * as React from 'react';

export function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M152.4 79.9999C148 79.4999 144.1 82.6999 143.6 87.0999C142 101.7 135.6 114.9 125.2 125.2C113.2 137.3 97.1 144 80 144C62.9 144 46.8 137.3 34.7 125.3C9.7 100.3 9.7 59.6999 34.7 34.7999C54.2 15.3999 83.8 10.4999 108.4 22.6999C112.4 24.6999 117.2 22.9999 119.1 19.0999C121.1 15.0999 119.4 10.2999 115.5 8.39992C84.7 -6.90008 47.7 -0.800074 23.4 23.4999C-7.8 54.6999 -7.8 105.4 23.4 136.6C38.5 151.7 58.6 160 80 160C101.4 160 121.5 151.7 136.6 136.6C149.4 123.8 157.5 106.9 159.5 88.8999C160 84.4999 156.8 80.4999 152.4 79.9999Z"
        fill="currentColor"
      />
      <path
        d="M57.7 70.2999C54.6 67.1999 49.5 67.1999 46.4 70.2999C43.3 73.3999 43.3 78.4999 46.4 81.5999L74.4 109.6C76 111.2 78 111.9 80.1 111.9C82.2 111.9 84.2 111.1 85.8 109.6L149.8 45.5999C152.9 42.4999 152.9 37.3999 149.8 34.2999C146.7 31.1999 141.6 31.1999 138.5 34.2999L80 92.6998L57.7 70.2999Z"
        fill="currentColor"
      />
    </svg>
  );
}
