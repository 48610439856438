import { type ReactNode } from 'react';

export interface ContactCardProps {
  image?: ReactNode | string;
  name: string;
  title?: string;
  jobRole?: string;
  roleClassName?: string;
  buttons: ReactNode[];
}

export function ContactCard({
  image,
  name,
  title,
  jobRole,
  roleClassName = 'text-green-800 bg-green-100',
  buttons,
}: ContactCardProps) {
  return (
    <div className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="flex-1 flex flex-col p-8">
        {typeof image === 'string' && (
          <img
            className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
            src={image}
            alt=""
          />
        )}
        {typeof image !== 'string' && image}

        <h3 className="mt-6 text-gray-900 text-sm font-medium">{name}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          {title && (
            <>
              <dt className="sr-only">Title</dt>
              <dd className="text-gray-500 text-sm">{title}</dd>
            </>
          )}
          {jobRole && (
            <>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <span
                  className={`${roleClassName} px-2 py-1 text-xs font-medium rounded-full`}
                >
                  {jobRole}
                </span>
              </dd>
            </>
          )}
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          {buttons.map((button, index) => (
            <div
              key={`button-${index}`}
              className={`w-0 flex-1 flex ${index > 0 ? '-ml-px' : ''}`}
            >
              {button}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
