interface CurrencyFormatOptions {
  locales?: string | string[];
  currency: string;
  value: number | bigint;
  formatterOptions?: Partial<Intl.NumberFormatOptions>;
}

export function formatCurrency({
  locales = 'en-AU',
  currency,
  value,
  formatterOptions = {},
}: CurrencyFormatOptions) {
  return new Intl.NumberFormat(locales, {
    ...formatterOptions,
    style: 'currency',
    currency,
  }).format(value);
}

export const createCurrencyFormatter =
  (
    options: Pick<
      CurrencyFormatOptions,
      'locales' | 'currency' | 'formatterOptions'
    > = {
      locales: 'en-AU',
      currency: 'AUD',
      formatterOptions: {},
    }
  ) =>
  (value: number | bigint) =>
    formatCurrency({ ...options, value });
