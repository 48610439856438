import { type Table } from '@tanstack/react-table';
import { Select } from '../../select';
import { PageButton } from './page-button';

interface TablePaginationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: Table<any>;
  amount?: number;
  pageSizeOptions?: number[];
}

export function TablePagination({
  instance,
  amount = 3,
  pageSizeOptions = [10, 20, 30, 40, 50],
}: TablePaginationProps) {
  const currentPage = instance.getState().pagination.pageIndex + 1;
  const totalPages = instance.getPageCount();

  const start = currentPage - amount < 1 ? 1 : currentPage - amount;
  const end =
    currentPage + amount > totalPages ? totalPages : currentPage + amount;
  const pages = new Array(end - start + 1)
    .fill(0)
    .map((page, index) => start + index);

  return (
    <nav className="px-4 flex items-center justify-between sm:px-0 text-sm">
      <div className="-mt-px w-0 flex-1 flex pt-4">
        <span className="flex items-center gap-1">
          <div>Showing page</div>
          <strong>
            {instance.getState().pagination.pageIndex + 1} of{' '}
            {instance.getPageCount()}
          </strong>
        </span>
      </div>
      <div className="hidden md:-mt-px md:flex pr-10">
        {start > 1 && (
          <PageButton
            label="≪"
            active={false}
            onClick={() => instance.setPageIndex(0)}
          />
        )}

        {pages.map((page) => (
          <PageButton
            key={page}
            label={page}
            active={page === currentPage}
            onClick={() => instance.setPageIndex(page - 1)}
          />
        ))}

        {totalPages > end && (
          <PageButton
            label="≫"
            active={false}
            onClick={() => instance.setPageIndex(totalPages - 1)}
          />
        )}
      </div>
      <div className="-mt-px w-0 grow flex justify-end items-center pt-4">
        <span className="flex items-center gap-1 mr-2">
          Go to page:
          <input
            type="number"
            value={instance.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              if (page <= totalPages) {
                instance.setPageIndex(page);
              }
            }}
            className="ml-2 bg-white relative border border-gray-300 rounded-md shadow-sm p-2 cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm w-16"
          />
        </span>

        <div className="w-32 relative flex items-center ml-2">
          Show:
          <Select
            value={instance.getState().pagination.pageSize}
            onChange={(pageSize) => {
              instance.setPageSize(Number(pageSize));
            }}
            items={pageSizeOptions.map((pageSize) => ({
              value: pageSize,
              label: `${pageSize} items`,
            }))}
            className="ml-2"
            showAsValue
          />
        </div>
      </div>
    </nav>
  );
}
