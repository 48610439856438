import { useAuth0 } from '@auth0/auth0-react';
import type { EmailQueueItem } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';

const useEmailQueueItem = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId } = useOrganization();

  const query = useQuery<EmailQueueItem, Error>(
    ['messages', 'queue', organizationId, id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'send:email',
      });

      try {
        const { data } = await (
          await fetch(`${API_ENDPOINT}/email/queue/${organizationId}/${id}`, {
            headers: { authorization: `Bearer ${token}` },
          })
        ).json();

        return data;
      } catch {
        throw Error('Unable to retrieve message');
      }
    },
    { staleTime: Infinity, retry: false }
  );

  return { ...query, message: query.data };
};

export default useEmailQueueItem;
