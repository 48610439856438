import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, PageHeader, Tabs } from '@pandler/shared/react-ui';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { InvoiceList } from '../../components/invoice-list';

export function HomePage() {
  const { status = 'all' } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>

      <PageHeader
        title="Invoices"
        breadcrumbs={
          <Breadcrumbs
            items={[{ name: 'Invoices', href: '/invoices', current: true }]}
          />
        }
      />

      <Content>
        <Tabs
          key={status}
          initialActive={status}
          onClick={(id) =>
            navigate(`/invoices${id === 'all' ? '' : `/status/${id}`}`)
          }
          tabs={[
            {
              id: 'all',
              title: 'All',
              content: <InvoiceList />,
            },
            {
              id: 'overdue',
              title: 'Overdue',
              content: <InvoiceList status="Overdue" />,
            },

            {
              id: 'unpaid',
              title: 'Awaiting payment',
              content: <InvoiceList status="Submitted" />,
            },
            {
              id: 'paid',
              title: 'Paid',
              content: <InvoiceList status="Paid" />,
            },
          ]}
        />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(HomePage);
