import { GenericSearchFilter } from '@pandler/cloud/shared';
import { type Template } from '@pandler/shared/data-types';
import { useState } from 'react';
import { TemplatesBlocks } from '../templates-blocks';
import { CreateTemplateButton } from '../create-template-button';
import { PlusIcon } from '@heroicons/react/24/outline';

export interface TemplatesSidebarProps {
  activeTemplate?: Template;
  onClick?: (template: Template) => void;
  onDelete?: () => void;
  onChange?: (data: Template[] | undefined) => void;
}

export function TemplatesSidebar({
  onClick,
  onChange,
  activeTemplate,
}: TemplatesSidebarProps) {
  const [filter, setFilter] = useState<string>('');

  return (
    <div className="px-[var(--margin-x)] w-96 min-w-[384px] max-w-[384px] shrink-0 p-0 text-sm border-r border-slate-200/80 flex flex-col justify-start">
      <div className="p-4 flex flex-row space-x-4">
        <GenericSearchFilter
          className="w-64 py-2.5"
          label="Search templates..."
          onChange={setFilter}
        />
        <CreateTemplateButton
          className="px-3.5"
          label={
            <div className="flex space-x-1 items-center justify-center">
              <PlusIcon className="w-5 h-5" />
              <span>New</span>
            </div>
          }
        />
      </div>

      <TemplatesBlocks
        filter={filter}
        onChange={onChange}
        onClick={onClick}
        activeTemplate={activeTemplate}
      />
    </div>
  );
}
