export function computeDaysDifference(date: Date) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const differenceInMs = date.getTime() - now.getTime();
  const differenceInDays = Math.max(
    0,
    Math.ceil(differenceInMs / (1000 * 60 * 60 * 24))
  );

  return differenceInDays === 1
    ? `${differenceInDays} day`
    : `${differenceInDays} days`;
}
