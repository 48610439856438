import { OrganizationSettingsForm, WipeAccount } from '@pandler/cloud/shared';
import { SectionPanelContainer } from '@pandler/shared/react-ui';
import { AccountSummary } from '../../components/account-summary';

export function GeneralTab() {
  return (
    <div className="pb-10">
      <SectionPanelContainer
        title="Account Summary"
        description="A quick overview of your account."
        showBorder
      >
        <AccountSummary />
      </SectionPanelContainer>

      <OrganizationSettingsForm />

      <WipeAccount title="Danger Zone" className="mt-10" />
    </div>
  );
}
