import { type DonutChart as TDonutChart } from '@pandler/shared/data-types';
import { type ApexOptions } from 'apexcharts';
import { merge } from 'lodash';
import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { donutColorsArray } from '../../utils/colors';

export interface DonutChartProps {
  options?: Partial<ApexOptions>;
  height?: number;
  chart: TDonutChart;
}

const defaultOptions: ApexOptions = {
  plotOptions: {
    pie: {
      donut: {
        size: '85%',
      },
    },
  },
  dataLabels: {
    enabled: !1,
  },
  stroke: {
    show: !0,
    width: 2,
    colors: ['transparent'],
  },
  legend: {
    show: !1,
    position: 'bottom',
    horizontalAlign: 'center',
    floating: !1,
    fontSize: '14px',
    offsetX: 0,
    offsetY: 5,
  },
  colors: donutColorsArray,
  tooltip: {
    y: {
      formatter: (number) => `${number}%`,
    },
  },
};

export function DonutChart({
  options = {},
  chart: { data, categories },
  height = 200,
}: DonutChartProps) {
  const mergedOptions = useMemo(
    () => merge(defaultOptions, { labels: categories }, options),
    [options, categories]
  );

  return (
    <ApexChart
      type="donut"
      options={mergedOptions}
      series={data}
      height={height}
    />
  );
}
