import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { TemplatePageEditor } from '../../components';
import { useParams } from 'react-router-dom';

export function EditTemplatePage() {
  const { id = '' } = useParams();

  return (
    <>
      <Helmet>
        <title>Edit template</title>
      </Helmet>

      <TemplatePageEditor key={id} initialActiveId={id} />
    </>
  );
}

export default withAuthenticationRequired(EditTemplatePage);
