import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { isString } from '@pandler/shared/type-guards';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export interface UseSendEmailQueueItemOptions {
  id: string;
}

export default function useSendEmailQueueItem() {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async ({ id }: UseSendEmailQueueItemOptions): Promise<void> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'send:email',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/email/queue/${organizationId}/${id}`,
        {
          method: 'PUT',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if ($response.status !== 204) {
        const { message } = ((await $response.json()) ?? {}) as Record<
          string,
          unknown
        >;

        if (isString(message)) {
          throw new Error(message);
        } else {
          throw Error('An error occurred when sending the message');
        }
      }

      queryClient.invalidateQueries({
        queryKey: ['messages', 'queue', organizationId],
      });
    },
  });
}
