import { Button, ModalContext } from '@pandler/shared/react-ui';
import { SendReminder } from '../send-reminder/send-reminder';
import { type Invoice } from '@pandler/shared/data-types';
import { type ReactNode, useContext } from 'react';

interface SendReminderButtonProps {
  className?: string;
  label?: string | ReactNode | ReactNode[];
  invoice: Invoice;
}

export function SendReminderButton({
  className = '',
  label = 'Send reminder',
  invoice,
}: SendReminderButtonProps) {
  const { closeModal, showModal } = useContext(ModalContext);

  const handleOnClick = () => {
    showModal(<SendReminder invoice={invoice} onSuccess={closeModal} />, {
      className: 'overflow-visible sm:p-0 transition-all',
      onClose: closeModal,
    });
  };

  return (
    <Button className={className} onClick={handleOnClick}>
      {label}
    </Button>
  );
}
