import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export const USER_ROLES = ['admin', 'member'] as const;

export const User = z.object({
  id: z.string().uuid().default(uuidv4),
  email: z.string().email(),
  organizations: z.string().uuid().array().default([]),
  firstName: z.string(),
  lastName: z.string(),
  avatar: z.string(),
});

export type User = z.infer<typeof User>;

type UserAttribute = keyof typeof User.shape;
const ADMIN_ATTRIBUTES: UserAttribute[] = ['email', 'organizations'];

export const UpdatableUser = (role: typeof USER_ROLES[number]) => {
  const omitted =
    role !== 'admin'
      ? ADMIN_ATTRIBUTES.reduce(
          (obj, attribute) => ({ ...obj, [attribute]: true }),
          {}
        )
      : {};

  return User.omit({ ...omitted, id: true });
};
