import { Button } from '@pandler/shared/react-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTransition, animated } from '@react-spring/web';
import {
  Introduction,
  Connect,
  Payments,
  Branding,
  Workflows,
  Done,
} from './steps';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useDismissNotification } from '@pandler/cloud/shared';

type Stage =
  | 'introduction'
  | 'accounting-platform'
  | 'payments'
  | 'branding'
  | 'workflows'
  | 'done';

const steps: Stage[] = [
  'introduction',
  'accounting-platform',
  'payments',
  'branding',
  'workflows',
  'done',
];

interface ProductTourProps {
  dismissable?: boolean;
  onComplete?: () => void;
}

export function ProductTour({ onComplete, dismissable }: ProductTourProps) {
  const { mutate: dismissNotification } = useDismissNotification();
  const [currentStep, setCurrentStep] = useState(0);
  const stage = useMemo(
    () => steps[currentStep] ?? steps[steps.length - 1],
    [currentStep]
  );

  useEffect(() => {
    if (dismissable) {
      dismissNotification({ id: 'welcomeTour', invalidate: false });
    }
  }, [dismissNotification, dismissable]);

  const transitions = useTransition(stage, {
    from: { x: 100 },
    enter: { x: 0 },
    leave: { x: -100 },
    config: { tension: 210, friction: 20 },
  });

  const isLast = currentStep >= steps.length - 1;
  const isFirst = currentStep === 0;
  const isComplete = currentStep >= steps.length;

  useEffect(() => {
    if (isComplete && onComplete) {
      onComplete();
    }
  }, [isComplete, onComplete]);

  return (
    <section className="lg:px-12 pt-20 pb-12 w-screen lg:max-w-lg bg-gradient-to-b from-violet-100/80 via-transparent to-transparent rounded-lg relative">
      <button
        onClick={() => onComplete?.()}
        tabIndex={-1}
        className="w-8 h-8 rounded-full flex items-center justify-center absolute right-6 top-6 bg-gray-50 transition-all duration-300 text-gray-400 hover:text-gray-700"
      >
        <XMarkIcon className="w-6 h-6" />
      </button>

      <section
        className={classNames(
          'overflow-hidden relative transition-all duration-300 h-[350px]',
          {
            'h-[375px]':
              stage === 'accounting-platform' || stage === 'workflows',
          }
        )}
      >
        {transitions(({ x }, item) => (
          <animated.div
            style={{ transform: x.to((val) => `translate3d(${val}%,0,0)`) }}
            className="absolute w-full h-full"
          >
            {item === 'introduction' && <Introduction />}
            {item === 'accounting-platform' && <Connect />}
            {item === 'payments' && <Payments />}
            {item === 'branding' && <Branding />}
            {item === 'workflows' && <Workflows />}
            {item === 'done' && <Done />}
          </animated.div>
        ))}
      </section>

      <section className="flex flex-row space-x-2 mt-5 justify-center items-center">
        {steps.map((stepName, step) => (
          <button
            tabIndex={-1}
            key={`dot-${stepName}`}
            className={classNames(
              'rounded-full cursor-pointer transition-all duration-500',
              {
                'bg-primary-600 w-2.5 h-2.5': currentStep === step,
                'bg-gray-300/80 hover:bg-gray-400/80 w-2 h-2':
                  currentStep !== step,
              }
            )}
            onClick={() => setCurrentStep(step)}
          />
        ))}
      </section>

      <section className="flex flex-row space-x-2 mt-12 justify-center">
        {!isFirst && !isLast && (
          <Button
            tabIndex={-1}
            onClick={() => setCurrentStep(currentStep - 1)}
            className="text-base justify-center py-3 w-1/2 bg-white border-gray-200/60 hover:bg-gray-50 transition-all ease-in-out duration-500 text-gray-800"
          >
            Back
          </Button>
        )}
        {
          <Button
            tabIndex={-1}
            onClick={() => setCurrentStep(currentStep + 1)}
            className="text-base justify-center py-3 w-1/2"
          >
            {isLast ? 'Close' : 'Continue'}
          </Button>
        }
      </section>
    </section>
  );
}
