import {
  InlineError,
  LoadingContainer,
  SectionPanelContainer,
} from '@pandler/shared/react-ui';
import { useDomainVerification } from '@pandler/cloud/shared';
import { NoEmailDomains } from '../../components/no-email-domains';
import { DomainsList } from '../../components/domains-list';

export function CustomDomainsTab() {
  const { isLoading, error, data } = useDomainVerification();

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (error || !data) {
    return <InlineError>{error.message}</InlineError>;
  }

  return (
    <SectionPanelContainer
      title="Emails"
      description="Send email from your own custom domain (e.g. payments@my-business.com)"
      showBorder
    >
      {data.length === 0 ? <NoEmailDomains /> : <DomainsList items={data} />}
    </SectionPanelContainer>
  );
}
