import { HappyNewsIcon } from '@pandler/shared/react-ui';
import { type ReactNode } from 'react';

export interface EmptyStateProps {
  title?: string;
  description?: string | ReactNode | ReactNode[];
}

export function EmptyState({
  title = "You're all caught up!",
  description = "We'll let you know when something happens.",
}: EmptyStateProps) {
  return (
    <div className="flex flex-col space-y-6 justify-center text-center p-16">
      <HappyNewsIcon className="h-40 mb-4" />

      <div>
        <h2 className="text-xl font-bold mb-1.5">{title}</h2>
        <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
          {description}
        </p>
      </div>
    </div>
  );
}
