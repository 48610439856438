import {
  IntegrationsList,
  useAgreeTermsConditions,
} from '@pandler/cloud/shared';
import { LoadingIcon } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import type { StageProps } from '../types';

export function Integrations({ onSuccess }: StageProps) {
  const { mutate, isLoading } = useAgreeTermsConditions({
    onSuccess,
  });

  const isDisabled = isLoading;

  return (
    <section className="flex flex-col items-center">
      <section className=" w-full sm:max-w-xl mb-6">
        <h2 className="text-3xl font-bold text-gray-900 truncate mb-2">
          Connect
        </h2>

        <p className="text-sm">
          Select an accounting platform to connect with.
        </p>
      </section>

      <section>
        <IntegrationsList className="xl:grid-cols-2" />
      </section>

      <section className="pt-6 sm:max-w-xl w-full">
        <button
          className={classNames(
            'rounded-full justify-center py-3 transition-all duration-500 text-xs text-gray-500 hover:underline hover:text-gray-600',
            {
              'w-full': !isLoading,
              'w-32': isLoading,
            }
          )}
          type="submit"
          disabled={isDisabled}
          onClick={() => mutate()}
        >
          {isLoading ? (
            <LoadingIcon className="w-4 h-4 mx-auto" />
          ) : (
            "Skip for now, I'll come back to this"
          )}
        </button>
      </section>
    </section>
  );
}
