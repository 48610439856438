import * as React from 'react';

export function EmptyWorkflowStateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 323 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M132.155 4.13c67.931 0 123 55.07 123 123 0 67.931-55.069 123-123 123-67.93 0-161.945-67.351-123-123 38.945-55.648 55.07-123 123-123Z"
        fill="#F2F2F2"
      />
      <path
        d="M296.782 9.982c-1.103 0-2 .897-2 2v64c0 1.103.897 2 2 2s2-.897 2-2v-64c0-1.103-.897-2-2-2Z"
        fill="#3F3D56"
      />
      <path
        d="M78.376 118.839H9.156v-2h69.22c6.013 0 10.905-4.892 10.905-10.906V59.13h2v46.803c0 7.116-5.789 12.906-12.905 12.906ZM210.455 220.294H53.019v2h157.436v-2Z"
        fill="#4F46E5"
      />
      <path
        d="M310.343 105.466h-84.092c-4.538 0-8.23-3.692-8.23-8.23V14.17c0-4.538 3.692-8.23 8.23-8.23h84.092c4.538 0 8.23 3.692 8.23 8.23v83.066c0 4.538-3.692 8.23-8.23 8.23Z"
        fill="#E6E6E6"
      />
      <path
        d="M241.912 8.392c-11.84 0-21.439 9.599-21.439 21.439v65.643a7.54 7.54 0 0 0 7.541 7.54h51.42c20.261 0 36.687-16.426 36.687-36.688V15.932a7.54 7.54 0 0 0-7.54-7.54h-66.669Z"
        fill="#fff"
      />
      <path
        d="M290.819 32.532h-45.22a1.567 1.567 0 0 1-1.565-1.565c0-.863.702-1.564 1.565-1.564h45.22c.863 0 1.565.701 1.565 1.564 0 .863-.702 1.565-1.565 1.565ZM290.819 65.895h-45.22a1.567 1.567 0 0 1-1.565-1.564c0-.863.702-1.565 1.565-1.565h45.22c.863 0 1.565.702 1.565 1.565 0 .862-.702 1.564-1.565 1.564ZM309.182 49.224h-81.946a1.567 1.567 0 0 1-1.565-1.564c0-.863.702-1.565 1.565-1.565h81.946c.863 0 1.565.702 1.565 1.565 0 .862-.702 1.564-1.565 1.564ZM310.019 79.46h-29.603a1.567 1.567 0 0 1-1.565-1.564c0-.863.702-1.564 1.565-1.564h29.603c.863 0 1.565.701 1.565 1.564 0 .863-.702 1.565-1.565 1.565ZM133.711 270.97H66.937a6.542 6.542 0 0 1-6.535-6.535v-65.96a6.543 6.543 0 0 1 6.535-6.535h66.774a6.542 6.542 0 0 1 6.535 6.535v65.96a6.542 6.542 0 0 1-6.535 6.535Z"
        fill="#E6E6E6"
      />
      <path
        d="M79.372 193.887c-9.401 0-17.023 7.622-17.023 17.024v52.125a5.988 5.988 0 0 0 5.987 5.987h40.831c16.089 0 29.132-13.043 29.132-29.132v-40.016a5.987 5.987 0 0 0-5.987-5.988h-52.94Z"
        fill="#fff"
      />
      <path
        d="M118.208 213.055H82.3a1.244 1.244 0 0 1-1.243-1.242c0-.685.558-1.242 1.243-1.242h35.908a1.243 1.243 0 0 1 0 2.484ZM118.208 239.548H82.3a1.244 1.244 0 0 1-1.243-1.242c0-.685.558-1.242 1.243-1.242h35.908a1.243 1.243 0 0 1 0 2.484ZM132.79 226.311H67.718a1.244 1.244 0 0 1 0-2.485h65.072a1.243 1.243 0 0 1 0 2.485ZM133.454 250.32h-23.507a1.244 1.244 0 0 1-1.243-1.242c0-.685.558-1.242 1.243-1.242h23.507a1.243 1.243 0 0 1 0 2.484ZM87.04 58.626H37.657a4.839 4.839 0 0 1-4.833-4.833V5.013A4.838 4.838 0 0 1 37.657.178H87.04a4.838 4.838 0 0 1 4.833 4.833v48.78a4.838 4.838 0 0 1-4.833 4.834Z"
        fill="#E6E6E6"
      />
      <path
        d="M46.854 1.62c-6.953 0-12.59 5.636-12.59 12.589v38.549a4.428 4.428 0 0 0 4.428 4.428h30.196c11.9 0 21.545-9.646 21.545-21.545V6.047a4.428 4.428 0 0 0-4.428-4.428H46.854Z"
        fill="#fff"
      />
      <path
        d="M75.575 15.795H49.019a.92.92 0 0 1 0-1.837h26.556a.92.92 0 0 1 0 1.837ZM75.575 35.388H49.019a.92.92 0 0 1 0-1.838h26.556a.92.92 0 0 1 0 1.838ZM86.359 25.598H38.235a.92.92 0 0 1 0-1.838H86.36a.92.92 0 0 1 0 1.838ZM86.85 43.354H69.465a.92.92 0 0 1 0-1.837H86.85a.92.92 0 0 1 0 1.837Z"
        fill="#E6E6E6"
      />
      <path
        d="M231.155 120.13c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10s-10 4.478-10 10c0 5.523 4.477 10 10 10ZM90.155 69.13c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10ZM138.155 206.13c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10s-10 4.478-10 10c0 5.523 4.477 10 10 10Z"
        fill="#4F46E5"
      />
      <path
        d="M221.359 261.13c4.455 0 8.066-3.535 8.066-7.896s-3.611-7.896-8.066-7.896-8.066 3.535-8.066 7.896 3.611 7.896 8.066 7.896ZM286.021 250.533h-43.416a2.704 2.704 0 0 1-2.701-2.701 2.704 2.704 0 0 1 2.701-2.702h43.416a2.705 2.705 0 0 1 2.702 2.702 2.705 2.705 0 0 1-2.702 2.701ZM319.904 259.676h-77.299a2.704 2.704 0 0 1-2.701-2.701 2.704 2.704 0 0 1 2.701-2.702h77.299a2.705 2.705 0 0 1 2.701 2.702 2.704 2.704 0 0 1-2.701 2.701Z"
        fill="#F2F2F2"
      />
      <path d="M124.405 5.97h-2v95h2v-95Z" fill="#E6E6E6" />
      <path
        d="M123.405 106.97a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM214.405 175.97a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM52.405 93.97a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
        fill="#E6E6E6"
      />
      <path
        d="m50.188 87.98-32.813.993.06 2 32.813-.993-.06-2ZM263.216 192.94H213.46v-22.97h2v20.97h47.756v2ZM49.942 159.486a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
        fill="#E6E6E6"
      />
      <path
        d="M98.161 176.455H48.405v-22.969h2v20.969h47.756v2ZM250.405 130.655H11.423v2h238.982v-2Z"
        fill="#E6E6E6"
      />
    </svg>
  );
}
