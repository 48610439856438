import {
  type Customer,
  type Invoice,
  type Organization,
  addressAsString,
} from '@pandler/shared/data-types';

import type { TemplateData } from '../types';
import {
  computeDaysDifference,
  createCurrencyFormatter,
  formatDate,
} from '@pandler/shared/utils';

interface ToTemplateData {
  invoice: Invoice;
  customer: Customer;
  organization: Organization;
}

function toPaymentData(): TemplateData['payment'] {
  return { enabled: false };
}
function toInvoiceData({
  invoice: { amountDue, number, dueDate, currency },
}: ToTemplateData): TemplateData['invoice'] {
  const formatCurrency = createCurrencyFormatter({ currency });

  return {
    number,
    amount: formatCurrency(amountDue),
    dueInDays: computeDaysDifference(new Date(dueDate)),
    dueDate: formatDate(dueDate),
  };
}

function toCompanyData({
  organization: { name, logo, supportEmail, address },
}: ToTemplateData): TemplateData['company'] {
  return {
    name,
    logo: logo || undefined,
    supportEmail,
    address: address ? addressAsString(address) : undefined,
  };
}

function toCustomerData({
  customer: { name, contactName },
}: ToTemplateData): TemplateData['customer'] {
  return {
    name: contactName || name,
  };
}

export function toTemplateData(data: ToTemplateData): TemplateData {
  return {
    payment: toPaymentData(),
    invoice: toInvoiceData(data),
    company: toCompanyData(data),
    customer: toCustomerData(data),
  };
}
