import { BiBold, BiItalic, BiUnderline } from 'react-icons/bi';
import { MarkButton } from '../mark-button';

interface ToolbarProps {
  className?: string;
}

export function Toolbar({ className = '' }: ToolbarProps) {
  return (
    <div className={`${className} flex space-x-3 justify-end`}>
      <MarkButton format="bold">
        <span className="sr-only">Bold text</span>
        <BiBold className="w-5 h-5" />
      </MarkButton>

      <MarkButton format="italic">
        <span className="sr-only">Italics text</span>
        <BiItalic className="w-5 h-5" />
      </MarkButton>

      <MarkButton format="underline">
        <span className="sr-only">Underline text</span>
        <BiUnderline className="w-5 h-5" />
      </MarkButton>
    </div>
  );
}
