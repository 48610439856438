import { type ReactNode, useEffect, useState } from 'react';

interface Step {
  text: string | ReactNode;
  offset: number;
}

export interface TimedTextProps {
  className?: string;
  steps: Step[];
  completeOffset?: number;
  onComplete?: () => void;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
}

export function TimedText({
  className = '',
  steps,
  onComplete,
  completeOffset = 2000,
  prefix,
  suffix,
}: TimedTextProps) {
  const [currentText, setCurrentText] = useState<string | ReactNode>('');

  useEffect(() => {
    const timeouts = steps.map((step) =>
      setTimeout(() => {
        setCurrentText(step.text);
      }, step.offset)
    );

    const successTimeout = setTimeout(() => {
      if (onComplete) {
        onComplete();
      }
    }, (steps[steps.length - 1]?.offset ?? 0) + completeOffset);

    return () => {
      timeouts.map((timeout) => clearTimeout(timeout));
      clearTimeout(successTimeout);
    };
  }, [completeOffset, onComplete, steps]);

  return (
    <p className={className}>
      {prefix}
      {currentText}
      {suffix}
    </p>
  );
}
