import { queryClient } from '@pandler/cloud/shared';

interface CallbackOptions {
  code: string;
  data: Record<string, unknown>;
}

// @todo type all of these better

const callbacks: Record<string, (options: CallbackOptions) => void> = {
  NOTIFICATION_NEW: () => {
    queryClient.invalidateQueries({ queryKey: ['notifications'] });
  },
};

const noop = () => {
  // noop
};

export function getCallback({ code }: CallbackOptions) {
  return callbacks[code] ?? noop;
}
