import { useAuth0 } from '@auth0/auth0-react';
import type { Notification } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';
import { useCallback } from 'react';

interface UseNotificationsOptions {
  status?: Notification['status'];
}

const useNotifications = ({ status }: UseNotificationsOptions = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationId } = useOrganization();

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['notifications', status],
      }),
    [status]
  );

  const query = useQuery<Notification[], Error>(
    ['notifications', status ?? ''],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:notifications',
      });

      try {
        const { data } = await (
          await fetch(
            `${API_ENDPOINT}/notifications?organization=${
              organizationId ?? ''
            }&status=${status ?? ''}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
        ).json();

        return data;
      } catch {
        throw Error('Unable to retrieve notifications');
      }
    },
    { staleTime: Infinity, retry: false }
  );

  return { ...query, notifications: query.data, invalidate };
};

export default useNotifications;
