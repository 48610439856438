import { useAuth0 } from '@auth0/auth0-react';
import type { Customer } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';

const useCustomer = (customerId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery<Customer, Error>(
    ['customer', customerId],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:customer',
      });

      const customer = await (
        await fetch(`${API_ENDPOINT}/customer/${customerId}`, {
          headers: { authorization: `Bearer ${token}` },
        })
      ).json();

      if (!customer.data) {
        throw new Error('Unable to retrieve customer details');
      }

      return customer.data as Customer;
    },
    { keepPreviousData: true }
  );

  return { ...query, customer: query.data };
};

export default useCustomer;
