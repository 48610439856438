import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteConfirmation } from '../delete-confirmation';
import { Toggle } from '../toggle/toggle';

export interface ToggleNodeProps {
  name: string;
  description?: string;
  on?: boolean;
  url?: string;
  onView?: () => void;
  viewLabel?: string | ReactNode;
  icon?: ReactNode;
  confirmText?: (on: boolean) => string | ReactNode;
  onDelete?: () => Promise<void>;
  bottomLeft?: string | ReactNode;
}

const defaultConfirmText = (on: boolean) =>
  `Are you sure you want to ${on ? 'disable' : 'enable'} the selected item?`;

export function ToggleNode({
  name,
  description,
  on = true,
  url,
  viewLabel = 'View',
  confirmText = defaultConfirmText,
  icon,
  onDelete,
  onView,
  bottomLeft,
}: ToggleNodeProps) {
  const { confirm } = useDeleteConfirmation();
  const navigate = useNavigate();

  return (
    <div className="rounded-lg shadow bg-white hover:shadow-lg transition-all duration-300 overflow-hidden">
      <header className="h-40">
        <div className="flex px-5 py-6">
          <div className="grow flex items-center px-3 py-0 gap-x-2.5">
            {icon}
            <span className="font-medium">{name}</span>
          </div>

          <Toggle
            enabled={on}
            onClick={() => {
              confirm({
                against: on ? 'confirm' : undefined,
                confirmButtonLabel: on ? 'Confirm' : 'Yes',
                placeholder: `Type 'confirm' to ${on ? 'disable' : 'enable'}`,
                body: confirmText(on),
                onDelete,
                type: on ? 'error' : 'info',
              });
            }}
          />
        </div>
        {description && (
          <div className=" px-8 text-sm text-gray-700">{description}</div>
        )}
      </header>
      {(url || onView) && (
        <footer className="border-t border-gray-200/60 py-4 px-4 flex justify-end items-center">
          {bottomLeft && <div className="flex-grow">{bottomLeft}</div>}

          <button
            className="text-sm text-primary-600 font-semibold hover:text-primary-700 transition-colors"
            onClick={() => {
              if (url) {
                navigate(url);
              } else if (onView) {
                onView();
              }
            }}
          >
            {viewLabel}
          </button>
        </footer>
      )}
    </div>
  );
}
