import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { CreateWizard } from '../../components/create-wizard';

export function NewPage() {
  return (
    <>
      <Helmet>
        <title>Create Workflow</title>
      </Helmet>

      <Content>
        <CreateWizard />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(NewPage);
