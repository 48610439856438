import { type Template } from '@pandler/shared/data-types';
import { TableSize } from '@pandler/shared/react-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { DeleteButton } from './delete-button';
import { Summary } from './summary';

const columnHelper = createColumnHelper<Template>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'Template',
    size: TableSize['w-11/12'],
    cell: ({ row }) => <Summary template={row.original} />,
  }),
  columnHelper.accessor('updatedAt', {
    header: '',
    size: TableSize['w-1/12'],
    cell: ({ row }) => <DeleteButton template={row.original} />,
  }),
];
