import useWebSocket from 'react-use-websocket';
import { WS_API_ENDPOINT } from '@pandler/shared/utils';
import { getCallback } from '../net/state-callbacks';

interface UseWebSocketStateOptions {
  token: string;
  organizationId: string;
  userId: string;
}

export const useWebSocketState = ({
  token,
  organizationId,
  userId,
}: UseWebSocketStateOptions) => {
  const { sendMessage } = useWebSocket(
    `${WS_API_ENDPOINT}/state?authorization=Bearer%20${token}`,
    {
      onOpen: () => {
        sendMessage(
          JSON.stringify({
            action: 'subscribe',
            data: {
              organizationId,
              userId,
            },
          })
        );
        console.log('[wss] connected and subscribed');
      },
      onClose: () => {
        console.log('[wss] connection closed');
      },
      onMessage: (e) => {
        console.log('[wss] received message', e);
        const { isTrusted, data } = e;
        if (!isTrusted) {
          console.log('[wss] untrusted');
          return;
        }

        try {
          const message = JSON.parse(data) as {
            code: string;
            data: Record<string, unknown>;
          };
          console.log('[wss] message', message);
          const callback = getCallback(message);
          callback(message);
        } catch {
          console.log('[wss] invalid message');
        }
      },
    }
  );
};
