import type { Message } from '../../types';
import { HtmlPreview } from '@pandler/shared/html-preview';
import type { AttachmentProps } from '../attachment';
import { Attachment } from '../attachment';
import { formatDateTime } from '@pandler/shared/utils';

const cssContent = `
.root {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #6B7280; /* slate-500 */
}`;

export type DetailedMessageProps = Omit<Message, 'attachments'> & {
  attachments: AttachmentProps[];
};

export function DetailedMessage({
  message,
  name,
  subject,
  attachments = [],
  status,
  date,
}: DetailedMessageProps) {
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-1">
        {subject && <h2 className="font-semibold text-gray-800">{subject}</h2>}
        <h4 className="text-sm text-gray-500">
          <span className="font-medium text-gray-600">To: </span>
          {name}
        </h4>
        {status === 'sent' && (
          <h4 className="text-sm text-gray-500">
            <span className="font-medium text-gray-600">Sent at: </span>
            {formatDateTime(date)}
          </h4>
        )}
      </div>

      <HtmlPreview htmlContent={message} cssContent={cssContent} />

      {attachments.length > 0 && (
        <div>
          {attachments.map((attachment) => (
            <Attachment key={attachment.fileName} {...attachment} />
          ))}
        </div>
      )}
    </div>
  );
}
