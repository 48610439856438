interface MarkAsReadProps {
  onClick?: () => Promise<void>;
}

export function MarkAsRead({ onClick }: MarkAsReadProps) {
  return (
    <button
      className="w-2.5 h-2.5 rounded-full bg-primary-600"
      title="Mark as read"
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
    />
  );
}
