import { type EmailTemplates, emailTemplates } from './email';
import { type SmsTemplates, smsTemplates } from './sms';

export * from './email';
export * from './sms';
export * from './utils';

export type Templates = [...SmsTemplates, ...EmailTemplates];
export type Template = Templates[number];
export const templates: Templates = [...smsTemplates, ...emailTemplates];
