export * from './blank';
export * from './overdue-invoice';
export * from './upcoming-invoice';
export * from './final-notice';

import { BlankEmailTemplate } from './blank';
import { FinalNoticeEmailTemplate } from './final-notice';
import {
  UpcomingInvoiceEmailTemplate,
  UpcomingInvoiceConversationalEmailTemplate,
} from './upcoming-invoice';
import { OverdueInvoiceEmailTemplate } from './overdue-invoice';
import { InvoiceGeneratedEmail } from './invoice-generated';
import { FinalReminderEmail } from './final-reminder';
import { DueTodayEmail } from './due-today';
import { LatePaymentEscalationEmail } from './late-payment-escalation';

export const emailTemplates = [
  // automated
  BlankEmailTemplate,
  InvoiceGeneratedEmail,
  UpcomingInvoiceEmailTemplate,
  FinalReminderEmail,
  DueTodayEmail,
  OverdueInvoiceEmailTemplate,
  LatePaymentEscalationEmail,
  FinalNoticeEmailTemplate,
  // conversational
  UpcomingInvoiceConversationalEmailTemplate,
] as const;

export type EmailTemplates = typeof emailTemplates;

export type EmailTemplateId = EmailTemplates[number]['id'];
