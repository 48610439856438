type AllowedValues = {
  gt: number | Date;
  gte: number | Date;
  lt: number | Date;
  lte: number | Date;
  eq: number | Date | string | boolean;
  neq: number | Date | string | boolean;
  contains: string;
  ncontains: string;
};

type FilterOperation = keyof AllowedValues;

export interface JsonSearchPagination {
  page: number;
  records: number;
  perPage: number;
}

interface SearchResult<T> {
  records: T[];
  pagination: JsonSearchPagination;
}

type PartialFilterOperation = Partial<Record<FilterOperation, unknown>>;

type AttributeFilter = Partial<{
  [key in FilterOperation]: AllowedValues[key];
}>;

type Filter = Record<string, AttributeFilter>;

type Sort<T> = Partial<Record<keyof T, 'asc' | 'desc'>>;

interface SearchOptions<T> {
  filter?: Filter;
  sort?: Sort<T>;
  perPage?: number;
  page?: number;
}

const filters: Record<
  FilterOperation,
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  (source: any, value: any) => boolean
> = {
  gt: (source: number, value: number) => Boolean(source && source > value),
  gte: (source: number, value: number) => Boolean(source && source >= value),
  lt: (source: number, value: number) => Boolean(source && source < value),
  lte: (source: number, value: number) => Boolean(source && source <= value),
  eq: (source: unknown, value: unknown) => Boolean(source == value),
  neq: (source: unknown, value: unknown) => Boolean(source != value),
  contains: (source: string, value: string) =>
    Boolean(source && source.includes(value)),
  ncontains: (source: string, value: string) =>
    Boolean(source && !source.includes(value)),
};

function filterItems<T>(data: T[], filter: Filter = {}) {
  let filtered = [...data];

  Object.entries(filter).forEach(
    ([attribute, filter]: [string, PartialFilterOperation]) => {
      Object.entries(filter).forEach(([operator, filterValue]) => {
        filtered = filtered.filter((item) =>
          filters[operator as FilterOperation](
            (item as T)[attribute as keyof T],
            filterValue
          )
        );
      });
    }
  );

  return filtered;
}

function paginateItems<T>(
  data: T[],
  options: { perPage: number; page: number }
) {
  const { perPage, page } = options;
  const start = (page - 1) * perPage;
  const end = start + perPage;

  return data.slice(start, end);
}

function sortItems<T>(data: T[], sort: Sort<T>) {
  if (!data[0]) {
    return [];
  }

  let sorted = [...data];
  const [firstItem] = data;

  Object.entries(sort).forEach(([attribute, order]) => {
    const isString = typeof firstItem[attribute as keyof T] === 'string';

    sorted = sorted.sort((a: T, b: T) => {
      const left = order === 'asc' ? a : b;
      const right = order === 'asc' ? b : a;

      if (isString) {
        return (
          (left[attribute as keyof T] as unknown as string) ?? ''
        ).localeCompare(
          (right[attribute as keyof T] as unknown as string) ?? ''
        );
      }

      return (
        (left[attribute as keyof T] as unknown as number) -
        (right[attribute as keyof T] as unknown as number)
      );
    });
  });

  return sorted;
}

export function search<T = Record<string, unknown>>(
  data: T[],
  options: SearchOptions<T> = {}
): SearchResult<T> {
  const { filter = {}, sort = {}, perPage = 25, page = 1 } = options;

  const filtered = filterItems<T>(data, filter);
  const records = paginateItems(sortItems<T>(filtered, sort), {
    perPage,
    page,
  });

  return {
    records,
    pagination: { page, records: filtered.length, perPage },
  };
}
