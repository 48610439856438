import {
  TINYMCE_API_KEY,
  useDeleteTemplate,
  useSaveTemplate,
} from '@pandler/cloud/shared';
import type { Template } from '@pandler/shared/data-types';
import { HtmlWysiwyg } from '@pandler/shared/html-wysiwyg';

import './template-editor.css';
import {
  Button,
  DeleteButton,
  LoadingIcon,
  error,
  success,
} from '@pandler/shared/react-ui';
import { useCallback, useRef } from 'react';

export interface TemplateEditorProps {
  template: Template;
  onDelete?: () => void;
}
export function TemplateEditor({ template, onDelete }: TemplateEditorProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any | null>(null);
  const { mutateAsync: deleteTemplate } = useDeleteTemplate();
  const { mutateAsync: saveTemplate, isLoading: isSaving } = useSaveTemplate();

  const handleOnSave = () => {
    if (editorRef.current) {
      const body = editorRef.current.getContent();
      saveTemplate({ id: template.id, data: { body } });
    }
  };

  const handleOnDelete = useCallback(async () => {
    try {
      await deleteTemplate(template.id);
      success({ message: 'Your template has been successfully been deleted' });
      if (onDelete) {
        onDelete();
      }
    } catch {
      error({ message: 'There was an error deleting the template' });
    }
  }, [template.id, deleteTemplate, onDelete]);

  return (
    <div className="h-full w-full template-editor flex flex-col">
      <div className="flex space-x-1.5 items-end w-full justify-end bg-white p-4 border-b border-b-slate-200/80">
        <Button disabled={isSaving} onClick={handleOnSave}>
          {isSaving ? <LoadingIcon className="w-5 h-5 text-white" /> : 'Save'}
        </Button>
        <DeleteButton onDelete={handleOnDelete}>Delete</DeleteButton>
      </div>

      <HtmlWysiwyg
        ref={editorRef}
        apiKey={TINYMCE_API_KEY}
        initialValue={template.body}
        height="100%"
      />
    </div>
  );
}
