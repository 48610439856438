import type { PlatformTemplate } from '../../../types';

export const UpcomingInvoiceConversationalEmailTemplate: PlatformTemplate<'upcoming-invoice-conversational-reminder-email'> =
  {
    id: 'upcoming-invoice-conversational-reminder-email',
    type: 'email',
    preview: 'https://i.imgur.com/U7M4686.png',
    name: 'Invoice reminder',
    category: ['conversational'],
    description:
      'Email reminding your customers of an unpaid upcoming invoice.',
    subject: 'Invoice {{invoice.number}} is due in {{invoice.dueInDays}}',
    order: 0,
    content: `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        .signature {
          font-size: 14px;
          line-height: 1.4;
          margin-top: 20px;
          color: #1e293b;
          padding-top: 10px;
          display: flex;
          flex-wrap: wrap;
        }

        .signature-logo {
          width: 140px;
          margin-right: 20px;
        }

        .signature-logo img {
          width: auto;
          max-width: 140px;
        }

        .signature-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .signature-personal {
          margin-bottom: 5px;
        }

        .signature-company {
          font-size: 12px;
          color: #3f3d56;
        }
      </style>
    </head>
    <body>
      <p>Hi {{customer.name}},</p>
      <p>I hope this message finds you well. I wanted to send you a quick reminder that your invoice {{invoice.number}} is due in {{invoice.dueInDays}} days. The total amount for this invoice is {{invoice.amount}}.</p>

      <p>Here are the details of the invoice:</p>
      <p>Due date: {{invoice.dueDate}}<br>
      Invoice number: {{invoice.number}}<br>
      Amount: {{invoice.amount}}</p>

      {{#payment.enabled}}
        <p>To make your payment online, you can simply click on this secure link: <a href="{{payment.url}}">{{payment.url}}</a></p>
      {{/payment.enabled}}

      {{#payment.customInstructions}}
        {{{payment.customInstructions}}}
      {{/payment.customInstructions}}

      {{#hasAttachment}}
        <p>For your convenience, I've attached the invoice as a PDF to this email.</p>
      {{/hasAttachment}}

      <p>If you have any questions or need assistance with this invoice, please don't hesitate to contact our support team at <a href="mailto:{{company.supportEmail}}">{{company.supportEmail}}</a>{{#company.supportPhone}} or give us a call at {{company.supportPhone}}{{/company.supportPhone}}.</p>

      <p>Thank you for your attention to this matter, and we truly appreciate your continued business.</p>

      <p>Best regards,</p>

      <p>--</p>

      <div class="signature">
        <div class="signature-logo">
          <a href="https://pandler.io/">
            <img src="https://uploads-ssl.webflow.com/626490f8e0a8277d749223fb/62958365c28863e6a8830ff1_logo-dark.png" alt="Company Logo">
          </a>
        </div>
        <div class="signature-details">
          <div class="signature-personal">
            {{sender.name}} - {{sender.jobTitle}}<br>
            <a href="mailto:{{sender.email}}">{{sender.email}}</a> | {{sender.phone}}
          </div>
          <div class="signature-company">
            {{company.name}}<br>
            {{company.address}}
          </div>
        </div>
      </div>
    </body>
    </html>`,
    dependencies: ['company', 'invoice', 'payment'] as const,
  };
