import type { Field, OptionGroup } from 'react-querybuilder';
import { defaultOperators } from 'react-querybuilder';
import { FilterableInvoice } from '@pandler/shared/data-types';
import type {
  ZodEnumDef} from 'zod';
import {
  ZodArray,
  ZodDefault,
  ZodEnum,
  ZodNumber,
  ZodString,
  type AnyZodObject,
} from 'zod';
import { camelCaseToWords, toTitleCase } from '@pandler/shared/utils';

export interface DataTypes {
  invoice: true;
}

const dataTypeMap: Record<keyof DataTypes, AnyZodObject> = {
  invoice: FilterableInvoice,
};

/**
 * Certain data types should only permit certain operators
 */
const Filters = {
  Number: [
    '=',
    '!=',
    '<',
    '>',
    '<=',
    '>=',
    'null',
    'notNull',
    'in',
    'notIn',
    'between',
    'notBetween',
  ],
  String: [
    '=',
    '!=',
    'null',
    'contains',
    'beginsWith',
    'endsWith',
    'doesNotContain',
    'doesNotBeginWith',
    'doesNotEndWith',
    'null',
    'notNull',
    'in',
    'notIn',
  ],
};

function filterOperators(operators: string[]) {
  return defaultOperators.filter(({ name }) => operators.includes(name));
}

/**
 * Convert a zod object to a react query field
 */
function zodObjectToField({
  type,
  id,
  input,
  prefix,
}: {
  type: keyof DataTypes;
  id: string;
  input: AnyZodObject;
  prefix?: string;
}): Field | null {
  if (input instanceof ZodArray || input instanceof ZodDefault) {
    return null;
  }

  const name = `${type}.${id}`;
  const field = {
    name,
    label: `${prefix ? `${prefix} ` : ''}${camelCaseToWords(id)}`,
  };

  if (input instanceof ZodNumber) {
    return {
      ...field,
      inputType: 'number',
      operators: filterOperators(Filters.Number),
    };
  }

  if (input instanceof ZodString) {
    return {
      ...field,
      operators: filterOperators(Filters.String),
    };
  }

  if (input instanceof ZodEnum) {
    return {
      ...field,
      valueEditorType: 'select',
      values: (input._def as ZodEnumDef).values.map((value) => ({
        name: value,
        label: value.toString(),
      })),
    };
  }

  return field;
}

interface MapDataTypesOptions {
  prefix?: string;
}

/**
 * Convert a feature set of data types to react query fields
 */
export function mapDataTypes(
  types: Partial<DataTypes>,
  { prefix }: MapDataTypesOptions = {}
): Field[] | OptionGroup<Field>[] {
  return Object.keys(types)
    .map((type) => {
      const obj = dataTypeMap[type as keyof DataTypes];
      const fields = (Object.entries(obj.shape) as [string, AnyZodObject][])
        .map(([id, input]) =>
          zodObjectToField({ type: type as keyof DataTypes, id, input, prefix })
        )
        .filter((f) => !!f) as Field[];

      return { label: toTitleCase(type), fields };
    })
    .reduce(
      (accumulator: OptionGroup[], { label, fields }) => [
        ...accumulator,
        { label, options: fields },
      ],
      []
    );
}
