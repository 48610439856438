export enum TableSize {
  'w-1/3' = 100,
  'w-2/3' = 200,
  'w-1/4' = 75,
  'w-2/4' = 150,
  'w-3/4' = 225,
  'w-1/5' = 60,
  'w-2/5' = 120,
  'w-3/5' = 180,
  'w-1/2' = 150,
  'w-1/6' = 50,
  'w-5/6' = 250,
  'w-1/12' = 25,
  'w-2/12' = 50,
  'w-3/12' = 75,
  'w-4/12' = 100,
  'w-5/12' = 125,
  'w-6/12' = 150,
  'w-7/12' = 175,
  'w-8/12' = 200,
  'w-9/12' = 225,
  'w-10/12' = 250,
  'w-11/12' = 275,
}

export function getColumnClassNameFromSize(size: TableSize, defaultValue = '') {
  const desiredSize = Object.entries(TableSize).find(
    ([, value]) => value === size
  );

  return desiredSize?.[0] ?? defaultValue;
}
