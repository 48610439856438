import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export default function useDeleteDomain() {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId = '' } = useOrganization();

  return useMutation({
    mutationFn: async (id: string): Promise<true> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:domain-verification',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/domain-verification/${id}`,
        {
          method: 'DELETE',
          headers: {
            authorization: `Bearer ${token}`,
            'x-organization-id': organizationId,
          },
        }
      );

      if ($response.status === 204) {
        queryClient.invalidateQueries({
          queryKey: ['domain-verification', organizationId],
        });
        return true;
      }

      throw Error('An unexpected error occurred deleting the domain');
    },
  });
}
