import { Alert } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';

export function CallOuts() {
  return (
    <div className="px-4 sm:px-6 md:px-8 flex mt-6">
      <Alert
        className="grow"
        type="warning"
        title="No accounting platform connected."
        description={
          <>
            You must connect an accounting platform so Pandler can manage
            invoice reminders.{' '}
            <Link
              to="/settings/integrations"
              className="underline text-yellow-800 hover:text-yellow-900"
            >
              Manage connections
            </Link>
            .
          </>
        }
      />
    </div>
  );
}
