import * as React from 'react';

export function Bell2Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M26.49 29.86H5.5a3.37 3.37 0 0 1-2.47-1 3.35 3.35 0 0 1-1-2.47V5.48A3.36 3.36 0 0 1 3 3a3.37 3.37 0 0 1 2.5-1h21A3.38 3.38 0 0 1 29 3a3.36 3.36 0 0 1 1 2.46v20.91a3.35 3.35 0 0 1-1 2.47 3.38 3.38 0 0 1-2.51 1.02Zm-5.38-6.71a.79.79 0 0 0 .85-.66l2.77-13.25a.55.55 0 0 0-.18-.46.62.62 0 0 0-.41-.17q-.08 0-16.53 6.11a.59.59 0 0 0-.41.59.57.57 0 0 0 .43.52l4 1.24 1.61 4.83a.62.62 0 0 0 .63.43.56.56 0 0 0 .4-.17L16.54 20l4.09 3a.9.9 0 0 0 .48.15Zm-7.31-2.44-1.21-4q8.72-5.55 8.78-5.55c.15 0 .23 0 .23.16a.18.18 0 0 1 0 .06s-2.51 2.3-7.52 6.8Z"
      />
    </svg>
  );
}
