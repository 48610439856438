import { useDismissNotification } from '@pandler/cloud/shared';
import { error, LoadingIcon } from '@pandler/shared/react-ui';

interface DismissNotificationProps {
  id: string;
}

export function DismissNotification({ id }: DismissNotificationProps) {
  const { mutate: dismissNotification, isLoading } = useDismissNotification({
    onError: () =>
      error({ message: 'An error occurred dismissing the notification' }),
  });

  return (
    <button
      className="text-red-500 text-sm hover:underline hover:text-red-600 transition-all duration-300 flex items-center pt-3"
      onClick={() => dismissNotification({ id })}
    >
      {isLoading ? <LoadingIcon className="w-4 h-4 mx-auto" /> : 'Dismiss'}
    </button>
  );
}
