import { z } from 'zod';

export const InvoiceLineItem = z.object({
  description: z.string(),
  quantity: z.number(),
  unitAmount: z.number(),
  discountAmount: z.number().default(0),
  discountPercentage: z.number().min(0).max(100),
  subTotal: z.number(),
  taxAmount: z.number(),
  totalAmount: z.number(),
});

export type InvoiceLineItem = z.infer<typeof InvoiceLineItem>;
