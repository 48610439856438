import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export interface UseDeleteMultipleMessageQueueItemsOptions {
  ids: string[];
}

export default function useDeleteMultipleMessageQueueItems() {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async ({
      ids,
    }: UseDeleteMultipleMessageQueueItemsOptions): Promise<void> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'send:email',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/email/queue/${organizationId}`,
        {
          method: 'DELETE',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            messages: ids,
          }),
        }
      );

      if ($response.status !== 204) {
        throw Error('An error occurred when deleting the messages');
      }

      queryClient.invalidateQueries({
        queryKey: ['messages', 'queue', organizationId],
      });
    },
  });
}
