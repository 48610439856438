import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { HomePage, LogsPage, QueuePage, TemplatesPage } from './pages';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/queue" element={<QueuePage />} />
      <Route path="/logs" element={<LogsPage />} />
      <Route path="/templates" element={<TemplatesPage />} />
    </ReactRouterRoutes>
  );
}
