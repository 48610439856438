import Result from 'esresult';

export type ValueTransformerOptions = {
  attribute: string;
  operator: string;
  value: unknown;
};

export type BuildSearchObjectFromQueryOptions = {
  attributeOperatorValidator?: (attribute: string, operator: string) => boolean;
  valueTransformer?: (options: ValueTransformerOptions) => unknown;
};

export type IsValidOptions = Pick<
  BuildSearchObjectFromQueryOptions,
  'attributeOperatorValidator'
>;

export type ParseFilterOptions = Pick<
  BuildSearchObjectFromQueryOptions,
  'valueTransformer'
>;

export type AddFilterOptions = {
  operator: string;
  attribute: string;
  value: unknown;
};

export type Filter = Record<string, Partial<SearchFilter>>;

export type BuildQueryFromFilterOptions = {
  filter: Filter;
};
export type SortOrder = 'asc' | 'desc';

export type Sort = Record<string, SortOrder>;

export type BuildSortOptions = {
  sort?: Sort;
};

export type Pagination = {
  page: number;
  perPage: number;
};

export type SearchObject = {
  filter: Filter;
  sort?: Sort;
  pagination: Pagination;
};

export const FILTERS = [
  'eq',
  'neq',
  'gt',
  'gte',
  'lt',
  'lte',
  'contains',
  'icontains',
  'includes',
];

export type SearchFilter = {
  eq: string | number;
  neq: string | number;
  gt: number | Date;
  gte: number | Date;
  lt: number | Date;
  lte: number | Date;
  contains: string;
  icontains: string;
  includes: string;
};

const FILTER_REGEX = new RegExp(
  `^([a-zA-Z]+)(?:\\[(${FILTERS.join('|')}){1}\\]){0,1}$`
);

const SORT_REGEX = /(^[a-zA-Z]+)\.(asc|desc)$/;

/**
 * Function to use in a reducer to produce a single filter object. Also shallow merge to
 * allows multiple operators to filter against a single attribute.
 *
 * For example, to filter users by age between 10-20, a URL query may look like:
 *  - age[gte]=10&age[lte]=20&country=Australia
 *
 * This will produce the object:
 * ```json
 *  {
 *    "age": {
 *      "gte": "10",
 *      "lte": "20"
 *    },
 *    "country": {
 *      "eq": "Australia"
 *    }
 *  }
 *
 * Usage:
 * ```typescript
 * const filter = Object.entries(Object.fromEntries(new URLSearchParams(params))).reduce(
 *   (accumulator, value) => ({
 *     ...accumulator,
 *     ...parseFilter(accumulator, value),
 *   }),
 *   {}
 * );
 * ```
 */
function parseFilter(
  filter: Record<string, unknown>,
  [key, value]: [string, string],
  options: ParseFilterOptions
) {
  const { valueTransformer = (v: ValueTransformerOptions) => v.value } =
    options;

  const [, attribute, operator = 'eq'] = key.match(FILTER_REGEX) as [
    string,
    string,
    string | undefined
  ];

  const alreadyExists = !!filter[attribute];
  return {
    ...filter,
    [attribute]: {
      ...(alreadyExists ? (filter[attribute] as Record<string, unknown>) : {}),
      [operator]: valueTransformer({ attribute, operator, value }),
    },
  };
}

function isValidFilter(
  key: string,
  options: IsValidOptions
): Result<true, ['INVALID', { message: string; key: string }]> {
  const { attributeOperatorValidator } = options;

  const match = key.match(FILTER_REGEX) as [string, string, string | undefined];
  if (!match) {
    return Result.error([
      'INVALID',
      {
        message: `Invalid filter: "${key}"`,
        key,
      },
    ]);
  }

  if (attributeOperatorValidator) {
    // some implementations of filtering may only allow certain operators to be used against certain
    // attributes. If a custom attribute operator validator was passed through, validate against it
    const [, attribute, operator = 'eq'] = match;
    if (!attributeOperatorValidator(attribute, operator)) {
      return Result.error([
        'INVALID',
        { message: `Illegal operator: "${key}"`, key },
      ]);
    }
  }

  return Result(true);
}

function buildFilter(
  params: URLSearchParams,
  options: BuildSearchObjectFromQueryOptions
): Result<Filter, ['INVALID', { invalid: Result.Any[] }]> {
  const { attributeOperatorValidator, valueTransformer } = options;

  const validator = Array.from(params.keys()).map((key) =>
    isValidFilter(key, { attributeOperatorValidator })
  );

  const invalid = validator.filter((v) => v.error);
  if (invalid.length > 0) {
    return Result.error(['INVALID', { invalid }]);
  }

  const filter = Object.entries(Object.fromEntries(params)).reduce(
    (accumulator, value) => ({
      ...accumulator,
      ...parseFilter(accumulator, value, { valueTransformer }),
    }),
    {}
  ) as Filter;

  return Result(filter);
}

export function isValidSort(
  condition: string
): Result<true, ['INVALID', { message: string; key: string }]> {
  const isValid = condition.match(SORT_REGEX);

  if (!isValid) {
    return Result.error([
      'INVALID',
      {
        message: `Invalid search condition: "${condition}"`,
        key: condition,
      },
    ]);
  }
  return Result(true);
}

// @todo: validate attributes
export function buildSort(conditions: string[]): Result<Sort, 'INVALID'> {
  if (conditions.length === 0) {
    return Result({});
  }

  const validator = conditions.map((condition) => isValidSort(condition));

  const invalid = validator.filter((v) => v.error);
  if (invalid.length > 0) {
    return Result.error('INVALID', { cause: invalid });
  }

  const sort = conditions.reduce((condition, candidate) => {
    const [attribute, sortOrder] = candidate.split('.') as [string, SortOrder];
    return { ...condition, [attribute]: sortOrder };
  }, {});

  return Result(sort);
}

export function buildSearchObjectFromQuery(
  input: string | Record<string, string>,
  options: BuildSearchObjectFromQueryOptions = {}
): Result<SearchObject, ['INVALID', { invalid: Result.Any[] }]> {
  const params = new URLSearchParams(input);

  const $sort = buildSort(params.getAll('sortBy'));
  if ($sort.error) {
    return Result.error(['INVALID', { invalid: [] }], { cause: $sort });
  }
  params.delete('sortBy');

  const perPage = Number(params.get('perPage') ?? 25);
  const page = Number(params.get('page') ?? 1);
  params.delete('perPage');
  params.delete('page');

  const $filter = buildFilter(params, options);
  if ($filter.error) {
    return Result.error(['INVALID', { invalid: [] }], { cause: $filter });
  }

  return Result({
    filter: $filter.value,
    sort: $sort.value,
    pagination: { page, perPage },
  });
}
