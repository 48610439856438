import { type Filter, FilterBar } from '@pandler/cloud/shared';
import { useState } from 'react';
import { Logs } from '../logs/logs';

export function LogsFilterableContainer() {
  const [filter, setFilter] = useState<Filter | undefined>();

  return (
    <>
      <div className="mb-4 p-8 pb-0">
        <FilterBar onChange={setFilter} label="Search messages..." />
      </div>

      <Logs filter={filter} />
    </>
  );
}
