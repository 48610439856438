import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';
import type { VerifiedDomain } from '@pandler/shared/data-types';

interface UseGetDomainOptions {
  id: string;
}

const useGetDomain = ({ id }: UseGetDomainOptions) => {
  const { id: organizationId = '' } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<VerifiedDomain, Error>(
    ['domain-verification', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:domain-verification',
      });

      try {
        const response = await fetch(
          `${API_ENDPOINT}/domain-verification/${id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              'x-organization-id': organizationId,
            },
          }
        );

        return response.json();
      } catch {
        throw Error('Unable to retrieve domain verifications');
      }
    },
    { staleTime: Infinity, retry: false }
  );
};

export default useGetDomain;
