import { twMerge } from 'tailwind-merge';
import type { Notification } from '@pandler/shared/data-types';
import { iconFromEntity } from './utils/icon-from-entity';
import { colorsFromEntity } from './utils/colors';
import { RoundedIcon } from '@pandler/shared/react-ui';

export interface IconProps {
  image?: string;
  entity: Notification['entity'];
  className?: string;
}

export function Icon({ image, entity, className = '' }: IconProps) {
  if (image) {
    return (
      <img
        src={image}
        alt=""
        className={twMerge('h-10 w-10 rounded-full shrink-0', className)}
      />
    );
  }

  return (
    <RoundedIcon
      icon={iconFromEntity(entity)}
      className={twMerge(
        'h-10 w-10 cursor-default shrink-0',
        colorsFromEntity(entity),
        className
      )}
    />
  );
}
