import { z } from 'zod';

// zod doesn't support cyclical data structures
// therefore only permit one level of nested conditions

export const Rule = z.object({
  fact: z.string(),
  operator: z.string(),
  value: z.union([
    z.object({
      fact: z.string(),
    }),
    z.any(),
  ]),
  path: z.string().optional(),
  priority: z.number().optional(),
  params: z.record(z.any()).optional(),
});

export const JsonRule = z.union([
  z.object({
    any: z.array(Rule).min(1),
  }),
  z.object({
    all: z.array(Rule).min(1),
  }),
]);

export const TopLevelJsonRule = z.union([
  z.object({
    any: z.array(z.union([JsonRule, Rule])).min(1),
  }),
  z.object({
    all: z.array(z.union([JsonRule, Rule])).min(1),
  }),
]);
