import { type PlatformApiScope } from '@pandler/shared/utils';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import isSlug from 'validator/lib/isSlug';
import isHexColor from 'validator/lib/isHexColor';
import isLocale from 'validator/lib/isLocale';
import { createUpdatableZodObject } from '../helpers';
import { Address } from './address';
import { Codat } from './codat';
import { Plan } from './plan';
import { dateSchema } from './date';
import { isValidAbnOrAcn } from '@pandler/shared/australian-validators';

export const ORGANIZATION_ROLES = ['admin', 'member'] as const;

export const OrganizationMember = z.object({
  id: z.string().uuid(),
  role: z.enum(ORGANIZATION_ROLES),
});

export const Organization = z.object({
  id: z.string().uuid().default(uuidv4),
  publicId: z
    .string()
    .min(5)
    .max(32)
    .refine(isSlug, {
      message: 'Must only use letters, numbers and hyphens/underscores',
    })
    .optional(),
  name: z.string(),
  legalName: z.string(),
  registrationNumber: z
    .string()
    .refine(isValidAbnOrAcn, { message: 'Must be a valid ABN or ACN' })
    .optional(),
  taxNumber: z.string().optional(),
  address: Address.optional(),
  billingAddress: Address.optional(),
  members: z.array(OrganizationMember).default([]),
  plan: Plan,
  website: z.string().url().optional(),
  logo: z.string().url().optional(),
  codat: Codat.optional(),
  supportEmail: z.string().email(),
  primaryColor: z
    .string()
    .refine(isHexColor, { message: 'Must be a valid hex color' })
    .optional(),
  secondaryColor: z
    .string()
    .refine(isHexColor, { message: 'Must be a valid hex color' })
    .optional(),
  locale: z
    .string()
    .refine(isLocale, { message: 'Must be a valid hex color' })
    .default('en_AU'),
  onboarded: z.boolean().default(false),
  sync: z
    .object({
      status: z.enum(['running', 'idle']),
      lastExecution: z.optional(
        z.object({
          time: dateSchema,
          result: z.enum(['SUCCESS', 'ERROR']),
          meta: z.any(),
        })
      ),
    })
    .optional(),
});

export type Organization = z.infer<typeof Organization>;

type OrganizationAttribute = keyof typeof Organization.shape;

const protectedAttributes = {
  'write:organization': ['members', 'plan', 'codat', 'sync'],
} as Record<PlatformApiScope, OrganizationAttribute[]>;

export const UpdatableOrganization = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: Organization,
    protectedAttributes,
  });

export const PartialOrganization = Organization.partial();

export type PartialOrganization = z.infer<typeof PartialOrganization>;

export const PublicOrganization = Organization.pick({
  name: true,
  logo: true,
  publicId: true,
  address: true,
});

export type PublicOrganization = z.infer<typeof PublicOrganization>;

export const OrganizationPublicAttributes: OrganizationAttribute[] = [
  'name',
  'logo',
  'publicId',
  'address',
];
