import type { Condition } from '@pandler/shared/data-types';
import { Button, FormGroup, QueryBuilder } from '@pandler/shared/react-ui';
import { ActionElement } from 'react-querybuilder';
import type { DataTypes} from '../../../utils/conditions';
import { mapDataTypes } from '../../../utils/conditions';

export interface ConditionalSplitFormProps {
  data: Condition;
}

export function ConditionalSplitForm({ data }: ConditionalSplitFormProps) {
  const dataTypes: Partial<DataTypes> = { invoice: true };
  const fields = mapDataTypes(dataTypes);

  return (
    <div className="overflow-x-auto">
      <FormGroup
        id="rule"
        label="Condition"
        description="Will create a branching yes/no decision in your workflow."
        input={
          <QueryBuilder
            fields={fields}
            controlClassnames={{ queryBuilder: '-ml-4' }}
            controlElements={{
              addGroupAction: (props) =>
                props.level === 0 ? <ActionElement {...props} /> : null,
            }}
          />
        }
        inputClassName="mt-4"
      />

      <Button type="submit">Save</Button>
    </div>
  );
}
