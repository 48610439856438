import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { type DropzoneOptions, useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import { LoadingIcon } from '../loading-icon';

export interface DropZoneProps {
  fileTypes?: string[];
  className?: string;
  containerClassName?: string;
  options?: DropzoneOptions;
  isLoading?: boolean;
}

export function DropZone({
  fileTypes = ['png', 'jpg', 'jpeg', 'gif', 'webp'],
  className = '',
  containerClassName = '',
  options,
  isLoading = false,
}: DropZoneProps) {
  const { getInputProps, getRootProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);
    },
    ...options,
  });

  return (
    <div
      className={twMerge(
        'flex items-center justify-center w-full',
        containerClassName
      )}
    >
      <label
        htmlFor="dropzone-file"
        {...getRootProps()}
        className={twMerge(
          'transition-all duration-100 flex flex-col items-center justify-center w-full h-36 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600',
          className
        )}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {!isLoading && (
            <>
              <ArrowUpOnSquareIcon className="w-10 h-10 mb-3 text-gray-400" />
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {fileTypes.join(', ').toLocaleUpperCase()}
              </p>
            </>
          )}
          {isLoading && (
            <LoadingIcon
              className="w-8 h-8 text-primary-600 mx-auto"
              strokeWidth={2}
            />
          )}
        </div>
        <input {...getInputProps()} />
      </label>
    </div>
  );
}
