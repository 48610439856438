import { useCurrentUser } from '@pandler/cloud/shared';
import { UsersTable } from '../../components/users-table';

export function UsersTab() {
  const { user } = useCurrentUser();

  if (!user) {
    return null;
  }

  const users = [user];

  return (
    <section>
      <p className="mb-6 text-sm text-gray-600">
        Users have varying levels of access to the Pandler dashboard based on
        their Roles, which are set when adding new members and can be edited any
        time.
      </p>

      <UsersTable users={users} />
    </section>
  );
}
