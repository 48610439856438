import { useAuth0 } from '@auth0/auth0-react';
import type { Organization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useCallback, useEffect } from 'react';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { organizationState, userState } from '../../state';
import { GetAccessTokenDefaults, queryClient } from '../../config';

interface UseOrganizationOptions {
  organizationId?: string;
  queryOptions?: UseQueryOptions<Organization, Error, Organization>;
}

export default function useOrganization({
  organizationId,
  queryOptions = {},
}: UseOrganizationOptions = {}) {
  const user = useRecoilValue(userState);
  const id = organizationId ?? user?.organizations?.[0];
  const setOrganization = useSetRecoilState(organizationState);

  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, error, data, isRefetching } = useQuery<
    Organization,
    Error
  >(
    ['organization', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:organization',
      });
      const $response = await fetch(`${API_ENDPOINT}/organization/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const { data } = await $response.json();
      if (!data) {
        throw new Error('Unable to retrieve organization');
      }

      return data;
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );

  useEffect(() => {
    if (data) {
      setOrganization(data as Organization);
    }
  }, [data, setOrganization]);

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['organization', id] });
  }, [id]);

  return {
    isLoading,
    error,
    id: data?.id,
    organizationId: data?.id,
    organization: data,
    hasOnboarded: !!data?.onboarded,
    invalidate,
    isRefetching,
    isSyncing: data?.sync?.status === 'running',
  };
}
