import type { StatBox } from '@pandler/shared/data-types';
import { StatBox2 } from '@pandler/shared/react-ui';
import { useMemo } from 'react';
import { formatAmount } from '../../utils/format-amount';
import { MiniLineChart } from '../mini-line-chart';

export interface StatBoxProps {
  title: string;
  className?: string;
  chart: StatBox;
}

export function StatBox({ title, chart, className = '' }: StatBoxProps) {
  const amount = useMemo(() => formatAmount(chart.amount), [chart.amount]);

  const right = chart.miniChart ? (
    <MiniLineChart chart={chart.miniChart} height={50} />
  ) : null;

  return (
    <StatBox2
      className={className}
      title={title}
      amount={amount}
      right={right}
    />
  );
}
