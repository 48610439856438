import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { LoadingScreen } from './loading-screen';
import { SuccessScreen } from './success-screen';

export function CreateDialog() {
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);

  useUpdateEffect(() => {
    setLoading(false);
  }, [ready]);

  if (loading) {
    return <LoadingScreen onComplete={() => setReady(true)} />;
  }

  return <SuccessScreen />;
}
