import { randomNumber } from '../numbers';

const words = [
  'ledger',
  'quark',
  'widget',
  'gizmo',
  'mogul',
  'nexus',
  'oasis',
  'vortex',
  'zenith',
  'alchemy',
  'blitz',
  'cipher',
  'dynamo',
  'elixir',
  'fizz',
  'gusto',
  'halo',
  'influx',
  'jazz',
  'kudos',
  'lattice',
  'mingle',
  'nifty',
  'orbit',
  'plaza',
  'quiver',
  'rover',
  'stark',
  'turbo',
  'vivid',
  'whirl',
  'zephyr',
];

interface RandomHumanFriendlyStringOptions {
  numberOfWords?: number;
  appendNumber?: boolean;
  minNumber?: number;
  maxNumber?: number;
}

export function randomHumanFriendlyString(
  options?: RandomHumanFriendlyStringOptions
) {
  const {
    numberOfWords = 2,
    appendNumber = true,
    minNumber = 100,
    maxNumber = 999,
  } = options ?? {};

  const randomWords = Array.from(
    { length: numberOfWords },
    () => words[randomNumber(0, words.length - 1)]
  ).join('-');

  return appendNumber
    ? `${randomWords}-${randomNumber(minNumber, maxNumber)}`
    : randomWords;
}
