import { GenericSearchFilter } from '@pandler/cloud/shared';

export interface EntityTabsSearchProps {
  activeTab: 'invoices' | 'payments' | 'messages';
  onChange?: (value: string) => void;
}

export function EntityTabsSearch({
  activeTab,
  onChange,
}: EntityTabsSearchProps) {
  return (
    <GenericSearchFilter
      onChange={(value) => {
        if (onChange) {
          onChange(value);
        }
      }}
      className="w-56 py-2"
      label={`Search ${activeTab}...`}
    />
  );
}
