import { useRef, useState, forwardRef, type ReactNode } from 'react';
import { Editor, type IAllProps } from '@tinymce/tinymce-react';
import { LoadingContainer } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { useMergedRef } from '../hooks/use-merged-ref';

type EditorOnInit = IAllProps['onInit'];

export interface HtmlWysiwygProps {
  initialValue?: string;
  height?: string;
  apiKey?: string;
  loader?: ReactNode | ReactNode[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HtmlWysiwyg = forwardRef<any, HtmlWysiwygProps>(
  ({ initialValue = '', height = '500px', apiKey, loader }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const internalEditorRef = useRef<any | null>(null);
    const mergedRef = useMergedRef(ref, internalEditorRef);
    const [editorLoaded, setEditorLoaded] = useState(false);

    const handleEditorInit: EditorOnInit = (event, editor) => {
      mergedRef(editor);
      setEditorLoaded(true);
    };

    return (
      <>
        {!editorLoaded && (loader ?? <LoadingContainer />)}
        <div
          className={classNames({
            visible: editorLoaded,
            invisible: !editorLoaded,
          })}
          style={{ height }}
        >
          <Editor
            apiKey={apiKey}
            onInit={handleEditorInit}
            initialValue={initialValue}
            init={{
              height,
              menubar: false,
              plugins:
                'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
              valid_children:
                '+body[style],+body[head],+body[link],+body[meta]',
              extended_valid_elements:
                'link[rel|href|type],meta[charset|name|content]',
              toolbar:
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
              mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
              ],
            }}
          />
        </div>
      </>
    );
  }
);

export default HtmlWysiwyg;
