import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { ProfilePage } from './pages';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/profile" element={<ProfilePage />} />
    </ReactRouterRoutes>
  );
}
