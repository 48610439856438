import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface RowProps {
  label: string | ReactNode;
  children: ReactNode;
  className?: string;
}

export function Row({ label, children, className = '' }: RowProps) {
  return (
    <tr>
      <th className="pl-2 pr-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left w-2/5">
        {label}
      </th>
      <td className={twMerge('text-sm py-4', className)}>{children}</td>
    </tr>
  );
}
