import relativeTime from 'dayjs/plugin/relativeTime';
import type { Activity } from '@pandler/shared/data-types';
import dayjs from 'dayjs';
import { Text } from '../text';
import type { ElementType } from 'react';

dayjs.extend(relativeTime);

export function Notification({
  date,
  body,
  iconBackgroundClassName,
  iconTextClassName = 'text-white',
  Icon,
}: Activity & {
  iconBackgroundClassName: string;
  iconTextClassName?: string;
  Icon: ElementType;
}) {
  return (
    <>
      <div>
        <div className="relative px-1">
          <div
            className={`${iconBackgroundClassName} ${iconTextClassName} h-8 w-8 rounded-full ring-8 ring-white flex items-center justify-center`}
          >
            <Icon
              className={`${iconTextClassName} h-5 w-5`}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <span className="whitespace-nowrap text-gray-700">
            {dayjs().to(dayjs(date))}
          </span>
          <Text value={body} />
        </div>
      </div>
    </>
  );
}
