import { Button, ModalContext } from '@pandler/shared/react-ui';
import { type ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateTypeSelection } from '../create-type-selection';

export interface CreateTemplateButton {
  label?: string | ReactNode;
  className?: string;
}

export function CreateTemplateButton({
  label = 'Create template',
  className = '',
}: CreateTemplateButton) {
  const { closeModal, showModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <Button
      className={className}
      onClick={() =>
        showModal(
          <CreateTypeSelection
            onSelect={(type) => {
              closeModal();
              navigate(`/templates/create/${type}`);
            }}
          />,
          {
            onClose: closeModal,
            className: 'sm:max-w-2xl sm:w-full p-0 sm:p-0 rounded-2xl',
          }
        )
      }
    >
      {label}
    </Button>
  );
}
