import type { RenderLeafProps } from 'slate-react';

export function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  if (leaf.bold) {
    children = <span className="font-bold">{children}</span>;
  }

  if (leaf.italic) {
    children = <span className="italic">{children}</span>;
  }

  if (leaf.underline) {
    children = <span className="underline">{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
}
