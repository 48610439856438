import { useContext, useCallback } from 'react';
import { ModalContext } from '../modal';
import {
  DeleteConfirmation,
  type DeleteConfirmationProps,
} from '../delete-confirmation';

type ConfirmOptions = Pick<
  DeleteConfirmationProps,
  'title' | 'body' | 'confirmButtonLabel' | 'placeholder' | 'type'
> & { onConfirm: DeleteConfirmationProps['onDelete'] };

export function useConfirmation() {
  const { showModal, closeModal } = useContext(ModalContext);

  const confirm = useCallback(
    (options: ConfirmOptions) => {
      const {
        title = 'Are you sure?',
        onConfirm,
        body,
        confirmButtonLabel = 'Yes',
        placeholder,
        type = 'info',
      } = options;

      showModal(
        <DeleteConfirmation
          title={title}
          onDelete={async () => {
            if (onConfirm) {
              await onConfirm();
              closeModal();
            } else {
              closeModal();
            }
          }}
          onCancel={closeModal}
          body={body}
          confirmButtonLabel={confirmButtonLabel}
          placeholder={placeholder}
          type={type}
        />,
        { className: 'sm:max-w-lg sm:w-full' }
      );
    },
    [closeModal, showModal]
  );

  return { confirm, close: closeModal };
}
