import { useContext } from 'react';
import type { Invoice } from '@pandler/shared/data-types';
import { ButtonDropdown, ModalContext } from '@pandler/shared/react-ui';

import { SendReminder } from '../send-reminder';
import { DownloadButton } from '../download-button';

interface OptionsButtonProps {
  invoice: Invoice;
}

export function OptionsButton({ invoice }: OptionsButtonProps) {
  const { closeModal, showModal } = useContext(ModalContext);

  return (
    <ButtonDropdown
      itemsClassName="w-32 -left-24"
      items={[
        {
          label: 'Send reminder',
          onClick: () => {
            showModal(
              <SendReminder invoice={invoice} onSuccess={closeModal} />,
              {
                className: 'overflow-visible sm:p-0 transition-all',
                onClose: closeModal,
              }
            );
          },
        },
        {
          label: (
            <DownloadButton
              className="bg-transparent text-black hover:bg-transparent p-0 shadow-none border-none disabled:bg-transparent"
              loadingClassName="text-black"
              invoiceId={invoice.id}
              label="Download"
              hideIcon
            />
          ),
        },
        {
          label: 'View',
          url: `/invoices/view/${invoice.id}`,
        },
      ]}
    />
  );
}
