import { useCallback, type ReactNode } from 'react';
import type { WorkflowAction } from '../types';
import { WorkflowContext } from './workflow-context';

export interface WorkflowProviderProps {
  actions?: WorkflowAction[];
  children: ReactNode;
}

export function WorkflowProvider({
  actions = [],
  children,
}: WorkflowProviderProps) {
  const getAction = useCallback(
    (id: string) => {
      return actions.find((a) => a.id === id);
    },
    [actions]
  );

  return (
    <WorkflowContext.Provider value={{ actions, getAction, initialized: true }}>
      {children}
    </WorkflowContext.Provider>
  );
}
