import { z } from 'zod';
import { dateSchema } from './date';
import { InvoiceLineItem } from './invoice-line-item';
import type { PlatformApiScope } from '@pandler/shared/utils';
import { Activity } from './activity';
import currency from 'currency-codes';
import { createUpdatableZodObject } from '../helpers';

export const CURRENCY_CODES = currency.codes() as [string, ...string[]];

export const Invoice = z.object({
  id: z.string(),
  number: z.string(),
  issuedDate: dateSchema,
  dueDate: dateSchema,
  status: z.enum(['Draft', 'Submitted', 'PartiallyPaid', 'Paid', 'Void']),
  subTotal: z.number(),
  total: z.number(),
  taxAmount: z.number(),
  amountPaid: z.number().default(0),
  amountDue: z.number(),
  currency: z.enum(CURRENCY_CODES),
  lineItems: z.array(InvoiceLineItem),
  customerId: z.string(),
  organizationId: z.string().uuid(),
  activity: z.array(Activity).default([]),
});

export type Invoice = z.infer<typeof Invoice>;

type InvoiceAttribute = keyof typeof Invoice.shape;

const protectedAttributes = {
  'write:invoice': ['organizationId'],
} as Record<PlatformApiScope, InvoiceAttribute[]>;

export const UpdatableInvoice = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: Invoice,
    protectedAttributes,
  });

export function friendlyInvoiceStatusName(status: Invoice['status']) {
  switch (status) {
    case 'PartiallyPaid':
      return 'Partially paid';
    case 'Void':
      return 'Deleted';
    case 'Submitted':
      return 'Awaiting payment';
    default:
      return status;
  }
}

export const FilterableInvoice = Invoice.omit({
  id: true,
  lineItems: true,
  customerId: true,
  organizationId: true,
  activity: true,
});

export type FilterableInvoice = z.infer<typeof FilterableInvoice>;

export const isInvoiceOverdue = ({ dueDate, status }: Invoice) =>
  ['Submitted', 'PartiallyPaid'].includes(status) &&
  new Date(dueDate).getTime() < new Date().getTime();
