import { getFileNameFromUrl } from '@pandler/shared/utils';

interface DownloadOptions {
  url: string;
  fileName?: string;
}

export async function downloadFile(options: DownloadOptions): Promise<void> {
  try {
    const { url, fileName } = options;
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement('a');
    const linkUrl = URL.createObjectURL(blob);
    link.href = linkUrl;
    link.download = fileName ?? getFileNameFromUrl(url) ?? 'file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(linkUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
