import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader, Tabs } from '@pandler/shared/react-ui';
import { tabs } from '../../config';

export function HomePage() {
  return (
    <>
      <Helmet>
        <title>General Settings</title>
      </Helmet>

      <PageHeader
        title="General Settings"
        breadcrumbs={
          <Breadcrumbs
            items={[{ name: 'Settings', href: '/settings', current: true }]}
          />
        }
      />

      <Content>
        <Tabs initialActive="general" tabs={tabs} contentClassName="mt-10" />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(HomePage);
