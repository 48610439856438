import {
  QueryBuilder as ReactQueryBuilder,
  type QueryBuilderProps as ReactQueryBuilderProps,
} from 'react-querybuilder';
import { RemoveRule } from './controls/remove-rule';

export type QueryBuilderProps = {
  lorem?: string;
} & ReactQueryBuilderProps;

const DefaultClassNames = {
  Button:
    'h-8 inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  Input:
    'px-4 h-12 text-sm rounded-md outline-none border border-gray-300 placeholder:text-gray-400 hover:border-primary-300 focus:border-primary-300 focus:ring-primary-500 focus:border-primary-500',
  Select:
    'pl-3 pr-10 h-12 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md',
};

export function QueryBuilder(props: QueryBuilderProps) {
  return (
    <ReactQueryBuilder
      {...props}
      controlClassnames={{
        queryBuilder: 'pb-4 bg-white shadow rounded-lg overflow-hidden',
        ruleGroup: 'pr-2 pl-4 bg-white transition-colors duration-300 rounded',
        header: 'flex space-x-2 items-center mt-3',
        rule: 'mt-3 flex space-x-2 items-center',
        combinators: DefaultClassNames.Select,
        addRule: DefaultClassNames.Button,
        addGroup: DefaultClassNames.Button,
        fields: DefaultClassNames.Select,
        operators: DefaultClassNames.Select,
        value: DefaultClassNames.Input,
        dragHandle: 'cursor-grab',
        ...props.controlClassnames,
      }}
      controlElements={{
        removeRuleAction: RemoveRule,
        removeGroupAction: RemoveRule,
        ...props.controlElements,
      }}
    />
  );
}
