import { type ReactNode, type ElementType } from 'react';
import { RoundedIcon } from '../rounded-icon';

type IconFeedItem = {
  id: string;
  content: string | ReactNode;
  dateTime?: string;
  icon: ElementType;
  iconClass?: string;
};

export interface IconFeedInputArguments {
  items: IconFeedItem[];
}

export function IconFeed({ items }: IconFeedInputArguments) {
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {items.map((item, itemIndex) => (
          <li key={item.id}>
            <div className="relative pb-8">
              {itemIndex !== items.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <RoundedIcon className={item.iconClass} icon={item.icon} />
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">{item.content}</p>
                  </div>
                  {item.dateTime && (
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={item.dateTime}>{item.dateTime}</time>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
