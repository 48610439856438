import type { ReactNode } from 'react';
import {
  ErrorPage,
  ErrorScreen,
  LoadingScreen,
} from '@pandler/shared/react-ui';
import { OrganizationWrapper } from './organization-wrapper';
import { useCurrentUser } from '@pandler/cloud/shared';

interface AuthenticatedWrapperProps {
  children: ReactNode;
}

export function AuthenticatedWrapper({ children }: AuthenticatedWrapperProps) {
  const { user, isLoading, error } = useCurrentUser();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !user) {
    return (
      <ErrorPage
        title="Error"
        screen={
          <ErrorScreen
            errorCode="Connection error"
            title="Authentication error"
            description={error?.message ?? 'Unable to fetch user details'}
          />
        }
      />
    );
  }

  return (
    <OrganizationWrapper id={user.organizations[0] ?? ''}>
      {children}
    </OrganizationWrapper>
  );
}
