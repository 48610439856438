import { useState } from 'react';
import type { DropZoneProps } from '../dropzone/dropzone';
import { DropZone } from '../dropzone/dropzone';

interface DropZoneS3Props<T> extends DropZoneProps {
  urlGenerator: (file: File) => string;
  urlParser: (data: T) => string;
  headersGenerator?: () => Promise<Record<string, unknown>>;
  onSuccess?: (url: string) => void;
  onError?: (error: unknown) => void;
  method?: string;
}

export function DropZoneS3<
  PreSignedResponse extends object = Record<string, unknown>
>(props: DropZoneS3Props<PreSignedResponse>) {
  const {
    urlGenerator,
    method = 'POST',
    urlParser,
    headersGenerator,
    onSuccess,
    onError,
  } = props;
  const [uploading, setUploading] = useState(false);

  const onDrop = async (acceptedFiles: File[]) => {
    try {
      setUploading(true);

      const file = acceptedFiles[0];
      if (!file) {
        return;
      }

      const presignedUrl = urlGenerator(file);
      const headers = headersGenerator ? await headersGenerator() : {};

      const response = await fetch(presignedUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });

      const json = (await response.json()) as PreSignedResponse;
      const url = urlParser(json);

      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (onSuccess) {
        onSuccess(url);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      setUploading(false);
    }
  };

  return <DropZone {...props} isLoading={uploading} options={{ onDrop }} />;
}
