import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import type { Message } from '../../types';
import { PaperClipIcon } from '@heroicons/react/24/outline';

export interface MessageProps extends Message {
  id: string;
  className?: string;
  columnClassName?: string;
  isUnread?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  onSelect?: () => void;
  onClick?: () => void;
  hasAttachment?: boolean;
  hideSelect?: boolean;
}

export function Message({
  className = '',
  columnClassName = '',
  name,
  message,
  isUnread = false,
  date,
  isSelected = false,
  isActive = false,
  onClick,
  onSelect,
  hasAttachment = false,
  hideSelect = false,
}: MessageProps) {
  const dateLabel = useMemo(() => dayjs(date).format('MMM DD'), [date]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onSelect) {
        onSelect();
      }
      event.stopPropagation();
    },
    [onSelect]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const isClickFromCheckbox =
        event.target instanceof HTMLInputElement &&
        event.target.type === 'checkbox';

      if (!isClickFromCheckbox && onClick) {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      className={twMerge(
        classNames(
          'flex cursor-pointer flex-col rounded-t-lg transition-colors duration-300 border-b p-2.5 text-slate-700 hover:bg-slate-100 dark:border-navy-500 dark:text-navy-100 dark:hover:bg-navy-600 sm:flex-row sm:items-center',
          {
            'font-semibold': isUnread,
            'bg-slate-100 dark:bg-navy-600 hover:bg-slate-200/80 dark:hover:bg-navy-700':
              isActive,
          }
        ),
        className
      )}
      onClick={handleClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex space-x-2 sm:w-72 grow">
          {!hideSelect && (
            <div className="flex">
              <label
                className="flex h-8 w-8 items-center justify-center"
                x-tooltip="'Select'"
              >
                <input
                  className="form-checkbox is-outline h-4.5 w-4.5 rounded border-slate-400/70 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                  type="checkbox"
                  checked={isSelected}
                  onChange={handleCheckboxChange}
                />
              </label>
            </div>
          )}
          <div className="flex items-center space-x-2">
            {hasAttachment && (
              <PaperClipIcon className="w-5 h-5 attachment-icon text-gray-700 shrink-0" />
            )}
            <h3 className="line-clamp-1 message-name font-medium">{name}</h3>
            <span className="h-2 w-2 shrink-0 rounded-full bg-secondary lg:hidden"></span>
          </div>
        </div>
        <div className="shrink-0 text-xs sm:hidden">{dateLabel}</div>
      </div>
      <div
        className={twMerge(
          'flex flex-1 items-center justify-between space-x-2',
          columnClassName
        )}
      >
        <div className="flex items-center space-x-2">
          <span className="line-clamp-1">{message}</span>
        </div>
      </div>
      <div className={twMerge('hidden text-xs+ sm:flex', columnClassName)}>
        {dateLabel}
      </div>
    </div>
  );
}
