import type { DashboardGetOverviewResponse } from '@pandler/shared/api-typings';
import { Chart } from '@pandler/shared/charts-ui';
import { Link } from 'react-router-dom';

interface HeroAnalyticsProps {
  data: DashboardGetOverviewResponse;
}

export function HeroAnalytics({ data }: HeroAnalyticsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
      <Link to="/payments">
        <Chart
          title="Total Payments"
          className="hover:bg-gray-50/50 transition-all duration-300"
          {...data.charts.totalPayments}
        />
      </Link>
      <Link to="/invoices">
        <Chart
          title="New Invoices"
          className="hover:bg-gray-50/50 transition-all duration-300"
          {...data.charts.newInvoices}
        />
      </Link>
      <Link to="/invoices/unpaid">
        <Chart
          title="Overdue Invoices"
          className="hover:bg-gray-50/50 transition-all duration-300"
          {...data.charts.overdueInvoices}
        />
      </Link>
      <Link to="/messages/log">
        <Chart
          title="Messages Sent"
          className="hover:bg-gray-50/50 transition-all duration-300"
          {...data.charts.messagesSent}
        />
      </Link>
    </div>
  );
}
