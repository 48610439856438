import type { PlatformTemplate } from '../../types';

export const OverdueInvoiceSmsTemplate: PlatformTemplate<'overdue-invoice-sms'> =
  {
    id: 'overdue-invoice-sms',
    type: 'sms',
    preview: 'https://i.imgur.com/U7M4686.png',
    category: ['automated'],
    name: 'Overdue invoice',
    description: 'Lorem ipsum',
    order: 10,
    content: '',
    dependencies: ['company', 'payment'] as const,
  };
