import { useAuth0 } from '@auth0/auth0-react';
import type { VerifiedDomain } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export default function useAddDomainVerification() {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId = '' } = useOrganization();

  return useMutation({
    mutationFn: async ({
      id = organizationId,
      data,
    }: {
      id?: string;
      data: Partial<VerifiedDomain>;
    }): Promise<{ id: string }> => {
      try {
        const token = await getAccessTokenSilently({
          ...GetAccessTokenDefaults,
          scope: 'write:domain-verification',
        });

        const $response = await fetch(`${API_ENDPOINT}/domain-verification`, {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
            'x-organization-id': id,
          },
          body: JSON.stringify({
            ...data,
            type: 'email',
          }),
        });

        if ($response.status === 201) {
          queryClient.invalidateQueries({
            queryKey: ['domain-verification', id],
          });

          return $response.json() as unknown as { id: string };
        }
      } catch {
        // ignore
      }

      throw new Error('Unable to add domain');
    },
  });
}
