import type { PlatformTemplate } from '../../../types';

export const BlankEmailTemplate: PlatformTemplate<'blank'> = {
  id: 'blank',
  type: 'email',
  preview: 'https://i.imgur.com/TlHpHzt.png',
  category: ['automated', 'conversational'],
  name: 'Blank',
  subject: '',
  description: 'Create a new email completely from scratch.',
  order: -10,
  content: '',
  dependencies: [],
};
