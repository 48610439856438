import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';
import type { DnsStatusResponse } from '@pandler/shared/api-typings';

interface UseGetDomainDnsStatusOptions {
  id: string;
  refetchUntilReady?: boolean;
  refetchInterval?: number;
}

const useGetDomainDnsStatus = ({
  id,
  refetchUntilReady = false,
  refetchInterval = 15000,
}: UseGetDomainDnsStatusOptions) => {
  const { id: organizationId = '' } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<DnsStatusResponse, Error>(
    ['domain-verification-dns', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:domain-verification',
      });

      try {
        const response = await fetch(
          `${API_ENDPOINT}/domain-verification/${id}/dns-status`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              'x-organization-id': organizationId,
            },
          }
        );

        return response.json();
      } catch {
        throw Error('Unable to retrieve domain verifications');
      }
    },
    {
      staleTime: Infinity,
      retry: false,
      refetchInterval: (data) => {
        if (!refetchUntilReady) {
          return false;
        }

        if (data === undefined) {
          return refetchInterval;
        }

        const isVerified = Object.values(data.dns).every(({ valid }) => valid);
        return isVerified ? false : refetchInterval;
      },
    }
  );
};

export default useGetDomainDnsStatus;
