import { type Address } from '@pandler/shared/data-types';
import {
  FieldGroupFieldError,
  FormGroup,
  Input,
} from '@pandler/shared/react-ui';
import { get } from 'lodash';
import { type Zorm } from 'react-zorm';
import type { ErrorChain, FieldChain } from 'react-zorm/dist/types';
import type { AnyZodObject } from 'zod';

export type AddressFormProps<T extends AnyZodObject> = {
  id: string;
  required?: boolean;
  form: Zorm<T>;
  prefix?: string;
  initialValue?: Address;
};

export function AddressForm<T extends AnyZodObject>({
  required,
  id,
  form,
  prefix = '',
  initialValue,
}: AddressFormProps<T>) {
  const fields = get(form.fields, prefix) as unknown as FieldChain<Address>;
  const errors = get(form.fields, prefix) as unknown as ErrorChain<Address>;

  return (
    <FormGroup
      id={`${id}_streetName`}
      label="Street name"
      showRequiredAsterisk={required}
      input={
        <Input
          type="text"
          placeholder="123 St Name"
          name={fields.streetName(0)()}
          defaultValue={initialValue?.streetName?.[0]}
          hasError={errors.streetName(0)(true)}
        />
      }
      error={errors.streetName(0)(FieldGroupFieldError)}
    />
  );
}
