import * as React from 'react';

export function PhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 34 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 4h-1.184A2.996 2.996 0 0 0 27 2H14c0-1.103-.897-2-2-2H6C4.897 0 4 .897 4 2H3C1.346 2 0 3.346 0 5v38c0 1.654 1.346 3 3 3h1c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2h17c1.654 0 3-1.346 3-3V7c0-1.654-1.346-3-3-3ZM3 44c-.551 0-1-.449-1-1V5c0-.551.449-1 1-1h1v40H3Zm8.997 2H6V2h6l-.003 44ZM14 14h10v10H14V14Zm11 30H14v-6h8v-2h-8v-2h10v-2H14v-2h12v-2H14v-2h12V12H14V4h13c.552 0 1 .449 1 1v38c0 .551-.448 1-1 1h-2Zm7-1c0 .551-.448 1-1 1h-1.184c.112-.314.184-.648.184-1V6h1c.552 0 1 .449 1 1v36Z"
        fill="#303033"
      />
      <path d="M34 20h-4v6h4v-6ZM34 30h-4v6h4v-6Z" fill="#4F46E5" />
    </svg>
  );
}
