import { ChartContainer, Tabs } from '@pandler/shared/react-ui';
import { PaymentsTable } from '@pandler/cloud/payments';
import { TabContainer } from './tab-container';
import { InvoiceList } from '@pandler/cloud/invoices';
import type { EntityTabsSearchProps } from '../entity-tabs-search';
import { EntityTabsSearch } from '../entity-tabs-search';
import { useMemo, useState } from 'react';
import { DEFAULT_DATE } from '@pandler/cloud/shared';
import { MessagesTab } from '../messages-tab';

export function EntityTabs() {
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState('invoices');
  const Search = useMemo(
    () => (
      <EntityTabsSearch
        key={activeTab}
        onChange={setSearchValue}
        activeTab={activeTab as EntityTabsSearchProps['activeTab']}
      />
    ),
    [activeTab]
  );

  return (
    <ChartContainer>
      <Tabs
        right={Search}
        onClick={setActiveTab}
        tabs={[
          {
            id: 'invoices',
            title: 'Invoices',
            content: (
              <TabContainer>
                <InvoiceList
                  key={searchValue}
                  hideFilterBar
                  hidePagination
                  narrow
                  initialFilter={{ searchValue, dateValue: DEFAULT_DATE }}
                />
              </TabContainer>
            ),
          },
          {
            id: 'payments',
            title: 'Payments',
            content: (
              <TabContainer>
                <PaymentsTable hidePagination narrow />
              </TabContainer>
            ),
          },
          {
            id: 'messages',
            title: 'Messages',
            content: (
              <TabContainer>
                <MessagesTab searchValue={searchValue} />
              </TabContainer>
            ),
          },
        ]}
      />
    </ChartContainer>
  );
}
