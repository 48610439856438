import { donutColorsArray } from '../../utils/colors';
import { DonutChart, type DonutChartProps } from '../donut-chart/donut-chart';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { ArrowSmallRightIcon, PlayIcon } from '@heroicons/react/24/solid';

export type DonutChartWithLegendProps = DonutChartProps & {
  datePeriod?: string;
  cta?: {
    url: string;
    label: string;
  };
};

export function DonutChartWithLegend({
  datePeriod,
  cta,
  ...donutChartProps
}: DonutChartWithLegendProps) {
  return (
    <div>
      <div className="grid md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4">
        <div className="sm:col-span-12  md:col-span-12 lg:col-span-12 xl:col-span-6">
          <DonutChart {...donutChartProps} />
        </div>
        <div className="sm:col-span-12  md:col-span-12 lg:col-span-12 xl:col-span-6 self-center">
          <ol className="list-none list-inside mb-3">
            {donutChartProps.chart.categories.map((category, index) => (
              <li
                key={category}
                className="mb-1 text-slate-700 dark:text-slate-400 text-sm"
              >
                <span className="flex gap-3 items-center">
                  <PlayIcon
                    className="w-4 h-4"
                    style={{ color: donutColorsArray[index] }}
                  />
                  {category}
                </span>
              </li>
            ))}
          </ol>
          {cta && (
            <Link
              to={cta.url}
              className="inline-flex items-center justify-center gap-1.5 focus:outline-none text-slate-600 hover:bg-primary-600 hover:text-white bg-transparent border border-gray-200 hover:border-primary-600 dark:bg-transparent dark:text-slate-400 dark:hover:text-white dark:border-gray-700 dark:hover:bg-primary-500 text-sm font-medium py-1 px-3 rounded"
            >
              {cta.label}
              <ArrowSmallRightIcon className="w3.5 h-3.5" />
            </Link>
          )}
        </div>
      </div>
      {datePeriod && (
        <div className="bg-slate-50 dark:bg-slate-700/40 py-3 px-2 mb-0 rounded-md text-center mt-3 flex items-center justify-center gap-3">
          <CalendarIcon className="w-4 h-4 text-slate-500" />
          <h6 className="text-sm text-slate-500 font-medium">{datePeriod}</h6>
        </div>
      )}
    </div>
  );
}
