import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

import { Button, DefaultClassNames } from '../button';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

interface DropdownItem {
  label: ReactNode | string | null;
  icon?: ReactNode;
  className?: string;
  onClick?: () => void;
}

interface OptionsButtonProps {
  className?: string;
  label: string | ReactNode;
  onClick: () => void;
  items: DropdownItem[];
  itemsClassName?: string;
  disabled?: boolean;
}

const noop = () => {
  // noop
};

const Item = ({
  className = '',
  label,
  icon,
  onClick,
}: Pick<DropdownItem, 'className' | 'label' | 'icon' | 'onClick'>) => {
  if (typeof label === 'object') {
    return (
      <div onClick={onClick} className="flex flex-col">
        {label}
      </div>
    );
  }

  return (
    <button
      className={`flex py-1 items-center w-full ${className}`}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </button>
  );
};

export function OptionsButton({
  className = '',
  label,
  onClick,
  items,
  itemsClassName = '',
  disabled = false,
}: OptionsButtonProps) {
  return (
    <Menu as="div" className={twMerge('flex relative text-left', className)}>
      <Button
        className={`h-10 focus:ring-offset-0 focus:ring-0 ${
          items.length > 0 ? 'rounded-r-none pr-2' : ''
        }`}
        onClick={onClick ?? noop}
        disabled={disabled}
      >
        <span>{label}</span>
      </Button>

      {items.length > 0 && (
        <Menu.Button
          className={twMerge(
            DefaultClassNames,
            'bg-primary-600 hover:bg-primary-700 text-white rounded-l-none h-10 pl-1.5 pr-3 focus:ring-offset-0 focus:ring-0'
          )}
          disabled={disabled}
        >
          <ChevronDownIcon className="w-3.5 h-3.5" />
        </Menu.Button>
      )}

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={twMerge(
            'absolute mt-10 w-48 origin-bottom-left divide-y overflow-hidden divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none z-10 text-gray-600',
            itemsClassName
          )}
        >
          {items.map((item) => (
            <Menu.Item key={`dropdown-${item.label}`}>
              {() => (
                <div
                  className={classNames(
                    'px-4 py-2 group hover:text-primary-300 text-sm text-gray-500',
                    item.label ? 'hover:bg-slate-50' : ''
                  )}
                >
                  {!item.label && (
                    <div className="h-px bg-gray-200" role="separator" />
                  )}
                  {item.label && <Item {...item} />}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
