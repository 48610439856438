import * as React from 'react';

export function WarningIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 524 418" {...props}>
      <path
        fill="#f2f2f2"
        d="M442.174 400.477c2.066.129 3.207-2.438 1.643-3.934l-.155-.618.061-.148c1.232-2.94 4.626-4.332 7.572-3.114 9.314 3.851-.52 12.7.22 18.688.258 2.067 8.354 2.18 7.896 4.208 4.305-9.412 6.568-19.688 6.565-30.023-.001-2.596-.144-5.193-.436-7.782a71.612 71.612 0 0 0-.997-6.31c-2.31-11.277-7.306-22.016-14.51-30.985-3.463-1.891-1.351-4.85-3.096-8.395-.627-1.28-6.218-1.308-6.451-2.71.25.033 3.864-3.72 2.67-5.576-.785-1.221-.54-2.776.468-3.82.1-.103.193-.21.278-.327 2.981-4.044 7.09-3.34 9.237 2.154 4.583 2.311 4.629 6.147 1.819 9.837-1.788 2.347-2.033 5.523-3.602 8.036.162.206.33.406.491.613a73.59 73.59 0 0 1 7.686 12.166c-.612-4.766.287-10.508 1.821-14.21 1.76-4.249 5.07-7.822 7.97-11.497 3.462-4.389 10.51-2.397 11.122 3.16l.017.16c-.429.242-.849.499-1.26.77-2.338 1.549-1.527 5.175 1.245 5.601l.063.01c-.155 1.544-5.633 6.407-6.02 7.912 3.705 14.308.933 16.197-10.466 16.437l-.598.852a73.229 73.229 0 0 1 2.602 9.507 73.853 73.853 0 0 1 1.282 9.049c.298 3.83.274 7.68-.048 11.503l.02-.135c.818-4.212 3.103-8.146 6.422-10.873 4.945-4.064 11.931-5.563 17.266-8.83 2.567-1.573 5.86.457 5.412 3.435l-.022.143a20.67 20.67 0 0 0-2.318 1.118c-.43.242-.85.5-1.26.771-2.34 1.549-1.528 5.176 1.245 5.6l.062.01.13.02c-1.362 3.236-14.336 7.801-16.712 10.392-2.31 12.498-1.175 12.126-11.81 8.49h-.007a75.091 75.091 0 0 1-5.04 14.728l-18.02.006c-.064-.2-.123-.407-.18-.607 1.666.103 3.345.006 4.985-.299-1.337-1.64-2.675-3.293-4.012-4.933a1.123 1.123 0 0 1-.084-.097l-.02-.025-1.856-6.107c.146-1.355.385-2.698.71-4.02Z"
      />
      <path
        fill="#3f3d56"
        d="m251.744 416.42-159.457.055c-8.01.003-15.186-4.137-19.194-11.073-2.004-3.468-3.006-7.273-3.008-11.079 0-3.805 1-7.611 3-11.08l79.678-145.05c4.004-6.939 11.177-11.083 19.187-11.086s15.187 4.137 19.195 11.073l79.789 145.012c1.998 3.46 2.998 7.26 2.999 11.063 0 3.803-1 7.609-3.002 11.079-4.003 6.939-11.176 11.083-19.187 11.086ZM72.09 394.32c0 3.464 17.595-4 19.418-.845 3.646 6.31-6.51 21.003.779 21 0 0 173.27-3.828 176.912-10.14 1.82-3.157 2.73-6.62 2.73-10.082s-.914-6.923-2.738-10.078l-79.788-145.012c-3.636-6.293-10.164-10.058-17.452-10.056-7.285.003-99.86 161.75-99.861 165.213Z"
      />
      <path
        fill="#3f3d56"
        d="M253.515 414.42c-1.998 4 91.614 2.77 92.72 2.77 0 0 26.313-.581 26.866-1.54.276-.48.414-1.005.414-1.53s-.139-1.052-.415-1.531l-12.117-22.02a3.029 3.029 0 0 0-2.65-1.528c-.046 0-.114.041-.202.12-16.163 14.443-36.082 23.992-57.757 24.314-23.047.343-46.732.69-46.859.944Z"
      />
      <path
        fill="#ccc"
        d="M0 417.081c0 .66.53 1.19 1.19 1.19l522.29-.18c.66 0 1.19-.531 1.19-1.191s-.53-1.19-1.19-1.19l-522.29.181c-.66 0-1.19.53-1.19 1.19Z"
      />
      <path
        fill="#a0616a"
        d="m403.878 411.305-9.045.003-4.315-34.886 13.35-.004.01 34.887z"
      />
      <path
        fill="#2f2e41"
        d="M372.89 411.111c-.282.474-.43 2.005-.43 2.557a3.07 3.07 0 0 0 3.07 3.069l28.011-.01a2.094 2.094 0 0 0 2.094-2.095v-1.166s1.384-3.506-1.47-7.825c0 0-3.545 3.384-8.845-1.912l-1.563-2.83-11.307 8.275-6.268.774c-1.372.17-2.588-.025-3.293 1.163Z"
      />
      <path
        fill="#a0616a"
        d="m369.197 381.468-8.567 2.898-15.26-31.666 12.644-4.279 11.183 33.047z"
      />
      <path
        fill="#2f2e41"
        d="M339.779 391.208c-.115.54.235 2.037.412 2.56a3.07 3.07 0 0 0 3.892 1.923l26.532-8.98a2.094 2.094 0 0 0 1.312-2.655l-.374-1.105s.189-3.764-3.898-6.941c0 0-2.274 4.34-8.991 1.02l-2.388-2.18-8.06 11.461-5.69 2.74c-1.246.6-2.46.805-2.747 2.157ZM355.226 179.09l-17.543 58.1-20.711 52.262 32.789 74.634 12.157-5.409-14.547-68.56 30.507-46.523 14.236 145.232 12.16-.68 16.49-175.303 2.35-38.167-67.888 4.414z"
      />
      <path
        fill="#e6e6e6"
        d="m408.21 50.385-25.668 4.738-2.023 11.484-15.534 7.436-8.077 84.443s-13.505 17.568-2.694 22.969l68.9-6.78s3.374-12.49-.004-13.675-3.389-31.02-3.389-31.02l15.516-61.365-22.97-8.098-4.057-10.132Z"
      />
      <circle cx={393.07} cy={29.436} r={20.728} fill="#a0616a" />
      <path
        fill="#2f2e41"
        d="M406.796 32.512c-1.654.906-.085 5.229-1.118 5.603-1.195.432-5.552-4.527-5.606-10.082-.016-1.681.37-2.488-.002-4.482-.486-2.607-1.98-5.682-3.363-5.6-.82.048-1.62 1.207-1.68 2.24-.083 1.45 1.325 2.02 1.122 2.801-.386 1.488-6.298 2.514-8.403.003-1.65-1.968-.385-5.424-1.123-5.601-.543-.13-1.127 1.772-3.36 3.922-.84.81-2.091 2.015-2.8 1.682-1.024-.482-.28-3.876-.561-3.921-.234-.038-.38 2.348-1.68 4.482-1.67 2.74-5.114 4.854-6.72 3.924-.985-.57-1.063-2.156-1.122-3.361-.125-2.538-3.813-8.377-2.86-11.092 1.943-5.526 5.271-1.63 6.776-3.475 2.038-2.497 3.866-1.154 7.281-3.924 3.337-2.705 3.366-5.425 5.6-5.604 2.013-.161 2.728 1.985 5.043 1.679 1.96-.26 2.387-1.924 3.92-1.682 1.405.222 1.497 1.689 3.363 2.24 1.19.352 1.453-.158 2.8-.001 2.806.325 4.69 2.88 5.044 3.36 1.36 1.844.833 2.623 2.242 4.48 1.216 1.602 3.91.922 4.922 1.722 3.31 2.614 4.731 7.435 4.76 12.93.02 3.842-.481 5.784-1.6 10.635-.848 3.683-4.78 8.443-6.949 10.566-.582.57-2.213 2.167-3.36 1.682-1.088-.46-1.12-2.528-1.122-2.801-.005-.939.245-2.1 2.24-5.043 2.094-3.09 2.926-3.442 2.799-4.482-.225-1.838-3.165-3.521-4.483-2.8Z"
      />
      <path
        fill="#a0616a"
        d="M334.495 225.76c-.646 5.402 1.619 10.115 5.057 10.525s6.748-3.636 7.393-9.039c.282-2.364.007-4.597-.68-6.395l2.447-22.926-10.784-.92-1.267 22.7c-1.09 1.585-1.884 3.69-2.166 6.055Z"
      />
      <path
        fill="#e6e6e6"
        d="M365.49 73.498s-5.586.08-9.285 7.304c-1.965 3.838-12.482 75.627-12.482 75.627l-9.687 58.809 16.502.083 12.859-55.693 10.817-32.301-8.723-53.83Z"
      />
      <path
        fill="#a0616a"
        d="M411.93 217.13c-1.58 5.207-.176 10.243 3.137 11.248s7.28-2.4 8.86-7.606c.693-2.28.812-4.526.45-6.415l6.42-22.146-10.457-2.79-5.218 22.126c-1.352 1.37-2.5 3.304-3.192 5.583Z"
      />
      <path
        fill="#e6e6e6"
        d="M423.416 69.632s8.104-6.082 14.862 3.373c6.759 9.455 9.488 87.138 9.488 87.138l-18.898 47.969-14.188-4.724 13.49-58.775-23.658-44.576 18.904-30.405Z"
      />
      <circle cx={218.48} cy={311.431} r={73} fill="#6c63ff" />
      <g fill="#fff">
        <circle cx={218.493} cy={349.479} r={6.703} />
        <path d="M218.464 266.681c-.616 4.092.488 4.948.494 22.586.006 17.637 3.665 41.285-.472 41.287s-5.65-48.803-20.017-53.122c-16.89-5.079 21.988-23.995 19.995-10.75Z" />
      </g>
      <path
        fill="#3f3d56"
        d="m120.503 348.702-6.595 13.935c7.973-5.717 20.427-10.646 30.337-13.285-9.234-4.46-20.533-11.652-27.283-18.772l3.793 14.724c-44.474-9.058-76.411-43.176-76.425-82.26l-1.606-.552c.014 40.823 31.51 76.961 77.78 86.21Z"
      />
    </svg>
  );
}
