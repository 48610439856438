import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { DashboardGetOverviewResponse } from '@pandler/shared/api-typings';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

type UseDashboardOptions = UseQueryOptions<
  DashboardGetOverviewResponse,
  Error,
  DashboardGetOverviewResponse
>;

const useDashboard = (options: UseDashboardOptions = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationId } = useOrganization();

  const query = useQuery<DashboardGetOverviewResponse, Error>(
    ['dashboard', organizationId],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:dashboard',
      });

      try {
        const data = await (
          await fetch(`${API_ENDPOINT}/dashboard/${organizationId}`, {
            headers: { authorization: `Bearer ${token}` },
          })
        ).json();

        return data;
      } catch {
        throw Error('Unable to retrieve dashboard information');
      }
    },
    { staleTime: Infinity, retry: false, ...options }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['dashboard', organizationId],
    });
  }, [organizationId]);

  return { ...query, invalidate };
};

export default useDashboard;
