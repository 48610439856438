import { useMemo, useRef, useState } from 'react';
import ReactFlow, {
  type Edge,
  type Node,
  type NodeMouseHandler,
} from 'react-flow-renderer';
import { TimingNode } from '../nodes/timing-node';
import { TriggerNode } from '../nodes/trigger-node';
import { ActionNode } from '../nodes/action-node';
import { LogicNode } from '../nodes/logic-node';
import { ExitNode } from '../nodes/exit-node';
import { getLayoutedElements } from './utils';

export interface WorkflowProps {
  className?: string;
  onNodeClick?: NodeMouseHandler;
  nodes: Node[];
  edges: Edge[];
}

// const initialNodes: Node[] = [
//   {
//     id: '1',
//     type: 'trigger',
//     data: {
//       title: 'Invoice Status Changes',
//       filters: [{ lorem: 'ipsum' }],
//     },
//     position: { x: 250, y: 25 },
//   },
//   {
//     id: '2',
//     type: 'timing',
//     data: { title: 'Wait 1 Day', type: 'delay-for', duration: 1, unit: 'day' },
//     position: { x: 100, y: 125 },
//   },
//   {
//     id: '3',
//     type: 'action',
//     data: { type: 'send-email', title: 'Invoice overdue (1 day)' },
//     position: { x: 250, y: 250 },
//   },
//   {
//     id: '4',
//     type: 'action',
//     data: { type: 'send-sms', title: 'Invoice overdue (1 day)' },
//     position: { x: 250, y: 250 },
//   },
//   {
//     id: '5',
//     type: 'timing',
//     data: {
//       title: 'Wait until next day at 12PM',
//       type: 'delay-until',
//       time: '12:00',
//       unit: 'nextDay',
//     },
//     position: { x: 100, y: 125 },
//   },
//   {
//     id: '6',
//     type: 'conditional-split',
//     data: { type: 'condition', title: 'Status is Unpaid', rule: {} },
//     position: { x: 100, y: 125 },
//   },
//   {
//     id: '7',
//     type: 'action',
//     data: { type: 'send-email', title: 'Invoice overdue (7 days)' },
//     position: { x: 100, y: 125 },
//   },
//   {
//     id: '8',
//     type: 'exit',
//     data: {},
//     position: { x: 100, y: 125 },
//   },
//   {
//     id: '9',
//     type: 'exit',
//     data: {},
//     position: { x: 100, y: 125 },
//   },
// ];

// const initialEdges: Edge[] = [
//   { id: 'e1-2', source: '1', target: '2', type: 'smoothstep' },
//   { id: 'e2-3', source: '2', target: '3', type: 'smoothstep' },
//   { id: 'e2-4', source: '2', target: '4', type: 'smoothstep' },
//   { id: 'e3-5', source: '3', target: '5', type: 'smoothstep' },
//   { id: 'e4-5', source: '4', target: '5', type: 'smoothstep' },
//   { id: 'e5-6', source: '5', target: '6', type: 'smoothstep' },
//   { id: 'e6-7', source: '6', target: '7', type: 'smoothstep', label: 'Yes' },
//   { id: 'e7-9', source: '7', target: '9', type: 'smoothstep' },
//   { id: 'e6-8', source: '6', target: '8', type: 'smoothstep', label: 'No' },
// ];

export function Workflow({
  className,
  onNodeClick,
  nodes: initialNodes,
  edges: initialEdges,
}: WorkflowProps) {
  const { nodes: positionedNodes, edges: positionedEdges } = useMemo(
    () => getLayoutedElements(initialNodes, initialEdges),
    [initialNodes, initialEdges]
  );
  const [nodes] = useState<Node[]>(positionedNodes);
  const [edges] = useState<Edge[]>(positionedEdges);
  const flowContainer = useRef<HTMLDivElement | null>(null);

  const nodeTypes = useMemo(
    () => ({
      action: ActionNode,
      exit: ExitNode,
      'conditional-split': LogicNode,
      trigger: TriggerNode,
      timing: TimingNode,
    }),
    []
  );

  return (
    <div className={className}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
        panOnScroll={true}
        preventScrolling={false}
        nodesDraggable={false}
        nodesConnectable={false}
        ref={flowContainer}
        proOptions={{
          account: 'paid-pro',
          hideAttribution: true,
        }}
        onNodeClick={onNodeClick}
      ></ReactFlow>
    </div>
  );
}
