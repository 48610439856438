export function friendlyTimeOfDay(date = new Date()) {
  const currentHour = date.getHours();

  if (currentHour < 12) {
    return 'morning';
  }

  if (currentHour < 18) {
    return 'afternoon';
  }

  return 'evening';
}
