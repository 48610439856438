import { DevicePhoneMobileIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { useTemplates } from '@pandler/cloud/shared';
import type { Template } from '@pandler/shared/data-types';
import {
  InlineError,
  LoadingContainer,
  RoundedIcon,
} from '@pandler/shared/react-ui';
import { formatDateTime } from '@pandler/shared/utils';
import classNames from 'classnames';
import { useEffect } from 'react';

export interface TemplatesBlocksProps {
  filter?: string;
  activeTemplate?: Template;
  onClick?: (template: Template) => void;
  onChange?: (data?: Template[]) => void;
}

export function TemplatesBlocks({
  activeTemplate,
  onClick,
  onChange,
}: TemplatesBlocksProps) {
  const { templates, isLoading, error, isRefetching } = useTemplates();
  const showLoading = isLoading || isRefetching;

  useEffect(() => {
    if (onChange) {
      onChange(templates);
    }
  }, [templates, onChange]);

  if (error) {
    return <InlineError>{error.message}</InlineError>;
  }

  if (showLoading || !templates) {
    return <LoadingContainer />;
  }

  return (
    <div className="border-slate-100 border-t">
      {templates.map((template) => (
        <button
          key={template.id}
          onClick={() => {
            if (onClick) {
              onClick(template);
            }
          }}
          className={classNames(
            'flex cursor-pointer transition-colors duration-300 border-b p-2.5 py-4 text-slate-700 hover:bg-slate-100 dark:border-navy-500 dark:text-navy-100 dark:hover:bg-navy-600 pb-4 rounded-none sm:items-start w-full',
            {
              'bg-slate-100 hover:bg-slate-200/80':
                template.id === activeTemplate?.id,
            }
          )}
        >
          <div className="flex flex-row space-x-4 items-center">
            <div className="shrink-0">
              {template.type === 'email' ? (
                <RoundedIcon icon={EnvelopeIcon} className="bg-primary-600" />
              ) : (
                <RoundedIcon
                  icon={DevicePhoneMobileIcon}
                  className="bg-emerald-600"
                />
              )}
            </div>
            <div className="flex flex-col space-y-1 items-start grow text-left">
              <div className="font-medium">{template.name}</div>
              <div className="line-clamp-1 text-slate-500">
                {template.description}
              </div>
              <div className="text-slate-400 text-xs mt-0.5">
                {template.updatedAt
                  ? `Last edited on ${formatDateTime(template.updatedAt)}`
                  : `Created on ${formatDateTime(template.createdAt)}`}
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}
