import { useAuth0 } from '@auth0/auth0-react';
import { type OnboardingResponse } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';

export default function useOnboarding() {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId } = useOrganization();
  const { isLoading, error, data, ...useQueryData } = useQuery<
    OnboardingResponse,
    Error
  >(
    ['onboarding', organizationId],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:onboarding',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/onboarding/${organizationId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      if ($response.status === 200) {
        const json = await $response.json();
        return json.data;
      }

      throw Error('An unknown error occurred');
    },
    { staleTime: Infinity, retry: false }
  );

  return { ...useQueryData, isLoading, error, data, onboarding: data };
}
