import type { Notification } from '@pandler/shared/data-types';

type Theme = {
  backgroundColor: string;
};

type EntityType<T> = T extends { type: infer U } ? U : never;

type Helper<T extends PropertyKey> = {
  [K in T]: Theme;
};

type Colors = Helper<EntityType<Notification['entity']>>;

const DEFAULT_COLORS: Theme = {
  backgroundColor: 'bg-primary-600',
};

export const colors: Colors = {
  invoice: DEFAULT_COLORS,
  payment: { backgroundColor: 'bg-green-500' },
  message: DEFAULT_COLORS,
};

const asString = (theme: Theme) => `${theme.backgroundColor}`;

export function colorsFromEntity(entity?: Notification['entity']) {
  return asString(entity ? colors[entity.type] : DEFAULT_COLORS);
}
