import { type RenderElementProps } from 'slate-react';

export function Element({ attributes, children, element }: RenderElementProps) {
  switch (element.type) {
    default:
      return (
        <p {...attributes} className="mb-2">
          {children}
        </p>
      );
  }
}
