import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';
import { DeleteButton } from '@pandler/shared/react-ui';
import { twMerge } from 'tailwind-merge';
import { SendButton } from './send-button';

export interface ViewActionBarProps {
  onDelete?: () => Promise<void>;
  onSend?: () => Promise<void>;
  className?: string;
}

export function ViewActionBar({
  onSend,
  onDelete,
  className = '',
}: ViewActionBarProps) {
  return (
    <div
      className={twMerge(
        'flex space-x-1.5 items-end w-full justify-end bg-white p-4 border-b border-b-slate-200/80',
        className
      )}
    >
      <SendButton onSend={onSend} />

      {onDelete && (
        <DeleteButton onDelete={onDelete}>
          <ArchiveBoxXMarkIcon className="w-5 h-5 mr-1.5" />
          Delete
        </DeleteButton>
      )}
    </div>
  );
}
