import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';
import type { ActivitySearchResponse } from '@pandler/shared/api-typings';

export interface UseActivityFeedOptions {
  entityId?: string;
}

export default function useActivityFeed({
  entityId,
}: UseActivityFeedOptions = {}) {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationId } = useOrganization();

  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    ...useQueryData
  } = useInfiniteQuery<ActivitySearchResponse, Error>(
    ['activity-feed', entityId ?? organizationId],
    async ({ pageParam }: { pageParam?: string }) => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:activity',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/activity?${
          entityId ? `entityId=${entityId}` : `organizationId=${organizationId}`
        }${pageParam ? `&from=${pageParam}` : ''}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      if ($response.status === 200) {
        const json = await $response.json();
        return json;
      }

      throw Error('An unknown error occurred');
    },
    {
      getNextPageParam: (lastPage, pages) => lastPage.pagination.next ?? false,
      staleTime: Infinity,
      retry: false,
    }
  );

  return {
    ...useQueryData,
    isLoading,
    error,
    feed: data,
    fetchNextPage,
    hasNextPage,
  };
}
