import * as React from 'react';

export function PortfolioIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 848 615" {...props}>
      <path
        fill="#f2f2f2"
        d="M459.04 114.575a554.592 554.592 0 0 1-5.228 71.261c-.114.83-.239 1.66-.354 2.488l-223.276-1.247a91.794 91.794 0 0 1-1.926-2.5 106.152 106.152 0 0 1-5.832-8.634c-8.62-14.308-12.567-31.06-8.612-42.618l.07-.18a20.281 20.281 0 0 1 3.96-6.878c9.016-10.15 27.247-8.458 43.791-.265-14.839-16.243-26.655-36.74-27.552-55.035-.88-17.845 8.45-30.593 18.571-41.427.332-.358.664-.706.996-1.054.161-.18.332-.349.493-.528 7.896-8.256 17.102-16.394 30.427-15.41 14.614 1.082 30.816 13.323 42.176 27.586 11.36 14.254 18.91 30.446 26.562 46.25 7.662 15.792 15.952 31.989 28.247 45.488-16.653-21.063-30.08-45.029-35.04-68.337s-.594-45.694 14.247-56.671A34.713 34.713 0 0 1 375.965.729c.64-.107 1.291-.193 1.952-.27 13.739-1.563 30.142 3.189 44.1 14.547 15.37 12.506 26.026 31.046 31.328 48.646s5.867 34.543 5.696 50.923Z"
      />
      <path
        fill="#fff"
        d="m312.96 187.54-4.27-.024q-2.033-1.272-4.085-2.513c-.848-.535-1.705-1.05-2.562-1.575q-21.243-12.973-42.751-24.889-21.489-11.925-43.194-22.751a1.79 1.79 0 0 1-1.029-2.046.515.515 0 0 1 .071-.15c.202-.379.653-.536 1.312-.213 1.775.89 3.56 1.78 5.334 2.69q21.765 11.012 43.342 23.123 21.563 12.105 42.88 25.28c.298.181.598.373.896.555 1.356.837 2.701 1.675 4.056 2.513ZM368.01 187.847l-2.64-.015c-.596-.833-1.181-1.666-1.777-2.5q-20.985-29.427-41.961-58.855-34.352-48.178-68.683-96.355a1.7 1.7 0 0 1-.297-.582c-.196-.711.327-1.128.996-1.054a2.006 2.006 0 0 1 1.395.887q19.26 27.018 38.51 54.016l68.335 95.864c1.448 2.028 2.897 4.066 4.346 6.094.595.834 1.19 1.667 1.776 2.5ZM426.826 172.516c-.135 4.449-.429 8.827-.814 13.165l-.223 2.489-2.77-.016c.084-.829.169-1.659.243-2.488.586-6.267 1.001-12.605 1.057-19.115a266.656 266.656 0 0 0-5.25-53.75 334.665 334.665 0 0 0-16.258-55.841A383.492 383.492 0 0 0 376.21 1.71a1.476 1.476 0 0 1-.245-.981c.115-.79 1.166-1.024 1.952-.27a2.293 2.293 0 0 1 .397.503q1.916 3.296 3.763 6.61a383.228 383.228 0 0 1 25.46 55.444 333.08 333.08 0 0 1 15.158 55.926 263.074 263.074 0 0 1 4.131 53.574Z"
      />
      <path
        fill="#3f3d56"
        d="M615.72 186.474h-607a8.728 8.728 0 0 0-8.72 8.72v411.32a8.728 8.728 0 0 0 8.72 8.72h607a8.712 8.712 0 0 0 6.63-3.06 2.043 2.043 0 0 0 .19-.24 8.166 8.166 0 0 0 1.25-2.11 8.507 8.507 0 0 0 .66-3.31v-411.32a8.73 8.73 0 0 0-8.73-8.72Zm6.24 420.04a6.175 6.175 0 0 1-1.03 3.42 6.446 6.446 0 0 1-2.36 2.12 6.184 6.184 0 0 1-2.85.69h-607a6.238 6.238 0 0 1-6.23-6.23v-411.32a6.238 6.238 0 0 1 6.23-6.23h607a6.24 6.24 0 0 1 6.24 6.23Z"
      />
      <path fill="#3f3d56" d="M1.243 221.423H623.2v2.493H1.243z" />
      <circle cx={22.432} cy={205.17} r={7.478} fill="#3f3d56" />
      <circle cx={43.932} cy={205.17} r={7.478} fill="#3f3d56" />
      <circle cx={65.433} cy={205.17} r={7.478} fill="#3f3d56" />
      <path
        fill="#3f3d56"
        d="M468.32 279.126h-312a13 13 0 0 1 0-26h312a13 13 0 0 1 0 26Zm-312-24a11 11 0 0 0 0 22h312a11 11 0 0 0 0-22Z"
      />
      <path
        fill="#6c63ff"
        d="M182.225 431.744h-120a17.02 17.02 0 0 1-17-17v-56a17.02 17.02 0 0 1 17-17h120a17.02 17.02 0 0 1 17 17v56a17.02 17.02 0 0 1-17 17ZM372.225 431.744h-120a17.02 17.02 0 0 1-17-17v-56a17.02 17.02 0 0 1 17-17h120a17.02 17.02 0 0 1 17 17v56a17.02 17.02 0 0 1-17 17ZM562.225 431.744h-120a17.02 17.02 0 0 1-17-17v-56a17.02 17.02 0 0 1 17-17h120a17.02 17.02 0 0 1 17 17v56a17.02 17.02 0 0 1-17 17ZM182.225 549.744h-120a17.02 17.02 0 0 1-17-17v-56a17.02 17.02 0 0 1 17-17h120a17.02 17.02 0 0 1 17 17v56a17.02 17.02 0 0 1-17 17ZM372.225 549.744h-120a17.02 17.02 0 0 1-17-17v-56a17.02 17.02 0 0 1 17-17h120a17.02 17.02 0 0 1 17 17v56a17.02 17.02 0 0 1-17 17ZM562.225 549.744h-120a17.02 17.02 0 0 1-17-17v-56a17.02 17.02 0 0 1 17-17h120a17.02 17.02 0 0 1 17 17v56a17.02 17.02 0 0 1-17 17Z"
      />
      <circle cx={697.115} cy={248.744} r={33} fill="#2f2e41" />
      <path
        fill="#ffb8b8"
        d="M614.443 420.413a10.743 10.743 0 0 1 6.253-15.24l41.816-88.46 19.128 13.414-47.119 82.735a10.8 10.8 0 0 1-20.078 7.55Z"
      />
      <path
        fill="#6c63ff"
        d="M650.275 328.458a4.812 4.812 0 0 1 .552-3.972l13.001-20.383a13.377 13.377 0 0 1 24.456 10.85l-6.549 23.354a4.817 4.817 0 0 1-6.604 3.096l-22.209-9.931a4.811 4.811 0 0 1-2.647-3.014Z"
      />
      <path
        fill="#ffb8b8"
        d="M755.49 429.183a10.743 10.743 0 0 1-7.445-14.695l-39.602-89.472 22.655-5.706 31.804 89.742a10.8 10.8 0 0 1-7.412 20.13ZM766.285 591.358l-11.52 4.195-21.66-42.438 17.002-6.191 16.178 44.434z"
      />
      <path
        fill="#2f2e41"
        d="m745.339 595.257 22.217-8.089 5.093 13.989-36.206 13.182a14.887 14.887 0 0 1 8.896-19.082ZM734.837 564.515l-43.888-76.802a4.822 4.822 0 0 1 .052-4.868l19.763-32.938a4.817 4.817 0 0 1 8.66.842l37.364 103.468a4.799 4.799 0 0 1-2.468 5.99l-13.24 6.27a4.774 4.774 0 0 1-2.043.46 4.84 4.84 0 0 1-4.2-2.422Z"
      />
      <circle cx={694.111} cy={260.239} r={24.561} fill="#ffb8b8" />
      <path
        fill="#6c63ff"
        d="M659.176 371.49c-19.343-42.888.157-66.858 9.438-75.173a14.557 14.557 0 0 1 9.73-3.721h34.6a14.576 14.576 0 0 1 13.037 21.094l-23.13 46.26-43.487 11.959Z"
      />
      <path
        fill="#6c63ff"
        d="M709.793 341.051a4.812 4.812 0 0 1-2.646-3.015l-6.924-23.163a13.377 13.377 0 0 1 24.198-11.413l13.39 20.222a4.817 4.817 0 0 1-1.974 7.022l-22.033 10.314a4.811 4.811 0 0 1-4.011.033Z"
      />
      <path
        fill="#ffb8b8"
        d="m687.147 602.955-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
      />
      <path
        fill="#2f2e41"
        d="M666.13 599.451h23.644v14.887h-38.531a14.887 14.887 0 0 1 14.887-14.887ZM681.952 584.903l-19.628-2.265a4.818 4.818 0 0 1-4.223-5.42l17.518-131.9a3.75 3.75 0 0 0-.073-1.376l-17.608-72.645a4.761 4.761 0 0 1 .754-3.926c2.73-3.764 12.086-12.537 39.914-12.703h.03a4.834 4.834 0 0 1 4.398 2.827l3.712 8.166a3.763 3.763 0 0 0 1.253 1.512c5.11 3.617 29.862 23.918 15.328 68.489-14.285 43.807-32.588 128.855-36.112 145.459a4.791 4.791 0 0 1-4.697 3.814 4.925 4.925 0 0 1-.566-.032ZM719.919 244.79a33.405 33.405 0 0 1-19.091 5.9 20.47 20.47 0 0 0 8.114 3.338 67.359 67.359 0 0 1-27.514.155 17.807 17.807 0 0 1-5.76-1.979 7.29 7.29 0 0 1-3.555-4.754c-.604-3.449 2.083-6.582 4.876-8.693a35.967 35.967 0 0 1 30.224-6.04c3.376.873 6.759 2.347 8.952 5.059s2.842 6.891.753 9.683Z"
      />
      <path
        fill="#2f2e41"
        d="M703.52 227.705c-2.806-.934-5.693-1.914-7.916-3.863s-3.635-5.139-2.635-7.922c.963-2.682 3.896-4.248 6.737-4.459s5.638.669 8.351 1.538c-1.227-1.855-2.494-5.45-3.604-9.241-2.533-8.652 8.02-16.553 17.011-15.9q.397.028.77.082c9.065 1.323 13.768 4.781 20.1 11.403 7.921 8.284 12.063 19.461 14.837 30.582s4.403 22.564 8.305 33.34 10.483 21.137 20.546 26.623a33.982 33.982 0 0 0 35.26-2.173 30.442 30.442 0 0 1-34.972 34.484c-12.992-2.099-23.4-12.563-28.958-24.492s-7.077-25.28-8.472-38.366-2.807-26.434-8.185-38.446c-2.176-4.861-5.38-9.809-11.107-10.584a6.89 6.89 0 0 0-7.387 3.958c-2.517 6.094 6.097 11.254 3.89 17.062a6.393 6.393 0 0 1-5.693 3.887c-4.163.274-7.584-2.113-10.806-4.416-6.592-4.713-6.072-3.097-6.072-3.097Z"
      />
      <path
        fill="#3f3d56"
        d="M847.284 615.234h-230a1 1 0 0 1 0-2h230a1 1 0 0 1 0 2Z"
      />
    </svg>
  );
}
