import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface InlineErrorProps {
  className?: string;
  children: ReactNode | ReactNode[] | string;
}

export function InlineError({ children, className = '' }: InlineErrorProps) {
  return (
    <p className={twMerge('text-red-500 font-semibold', className)}>
      {children}
    </p>
  );
}
