import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useDownloadInvoicePdf } from '@pandler/cloud/shared';
import { Button, LoadingIcon } from '@pandler/shared/react-ui';
import { type ReactNode, type MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

interface DownloadButtonProps {
  invoiceId: string;
  className?: string;
  loadingClassName?: string;
  label?: string | ReactNode | ReactNode[];
  hideIcon?: boolean;
}

export function DownloadButton({
  invoiceId,
  className = '',
  loadingClassName = '',
  label,
  hideIcon = false,
}: DownloadButtonProps) {
  const { download, isLoading } = useDownloadInvoicePdf();

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    download({ invoiceId });
  };

  return (
    <Button
      onClick={handleOnClick}
      disabled={isLoading}
      className={twMerge(
        'bg-emerald-500 hover:bg-emerald-600 disabled:bg-emerald-300',
        className
      )}
    >
      {isLoading ? (
        <LoadingIcon
          className={twMerge('w-5 h-5 text-white', loadingClassName)}
          strokeWidth={2}
        />
      ) : (
        <span className="inline-flex flex-row gap-2 items-center">
          {!hideIcon && <ArrowDownTrayIcon className="w-5 h-5 text-white" />}
          {label}
        </span>
      )}
    </Button>
  );
}
