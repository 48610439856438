import {
  useDeleteMultipleMessageQueueItems,
  useMessageQueue,
  useSendMultipleMessageQueueItems,
} from '@pandler/cloud/shared';
import type { EmailQueueItem } from '@pandler/shared/data-types';
import {
  LoadingIcon,
  error,
  success,
  useDeleteConfirmation,
} from '@pandler/shared/react-ui';
import {
  Inbox,
  type MessageProps,
  type InboxProps,
} from '@pandler/shared/inbox-ui';
import { useEffect } from 'react';
import { EditQueueItem } from '../edit-queue-item';
import { twMerge } from 'tailwind-merge';
import { InvoiceEmptyState } from '../inbox-empty-state';

const toInboxMessage =
  (activeMessage?: string) =>
  ({
    id,
    recipient,
    subject,
    queuedAt,
    email,
  }: EmailQueueItem): InboxProps['messages'][number] => ({
    id,
    name: recipient,
    message: subject,
    date: new Date(queuedAt),
    isUnread: true,
    hasAttachment: email.attachments.length > 0,
    isActive: id === activeMessage,
  });

export type OnDataChangeCallback = (
  options: Pick<
    ReturnType<typeof useMessageQueue>,
    'data' | 'isLoading' | 'isRefetching'
  >
) => void;
export interface MessageQueueInboxProps {
  onClear?: () => void;
  className?: string;
  messageClassName?: string;
  onDataChange?: OnDataChangeCallback;
  onMessageClick?: (message: MessageProps) => void;
  activeMessage?: string;
  dontSlide?: boolean;
}

export function MessageQueueInbox({
  onClear,
  className = '',
  messageClassName = '',
  onDataChange,
  onMessageClick,
  activeMessage,
  dontSlide = false,
}: MessageQueueInboxProps) {
  const { data, isLoading, isRefetching } = useMessageQueue({
    filter:
      'status=pending&attributes=id,queuedAt,subject,status,recipient,email',
  });
  const { confirm } = useDeleteConfirmation();
  const { mutateAsync: deleteMultiple } = useDeleteMultipleMessageQueueItems();
  const { mutateAsync: sendMultiple } = useSendMultipleMessageQueueItems();

  useEffect(() => {
    if (onClear && data && data.length === 0) {
      onClear();
    }
  }, [data, onClear]);

  useEffect(() => {
    if (onDataChange) {
      onDataChange({ data, isLoading, isRefetching });
    }
  }, [onDataChange, data, isLoading, isRefetching]);

  if (isLoading || isRefetching || !data) {
    return (
      <div className={className}>
        <LoadingIcon
          className="w-5 h-5 text-primary-600 mt-4 mx-auto"
          strokeWidth={2.5}
        />
      </div>
    );
  }

  const messages = data.map(toInboxMessage(activeMessage));

  return (
    <Inbox
      className={twMerge('p-4', className)}
      messageClassName={messageClassName}
      emptyState={<InvoiceEmptyState />}
      onMessageClick={onMessageClick}
      dontSlide={dontSlide}
      detailsPane={({ context, goBack }) => (
        <EditQueueItem
          id={context?.id ?? ''}
          onClose={goBack}
          noModal
          goBack={{ onClick: goBack }}
        />
      )}
      messages={messages}
      onDeleteAll={(selected) => {
        if (selected.length > 0) {
          confirm({
            confirmButtonLabel: 'Delete',
            body: 'Are you sure you want to delete the selected messages?',
            onDelete: async () => {
              try {
                await deleteMultiple({ ids: selected });
                success({
                  message: 'Your messages have successfully been deleted.',
                });
              } catch {
                error({
                  message: 'An error occurred when deleting your messages.',
                });
              }
            },
          });
        }
      }}
      onSendAll={(selected) => {
        if (selected.length > 0) {
          confirm({
            type: 'info',
            confirmButtonLabel: 'Send',
            body: 'Are you sure you want to send the selected messages?',
            onDelete: async () => {
              try {
                await sendMultiple({ ids: selected });
                success({
                  message: 'Your messages have successfully been sent.',
                });
              } catch {
                error({
                  message: 'An error occurred when sending your messages.',
                });
              }
            },
          });
        }
      }}
    />
  );
}
