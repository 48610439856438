import type { ElementType, ReactNode } from 'react';

export interface CustomerDetailProps {
  icon?: ElementType;
  children: ReactNode | ReactNode[];
}

export function CustomerDetail({ icon: Icon, children }: CustomerDetailProps) {
  return (
    <div className="flex flex-row items-center justify-center gap-2 text-gray-600 text-sm xl:w-4/5">
      {Icon && <Icon className="w-5 h-5 text-gray-500 shrink-0" />}
      <span>{children}</span>
    </div>
  );
}
