import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { PageWrapper } from '../../components/page-wrapper';
import { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import {
  Button,
  error,
  LoadingIcon,
  SuccessIcon,
} from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { useCompleteOnboarding, useOrganization } from '@pandler/cloud/shared';
import { useNavigate } from 'react-router-dom';

export function CompletePage() {
  const [isComplete, setIsComplete] = useState(false);
  const { organization } = useOrganization();

  useEffect(() => {
    confetti({
      particleCount: 300,
      spread: 120,
      gravity: 0.8,
      colors: [
        '#6366f1', // indigo-500
        '#4f46e5', // indigo-600
        '#4338ca', // indigo-700
        '#4ade80', // green-400
        '#f472b6', // pink-400
        '#fbbf24', // amber-400
      ],
      origin: {
        x: 0.5,
        y: 0.5,
      },
    });
  }, []);

  const navigate = useNavigate();
  const { mutate, isLoading } = useCompleteOnboarding({
    onSuccess: () => {
      setIsComplete(true);
    },
    onError: () => {
      error({ message: 'Unable to update account' });
    },
  });

  useEffect(() => {
    if (organization?.onboarded) {
      navigate('/');
    }
  }, [organization?.onboarded, navigate]);

  if (organization?.onboarded) {
    return null;
  }

  const showAsLoading = isLoading || isComplete;

  return (
    <>
      <Helmet>
        <title>Complete</title>
      </Helmet>

      <PageWrapper>
        <div className="flex flex-col space-y-6 justify-center text-center mt-16 mb-24">
          <SuccessIcon className="h-40 mb-10 mx-auto" />

          <h3 className="font-bold text-xl text-gray-800 text-center mb-2">
            All done!
          </h3>

          <p className="md:w-2/3 mx-auto text-center text-gray-500 text-sm mb-8">
            Your account is ready to go. Congratulations on taking the first
            step in making sure your invoices get paid faster.
          </p>

          <section className="pt-5">
            <Button
              className={classNames(
                'rounded-full justify-center py-3 transition-all duration-500',
                {
                  'w-48': !showAsLoading,
                  'w-24': showAsLoading,
                }
              )}
              type="submit"
              disabled={showAsLoading}
              onClick={() => {
                mutate();
              }}
            >
              {showAsLoading ? (
                <LoadingIcon className="w-5 h-5 mx-auto" strokeWidth={4} />
              ) : (
                'Get started'
              )}
            </Button>
          </section>
        </div>
      </PageWrapper>
    </>
  );
}

export default withAuthenticationRequired(CompletePage);
