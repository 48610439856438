import { ErrorScreen, TakenIcon } from '@pandler/shared/react-ui';
import { NotFoundError } from '@pandler/cloud/shared';

interface RuntimeErrorProps {
  error: unknown;
}

export function RuntimeError({ error }: RuntimeErrorProps) {
  if (error instanceof NotFoundError) {
    const { title, description } = error.display;

    return (
      <ErrorScreen
        title={title}
        description={description}
        icon={<TakenIcon className="h-48" />}
        bodyClassName="py-8"
        titleClassName="capitalize"
      />
    );
  }

  return (
    <ErrorScreen
      title="An error occurred"
      description="Please retry the operation or contact support if the error persists"
    />
  );
}
