import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { queryClient, useGetDomainDnsStatus } from '@pandler/cloud/shared';
import type { VerifiedDomain } from '@pandler/shared/data-types';
import { InlineError, LoadingIcon, success } from '@pandler/shared/react-ui';
import { useEffect } from 'react';

export interface DnsInstructionsProps {
  domain: VerifiedDomain;
}

const replaceHost = (domain: string) => (host: string) =>
  host.replace(`.${domain}`, '');

export function DnsInstructions({ domain }: DnsInstructionsProps) {
  const { data, isLoading, error } = useGetDomainDnsStatus({
    id: domain.id,
    refetchUntilReady: true,
  });
  const replace = replaceHost(domain.domain);

  useEffect(() => {
    if (data?.record.status === 'VERIFIED') {
      queryClient.invalidateQueries({
        queryKey: ['domain-verification', domain.id],
      });
      success({ message: 'Your domain has successfully been verified.' });
    }
  }, [data?.record.status, domain.id]);

  return (
    <div className="space-y-4 text-gray-500/80">
      <p>
        Change your DNS records in your third-party domain provider account. On
        your domain provider's website, log in to your account. Find the DNS
        settings or domain management area.
      </p>

      {isLoading && (
        <LoadingIcon className="w-5 h-5 text-primary-600" strokeWidth={3} />
      )}
      {error && <InlineError>{error.message}</InlineError>}
      {data?.dns && (
        <div className="space-y-8">
          {Object.entries(data.dns).map(([name, record]) => (
            <div key={name} className="text-gray-500/80 space-y-2">
              <div className="text-gray-800 font-bold flex flex-row space-x-1 items-center">
                {record.valid && (
                  <CheckBadgeIcon className="w-5 h-5 text-emerald-500" />
                )}
                <p>
                  Create {replace(record.host)}{' '}
                  <span className="uppercase">{record.type}</span>
                </p>
              </div>
              {!record.valid && (
                <>
                  <p>
                    Point the <span className="uppercase">{record.type}</span>{' '}
                    record for {replace(record.host)} to {record.data}.
                  </p>
                  <pre className="border bg-gray-50 px-4 py-3 rounded-md">
                    {replace(record.host)} {record.type.toUpperCase()}{' '}
                    {record.data}
                  </pre>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
