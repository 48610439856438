import type { PlatformApiScope } from '@pandler/shared/utils';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { createUpdatableZodObject } from '../helpers';

const entity = z.discriminatedUnion('type', [
  z.object({ type: z.literal('invoice'), id: z.string().uuid() }),
  z.object({ type: z.literal('payment'), id: z.string().uuid() }),
  z.object({ type: z.literal('message'), id: z.string().uuid() }),
]);

const STATUS = ['READ', 'UNREAD'] as const;

const TYPE = ['TEAM', 'USER'] as const;

export const Notification = z.object({
  id: z.string().uuid().optional().default(uuidv4),
  createdAt: z
    .number()
    .optional()
    .default(() => new Date().getTime()),
  type: z.enum(TYPE),
  authorId: z.string().uuid(),
  entity: entity.optional(),
  title: z.string(),
  description: z.string().optional(),
  image: z.string().url().optional(),
  status: z.enum(STATUS).optional().default('UNREAD'),
});

export type Notification = z.infer<typeof Notification>;

type NotificationAttribute = keyof typeof Notification.shape;

const protectedAttributes = {
  'write:notifications': [
    'id',
    'createdAt',
    'type',
    'authorId',
    'entity',
    'title',
    'description',
    'image',
  ],
} as Record<PlatformApiScope, NotificationAttribute[]>;

export const UpdatableNotification = (scope: PlatformApiScope) =>
  createUpdatableZodObject({
    scope,
    object: Notification,
    protectedAttributes,
  });

export const NOTIFICATION_STATUS = STATUS;
export const NOTIFICATION_TYPE = TYPE;
