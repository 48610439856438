import { type Notification } from '@pandler/shared/data-types';
import { Button, LoadingIcon } from '@pandler/shared/react-ui';
import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Action = 'APPROVE' | 'DENY';

export interface ApproveDenyProps {
  approveLabel?: string;
  denyLabel?: string;
  className?: string;
  id: string;
  entity?: Notification['entity'];
  onClick?: (options: {
    id: string;
    entity?: Notification['entity'];
    type: Action;
  }) => Promise<void>;
}

export function ApproveDeny({
  approveLabel = 'Approve',
  denyLabel = 'Deny',
  className = '',
  onClick,
  id,
  entity,
}: ApproveDenyProps) {
  const [{ type, isLoading }, setIsLoading] = useState<{
    type?: Action;
    isLoading: boolean;
  }>({ isLoading: false });

  const handleOnClick = useCallback(
    (type: Action) => async () => {
      if (onClick) {
        setIsLoading({ type, isLoading: true });
        await onClick({ entity, type, id });
        setIsLoading({ type, isLoading: false });
      }
    },
    [onClick, setIsLoading, entity, id]
  );

  return (
    <div className={twMerge('flex flex-row gap-2', className)}>
      <Button
        intent="primary"
        className="py-1 px-2"
        disabled={isLoading && type === 'APPROVE'}
        onClick={handleOnClick('APPROVE')}
      >
        {' '}
        {isLoading && type === 'APPROVE' ? (
          <LoadingIcon className="w-3.5 h-3.5 text-white" strokeWidth={3} />
        ) : (
          approveLabel
        )}
      </Button>

      <Button
        className="py-1 px-2 bg-white hover:bg-gray-50 border-gray-200 text-gray-800 focus:ring-gray-200 disabled:bg-gray-800/10"
        disabled={isLoading && type === 'DENY'}
        onClick={handleOnClick('DENY')}
      >
        {isLoading && type === 'DENY' ? (
          <LoadingIcon
            className="w-3.5 h-3.5 text-primary-300"
            strokeWidth={3}
          />
        ) : (
          denyLabel
        )}
      </Button>
    </div>
  );
}
