import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Input, type InputProps } from '../input/input';
import classNames from 'classnames';

export interface InputWithLabelsProps {
  className?: string;
  labelContainerClassName?: string;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
  input?: InputProps;
}

export function InputWithLabels({
  className = '',
  labelContainerClassName = '',
  prefix,
  input = {},
  suffix,
}: InputWithLabelsProps) {
  return (
    <div className={twMerge('flex rounded-md shadow-sm', className)}>
      {prefix && (
        <span
          className={twMerge(
            'inline-flex items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm',
            labelContainerClassName
          )}
        >
          {prefix}
        </span>
      )}
      <Input
        {...input}
        className={twMerge(
          input.className,
          classNames({ 'rounded-l-none': !!prefix, 'rounded-r-none': !!suffix })
        )}
      />
      {suffix && (
        <span
          className={twMerge(
            'inline-flex items-center px-3 rounded-r-md border border-l-0 bg-gray-50 text-gray-500 sm:text-sm',
            labelContainerClassName
          )}
        >
          {suffix}
        </span>
      )}
    </div>
  );
}
