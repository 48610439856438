import type { PlatformApiScope } from '@pandler/shared/utils';
import type { z } from 'zod';

export function createUpdatableZodObject<Input extends z.ZodRawShape>({
  scope,
  object,
  protectedAttributes,
}: {
  scope: PlatformApiScope;
  object: z.ZodObject<Input>;
  protectedAttributes: Partial<Record<PlatformApiScope, (keyof Input)[]>>;
}) {
  const scopes = protectedAttributes[scope];
  const omitted =
    scopes !== undefined
      ? scopes.reduce((obj, attribute) => ({ ...obj, [attribute]: true }), {})
      : {};

  return object.omit({
    ...omitted,
    id: true,
  });
}
