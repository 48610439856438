import { ButtonDropdown } from '@pandler/shared/react-ui';
import { useState } from 'react';

export interface ActionBarProps {
  isWithSelectedVisible?: boolean;
  onToggle?: (checked: boolean) => void;
  onDelete?: () => void;
  onSend?: () => void;
}

export function ActionBar({
  isWithSelectedVisible = false,
  onToggle,
  onSend,
  onDelete,
}: ActionBarProps) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="flex flex-col-reverse py-5 sm:flex-row sm:justify-between">
      <div className="mt-2 flex items-center justify-between space-x-1">
        <div className="flex items-center px-2.5">
          <label
            className="flex h-8 w-8 items-center justify-center"
            x-tooltip="'Select All'"
          >
            <input
              className="form-checkbox is-basic h-5 w-5 rounded border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent"
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                const update = !isChecked;
                setIsChecked(update);
                if (onToggle) {
                  onToggle(update);
                }
              }}
            />
          </label>
        </div>

        <div
          className={`flex transition-opacity ${
            isWithSelectedVisible
              ? 'opacity-100 visible'
              : 'opacity-0 invisible'
          }`}
        >
          <ButtonDropdown
            itemsClassName="w-32"
            prefix="With selected"
            items={[
              {
                label: 'Send',
                onClick: onSend,
              },
              {
                label: 'Delete',
                onClick: onDelete,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
