import { useCreateTemplate } from '@pandler/cloud/shared';
import { Button, error, Input, LoadingIcon } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { useState } from 'react';

interface NewTemplateFormProps {
  template: string;
  onCancel?: () => void;
  onSuccess?: (id: string) => void;
}

export function NewTemplateForm({
  template,
  onCancel,
  onSuccess,
}: NewTemplateFormProps) {
  const [name, setName] = useState('');
  const { isLoading, mutate } = useCreateTemplate();

  const isDisabled = isLoading || name.length === 0;

  return (
    <div className="text-center sm:mt-0 sm:text-left grow">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Enter your template name
      </h3>

      <p className="mb-6 mt-2 text-sm text-gray-600">
        Template names aren't public and are only visible to you.
      </p>

      <div className="flex flex-col mb-6">
        <Input
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
          placeholder="Your new template name"
        />
      </div>

      <div className="flex justify-start">
        <Button
          className={classNames(
            `rounded-md text-white transition-all duration-500 text-center flex items-center justify-center bg-primary-500 disabled:bg-primary-200 hover:bg-primary-600 ${
              isLoading ? 'w-12' : 'w-20'
            }`
          )}
          disabled={isDisabled}
          onClick={() => {
            mutate(
              { id: template, name },
              {
                onSuccess: ({ id }) => {
                  if (onSuccess) {
                    onSuccess(id);
                  }
                },
                onError: () => {
                  error({ message: 'Unable to create template' });
                },
              }
            );
          }}
        >
          {isLoading ? <LoadingIcon className="w-4 h-4 mx-auto" /> : 'Save'}
        </Button>
        <Button
          className="bg-gray-100 text-gray-800 rounded-md ml-3 hover:bg-gray-200/80 border"
          onClick={() => {
            if (onCancel) {
              onCancel();
            }
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
