import {
  withAuthenticationRequired,
  RuntimeError,
} from '@pandler/cloud/skeleton';
import { OnboardingPage } from '../onboarding';
import { DashboardPage } from '../dashboard';
import { useOnboarding } from '@pandler/cloud/shared';
import { LoadingIcon } from '@pandler/shared/react-ui';

export function HomePage() {
  const { data, isLoading, error } = useOnboarding();

  if (isLoading) {
    return (
      <section className="py-10 flex justify-center">
        <LoadingIcon
          strokeWidth={1.9}
          className="w-10 h-10 text-primary-600"
          iconClassName="text-primary-700 h-6"
        />
      </section>
    );
  }

  if (error || !data) {
    return <RuntimeError error={error} />;
  }

  const showOnboarding = !(
    data.hasCustomTemplates &&
    data.hasCustomizedBranding &&
    data.hasVerifiedPayments
  );

  if (showOnboarding) {
    return <OnboardingPage />;
  }

  return <DashboardPage />;
}

export default withAuthenticationRequired(HomePage);
