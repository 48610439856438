import { SendSmsAction } from '@pandler/shared/data-types';
import {
  Button,
  FieldGroupFieldError,
  FormGroup,
  Input,
  Textarea,
} from '@pandler/shared/react-ui';
import { useZorm } from 'react-zorm';

export interface SendSmsFormProps {
  data: SendSmsAction;
}

const FormDefinition = SendSmsAction.omit({ type: true });

export function SendSmsForm({ data }: SendSmsFormProps) {
  const zo = useZorm('settings', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      console.log('Form ok!\n' + JSON.stringify(e.data, null, 2));
    },
  });

  return (
    <div>
      <form ref={zo.ref}>
        <FormGroup
          id="to"
          label="Mobile number(s)"
          description={
            <p>
              <span className="font-semibold">Note:</span> use tokens to
              dynamically set this value.
            </p>
          }
          input={
            <Input
              type="text"
              name={zo.fields.to()}
              defaultValue={data.to}
              hasError={zo.errors.to(true)}
            />
          }
          error={zo.errors.to(FieldGroupFieldError)}
        />

        <FormGroup
          id="body"
          label="Body"
          input={
            <Textarea
              name={zo.fields.body()}
              className="min-h-[80px]"
              defaultValue={data.body}
              placeholder="e.g. Your invoice is 7 days overdue"
              hasError={zo.errors.body(true)}
            />
          }
          error={zo.errors.body(
            'This field is required and must have a minimum length of 10'
          )}
        />

        <Button type="submit">Save</Button>
      </form>
    </div>
  );
}
