import { BasicLogMessage, Logs } from '@pandler/cloud/messages';
import type { MessageProps } from '@pandler/shared/inbox-ui';
import { SlideOutContext } from '@pandler/shared/react-ui';
import { useContext } from 'react';

export interface MessagesTabProps {
  searchValue?: string;
}

export function MessagesTab({ searchValue = '' }: MessagesTabProps) {
  const { closePane, showPane } = useContext(SlideOutContext);

  const onMessageClick = ({ id }: MessageProps) => {
    showPane(
      <BasicLogMessage
        id={id}
        messageProps={{ innerClassName: 'p-0 py-6', hideChart: true }}
      />,
      {
        onClose: closePane,
        innerClassName: 'w-screen max-w-3xl',
        childrenContainerClassName: 'pt-0',
        title: 'View sent message',
      }
    );
  };

  return (
    <Logs
      filter={{ searchValue }}
      messageClassName="bg-transparent"
      mainClassName="p-0"
      inboxProps={{
        dontSlide: true,
        onMessageClick,
      }}
    />
  );
}
