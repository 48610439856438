import { Button, ModalContext } from '@pandler/shared/react-ui';
import { useCallback, useContext, useEffect } from 'react';
import { ProductTour } from '../product-tour/product-tour';

interface ProductTourButtonProps {
  show?: boolean;
}

export function ProductTourButton({ show }: ProductTourButtonProps) {
  const { closeModal, showModal } = useContext(ModalContext);

  const handleOnClick = useCallback(
    () =>
      showModal(<ProductTour onComplete={closeModal} dismissable={show} />, {
        className: 'overflow-visible sm:p-0 transition-all',
        onClose: closeModal,
      }),
    [closeModal, showModal, show]
  );

  useEffect(() => {
    if (show) {
      handleOnClick();
    }
  }, [handleOnClick, show]);

  return <Button onClick={handleOnClick}>Take the tour</Button>;
}
