import { useAuth0 } from '@auth0/auth0-react';
import { type Template } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';

// @todo any
export default function useTemplates(options: Record<string, unknown> = {}) {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useOrganization();
  const { isLoading, error, data, isPreviousData, ...useQueryData } = useQuery<
    Template[],
    Error
  >(
    ['organization-templates', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:templates',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/templates?organizationId=${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      if ($response.status === 200) {
        const json = await $response.json();
        return json.data as Template[];
      }

      throw Error('An unknown error occurred');
    },
    { staleTime: Infinity, retry: false, keepPreviousData: true, ...options }
  );

  return { ...useQueryData, isLoading, error, templates: data, isPreviousData };
}
