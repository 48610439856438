import { colors } from '@pandler/shared/tailwind';

export const colorsArray = [
  colors.indigo[700],
  colors.indigo[600],
  colors.indigo[500],
  colors.indigo[400],
  colors.indigo[300],
];

export const donutColorsArray = [
  colors.indigo[600],
  colors.blue[400],
  colors.pink[400],
  colors.purple[400],
  colors.orange[400],
  colors.green[400],
];
