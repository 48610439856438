import {
  Button,
  FieldGroupFieldError,
  InlineError,
  Input,
  LoadingIcon,
} from '@pandler/shared/react-ui';
import { useEffect } from 'react';
import { FormDefinition } from './form-definition';
import { useZorm } from 'react-zorm';
import { useAddDomainVerification } from '@pandler/cloud/shared';

interface AddDomainVerificationFormProps {
  onSuccess: (id: string) => void;
}

export function AddDomainVerificationForm({
  onSuccess,
}: AddDomainVerificationFormProps) {
  const addDomain = useAddDomainVerification();
  const zo = useZorm('domainVerification', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      addDomain.mutate({ data: e.data });
    },
  });

  useEffect(() => {
    if (addDomain.isSuccess) {
      onSuccess(addDomain.data.id);
    }
  }, [addDomain.isSuccess, addDomain.data?.id, onSuccess]);

  return (
    <form ref={zo.ref} className="lg:max-w-xl mx-auto space-y-4 flex flex-col">
      <div className="space-x-2 flex flex-row grow">
        <Input
          className="w-full py-2.5"
          name={zo.fields.domain()}
          hasError={zo.errors.domain(true)}
          placeholder="example.com"
        />
        <Button
          type="submit"
          className="w-16 text-center justify-center shrink-0"
          disabled={addDomain.isLoading}
        >
          {addDomain.isLoading ? (
            <LoadingIcon className="w-5 h-5 text-white" strokeWidth={3} />
          ) : (
            'Add'
          )}
        </Button>
      </div>

      {zo.errors.domain(true) && (
        <InlineError className="text-sm">
          {zo.errors.domain(FieldGroupFieldError)}
        </InlineError>
      )}
    </form>
  );
}
