// ContentSlider.tsx
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { InlineError } from '../inline-error';
import { Button } from '../button';

export interface ContentSliderProps<T = unknown> {
  mainPane: (options: {
    slide: () => void;
    setContext: (context: T) => void;
  }) => React.ReactNode | React.ReactNode[];
  slidePane?: (options: {
    goBack: () => void;
    context?: T;
  }) => React.ReactNode | React.ReactNode[];
}

export const ContentSlider = <T,>({
  mainPane,
  slidePane,
}: ContentSliderProps<T>): JSX.Element => {
  const [isMainPaneActive, setIsMainPaneActive] = useState(true);
  const [context, setContext] = useState<T | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const mainPaneRef = useRef<HTMLDivElement>(null);
  const slidePaneRef = useRef<HTMLDivElement>(null);

  const slide = () => {
    setIsMainPaneActive(false);
  };

  const goBack = () => {
    setIsMainPaneActive(true);
  };

  useEffect(() => {
    if (isMainPaneActive) {
      const timeoutId = setTimeout(() => {
        setContext(undefined);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return () => {
      // noop
    };
  }, [isMainPaneActive]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === mainPaneRef.current && isMainPaneActive) {
          setHeight(entry.contentRect.height);
        } else if (entry.target === slidePaneRef.current && !isMainPaneActive) {
          setHeight(entry.contentRect.height);
        }
      }
    });

    const mainPaneCurrent = mainPaneRef.current;
    const slidePaneCurrent = slidePaneRef.current;

    if (mainPaneCurrent) {
      resizeObserver.observe(mainPaneCurrent);
    }

    if (slidePaneCurrent) {
      resizeObserver.observe(slidePaneCurrent);
    }

    return () => {
      if (mainPaneCurrent) {
        resizeObserver.unobserve(mainPaneCurrent);
      }

      if (slidePaneCurrent) {
        resizeObserver.unobserve(slidePaneCurrent);
      }
    };
  }, [isMainPaneActive]);

  const transitionClasses = 'transform duration-500 ease-in-out';

  const mainPaneClasses = classNames(
    'absolute top-0 left-0 w-full',
    transitionClasses,
    {
      'translate-x-0': isMainPaneActive,
      '-translate-x-full': !isMainPaneActive,
    }
  );

  const slidePaneClasses = classNames(
    'absolute top-0 right-0 w-full',
    transitionClasses,
    {
      'translate-x-full': isMainPaneActive,
      'translate-x-0': !isMainPaneActive,
    }
  );

  return (
    <div
      className="relative w-full overflow-x-hidden"
      style={{ height: height ? height + 'px' : 'auto' }}
    >
      <div ref={mainPaneRef} className={mainPaneClasses}>
        {mainPane({ slide, setContext })}
      </div>
      <div ref={slidePaneRef} className={slidePaneClasses}>
        {slidePane ? (
          slidePane({ goBack, context })
        ) : (
          <>
            <InlineError>slidePane not set</InlineError>
            <Button onClick={goBack}>Go back</Button>
          </>
        )}
      </div>
    </div>
  );
};
