import Markdown from 'react-markdown';
import type { Notification as TNotification } from '@pandler/shared/data-types';
import { Icon } from '../icon';
import { timeSince } from '@pandler/shared/utils';
import { As, LoadingIcon } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';
import { ApproveDeny, type ApproveDenyProps } from '../approve-deny';
import { MarkAsRead } from '../mark-as-read';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type NotificationProps = TNotification & {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  urlGenerator?: (entity?: TNotification['entity']) => string | undefined;
  markAsRead?: (id: string) => Promise<void>;
  onApproveDeny?: ApproveDenyProps['onClick'];
};

export function Notification({
  id,
  image,
  entity,
  title,
  description,
  createdAt,
  className = '',
  as = 'div',
  urlGenerator,
  markAsRead,
  onApproveDeny,
}: NotificationProps) {
  const [isLoading, setIsLoading] = useState(false);
  const url = urlGenerator ? urlGenerator(entity) : undefined;

  const notification = (
    <>
      <Icon image={image} entity={entity} />
      <section className="flex flex-col gap-1.5 grow">
        <section className="flex flex-col">
          <span className="text-sm text-gray-800">
            <Markdown>{title}</Markdown>
          </span>
          {description && (
            <span className="text-xs text-gray-700">
              <Markdown>{description}</Markdown>
            </span>
          )}
        </section>
        <span className="text-xs text-gray-500">
          {timeSince(new Date(createdAt))}
        </span>
      </section>
      <section className="flex items-center justify-center">
        <MarkAsRead
          onClick={async () => {
            if (markAsRead) {
              setIsLoading(true);
              await markAsRead(id);
              setIsLoading(false);
            }
          }}
        />
      </section>
    </>
  );

  return (
    <As as={as} className={twMerge('relative', className)}>
      {isLoading && (
        <div className="w-full h-full inset-0 bg-gray-800/25 z-10 flex items-center justify-center absolute rounded-md">
          <LoadingIcon className="w-4 h-4 text-primary-600" strokeWidth={2.5} />
        </div>
      )}

      {url ? (
        <Link
          to={url}
          className="flex flex-row gap-3 cursor-pointer bg-white hover:bg-gray-50/75 transition-colors p-2 rounded-md"
        >
          {notification}
        </Link>
      ) : (
        <div className="flex flex-row gap-3 cursor-pointer bg-white hover:bg-gray-50/75 transition-colors p-2 rounded-md">
          {notification}
        </div>
      )}

      {entity?.type === 'message' && (
        <ApproveDeny
          id={id}
          className="mt-2.5 mb-2"
          onClick={onApproveDeny}
          entity={entity}
        />
      )}
    </As>
  );
}
