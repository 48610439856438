import { DevicePhoneMobileIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { type WorkflowAction } from '@pandler/shared/workflow-ui';

export const Actions: WorkflowAction[] = [
  {
    id: 'send-email',
    title: 'Send Email',
    icon: <EnvelopeIcon className="w-5 h-5 text-primary-600" />,
  },
  {
    id: 'send-sms',
    title: 'Send SMS',
    icon: <DevicePhoneMobileIcon className="w-5 h-5 text-primary-600" />,
  },
];
