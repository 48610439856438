import { type MiniLineChart as TMiniLineChart } from '@pandler/shared/data-types';
import { type ApexOptions } from 'apexcharts';
import { merge } from 'lodash';
import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { colorsArray } from '../../utils/colors';

export interface MiniLineChartProps {
  options?: Partial<ApexOptions>;
  height?: number;
  chart: TMiniLineChart;
}

const defaultOptions: ApexOptions = {
  chart: {
    type: 'line',
    sparkline: {
      enabled: true,
    },
  },
  colors: colorsArray,
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: { formatter: () => '' },
    },
    marker: {
      show: false,
    },
  },
};

export function MiniLineChart({
  options = {},
  chart: { data, categories },
  height = 50,
}: MiniLineChartProps) {
  const mergedOptions = useMemo(
    () =>
      merge(
        defaultOptions,
        categories
          ? {
              xaxis: {
                type: 'category',
                categories,
              },
              tooltip: {
                y: {
                  title: {
                    formatter: (
                      seriesName: string,
                      { dataPointIndex }: { dataPointIndex: number }
                    ) => categories[dataPointIndex],
                  },
                },
              },
            }
          : {},
        options
      ),
    [options, categories]
  );

  return (
    <ApexChart
      type="line"
      options={mergedOptions}
      series={[{ data }]}
      height={height}
    />
  );
}
