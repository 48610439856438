import { isEqual, pickBy } from 'lodash';

export function shallowDifference<T extends Record<string, unknown>>(
  left?: T,
  right?: T
): Partial<T> {
  return pickBy(
    right,
    (value, key) => !isEqual(left?.[key], value)
  ) as Partial<T>;
}
