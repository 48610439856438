import classNames from 'classnames';
import type { ReactNode } from 'react';

export interface SummaryTableItem {
  label: string | ReactNode;
  value: string | ReactNode;
}

export interface SummaryTableProps {
  title?: string;
  description?: string;
  items: SummaryTableItem[];
  className?: string;
}

export function SummaryTable({
  items,
  title,
  description,
  className = '',
}: SummaryTableProps) {
  const hasTitle = title || description;

  return (
    <div className={classNames({ [className]: className })}>
      {hasTitle && (
        <div>
          {title && typeof title === 'string' && (
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          )}
          {title && typeof title !== 'string' && title}

          {description && typeof description === 'string' && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {description}
            </p>
          )}
          {description && typeof description !== 'string' && description}
        </div>
      )}

      <div
        className={classNames('border-b last:border-b-0 border-gray-200', {
          'mt-5': hasTitle,
        })}
      >
        <dl className="sm:divide-y sm:divide-gray-200">
          {items.map(({ label, value }) => (
            <div
              key={`summary-${label}`}
              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
            >
              <dt className="text-sm font-medium text-gray-500">{label}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
