import { type LineChart as TLineChart } from '@pandler/shared/data-types';
import { type ApexOptions } from 'apexcharts';
import { merge } from 'lodash';
import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { colorsArray } from '../../utils/colors';

export interface LineChartProps {
  options?: Partial<ApexOptions>;
  height?: number;
  chart: TLineChart;
}

const defaultOptions: ApexOptions = {
  chart: {
    width: '100%',
    toolbar: {
      show: !1,
      autoSelected: 'zoom',
    },
  },
  colors: colorsArray,
  stroke: {
    curve: 'smooth',
    width: 2.5,
  },
  grid: {
    strokeDashArray: 3,
  },
  xaxis: {
    type: 'category',
    labels: {
      show: false,
      hideOverlappingLabels: true,
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
  },
};

export function LineChart({
  options = {},
  chart: { data, categories },
  height = 350,
}: LineChartProps) {
  const mergedOptions = useMemo(
    () => merge(defaultOptions, { xaxis: { categories } }, options),
    [categories, options]
  );

  return (
    <ApexChart
      type="line"
      options={mergedOptions}
      series={data as unknown as ApexOptions['series']}
      height={height}
    />
  );
}
