import { z } from 'zod';

import { v4 as uuidv4 } from 'uuid';
import { Email } from './email';

export const EmailLogItem = z.object({
  id: z.string().uuid().optional().default(uuidv4),
  sentAt: z
    .number()
    .optional()
    .default(() => new Date().getTime()),
  organizationId: z.string().uuid(),
  result: z.enum(['success', 'fail']),
  email: Email,
  subject: z.string(),
  recipient: z.string(),
});

export type EmailLogItem = z.infer<typeof EmailLogItem>;
