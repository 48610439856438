import { ChatIcon, MobileIcon, RadioListGroup } from '@pandler/shared/react-ui';
import { useEffect, useState } from 'react';

interface OnValidChangeProps {
  onValidChange: (isValid: boolean) => void;
}

export function CommunicationType({ onValidChange }: OnValidChangeProps) {
  const [selected, setSelected] = useState<string[]>(['email']);
  const [isValid, setIsValid] = useState(selected.length > 0);

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  return (
    <form className="pb-10 px-6">
      <h3 className="text-xl mb-1 text-gray-700 font-semibold">
        What types of communication would you like to send?
      </h3>
      <p className="text-sm text-gray-400">
        You can send both email and SMS reminders to your customers as invoices
        approach their due date.
      </p>

      <RadioListGroup
        className="mt-6 mb-4 lg:grid-cols-1"
        itemClassName="text-sm"
        items={[
          {
            icon: <ChatIcon />,
            title: 'Email',
            description: 'Delivered straight to your customers inbox.',
            value: 'email',
          },
          {
            icon: <MobileIcon />,
            title: 'SMS',
            description: 'Delivered to your customers mobile phone.',
            value: 'sms',
          },
        ]}
        selected={selected}
        onClick={({ value }) => {
          const newValue = selected.includes(value)
            ? selected.filter((v) => v !== value)
            : [...selected, value];
          setSelected(newValue);
          setIsValid(newValue.length > 0);
        }}
      />

      <p className="text-sm text-gray-400">
        You can select more than one option.
      </p>
    </form>
  );
}
