import { z } from 'zod';
import { Invoice } from './invoice';
import { Customer } from './customer';

const InvoiceWithCustomer = Invoice.merge(
  z.object({
    customer: Customer.optional(),
  })
);

export type InvoiceWithCustomer = z.infer<typeof InvoiceWithCustomer>;
