/**
 * {
 * 	"$patch": [
 * 		{
 * 			"op": "add",
 * 			"path": "/nodes/-",
 * 			"value": {
 * 				"type": "action",
 * 				"data": {
 * 					"type": "fetch-invoices",
 * 					"filter": {
 * 						"all": [
 * 							{
 * 								"fact": "invoice",
 * 								"path": "$.status",
 * 								"operator": "in",
 * 								"value": ["Submitted", "PartiallyPaid"]
 * 							},
 * 							{
 * 								"fact": "invoice",
 * 								"path": "$.dueInDays",
 * 								"operator": "in",
 * 								"value": [14, 7, 3, 1]
 * 							}
 * 						]
 * 					}
 * 				}
 * 			}
 * 		}
 * 	]
 * }
 */

import { z } from 'zod';
import { TopLevelJsonRule } from '../../definitions/json-rule';

export const FetchInvoicesAction = z.object({
  type: z.literal('fetch-invoices'),
  filter: TopLevelJsonRule,
});

export type FetchInvoicesAction = z.infer<typeof FetchInvoicesAction>;
