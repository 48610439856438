import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export const WorkflowEdge = z.object({
  id: z.string().uuid().default(uuidv4),
  source: z.string().uuid(),
  destination: z.string().uuid(),
  label: z.string().optional(),
});

export type WorkflowEdge = z.infer<typeof WorkflowEdge>;
