import { RichTextEditor, Select } from '@pandler/shared/react-ui';
import { useEffect, useState } from 'react';
import { type Descendant } from 'slate';

interface MessageContentProps {
  onValidChange: (isValid: boolean) => void;
}

const defaultValue: Record<'email' | 'sms', Descendant[]> = {
  email: [
    {
      type: 'paragraph',
      children: [{ text: '{{invoice.customer.name}}' }],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: 'This is a reminder that invoice #{{invoice.number}} is due in {{amountOfDays}} days.',
        },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: 'To avoid any further action, please pay the invoice by the due date',
        },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: 'Regards,',
        },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: '{{business.name}}',
        },
      ],
    },
  ],
  sms: [],
};

export function MessageContent({ onValidChange }: MessageContentProps) {
  const [isValid] = useState(true);
  const [value, setValue] = useState<Descendant[]>(defaultValue.email);
  const [active, setActive] = useState('');

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  return (
    <form className="w-full pb-14 px-6">
      <h3 className="text-xl mb-1 text-gray-700 font-semibold">
        Message content
      </h3>
      <p className="text-sm text-gray-400 mb-4">
        Select a reminder from the dropdown to update its content.
      </p>

      <Select
        className="mb-2"
        items={[{ label: 'Email - 7 Days Reminders', value: 'email-7' }]}
        includeEmpty
        value={active}
        onChange={(value) => setActive(value as string)}
      />

      <p className="text-sm text-gray-400 mb-5">
        Message content can be updated at a later date.
      </p>

      {active && (
        <>
          <h4 className="text-sm text-gray-400 mb-2 font-medium">
            Email Reminder - 7 Days
          </h4>

          <RichTextEditor
            className="max-h-40 overflow-y-auto"
            placeholder=""
            value={value}
            onValueChange={(value) => setValue(value)}
            toolBar={{ position: 'bottom' }}
          />
        </>
      )}
    </form>
  );
}
