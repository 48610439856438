// Compares two collections of objects to generate a change set of
// new, modified, deleted and unchanged items.

export type ChangeSetItemGeneric = Record<string, unknown>;

export interface ChangeSetItem<T = ChangeSetItemGeneric> {
  old: T | null;
  current: T | null;
  modified: Partial<T> | null;
}

export interface ChangeSet<T = ChangeSetItemGeneric> {
  new: ChangeSetItem<T>[];
  modified: ChangeSetItem<T>[];
  deleted: ChangeSetItem<T>[];
  unchanged: ChangeSetItem<T>[];
  hasChanges: boolean;
}

type ChangeSetPredicate<T> = (left: T, right: T) => boolean;

function changedAttributes<T>(
  left: Record<string, unknown>,
  right: Record<string, unknown>
): Partial<T> {
  return Object.keys(right)
    .filter((key) => left[key] !== right[key])
    .reduce((changed, key) => ({ ...changed, [key]: right[key] }), {});
}

function newItems<T>(left: T[], right: T[], predicate: ChangeSetPredicate<T>) {
  const items = right.filter(
    (rightItem) => !left.some((leftItem) => predicate(leftItem, rightItem))
  );

  return items.map((item) => ({ old: null, current: item, modified: item }));
}

function deletedItems<T>(
  left: T[],
  right: T[],
  predicate: ChangeSetPredicate<T>
) {
  const items = left.filter(
    (leftItem) => !right.some((rightItem) => predicate(leftItem, rightItem))
  );

  return items.map((item) => ({ old: item, current: null, modified: item }));
}

function unchangedItems<T>(
  left: T[],
  right: T[],
  predicate: ChangeSetPredicate<T>
) {
  return right
    .filter((rightItem) => {
      const old = left.find((leftItem) => predicate(leftItem, rightItem));
      return old && JSON.stringify(old) === JSON.stringify(rightItem);
    })
    .map((item) => ({ old: item, current: item, modified: null }));
}

function modifiedItems<T>(
  left: T[],
  right: T[],
  predicate: ChangeSetPredicate<T>
) {
  const modified = right.filter((rightItem) => {
    const old = left.find((leftItem) => predicate(leftItem, rightItem));
    if (old) {
      return JSON.stringify(old) !== JSON.stringify(rightItem);
    }

    return false;
  });

  return modified.map((item) => {
    const old = left.find((leftItem) => predicate(leftItem, item));
    const modified = changedAttributes<T>(
      old as Record<string, unknown>,
      item as Record<string, unknown>
    );

    return {
      old: old as T,
      current: item,
      modified,
    };
  });
}

export function createChangeSet<T = ChangeSetItemGeneric>(
  left: T[],
  right: T[],
  predicate: ChangeSetPredicate<T>
): ChangeSet<T> {
  const changeSet = {
    new: newItems<T>(left, right, predicate),
    modified: modifiedItems<T>(left, right, predicate),
    deleted: deletedItems<T>(left, right, predicate),
    unchanged: unchangedItems<T>(left, right, predicate),
  };

  const hasChanges =
    changeSet.new.length > 0 ||
    changeSet.modified.length > 0 ||
    changeSet.deleted.length > 0;

  return {
    ...changeSet,
    hasChanges,
  };
}
