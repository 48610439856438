import { PlayCircleIcon } from '@heroicons/react/24/outline';
import type { OnboardingResponse } from '@pandler/shared/data-types';
import { VerticalSteps, type Step } from '@pandler/shared/react-ui';
import { useMemo } from 'react';
import { DismissNotification } from '../dismiss-notification';

interface OnboardingPaneProps {
  data: OnboardingResponse;
}

const toInt = (value: boolean) => (value ? 1 : 0);

export function OnboardingPane({ data }: OnboardingPaneProps) {
  const steps = useMemo<Step[]>(() => {
    const steps = [
      {
        name: 'Enable online payments',
        description:
          'Improve the speed in which you get paid by enabling online payments. Get paid via Visa, Mastercard, Google Pay and Apple Pay.',
        link: '/payments',
        completed: data.hasVerifiedPayments,
      },
      {
        name: 'Add your branding',
        description: (
          <>
            Set up email delivery from your company's email address and get paid
            on your company domain (e.g.{' '}
            <span className="font-semibold">payments.api.vision</span>)
          </>
        ),
        link: '/settings',
        completed: data.hasCustomizedBranding,
      },
      {
        name: 'Create your first communication template',
        description:
          'Personalise email and sms communications that are sent to your customers.',
        link: '/templates',
        completed: data.hasCustomTemplates,
      },
    ]
      .sort((a, b) => toInt(b.completed) - toInt(a.completed))
      .map(({ completed, ...step }) => ({
        ...step,
        status: completed ? 'complete' : 'upcoming',
      })) as Step[];

    const currentStep = steps.findIndex((step) => step.status === 'upcoming');

    return steps.map((step, index) =>
      index === currentStep ? { ...step, status: 'current' } : step
    );
  }, [
    data.hasCustomTemplates,
    data.hasVerifiedPayments,
    data.hasCustomizedBranding,
  ]);

  return (
    <section className="border rounded-lg py-7 px-9 pb-12 shadow-md border-gray-50">
      <section className="flex items-center mb-6">
        <h2 className="text-xl font-bold text-gray-800 dark:text-neutral-200 sm:leading-9 sm:truncate flex-grow">
          Your quick start guide
        </h2>
        <div className="flex space-x-6">
          <a
            href="https://pandler.io/tutorials"
            className="text-primary-500 text-sm hover:underline hover:text-primary-600 transition-all duration-300 flex items-center pt-3"
          >
            <PlayCircleIcon className="h-5 mr-1.5" /> Watch tutorials
          </a>
          <DismissNotification id="quickStartNotification" />
        </div>
      </section>

      <VerticalSteps
        descriptionClassName="text-gray-600 dark:text-neutral-200 text-sm"
        steps={steps}
      />
    </section>
  );
}
