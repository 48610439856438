import { useAuth0 } from '@auth0/auth0-react';
import { type Template } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';

export interface UseTemplateOptions {
  id: string;
  queryOptions?: UseQueryOptions<Template, Error, Template>;
}

export default function useTemplate({
  id,
  queryOptions = {},
}: UseTemplateOptions) {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery<Template, Error>(
    ['template', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:templates',
      });

      const $response = await fetch(`${API_ENDPOINT}/templates/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      if ($response.status === 200) {
        const json = await $response.json();
        return json.data as Template;
      }

      throw Error('An unknown error occurred');
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );

  return query;
}
