import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import {
  Condition,
  DelayFor,
  DelayUntil,
  FetchInvoicesAction,
  SendEmailAction,
  SendSmsAction,
} from '../workflows';
import {
  CronTrigger,
  CustomerUpdatedTrigger,
  InvoiceUpdatedTrigger,
} from '../workflows/triggers';

const BaseNode = z.object({
  id: z.string().uuid().default(uuidv4),
});

const Actions = z.object({
  type: z.literal('action'),
  data: z.discriminatedUnion('type', [
    FetchInvoicesAction,
    SendEmailAction,
    SendSmsAction,
  ]),
});

const ConditionalSplit = z.object({
  type: z.literal('conditional-split'),
  data: Condition,
});
const Timings = z.object({
  type: z.literal('timing'),
  data: z.discriminatedUnion('type', [DelayFor, DelayUntil]),
});

const Triggers = z.object({
  type: z.literal('trigger'),
  data: z.discriminatedUnion('type', [
    InvoiceUpdatedTrigger,
    CronTrigger,
    CustomerUpdatedTrigger,
  ]),
});

export const WorkflowNode = z.discriminatedUnion('type', [
  BaseNode.merge(Actions),
  BaseNode.merge(ConditionalSplit),
  BaseNode.merge(Timings),
  BaseNode.merge(Triggers),
]);

export type WorkflowNode = z.infer<typeof WorkflowNode>;
