import { CircleSteps } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Integrations } from '../../stage/integrations';
import { Introduction } from '../../stage/introduction';
import { Organization } from '../../stage/organization';
import { PageWrapper } from '../page-wrapper';

interface WizardProps {
  stage: Stage;
}
type Stage = 'organization' | 'integrations' | 'introduction' | 'complete';

const steps = [
  { id: 'introduction', path: '' },
  { id: 'organization', path: 'organization' },
  { id: 'integrations', path: 'integrations' },
];

export function Wizard({ stage }: WizardProps) {
  const navigate = useNavigate();
  const currentStep = useMemo(
    () => steps.findIndex(({ id }) => id === stage),
    [stage]
  );

  const onSuccess = useCallback(() => {
    const next = steps.find((step, index) => index === currentStep + 1);
    navigate(`/onboarding/${next ? next.path : 'complete'}`);
  }, [currentStep, navigate]);

  const props = useMemo(() => ({ onSuccess }), [onSuccess]);

  return (
    <PageWrapper
      className={classNames({
        'sm:max-w-2xl xl:max-w-2xl xl:mt-10': stage === 'integrations',
      })}
    >
      {' '}
      <CircleSteps
        className="flex justify-center"
        steps={steps.length}
        current={currentStep}
        showSteps
      />
      <section className="mt-14 text-gray-600">
        {stage === 'introduction' && <Introduction {...props} />}
        {stage === 'organization' && <Organization {...props} />}
        {stage === 'integrations' && <Integrations {...props} />}
      </section>
    </PageWrapper>
  );
}
