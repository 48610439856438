import { useAuth0 } from '@auth0/auth0-react';
import { GetAccessTokenDefaults } from '../../config';
import { useCallback, useEffect, useState } from 'react';
import { invoicePdfUrlQueryFn } from './use-invoice-pdf-url';
import { error } from '@pandler/shared/react-ui';
import { downloadFile } from '../../utils';

export interface UseDownloadInvoicePdfResponse {
  url: string;
}

interface UseDownloadInvoicePdfOptions {
  invoiceId: string;
}

const useDownloadInvoicePdf = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState<string | undefined>();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (url && isLoading) {
      downloadFile({ url });
      setUrl(undefined);
      setIsLoading(false);
    }
  }, [url, setUrl, isLoading]);

  const download = useCallback(
    async ({ invoiceId }: UseDownloadInvoicePdfOptions) => {
      setIsLoading(true);
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:invoice',
      });

      const fetcher = invoicePdfUrlQueryFn({ token });
      try {
        const result = await fetcher(invoiceId);

        if (result.url) {
          setUrl(result.url);
        }
      } catch {
        setIsLoading(false);
        error({ message: 'Unable to download PDF' });
      }
    },
    [getAccessTokenSilently]
  );

  return { download, isLoading };
};

export default useDownloadInvoicePdf;
