import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader } from '@pandler/shared/react-ui';

export function IntegrationsPage() {
  return (
    <>
      <Helmet>
        <title>Integrations</title>
      </Helmet>

      <PageHeader
        title="Integrations"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Settings', href: '/settings' },
              {
                name: 'Integrations',
                href: '/settings/integrations',
                current: true,
              },
            ]}
          />
        }
      />

      <Content>
        <p>integrations</p>
      </Content>
    </>
  );
}

export default withAuthenticationRequired(IntegrationsPage);
