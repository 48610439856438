import { useState, useEffect } from 'react';
import {
  ALL_TIME_DATETIME,
  DateFilter,
  type OnChangeValue,
} from '../date-filter';
import { GenericSearchFilter } from '../generic-search-filter';

export interface Filter {
  dateValue?: OnChangeValue;
  searchValue: string;
}

interface FilterBarProps {
  onChange?: (value: Filter) => void;
  label?: string;
}

export function FilterBar({ onChange, label = 'Search...' }: FilterBarProps) {
  const [searchValue, setSearchValue] = useState('');
  const [dateValue, setDateValue] = useState<OnChangeValue>({
    type: 'all time',
    dateTime: ALL_TIME_DATETIME,
  });

  useEffect(() => {
    if (onChange) {
      onChange({ dateValue, searchValue });
    }
  }, [searchValue, onChange, dateValue]);

  return (
    <div className="inline-flex gap-2">
      <GenericSearchFilter label={label} onChange={setSearchValue} />
      <DateFilter onChange={setDateValue} />
    </div>
  );
}
