import * as React from 'react';

export function VisaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 200 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_503_1271)">
        <path
          d="M192 0H8C3.58172 0 0 3.58172 0 8V112C0 116.418 3.58172 120 8 120H192C196.418 120 200 116.418 200 112V8C200 3.58172 196.418 0 192 0Z"
          fill="#25459A"
        />
        <path
          d="M100.267 52.7878C100.192 58.3597 105.559 61.4692 109.602 63.3178C113.756 65.2148 115.151 66.4311 115.135 68.1272C115.103 70.7235 111.822 71.8691 108.75 71.9137C103.391 71.9918 100.275 70.5561 97.7979 69.47L95.8675 77.9468C98.3528 79.0218 102.955 79.9591 107.727 80C118.928 80 126.257 74.8112 126.297 66.7659C126.341 56.5557 111.247 55.9903 111.35 51.4264C111.386 50.0428 112.793 48.5661 115.876 48.1904C117.402 48.0007 121.616 47.8557 126.392 49.92L128.267 41.7184C125.699 40.8406 122.397 40 118.286 40C107.743 40 100.327 45.2594 100.267 52.7878ZM146.282 40.7067C144.237 40.7067 142.513 41.8263 141.744 43.5447L125.742 79.3974H136.936L139.163 73.621H152.842L154.134 79.3974H164L155.391 40.7067H146.282ZM147.848 51.1586L151.078 65.6872H142.231L147.848 51.1586ZM86.6955 40.7067L77.8722 79.3974H88.5386L97.3579 40.7067H86.6955ZM70.9159 40.7067L59.8135 67.0411L55.3226 44.6494C54.7954 42.1499 52.7144 40.7067 50.4036 40.7067H32.2537L32 41.83C35.7259 42.5888 39.9592 43.8125 42.5237 45.1218C44.0933 45.9215 44.5412 46.6208 45.0565 48.5215L53.5627 79.3974H64.8355L82.1174 40.7067H70.9159Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_503_1271">
          <rect width="200" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
