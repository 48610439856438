/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactElement, ReactNode } from 'react';
import React from 'react';

type AsProps<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = {
  as: T;
  children?: ReactNode;
} & (T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T]
  : T extends React.JSXElementConstructor<infer P>
  ? P
  : Record<string, never>);

export function As<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
>({ as: Component, children, ...rest }: AsProps<T>): ReactElement {
  return <Component {...(rest as any)}>{children}</Component>;
}
