import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { HomePage, ReturnPage } from './pages';
import { PublicIdLayout } from './layouts';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<PublicIdLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/return" element={<ReturnPage />} />
      </Route>
    </ReactRouterRoutes>
  );
}
