export function Branding() {
  return (
    <section className="text-center space-y-2">
      <section className="relative h-44 mb-12">
        <img
          src="https://i.imgur.com/82Tw3TO.png"
          className="rounded-lg shadow-sm absolute top-0 right-0 h-28"
          alt=""
        />
        <img
          src="https://i.imgur.com/fxx4j3T.png"
          className="rounded-lg shadow-sm absolute bottom-0 left-4"
          alt=""
        />
      </section>
      <h3 className="font-bold subpixel-antialiased text-gray-800 text-lg">
        Add your brand
      </h3>
      <p className="text-neutral-800 opacity-90 antialiased text-base leading-relaxed">
        Upload your logo and provide your brand details to create personalized
        and consistent communications. Ensure your brand's voice is heard in
        every interaction.
      </p>
    </section>
  );
}
