import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader } from '@pandler/shared/react-ui';
import { EmailTemplateTabs } from '../../components/email-template-tabs';

export function CreateEmailPage() {
  return (
    <>
      <Helmet>
        <title>Create a template</title>
      </Helmet>

      <>
        <PageHeader
          title="Create template"
          breadcrumbs={
            <Breadcrumbs
              items={[
                { name: 'Templates', href: '/templates' },
                {
                  name: 'Create a template',
                  href: '/templates/create/email',
                  current: true,
                },
              ]}
            />
          }
        />

        <Content>
          <EmailTemplateTabs />
        </Content>
      </>
    </>
  );
}

export default withAuthenticationRequired(CreateEmailPage);
