import {
  Button,
  LoadingIcon,
  success,
  useDeleteConfirmation,
} from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { queryClient } from '../../config';
import { useOrganization, useWipeAccount } from '../../hooks';

interface WipeButtonProps {
  className?: string;
  label?: string;
}

export function WipeButton({
  className = '',
  label = 'Wipe account',
}: WipeButtonProps) {
  const { id } = useOrganization();
  const { confirm } = useDeleteConfirmation();
  const { mutate, isLoading } = useWipeAccount();

  if (!id) {
    return null;
  }

  return (
    <Button
      disabled={isLoading}
      className={classNames('bg-red-600 disabled:bg-red-200 hover:bg-red-700', {
        [className]: className,
      })}
      onClick={() => {
        confirm({
          against: 'confirm',
          confirmButtonLabel: 'Confirm',
          placeholder: "Type 'confirm' to wipe your account",
          body: 'This will wipe all of your accounting data and integrations.',
          onDelete: async () => {
            await mutate(
              { id },
              {
                onSuccess: () => {
                  queryClient.removeQueries({ queryKey: ['invoices', id] });
                  success({
                    message:
                      'Your account data has successfully been queued for deletion.',
                  });
                },
              }
            );
          },
          type: 'error',
        });
      }}
    >
      {isLoading ? (
        <LoadingIcon className="w-5 h-5 text-white" strokeWidth={4} />
      ) : (
        label
      )}
    </Button>
  );
}
