import { GenericSearchFilter } from '@pandler/cloud/shared';
import type { Templates } from '@pandler/shared/templates';
import { useState } from 'react';
import { PlatformTemplatesSelection } from '../platform-templates-selection/platform-templates-selection';

export interface PlatformTemplatesSelectionFilterableProps {
  type: Templates[number]['type'];
  category?: Templates[number]['category'][number];
}

export function PlatformTemplatesSelectionFilterable({
  type,
  category,
}: PlatformTemplatesSelectionFilterableProps) {
  const [filter, setFilter] = useState<string>('');
  return (
    <>
      <div className="mb-4">
        <GenericSearchFilter label="Search templates..." onChange={setFilter} />
      </div>

      <PlatformTemplatesSelection
        category={category}
        type={type}
        filter={filter}
      />
    </>
  );
}
