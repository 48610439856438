import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface StatBox2Props {
  title: string;
  amount: string;
  className?: string;
  right?: ReactNode | ReactNode[];
}

export function StatBox2({
  amount,
  title,
  right,
  className = '',
}: StatBox2Props) {
  return (
    <div
      className={twMerge(
        'dark:bg-zinc-800 dark:border-zinc-600 shadow border border-gray-100/75 rounded-md',
        className
      )}
    >
      <div className="p-5">
        <div>
          <div className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-6">
              <span className="text-gray-700 dark:text-zinc-100 text-sm font-medium">
                {title}
              </span>
              <h4 className="my-4 text-xl text-gray-800 dark:text-gray-100 font-bold">
                {amount}
              </h4>
            </div>
            {right && <div className="col-span-6">{right}</div>}
          </div>
          <div className="flex items-center">
            <span className="text-xs py-[1px] px-1 bg-green-100 text-green-700 rounded font-medium dark:bg-green-500/30">
              +2.95%
            </span>
            <span className="ml-1.5 text-gray-700 text-13 dark:text-zinc-100 text-xs">
              Since last week
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
