import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid';
import { MessageQueueInbox } from '@pandler/cloud/messages';
import { SlideOutContext } from '@pandler/shared/react-ui';
import { useContext } from 'react';

export function MessagesAlert() {
  const { closePane, showPane } = useContext(SlideOutContext);

  const handleOnClick = () => {
    showPane(<MessageQueueInbox onClear={closePane} />, {
      onClose: closePane,
      innerClassName: 'w-screen max-w-3xl',
      childrenContainerClassName: 'pt-0',
      title: 'Reminders',
    });
  };

  return (
    <div
      className="bg-primary-800 border-transparent shadow shadow-violet-100 dark:shadow-zinc-800 relative overflow-hidden rounded-md h-[115px] flex gap-6 items-center justify-center px-8 cursor-pointer"
      onClick={handleOnClick}
    >
      <div className=" flex flex-col">
        <h4 className="text-white text-lg font-medium">Action required</h4>
        <p className="text-white/50 text-sm mt-1">
          You have invoice reminders that need approval before being sent.
        </p>
      </div>
      <div className="w-16 h-16 bg-primary-700 rounded-full flex items-center justify-center shrink-0 mr-2">
        <ChatBubbleLeftRightIcon className="w-8 h-8 text-primary-800 rotate-12" />
      </div>
    </div>
  );
}
