export default function isValidAcn(acn: string) {
  // Remove spaces and validate length
  const sanitizedACN = acn.replace(/\s+/g, '');
  if (sanitizedACN.length !== 9) {
    return false;
  }

  // Ensure it contains only digits
  if (!/^\d{9}$/.test(sanitizedACN)) {
    return false;
  }

  // Calculate the check digit using the reduce method
  const sum = sanitizedACN
    .slice(0, 8)
    .split('')
    .reduce((accumulator, currentValue, currentIndex) => {
      const digit = parseInt(currentValue, 10);
      const weight = 8 - currentIndex;
      return accumulator + digit * weight;
    }, 0);

  const remainder = sum % 10;
  const complement = (10 - remainder) % 10;

  // Compare the calculated check digit with the provided check digit
  return complement === parseInt(sanitizedACN[8] ?? '', 10);
}
