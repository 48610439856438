import React from "react";

type RadioListIconItemProps = {
  icon: string;
  label: string;
  title: string;
};

export function RadioListIconItem(props: RadioListIconItemProps) {
  const { icon, label, title } = props;

  return (
    <div className="flex items-center">
      <span className="mr-2.5 text-3xl" role="img" aria-label={title}>
        {icon}
      </span>

      {label}
    </div>
  );
}
