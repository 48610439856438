import { PrinterIcon } from '@heroicons/react/24/outline';
import { Button } from '@pandler/shared/react-ui';
import { DownloadButton } from '../download-button';
import type { Invoice } from '@pandler/shared/data-types';
import { SendReminderButton } from '../send-reminder-button';

interface InvoiceActionBarProps {
  invoice: Invoice;
}

export function InvoiceActionBar({ invoice }: InvoiceActionBarProps) {
  return (
    <div className="flex flex-row gap-2">
      {['Submitted', 'PartiallyPaid'].includes(invoice.status) && (
        <SendReminderButton invoice={invoice} label="Send invoice" />
      )}

      <Button onClick={window.print}>
        <PrinterIcon className="w-5 h-5 text-white" />
      </Button>

      <DownloadButton invoiceId={invoice.id} />
    </div>
  );
}
