import {
  BellIcon,
  ChatBubbleBottomCenterTextIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/solid';
import type { Notification } from '@pandler/shared/data-types';

export function iconFromEntity(entity?: Notification['entity']) {
  if (!entity) {
    return BellIcon;
  }

  switch (entity.type) {
    case 'message':
      return ChatBubbleBottomCenterTextIcon;
    case 'payment':
      return CurrencyDollarIcon;
    case 'invoice':
      return DocumentTextIcon;
  }

  return BellIcon;
}
