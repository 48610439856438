import type { VerifiedDomain } from '@pandler/shared/data-types';
import { Domain } from './domain';

interface DomainsListProps {
  items: VerifiedDomain[];
}

export function DomainsList({ items }: DomainsListProps) {
  return (
    <section className="text-sm space-y-2">
      <p className="text-gray-500 pl-3">
        Manage your custom domains for improved email deliverability.
      </p>

      <section className="space-y-2">
        {items.map((domain, index) => (
          <Domain key={domain.domain} domain={domain} number={index + 1} />
        ))}
      </section>
    </section>
  );
}
