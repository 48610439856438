import { Accordion } from '@pandler/shared/react-ui';
import { AddDomainVerificationForm } from '../add-domain-verification-form';
import { useState } from 'react';
import { DomainDetailsPaneDataFetcher } from '../domain-details-pane-data-fetcher';

export function DomainForm() {
  const [domain, setDomain] = useState<undefined | string>();

  if (domain) {
    return <DomainDetailsPaneDataFetcher id={domain} />;
  }

  return (
    <Accordion
      buttonClassName="bg-gray-50 hover:bg-gray-100 text-gray-800 font-medium"
      panelClassName="px-0"
      items={[
        {
          heading: 'Domain',
          body: <AddDomainVerificationForm onSuccess={setDomain} />,
        },
        // {
        //   heading: 'DNS Instructions',
        //   body: (
        //     <div className="space-y-4 text-gray-500">
        //       <p>
        //         Change your DNS records in your third-party domain provider
        //         account. On your domain provider's website, log in to your
        //         account. Find the DNS settings or domain management area.
        //       </p>
        //       <p className="font-bold">
        //         Point em1890 CNAME record to u31738946.wl197.sendgrid.net
        //       </p>
        //       <p>Point the CNAME record with the name www to lorem.ipsum.com</p>
        //       <pre className="border bg-gray-50 px-4 py-3 rounded-md">
        //         www CNAME lorem.ipsum.com
        //       </pre>
        //     </div>
        //   ),
        // },
      ]}
      defaultOpen
    />
  );
}
