import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import { HtmlPreviewElement } from '../html-preview-element';
import { moveStylesToBody } from '../../utils/move-styles-to-body';

export interface HtmlPreviewProps {
  htmlContent: string;
  cssContent?: string;
}

if (!customElements.get('html-preview')) {
  customElements.define('html-preview', HtmlPreviewElement);
}

export const HtmlPreview: React.FC<HtmlPreviewProps> = ({
  htmlContent,
  cssContent = '',
}) => {
  const previewRef = useRef<HtmlPreviewElement>(null);

  useEffect(() => {
    if (previewRef.current) {
      const htmlWithStylesInBody = moveStylesToBody(htmlContent);
      const sanitizedHTML = DOMPurify.sanitize(htmlWithStylesInBody, {
        ADD_TAGS: ['style'],
        ADD_ATTR: ['type', 'media'],
      });
      previewRef.current.setAttribute('content', sanitizedHTML);
      previewRef.current.setAttribute('css', cssContent);
    }
  }, [htmlContent, cssContent]);

  return <html-preview ref={previewRef}></html-preview>;
};
