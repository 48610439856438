import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { HomePage } from './pages/home';
import { ViewPage } from './pages/view';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/status/:status" element={<HomePage />} />
      <Route path="/view/:id" element={<ViewPage />} />
    </ReactRouterRoutes>
  );
}
