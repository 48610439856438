import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface LoadingEllipsisProps {
  amount?: number;
  character?: string;
  className?: string;
  pause?: number;
  interval?: number;
  pauseOffset?: number;
  itemClassName?: string;
}

export function LoadingEllipsis({
  amount = 3,
  character = '.',
  className = '',
  pauseOffset = 3,
  interval: intervalDuration = 200,
  itemClassName = '',
}: LoadingEllipsisProps) {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((current) =>
        current > amount + pauseOffset ? 0 : current + 1
      );
    }, intervalDuration);

    return () => clearInterval(interval);
  });

  return (
    <span className={className}>
      {new Array(amount).fill(character).map((character, index) => (
        <span
          key={`char-${index}`}
          className={twMerge(
            classNames('transition-all duration-300', {
              'opacity-30': index === active,
              'opacity-100': index !== active,
              'ease-in': index === 0,
              'ease-out': index === amount - 1,
              'ease-linear': index > 0 && index < amount - 1,
            }),
            itemClassName
          )}
        >
          {character}
        </span>
      ))}
    </span>
  );
}
