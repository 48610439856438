/**
 * {
 * 	"$patch": [
 * 		{
 * 			"op": "add",
 * 			"path": "/nodes/-",
 * 			"value": {
 * 				"type": "trigger",
 * 				"data": {
 * 					"type": "cron",
 * 					"minute": 0,
 * 					"hour": 10,
 * 					"dayOfMonth": "*",
 * 					"month": "*",
 * 					"dayOfWeek": "1-5"
 * 				}
 * 			}
 * 		}
 * 	]
 * }
 */

import { z } from 'zod';

export const CronTrigger = z.object({
  type: z.literal('cron'),
  minute: z
    .union([z.literal('*'), z.string(), z.number().min(0).max(59)])
    .default('*'),
  hour: z
    .union([z.literal('*'), z.string(), z.number().min(0).max(23)])
    .default('*'),
  dayOfMonth: z
    .union([z.literal('*'), z.string(), z.number().min(1).max(31)])
    .default('*'),
  month: z
    .union([z.literal('*'), z.string(), z.number().min(1).max(12)])
    .default('*'),
  dayOfWeek: z
    .union([z.literal('*'), z.string(), z.number().min(0).max(7)])
    .default('*'),
});

export type CronTrigger = z.infer<typeof CronTrigger>;
