import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const PaymentDef = {
  id: z.string().uuid().default(uuidv4),
  organizationId: z.string().uuid(),
  invoiceId: z.string().uuid(),
  invoiceNumber: z.string(),
  stripeId: z.string(),
  chargeId: z.string(),
  createdAt: z.number(),
  amount: z.number(),
  billing: z.object({
    email: z.string().email(),
    name: z.string(),
    phone: z.string().optional(),
  }),
  currency: z.string(),
  payment: z.object({
    type: z.string(),
    brand: z.string(),
  }),
  receipt: z.string().url(),
  status: z.enum(['succeeded', 'pending', 'failed']),
};

export const Payment = z.object(PaymentDef);
export type Payment = z.infer<typeof Payment>;

export const isPayment = (candidate: unknown): candidate is Payment =>
  Boolean(
    typeof candidate === 'object' &&
      candidate &&
      'organizationId' in candidate &&
      'invoiceId' in candidate &&
      'chargeId' in candidate
  );

export const friendlyPaymentStatus = (status: Payment['status']) => {
  switch (status) {
    case 'succeeded':
      return 'Paid';
    case 'pending':
      return 'Pending';
    case 'failed':
      return 'Failed';
    default:
      return status;
  }
};
