import { type ReactNode } from 'react';

export interface BuilderPaneProps {
  className?: string;
  title?: string;
  icon?: ReactNode;
}

export function BuilderPane({
  className = '',
  title = 'Workflow Designer',
  icon,
}: BuilderPaneProps) {
  return (
    <div className={className}>
      <div className="h-16 bg-slate-800 text-white/95 font-semibold items-center justify-center flex px-3">
        {icon && <div className="w-8 h-8">{icon}</div>}
        <div className={`grow justify-center flex ${icon ? 'pr-8' : ''}`}>
          {title}
        </div>
      </div>
      <div className="py-5 px-7">
        <p className="text-sm text-slate-500">
          Drag and drop components to build your flow.
        </p>
      </div>
    </div>
  );
}
