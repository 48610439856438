import wretch from 'wretch';
import { createCustomIssues } from 'react-zorm';
import { FormDefinition } from './form-definition';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { noop } from 'lodash';

export async function validateId(id: string) {
  const issues = createCustomIssues(FormDefinition);

  await wretch(`${API_ENDPOINT}/organization/public/${id}`)
    .head()
    .notFound(noop)
    .res(() => {
      issues.publicId(`The URL https://${id}.pandler.app is already in use.`);
    });

  return {
    issues: issues.toArray(),
  };
}
