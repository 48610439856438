import { Alert } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { WipeButton } from './wipe-button';

interface WipeAccountProps {
  title: string;
  className?: string;
}

export function WipeAccount({ title, className = '' }: WipeAccountProps) {
  return (
    <div className={classNames({ [className]: className })}>
      <h2 className="text-lg leading-6 font-semibold text-gray-900 mb-4">
        {title}
      </h2>

      <Alert
        type="danger"
        className="pt-5 px-3 pb-6 rounded-md items-center"
        title="Wipe account"
        titleClassName="text-red-800"
        description="This will wipe all of your accounting data and disable any active integrations."
        descriptionClassName="text-red-800"
        copyClassName="grow"
        hideIcon
      >
        <WipeButton className="mr-3" />
      </Alert>
    </div>
  );
}
