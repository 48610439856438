import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import type { VerifiedDomain } from '@pandler/shared/data-types';
import { Input, LoadingEllipsis, LoadingIcon } from '@pandler/shared/react-ui';

export interface DomainDetailsProps {
  domain: VerifiedDomain;
}

export function DomainDetails({ domain }: DomainDetailsProps) {
  const isVerified = domain.status === 'VERIFIED';

  return (
    <div className="space-y-3 pb-2">
      <Input disabled value={domain.domain} />
      <div className="flex flex-row space-x-2 items-center">
        {isVerified ? (
          <>
            <CheckBadgeIcon className="w-5 h-5 text-emerald-500" />
            <span className="font-semibold text-gray-800">
              DNS settings verified
            </span>
          </>
        ) : (
          <>
            <LoadingIcon className="w-4 h-4 text-gray-500" strokeWidth={3} />
            <span className="text-gray-400">
              Waiting for DNS verification
              <LoadingEllipsis />
            </span>
          </>
        )}
      </div>
    </div>
  );
}
