import { BuildingBlocksIcon, Button } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';

export function EmptyWorkflow() {
  return (
    <div className="flex flex-col space-y-6 justify-center text-center mt-8">
      <BuildingBlocksIcon className="h-60 mb-4" />
      <div>
        <h2 className="text-xl font-bold mb-1.5">This workflow is empty</h2>
        <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
          To begin, you need to configure a{' '}
          <span className="font-medium">trigger</span> to kick-start your
          automation.
        </p>
      </div>

      <div>
        <Button>Add trigger</Button>
        <Link
          to="/workflows"
          className="block text-sm text-primary-600 mt-1.5 hover:underline hover:text-primary-700"
        >
          Back
        </Link>
      </div>
    </div>
  );
}
