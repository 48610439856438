import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { Handle, Position, type NodeProps } from 'react-flow-renderer';

export function ExitNode({ isConnectable }: NodeProps) {
  return (
    <div className="">
      <Handle
        type="target"
        position={Position.Top}
        className="invisible"
        isConnectable={isConnectable}
      />
      <div className="px-4 py-1 rounded-full border border-gray-300/80 w-16 text-sm text-gray-400 uppercase font-medium text-center">
        Exit
      </div>

      <div className="mt-px w-16 text-sm text-gray-400   flex items-center justify-center">
        <PlusCircleIcon className="w-5 h-5" />
      </div>
    </div>
  );
}
