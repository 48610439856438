import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '@pandler/cloud/shared';
import { GetAccessTokenDefaults } from '@pandler/cloud/shared';
import { type Invoice } from '@pandler/shared/data-types';
import type { JsonSearchPagination } from '@pandler/shared/utils';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';

interface SearchResults {
  data: Invoice[];
  pagination: JsonSearchPagination;
}

export default function useSearchInvoice(
  options: {
    page?: number;
    perPage?: number;
    query?: Record<string, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter?: Record<string, any>; // @todo need to type advanced condition
  } = {}
) {
  const { page = 1, perPage = 25, query = {}, filter } = options;
  const queryString = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();
  const { isLoading, error, data, isPreviousData } = useQuery<
    SearchResults,
    Error
  >(
    ['invoices', organization?.id, options],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:invoice',
      });
      const $response = await fetch(
        `${API_ENDPOINT}/invoices/${organization?.id}?page=${page}&perPage=${perPage}&${queryString}`,
        {
          method: filter ? 'PUT' : 'GET',
          headers: { authorization: `Bearer ${token}` },
          ...(filter ? { body: JSON.stringify(filter) } : {}),
        }
      );

      const json = await $response.json();
      if (!Array.isArray(json.data)) {
        throw new Error('Unable to retrieve integrations');
      }

      return json as SearchResults;
    },
    { staleTime: Infinity, retry: false, keepPreviousData: true }
  );

  return { isLoading, error, data, isPreviousData };
}
