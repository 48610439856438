import { Button, SuccessIcon } from '@pandler/shared/react-ui';
import confetti from 'canvas-confetti';
import { useEffect } from 'react';

export function SuccessScreen() {
  useEffect(() => {
    confetti({
      particleCount: 200,
      spread: 120,
      gravity: 0.8,
      colors: [
        '#6366f1', // indigo-500
        '#4f46e5', // indigo-600
        '#4338ca', // indigo-700
        '#4ade80', // green-400
        '#f472b6', // pink-400
        '#fbbf24', // amber-400
      ],
      origin: {
        x: 0.6,
        y: 0.65,
      },
    });
  }, []);

  return (
    <div className="w-full flex-col items-center justify-center mb-2 text-center">
      <SuccessIcon className="h-40 mb-10 mx-auto" />

      <h3 className="font-bold text-xl text-gray-800 text-center mb-2">
        Your workflow has been created!
      </h3>

      <p className="md:w-2/3 mx-auto text-center text-gray-800 text-sm mb-8">
        You'll receive notifications after your first few executions so you can
        confirm everything is working correctly.
      </p>

      <Button>Close window</Button>
    </div>
  );
}
