import * as React from 'react';

export function CancelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586 659" {...props}>
      <circle cx={332.479} cy={254} r={254} fill="#f2f2f2" />
      <path
        fill="#cacaca"
        d="M498.464 113.588H33.17a1.807 1.807 0 0 1 0-3.613h465.293a1.807 1.807 0 0 1 0 3.613Z"
      />
      <rect
        width={163.611}
        height={34.986}
        x={193.774}
        y={174.473}
        fill="#fff"
        rx={17.493}
        ry={17.493}
      />
      <path
        fill="#fff"
        d="M128.175 244.445h294.81c9.662 0 17.494 7.832 17.494 17.494 0 9.66-7.832 17.493-17.494 17.493h-294.81c-9.661 0-17.493-7.832-17.493-17.493 0-9.662 7.832-17.494 17.493-17.494ZM128.175 314.418h294.81c9.662 0 17.494 7.832 17.494 17.493 0 9.662-7.832 17.493-17.494 17.493h-294.81c-9.661 0-17.493-7.831-17.493-17.493 0-9.66 7.832-17.493 17.493-17.493Z"
      />
      <path
        fill="#f2f2f2"
        d="m91.64 657.76-.693-.069a80.529 80.529 0 0 1-58.188-34.618 78.131 78.131 0 0 1-8.815-17.504l-.211-.59.624-.05c7.447-.6 15.097-1.863 18.495-2.464l-21.914-7.425-.136-.65a15.272 15.272 0 0 1 6.429-15.863 15.534 15.534 0 0 1 17.404-.294c2.386 1.524 4.881 3.033 7.295 4.494 8.293 5.016 16.868 10.203 23.298 17.3a48.675 48.675 0 0 1 11.63 40.256l4.783 17.476Z"
      />
      <path
        fill="#a0616a"
        d="m171.3 646.861 10.8-.001 5.14-41.662-15.943.001.003 41.662z"
      />
      <path
        fill="#2f2e41"
        d="m170.92 658.128 33.214-.001v-.42c0-7.14-5.789-12.927-12.928-12.928h-.001l-6.067-4.603-11.32 4.604h-2.9l.001 13.348Z"
      />
      <path
        fill="#a0616a"
        d="m84.741 616.945 8.64 6.481 29.112-30.241-12.752-9.566-25 33.326z"
      />
      <path
        fill="#2f2e41"
        d="m77.674 625.73 26.57 19.932.252-.336c4.284-5.712 3.128-13.815-2.583-18.1l-2.092-7.323-11.817-3.111-2.32-1.74-8.01 10.678ZM120.645 451.353s.596 16.264 1.348 29.307c.123 2.14-4.888 4.463-4.758 6.79.086 1.544 1.028 3.045 1.115 4.655.092 1.699-1.208 3.203-1.115 4.96.09 1.716 1.574 3.685 1.663 5.442.963 18.91 4.455 41.547.959 47.875-1.723 3.118-23.686 46.324-23.686 46.324s12.237 18.355 15.733 12.237c4.618-8.081 40.206-45.888 40.206-53.107 0-7.211 8.234-61.254 8.234-61.254l5.74 31.982 2.633 6.337-.827 3.72 1.701 5.02.092 4.968 1.656 9.226s-4.982 71.882-2.176 73.884c2.814 2.01 16.44 5.62 18.049 2.01 1.6-3.61 12.01-75.02 12.01-75.02s1.679-32.724 3.497-63.14c.105-1.766 1.346-3.899 1.442-5.638.114-2.02-.673-4.641-.568-6.6.114-2.246 1.11-3.828 1.206-5.978.743-16.615-3.427-36.849-4.756-38.842a265.865 265.865 0 0 0-7.622-10.82s-54.035-17.754-68.475.286l-3.301 25.376Z"
      />
      <path
        fill="#3f3d56"
        d="m174.538 284.104-21.421-4.284-9.997 13.566c-18.652 18.34-18.933 34.528-15.603 60.474v36.416l-2.41 24.411s-8.532 17.846.268 22 66.598 3.801 72.117 2.143.735-3.695-.714-11.425c-2.723-14.52-.902-7.516-.714-12.138 2.685-66.314-3.57-93.538-4.206-100.694l-10.894-19.759-6.426-10.71Z"
      />
      <path
        fill="#a0616a"
        d="M287.44 337.571a8.66 8.66 0 0 1-12.985 2.788l-46.26 29.373.133-15.992 44.939-26.079a8.707 8.707 0 0 1 14.172 9.91Z"
      />
      <path
        fill="#3f3d56"
        d="m157.625 302.624-5.267-.558a13.936 13.936 0 0 0-12.58 5.491 13.58 13.58 0 0 0-2.374 4.992l-.003.014a13.922 13.922 0 0 0 5.258 14.481l18.19 13.411c12.765 17.25 36.757 28.693 64.898 37.99l43.743-27.166-15.472-18.739-30.003 16.08-44.598-34.524-.026-.02-16.974-10.937-4.792-.515Z"
      />
      <circle cx={167.3} cy={248.605} r={24.98} fill="#a0616a" />
      <path
        fill="#2f2e41"
        d="M167.877 273.59c-.201.007-.403.012-.605.017-.086.224-.18.446-.287.664l.892-.68ZM174.732 249.298c.04.246.1.489.18.725a3.409 3.409 0 0 0-.18-.725Z"
      />
      <path
        fill="#2f2e41"
        d="M192.599 224.694c-1.029 3.193-1.946-.857-5.329-.128-4.069.876-8.803.575-12.136-1.92a18.801 18.801 0 0 0-17.223-2.624c-5.701 2.017-15.253 3.439-16.739 9.301-.516 2.038-.721 4.25-1.973 5.939-1.095 1.476-2.822 2.318-4.266 3.455-4.878 3.837-1.15 14.74 1.159 20.5 2.308 5.76 7.603 9.957 13.425 12.107 5.633 2.08 11.746 2.446 17.755 2.283 1.045-2.71.594-5.841-.269-8.651-.933-3.042-2.316-5.978-2.706-9.136s.467-6.746 3.115-8.51c2.434-1.622 6.395-.634 7.32 1.988-.54-3.278 2.781-6.45 6.206-7.009 3.676-.599 7.352.729 11.018 1.39s2.365-14.774.643-18.985Z"
      />
      <circle
        cx={281.358}
        cy={285.711}
        r={51.12}
        fill="#6c63ff"
        transform="rotate(-85.269 281.359 285.71)"
      />
      <path
        fill="#fff"
        d="m294.787 264.41-13.429 13.429-13.428-13.428a5.566 5.566 0 1 0-7.872 7.871l13.429 13.429-13.429 13.428a5.566 5.566 0 0 0 7.872 7.872l13.428-13.429 13.429 13.429a5.566 5.566 0 0 0 7.872-7.872L289.23 285.71l13.429-13.429a5.566 5.566 0 0 0-7.872-7.871Z"
      />
      <path
        fill="#a0616a"
        d="M261.214 242.744a8.66 8.66 0 0 1-6.977 11.3l-10.83 53.716-11.448-11.168 12.292-50.482a8.707 8.707 0 0 1 16.963-3.366Z"
      />
      <path
        fill="#3f3d56"
        d="m146.125 312.225-4.049 3.414a13.935 13.935 0 0 0-4.746 12.879 13.58 13.58 0 0 0 1.958 5.17l.008.012a13.923 13.923 0 0 0 14.09 6.23l22.27-3.841c21.285 2.73 46.152-6.66 72.344-20.53l10.68-50.373-24.234-1.808-9.168 32.782-55.788 8.282-.033.004-19.643 4.678-3.689 3.1Z"
      />
      <path
        fill="#cacaca"
        d="m272.937 658.99-271.75.308a1.19 1.19 0 0 1 0-2.382l271.75-.307a1.19 1.19 0 0 1 0 2.381Z"
      />
      <g fill="#3f3d56">
        <ellipse cx={56.777} cy={82.058} rx={8.457} ry={8.645} />
        <ellipse cx={85.991} cy={82.058} rx={8.457} ry={8.645} />
        <ellipse cx={115.204} cy={82.058} rx={8.457} ry={8.645} />
        <path d="M148.516 88.891c-.26 0-.52-.1-.715-.3l-5.706-5.834a1 1 0 0 1 0-1.398l5.706-5.833a1.001 1.001 0 0 1 1.43 1.399l-5.022 5.133 5.022 5.134a1 1 0 0 1-.715 1.7ZM158.104 88.891a1 1 0 0 1-.715-1.7l5.022-5.133-5.022-5.133a1 1 0 1 1 1.43-1.399l5.706 5.833a1 1 0 0 1 0 1.398l-5.706 5.833c-.196.2-.455.301-.715.301ZM456.614 74.414h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19v-10.62c0-1.21-.99-2.19-2.2-2.19ZM430.614 74.414h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19v-10.62c0-1.21-.99-2.19-2.2-2.19ZM481.114 74.914h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19v-10.62c0-1.21-.99-2.19-2.2-2.19ZM321.192 78.954h-84.81a2.67 2.67 0 1 0 0 5.34h84.81c1.47 0 2.67-1.2 2.67-2.67s-1.2-2.67-2.67-2.67Z" />
      </g>
    </svg>
  );
}
