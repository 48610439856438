import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { useOrganization } from '../organization';

export default function useMarkAllAsRead() {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationId } = useOrganization();

  return useMutation({
    mutationFn: async (): Promise<boolean> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:notifications',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/notifications?organization=${
          organizationId ?? ''
        }&status=UNREAD`,
        {
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: 'READ' }),
        }
      );

      const isSuccess = $response.status === 204;
      if (isSuccess) {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      }

      return isSuccess;
    },
  });
}
