import { type ReactNode } from 'react';
import type { CheckboxProps} from '../checkbox';
import { Checkbox } from '../checkbox';

export interface CheckboxGroupProps {
  id: string;
  checkbox: CheckboxProps;
  label: string | ReactNode;
  labelClass?: string;
}

export function CheckboxGroup({
  checkbox,
  label,
  labelClass = 'font-medium text-gray-700 cursor-pointer hover:text-gray-800 transition-colors duration-300',
  id,
}: CheckboxGroupProps) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <Checkbox id={id} {...checkbox} />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      </div>
    </div>
  );
}
