import { UserPlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { DismissNotification } from '../dismiss-notification';

export function InviteTeamMembers() {
  return (
    <section className="border rounded-lg py-5 px-6 pb-6 shadow-md border-gray-50 flex items-center">
      <section className="flex-grow">
        <h2 className="text-xl font-bold text-gray-800 sm:leading-9 sm:truncate flex-grow">
          Invite team members
        </h2>

        <p className="text-gray-600 text-sm">
          Invite users of your business to help manage your receivables.
        </p>
      </section>
      <div className="flex space-x-6">
        <Link
          to="/settings/users"
          className="text-primary-500 text-sm hover:underline hover:text-primary-600 transition-all duration-300 flex items-center pt-3"
        >
          <UserPlusIcon className="h-5 mr-1.5" /> Add team member
        </Link>

        <DismissNotification id="membersNotification" />
      </div>
    </section>
  );
}
