import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export const PaymentIntent = z.object({
  id: z.string().uuid().default(uuidv4),
  organizationId: z.string().uuid(),
  invoiceId: z.string().uuid(),
  intentId: z.string(),
  secret: z.string(),
  stripeId: z.string(),
  createdAt: z.number().default(() => new Date().getTime()),
  amount: z.number(),
  currency: z.string(),
});

export type PaymentIntent = z.infer<typeof PaymentIntent>;
