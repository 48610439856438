import type { Payment } from '@pandler/shared/data-types';
import { MastercardIcon, VisaIcon } from '@pandler/shared/react-ui';

interface PaymentMethodProps {
  type: Payment['payment']['brand'];
  className?: string;
}

function Icon({ type, className = '' }: PaymentMethodProps) {
  switch (type) {
    case 'mastercard':
      return <MastercardIcon className={className} />;
    case 'visa':
      return <VisaIcon className={className} />;
    default:
      return null;
  }
}

export function PaymentMethod({ type, className = 'h-8' }: PaymentMethodProps) {
  return <Icon type={type} className={className} />;
}
