import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader, Tabs } from '@pandler/shared/react-ui';
import { tabs } from '../../config';

export function UsersPage() {
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <PageHeader
        title="Users"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Settings', href: '/settings' },
              {
                name: 'Users',
                href: '/settings/users',
                current: true,
              },
            ]}
          />
        }
        right={
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Add user
          </button>
        }
      />

      <Content>
        <Tabs initialActive="users" tabs={tabs} contentClassName="mt-10" />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(UsersPage);
