import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import type { Templates } from '@pandler/shared/templates';

const usePlatformTemplate = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const query = useQuery<Templates[number], Error>(
    ['platform-templates', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:templates',
      });

      try {
        const data = await fetch(`${API_ENDPOINT}/platform-templates/${id}`, {
          headers: { authorization: `Bearer ${token}` },
        });

        return data.json();
      } catch {
        throw Error('Unable to get template');
      }
    },
    { staleTime: Infinity, retry: false }
  );

  return query;
};

export default usePlatformTemplate;
