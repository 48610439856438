export function flatFromArrayOfObjects<TArray>(
  arr: TArray[],
  keyFrom: keyof TArray,
  valueFrom: keyof TArray
) {
  return arr.reduce(
    (obj, record) =>
      record[keyFrom] && record[valueFrom]
        ? { ...obj, [record[keyFrom] as unknown as string]: record[valueFrom] }
        : obj,
    {}
  );
}
