import relativeTime from 'dayjs/plugin/relativeTime';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/solid';
import type { Activity } from '@pandler/shared/data-types';
import dayjs from 'dayjs';
import { Text } from '../text';

dayjs.extend(relativeTime);

export function Comment({
  avatar,
  author,
  date,
  body,
}: Activity & { type: 'Comment' }) {
  return (
    <>
      <div className="relative">
        {avatar && (
          <img
            className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
            src={avatar}
            alt={author ?? ''}
          />
        )}

        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
          <ChatBubbleLeftEllipsisIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm font-medium text-gray-900">{author}</div>
          <p className="mt-0.5 text-sm text-gray-500">
            Commented {dayjs().to(dayjs(date))}
          </p>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <Text value={body} />
        </div>
      </div>
    </>
  );
}
