import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '@pandler/cloud/shared';
import { GetAccessTokenDefaults } from '@pandler/cloud/shared';
import { type Payment } from '@pandler/shared/data-types';
import { API_ENDPOINT, type JsonSearchPagination } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';

// @todo any
export default function usePaymentSettings(
  options: Record<string, unknown> = {}
) {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();
  const { isLoading, error, data, isPreviousData } = useQuery<
    { data: Payment[]; pagination: JsonSearchPagination },
    Error
  >(
    ['payments', 'search', organization?.id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:payments',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/payments/transactions/${organization?.id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      if ($response.status === 200) {
        const json = await $response.json();
        return json;
      }

      throw Error('An unknown error occurred');
    },
    { staleTime: Infinity, retry: false, keepPreviousData: true, ...options }
  );

  return { isLoading, error, data, isPreviousData };
}
