import { z } from 'zod';

export const SendEmailAction = z.object({
  type: z.literal('send-email'),
  to: z.string().min(1),
  cc: z.string().min(1).optional(),
  bcc: z.string().min(1).optional(),
  subject: z.string().min(1),
  body: z.string().min(1),
  attachInvoicePdf: z.literal(true).optional(),
});

export type SendEmailAction = z.infer<typeof SendEmailAction>;
