import type { DashboardGetOverviewResponse } from '@pandler/shared/api-typings';
import { DonutChartWithLegend } from '@pandler/shared/charts-ui';
import { ChartContainer } from '@pandler/shared/react-ui';
import { InvoiceOrMessageSlot } from '../invoice-or-message-slot/invoice-or-message-slot';

interface InvoiceSummaryOverviewProps {
  data: DashboardGetOverviewResponse;
}

export function InvoiceSummaryOverview({ data }: InvoiceSummaryOverviewProps) {
  return (
    <>
      <ChartContainer title="Invoice Summary">
        <DonutChartWithLegend
          chart={data.charts.invoiceSummary}
          datePeriod="01 January 2022 to 31 December 2022"
          cta={{ url: '/invoices', label: 'View all invoices' }}
        />
      </ChartContainer>
      <InvoiceOrMessageSlot data={data} />
    </>
  );
}
