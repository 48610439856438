import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GetAccessTokenDefaults } from '../../config';
import { useOrganization } from '../organization';
import type { EmailLogSearchResponse } from '@pandler/shared/api-typings';

export interface UseSearchMessageLogsOptions {
  filter?: string;
  dateFrom?: string;
}

export default function useSearchMessageLogs({
  filter = '',
  dateFrom = '',
}: UseSearchMessageLogsOptions = {}) {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationId } = useOrganization();

  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    ...useQueryData
  } = useInfiniteQuery<EmailLogSearchResponse, Error>(
    ['message-logs', organizationId, { filter, dateFrom }],
    async ({ pageParam }: { pageParam?: string }) => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'send:email',
      });

      const $response = await fetch(
        `${API_ENDPOINT}/email/logs?organizationId=${organizationId}${
          pageParam ? `&from=${pageParam}` : ''
        }${filter ? `&query=${encodeURIComponent(filter)}` : ''}${
          dateFrom ? `&dateFrom=${encodeURIComponent(dateFrom)}` : ''
        }`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      if ($response.status === 200) {
        const json = await $response.json();
        return json;
      }

      throw Error('An unknown error occurred');
    },
    {
      getNextPageParam: (lastPage) => lastPage.pagination.next ?? false,
      staleTime: Infinity,
      retry: false,
    }
  );

  return {
    ...useQueryData,
    isLoading,
    error,
    feed: data,
    fetchNextPage,
    hasNextPage,
  };
}
