import { useAuth0 } from '@auth0/auth0-react';
import type { User } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import { userState } from '../../state';

export default function useCurrentUser() {
  const { getAccessTokenSilently } = useAuth0();
  const setUserState = useSetRecoilState(userState);

  const { isLoading, error, data, isRefetching } = useQuery<User, Error>(
    ['activeUser'],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:me',
      });
      const $response = await fetch(`${API_ENDPOINT}/user`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const { data } = await $response.json();
      if (!data.id) {
        throw new Error('Unable to retrieve user details.');
      }

      if (
        !Array.isArray(data.organizations) ||
        data.organizations.length === 0
      ) {
        throw new Error('No organization found for user');
      }

      return data;
    },
    { staleTime: Infinity, retry: false }
  );

  useEffect(() => {
    if (data) {
      setUserState(data as User);
    }
  }, [data, setUserState]);

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['activeUser'] });
  }, []);

  return {
    isLoading,
    error,
    user: data,
    userId: data?.id,
    invalidate,
    isRefetching,
  };
}
