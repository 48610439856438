import classNames from 'classnames';
import type { ComponentProps, ElementType } from 'react';
import { DefaultInputClassName } from '../input';
import { twMerge } from 'tailwind-merge';

export type IconInputProps = ComponentProps<'input'> & {
  icon: ElementType;
  hasError?: boolean;
  iconClassName?: string;
};
export function IconInput({
  className,
  hasError,
  icon: Icon,
  iconClassName = '',
  ...props
}: IconInputProps) {
  return (
    <div className="relative">
      <Icon
        className={twMerge(
          'w-5 h-5 text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3 z-10',
          iconClassName
        )}
      />
      <input
        className={twMerge(
          classNames(`${DefaultInputClassName} relative pl-9`, {
            'border-gray-200 bg-white text-gray-600': !hasError,
            'border-red-300 bg-white text-red-900': hasError,
          }),
          className
        )}
        {...props}
      />
    </div>
  );
}
