import { debounce } from 'lodash';
import { useState, useEffect } from 'react';

interface Size {
  width: number;
  height: number;
}

export function useWindowSize(): Size {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResizeDebounced = debounce(function handleResize() {
      setWindowSize(getSize());
    }, 250);

    window.addEventListener('resize', handleResizeDebounced);
    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, []);

  return windowSize;
}
