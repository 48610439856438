import type { EmailLogItem } from '@pandler/shared/data-types';
import type { InboxProps } from '@pandler/shared/inbox-ui';
import { Inbox } from '@pandler/shared/inbox-ui';
import { LogMessage } from '../log-message';
import { logItemToMessage } from '../../utils/log-item-to-message';
import { type Filter } from '@pandler/cloud/shared';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface LogsListProps {
  items: EmailLogItem[];
  onFilterChange?: (value: Filter) => void;
  filter?: ReactNode | ReactNode[];
  messageClassName?: string;
  mainClassName?: string;
  inboxProps?: Partial<InboxProps>;
}

export function LogsList({
  items,
  filter,
  mainClassName = '',
  messageClassName = '',
  inboxProps = {},
}: LogsListProps) {
  const messages = items.map(logItemToMessage);

  return (
    <Inbox
      messageClassName={twMerge(
        'py-5 px-3 rounded-t-none bg-slate-50 border-slate-200/80',
        messageClassName
      )}
      mainClassName={twMerge('p-8 pt-0 text-sm', mainClassName)}
      mainPaneBefore={filter}
      detailsPane={({ context, goBack }) =>
        context?.id ? (
          <LogMessage
            className="border-t border-slate-100"
            id={context.id}
            messages={messages}
            goBack={goBack}
          />
        ) : null
      }
      messages={messages}
      hideActionBar
      hideSelect
      {...inboxProps}
    />
  );
}
