import { useCurrentUser } from '@pandler/cloud/shared';
import { Logo } from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';
import { NavigationLink } from './navigation-link';
import type { NavigationItem } from './types';

export interface Sidebar {
  navigation: NavigationItem[];
}

export function Sidebar({ navigation }: Sidebar) {
  const { user } = useCurrentUser();

  return (
    <div className="flex-1 flex flex-col min-h-0 bg-primary-700 dark:bg-neutral-900">
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <Link to="/">
            <Logo className="h-10 text-white" textColor="#ffffff" />
          </Link>
        </div>
        <nav className="mt-5 flex flex-col px-2 space-y-1">
          {navigation.map((item) => (
            <NavigationLink key={item.name} {...item} />
          ))}
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-primary-800 dark:border-none p-4">
        {user && (
          <div className="flex-shrink-0 w-full group block">
            <div className="flex items-center">
              {user.avatar && (
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={user.avatar}
                    alt=""
                  />
                </div>
              )}
              <div className="ml-3 flex flex-col gap-1">
                <Link
                  to="/user/profile"
                  className="text-sm font-medium text-white"
                >
                  {user.firstName} {user.lastName}
                </Link>
                <Link
                  className="text-xs font-medium text-primary-200 group-hover:text-white"
                  to="/user/profile"
                >
                  View profile
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
