import { useAuth0 } from '@auth0/auth0-react';
import { type Organization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useMutation } from '@tanstack/react-query';
import { Result } from 'esresult';
import { GetAccessTokenDefaults, queryClient } from '../../config';
import useOrganization from './use-organization';

export default function useUpdateOrganization() {
  const { getAccessTokenSilently } = useAuth0();
  const { id: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async ({
      id = organizationId,
      data,
    }: {
      id?: string;
      data: Partial<Organization>;
    }): Result.Async<Organization, 'UNKNOWN'> => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:organization',
      });

      const $response = await fetch(`${API_ENDPOINT}/organization/${id}`, {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if ($response.status === 200) {
        const json = await $response.json();
        queryClient.invalidateQueries({ queryKey: ['organization', id] });

        return Result(json.data);
      }

      return Result.error('UNKNOWN');
    },
  });
}
