import { useAuth0 } from '@auth0/auth0-react';
import { GetAccessTokenDefaults } from '@pandler/cloud/shared';
import type { Customer} from '@pandler/shared/data-types';
import { type Invoice } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery } from '@tanstack/react-query';

interface UseGetInvoiceOptions {
  id: string;
}

const useGetInvoice = ({ id }: UseGetInvoiceOptions) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<{ invoice: Invoice; customer?: Customer }, Error>(
    ['invoice', id],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'read:invoice read:customer',
      });
      const $response = await fetch(`${API_ENDPOINT}/invoice/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const json = await $response.json();
      if (!json.data?.['customerId']) {
        throw new Error('Unable to retrieve invoice details');
      }

      const customer = await (
        await fetch(`${API_ENDPOINT}/customer/${json.data['customerId']}`, {
          headers: { authorization: `Bearer ${token}` },
        })
      ).json();

      return {
        invoice: json.data as Invoice,
        customer: (customer.data as Customer) ?? undefined,
      };
    },
    {
      staleTime: Infinity,
      retry: false,
      keepPreviousData: true,
      suspense: true,
    }
  );
};

export default useGetInvoice;
