import { useDeleteDomain } from '@pandler/cloud/shared';
import type { VerifiedDomain } from '@pandler/shared/data-types';
import { DeleteButton, error, success } from '@pandler/shared/react-ui';
import { useCallback } from 'react';

interface DeleteDomainProps {
  domain: VerifiedDomain;
}

export function DeleteDomain({ domain }: DeleteDomainProps) {
  const deleteDomain = useDeleteDomain();

  const handleOnDelete = useCallback(async () => {
    const result = await await deleteDomain.mutateAsync(domain.id);
    if (result) {
      success({ message: 'The domain has successfully been deleted.' });
    } else {
      error({ message: 'An error occurred deleting the domain.' });
    }
  }, [domain.id, deleteDomain]);

  return (
    <DeleteButton
      className="bg-transparent hover:bg-transparent text-red-600 text-left px-0 py-1 shadow-none"
      confirmationText={
        <>
          Are you sure you want to delete the selected domain? If so, type{' '}
          <span className="font-semibold">{domain.domain}</span> below.
        </>
      }
      against={domain.domain}
      onDelete={handleOnDelete}
    >
      Delete
    </DeleteButton>
  );
}
