import { useGetDomain } from '@pandler/cloud/shared';
import { InlineError, LoadingContainer } from '@pandler/shared/react-ui';
import { DomainDetailsPane } from '../domain-details-pane/domain-details-pane';

interface DomainDetailsPaneDataFetcherProps {
  id: string;
}

export function DomainDetailsPaneDataFetcher({
  id,
}: DomainDetailsPaneDataFetcherProps) {
  const { data, isLoading, error } = useGetDomain({ id });

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (error || !data) {
    return (
      <InlineError>{error?.message ?? 'An unknown error occurred'}</InlineError>
    );
  }

  return <DomainDetailsPane domain={data} />;
}
