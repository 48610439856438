import { z } from 'zod';
import { EmailContent } from './email-content';
import { EmailTarget } from './email-target';
import { EmailAttachment } from './email-attachment';

export const Email = z.object({
  subject: z.string().min(1),
  from: EmailTarget.optional(),
  to: z.union([EmailTarget, EmailTarget.array()]),
  cc: z.union([EmailTarget, EmailTarget.array()]).optional(),
  bcc: z.union([EmailTarget, EmailTarget.array()]).optional(),
  replyTo: z.union([EmailTarget, EmailTarget.array()]).optional(),
  content: z.union([EmailContent, EmailContent.array().min(1)]),
  data: z.any(),
  attachments: z.array(EmailAttachment).optional().default([]),
});

export type Email = z.infer<typeof Email>;
