import { type ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { LoadingContainer } from '@pandler/shared/react-ui';
import { RouteErrorBoundary } from '../../components/route-error-boundary';

interface PortalLayoutOptions {
  Header: () => ReactElement | null;
}

export function PortalLayout({ Header }: PortalLayoutOptions) {
  return (
    <div className="font-noto-sans">
      <div
        className={classNames(
          'flex flex-col flex-1 min-h-screen dark:bg-neutral-900 gap-y-4 lg:gap-y-8'
        )}
      >
        <Header />

        <div className="container mx-auto">
          <main className="flex-1 flex flex-col lg:w-4/5 px-4 lg:px-0 w-full mx-auto">
            <RouteErrorBoundary>
              <Suspense fallback={<LoadingContainer />}>
                <Outlet />
              </Suspense>
            </RouteErrorBoundary>
          </main>
        </div>
      </div>
    </div>
  );
}
